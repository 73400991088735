// cspell:disable
export type RegionIso3166 = {
  fullCode: string; // Full ISO 3166-2 code, e.g., "AO-HUI"
  regionCode: string; // State/Region code after the hyphen, e.g., "HUI"
  regionName: string; // Name of the state/region, e.g., "Huila"
};

export type CountryIso3166 = {
  countryCode: string; // ISO 3166-1 alpha-2 code, e.g., "AO"
  countryName: string; // Name of the country, e.g., "Angola"
  regions: RegionIso3166[]; // List of regions for the country
};

export type CountryMap = Map<string, CountryIso3166>;

// ISO 3166-2 Country and Region names and codes; From https://en.wikipedia.org/wiki/ISO_3166-2
export const ISO_3166_COUNTRY_DATA: Map<string, CountryIso3166> = new Map([
  [
    'united states of america',
    {
      countryCode: 'US',
      countryName: 'United States of America',
      regions: [
        {
          fullCode: 'US-AL',
          regionCode: 'AL',
          regionName: 'Alabama',
        },
        {
          fullCode: 'US-AK',
          regionCode: 'AK',
          regionName: 'Alaska',
        },
        {
          fullCode: 'US-AZ',
          regionCode: 'AZ',
          regionName: 'Arizona',
        },
        {
          fullCode: 'US-AR',
          regionCode: 'AR',
          regionName: 'Arkansas',
        },
        {
          fullCode: 'US-CA',
          regionCode: 'CA',
          regionName: 'California',
        },
        {
          fullCode: 'US-CO',
          regionCode: 'CO',
          regionName: 'Colorado',
        },
        {
          fullCode: 'US-CT',
          regionCode: 'CT',
          regionName: 'Connecticut',
        },
        {
          fullCode: 'US-DE',
          regionCode: 'DE',
          regionName: 'Delaware',
        },
        {
          fullCode: 'US-FL',
          regionCode: 'FL',
          regionName: 'Florida',
        },
        {
          fullCode: 'US-GA',
          regionCode: 'GA',
          regionName: 'Georgia',
        },
        {
          fullCode: 'US-HI',
          regionCode: 'HI',
          regionName: 'Hawaii',
        },
        {
          fullCode: 'US-ID',
          regionCode: 'ID',
          regionName: 'Idaho',
        },
        {
          fullCode: 'US-IL',
          regionCode: 'IL',
          regionName: 'Illinois',
        },
        {
          fullCode: 'US-IN',
          regionCode: 'IN',
          regionName: 'Indiana',
        },
        {
          fullCode: 'US-IA',
          regionCode: 'IA',
          regionName: 'Iowa',
        },
        {
          fullCode: 'US-KS',
          regionCode: 'KS',
          regionName: 'Kansas',
        },
        {
          fullCode: 'US-KY',
          regionCode: 'KY',
          regionName: 'Kentucky',
        },
        {
          fullCode: 'US-LA',
          regionCode: 'LA',
          regionName: 'Louisiana',
        },
        {
          fullCode: 'US-ME',
          regionCode: 'ME',
          regionName: 'Maine',
        },
        {
          fullCode: 'US-MD',
          regionCode: 'MD',
          regionName: 'Maryland',
        },
        {
          fullCode: 'US-MA',
          regionCode: 'MA',
          regionName: 'Massachusetts',
        },
        {
          fullCode: 'US-MI',
          regionCode: 'MI',
          regionName: 'Michigan',
        },
        {
          fullCode: 'US-MN',
          regionCode: 'MN',
          regionName: 'Minnesota',
        },
        {
          fullCode: 'US-MS',
          regionCode: 'MS',
          regionName: 'Mississippi',
        },
        {
          fullCode: 'US-MO',
          regionCode: 'MO',
          regionName: 'Missouri',
        },
        {
          fullCode: 'US-MT',
          regionCode: 'MT',
          regionName: 'Montana',
        },
        {
          fullCode: 'US-NE',
          regionCode: 'NE',
          regionName: 'Nebraska',
        },
        {
          fullCode: 'US-NV',
          regionCode: 'NV',
          regionName: 'Nevada',
        },
        {
          fullCode: 'US-NH',
          regionCode: 'NH',
          regionName: 'New Hampshire',
        },
        {
          fullCode: 'US-NJ',
          regionCode: 'NJ',
          regionName: 'New Jersey',
        },
        {
          fullCode: 'US-NM',
          regionCode: 'NM',
          regionName: 'New Mexico',
        },
        {
          fullCode: 'US-NY',
          regionCode: 'NY',
          regionName: 'New York',
        },
        {
          fullCode: 'US-NC',
          regionCode: 'NC',
          regionName: 'North Carolina',
        },
        {
          fullCode: 'US-ND',
          regionCode: 'ND',
          regionName: 'North Dakota',
        },
        {
          fullCode: 'US-OH',
          regionCode: 'OH',
          regionName: 'Ohio',
        },
        {
          fullCode: 'US-OK',
          regionCode: 'OK',
          regionName: 'Oklahoma',
        },
        {
          fullCode: 'US-OR',
          regionCode: 'OR',
          regionName: 'Oregon',
        },
        {
          fullCode: 'US-PA',
          regionCode: 'PA',
          regionName: 'Pennsylvania',
        },
        {
          fullCode: 'US-RI',
          regionCode: 'RI',
          regionName: 'Rhode Island',
        },
        {
          fullCode: 'US-SC',
          regionCode: 'SC',
          regionName: 'South Carolina',
        },
        {
          fullCode: 'US-SD',
          regionCode: 'SD',
          regionName: 'South Dakota',
        },
        {
          fullCode: 'US-TN',
          regionCode: 'TN',
          regionName: 'Tennessee',
        },
        {
          fullCode: 'US-TX',
          regionCode: 'TX',
          regionName: 'Texas',
        },
        {
          fullCode: 'US-UT',
          regionCode: 'UT',
          regionName: 'Utah',
        },
        {
          fullCode: 'US-VT',
          regionCode: 'VT',
          regionName: 'Vermont',
        },
        {
          fullCode: 'US-VA',
          regionCode: 'VA',
          regionName: 'Virginia',
        },
        {
          fullCode: 'US-WA',
          regionCode: 'WA',
          regionName: 'Washington',
        },
        {
          fullCode: 'US-WV',
          regionCode: 'WV',
          regionName: 'West Virginia',
        },
        {
          fullCode: 'US-WI',
          regionCode: 'WI',
          regionName: 'Wisconsin',
        },
        {
          fullCode: 'US-WY',
          regionCode: 'WY',
          regionName: 'Wyoming',
        },
        {
          fullCode: 'US-DC',
          regionCode: 'DC',
          regionName: 'District of Columbia',
        },
        {
          fullCode: 'US-AS',
          regionCode: 'AS',
          regionName: 'American Samoa',
        },
        {
          fullCode: 'US-GU',
          regionCode: 'GU',
          regionName: 'Guam',
        },
        {
          fullCode: 'US-MP',
          regionCode: 'MP',
          regionName: 'Northern Mariana Islands',
        },
        {
          fullCode: 'US-PR',
          regionCode: 'PR',
          regionName: 'Puerto Rico',
        },
        {
          fullCode: 'US-UM',
          regionCode: 'UM',
          regionName: 'United States Minor Outlying Islands',
        },
        {
          fullCode: 'US-VI',
          regionCode: 'VI',
          regionName: 'Virgin Islands, U.S.',
        },
      ],
    },
  ],
  [
    'canada',
    {
      countryCode: 'CA',
      countryName: 'Canada',
      regions: [
        {
          fullCode: 'CA-AB',
          regionCode: 'AB',
          regionName: 'Alberta',
        },
        {
          fullCode: 'CA-BC',
          regionCode: 'BC',
          regionName: 'British Columbia',
        },
        {
          fullCode: 'CA-MB',
          regionCode: 'MB',
          regionName: 'Manitoba',
        },
        {
          fullCode: 'CA-NB',
          regionCode: 'NB',
          regionName: 'New Brunswick',
        },
        {
          fullCode: 'CA-NL',
          regionCode: 'NL',
          regionName: 'Newfoundland and Labrador',
        },
        {
          fullCode: 'CA-NT',
          regionCode: 'NT',
          regionName: 'Northwest Territories',
        },
        {
          fullCode: 'CA-NS',
          regionCode: 'NS',
          regionName: 'Nova Scotia',
        },
        {
          fullCode: 'CA-NU',
          regionCode: 'NU',
          regionName: 'Nunavut',
        },
        {
          fullCode: 'CA-ON',
          regionCode: 'ON',
          regionName: 'Ontario',
        },
        {
          fullCode: 'CA-PE',
          regionCode: 'PE',
          regionName: 'Prince Edward Island',
        },
        {
          fullCode: 'CA-QC',
          regionCode: 'QC',
          regionName: 'Quebec',
        },
        {
          fullCode: 'CA-SK',
          regionCode: 'SK',
          regionName: 'Saskatchewan',
        },
        {
          fullCode: 'CA-YT',
          regionCode: 'YT',
          regionName: 'Yukon',
        },
      ],
    },
  ],
  [
    'afghanistan',
    {
      countryCode: 'AF',
      countryName: 'Afghanistan',
      regions: [
        {
          fullCode: 'AF-BDS',
          regionCode: 'BDS',
          regionName: 'Badakhshan',
        },
        {
          fullCode: 'AF-BDG',
          regionCode: 'BDG',
          regionName: 'Badghis',
        },
        {
          fullCode: 'AF-BGL',
          regionCode: 'BGL',
          regionName: 'Baghlan',
        },
        {
          fullCode: 'AF-BAL',
          regionCode: 'BAL',
          regionName: 'Balkh',
        },
        {
          fullCode: 'AF-BAM',
          regionCode: 'BAM',
          regionName: 'Bamyan',
        },
        {
          fullCode: 'AF-DAY',
          regionCode: 'DAY',
          regionName: 'Daykundi',
        },
        {
          fullCode: 'AF-FRA',
          regionCode: 'FRA',
          regionName: 'Farah',
        },
        {
          fullCode: 'AF-FYB',
          regionCode: 'FYB',
          regionName: 'Faryab',
        },
        {
          fullCode: 'AF-GHA',
          regionCode: 'GHA',
          regionName: 'Ghazni',
        },
        {
          fullCode: 'AF-GHO',
          regionCode: 'GHO',
          regionName: 'Ghōr',
        },
        {
          fullCode: 'AF-HEL',
          regionCode: 'HEL',
          regionName: 'Helmand',
        },
        {
          fullCode: 'AF-HER',
          regionCode: 'HER',
          regionName: 'Herat',
        },
        {
          fullCode: 'AF-JOW',
          regionCode: 'JOW',
          regionName: 'Jowzjan',
        },
        {
          fullCode: 'AF-KAB',
          regionCode: 'KAB',
          regionName: 'Kabul',
        },
        {
          fullCode: 'AF-KAN',
          regionCode: 'KAN',
          regionName: 'Kandahar',
        },
        {
          fullCode: 'AF-KAP',
          regionCode: 'KAP',
          regionName: 'Kapisa',
        },
        {
          fullCode: 'AF-KHO',
          regionCode: 'KHO',
          regionName: 'Khost',
        },
        {
          fullCode: 'AF-KNR',
          regionCode: 'KNR',
          regionName: 'Kunar',
        },
        {
          fullCode: 'AF-KDZ',
          regionCode: 'KDZ',
          regionName: 'Kunduz',
        },
        {
          fullCode: 'AF-LAG',
          regionCode: 'LAG',
          regionName: 'Laghman',
        },
        {
          fullCode: 'AF-LOG',
          regionCode: 'LOG',
          regionName: 'Logar',
        },
        {
          fullCode: 'AF-NAN',
          regionCode: 'NAN',
          regionName: 'Nangarhar',
        },
        {
          fullCode: 'AF-NIM',
          regionCode: 'NIM',
          regionName: 'Nimruz',
        },
        {
          fullCode: 'AF-NUR',
          regionCode: 'NUR',
          regionName: 'Nuristan',
        },
        {
          fullCode: 'AF-PKA',
          regionCode: 'PKA',
          regionName: 'Paktika',
        },
        {
          fullCode: 'AF-PIA',
          regionCode: 'PIA',
          regionName: 'Paktia',
        },
        {
          fullCode: 'AF-PAN',
          regionCode: 'PAN',
          regionName: 'Panjshir',
        },
        {
          fullCode: 'AF-PAR',
          regionCode: 'PAR',
          regionName: 'Parwan',
        },
        {
          fullCode: 'AF-SAM',
          regionCode: 'SAM',
          regionName: 'Samangan',
        },
        {
          fullCode: 'AF-SAR',
          regionCode: 'SAR',
          regionName: 'Sar-e Pol',
        },
        {
          fullCode: 'AF-TAK',
          regionCode: 'TAK',
          regionName: 'Takhar',
        },
        {
          fullCode: 'AF-URU',
          regionCode: 'URU',
          regionName: 'Urozgan',
        },
        {
          fullCode: 'AF-WAR',
          regionCode: 'WAR',
          regionName: 'Maidan Wardak',
        },
        {
          fullCode: 'AF-ZAB',
          regionCode: 'ZAB',
          regionName: 'Zabul',
        },
      ],
    },
  ],
  [
    'albania',
    {
      countryCode: 'AL',
      countryName: 'Albania',
      regions: [
        {
          fullCode: 'AL-01',
          regionCode: '01',
          regionName: 'Berat',
        },
        {
          fullCode: 'AL-09',
          regionCode: '09',
          regionName: 'Dibër',
        },
        {
          fullCode: 'AL-02',
          regionCode: '02',
          regionName: 'Durrës',
        },
        {
          fullCode: 'AL-03',
          regionCode: '03',
          regionName: 'Elbasan',
        },
        {
          fullCode: 'AL-04',
          regionCode: '04',
          regionName: 'Fier',
        },
        {
          fullCode: 'AL-05',
          regionCode: '05',
          regionName: 'Gjirokastër',
        },
        {
          fullCode: 'AL-06',
          regionCode: '06',
          regionName: 'Korçë',
        },
        {
          fullCode: 'AL-07',
          regionCode: '07',
          regionName: 'Kukës',
        },
        {
          fullCode: 'AL-08',
          regionCode: '08',
          regionName: 'Lezhë',
        },
        {
          fullCode: 'AL-10',
          regionCode: '10',
          regionName: 'Shkodër',
        },
        {
          fullCode: 'AL-11',
          regionCode: '11',
          regionName: 'Tiranë',
        },
        {
          fullCode: 'AL-12',
          regionCode: '12',
          regionName: 'Vlorë',
        },
      ],
    },
  ],
  [
    'algeria',
    {
      countryCode: 'DZ',
      countryName: 'Algeria',
      regions: [
        {
          fullCode: 'DZ-01',
          regionCode: '01',
          regionName: 'Adrar',
        },
        {
          fullCode: 'DZ-44',
          regionCode: '44',
          regionName: 'Aïn Defla',
        },
        {
          fullCode: 'DZ-46',
          regionCode: '46',
          regionName: 'Aïn Témouchent',
        },
        {
          fullCode: 'DZ-16',
          regionCode: '16',
          regionName: 'Alger',
        },
        {
          fullCode: 'DZ-23',
          regionCode: '23',
          regionName: 'Annaba',
        },
        {
          fullCode: 'DZ-05',
          regionCode: '05',
          regionName: 'Batna',
        },
        {
          fullCode: 'DZ-08',
          regionCode: '08',
          regionName: 'Béchar',
        },
        {
          fullCode: 'DZ-06',
          regionCode: '06',
          regionName: 'Béjaïa',
        },
        {
          fullCode: 'DZ-52',
          regionCode: '52',
          regionName: 'Béni Abbès',
        },
        {
          fullCode: 'DZ-07',
          regionCode: '07',
          regionName: 'Biskra',
        },
        {
          fullCode: 'DZ-09',
          regionCode: '09',
          regionName: 'Blida',
        },
        {
          fullCode: 'DZ-50',
          regionCode: '50',
          regionName: 'Bordj Badji Mokhtar',
        },
        {
          fullCode: 'DZ-34',
          regionCode: '34',
          regionName: 'Bordj Bou Arréridj',
        },
        {
          fullCode: 'DZ-10',
          regionCode: '10',
          regionName: 'Bouira',
        },
        {
          fullCode: 'DZ-35',
          regionCode: '35',
          regionName: 'Boumerdès',
        },
        {
          fullCode: 'DZ-02',
          regionCode: '02',
          regionName: 'Chlef',
        },
        {
          fullCode: 'DZ-25',
          regionCode: '25',
          regionName: 'Constantine',
        },
        {
          fullCode: 'DZ-56',
          regionCode: '56',
          regionName: 'Djanet',
        },
        {
          fullCode: 'DZ-17',
          regionCode: '17',
          regionName: 'Djelfa',
        },
        {
          fullCode: 'DZ-32',
          regionCode: '32',
          regionName: 'El Bayadh',
        },
        {
          fullCode: 'DZ-57',
          regionCode: '57',
          regionName: 'El Meghaier',
        },
        {
          fullCode: 'DZ-58',
          regionCode: '58',
          regionName: 'El Meniaa',
        },
        {
          fullCode: 'DZ-39',
          regionCode: '39',
          regionName: 'El Oued',
        },
        {
          fullCode: 'DZ-36',
          regionCode: '36',
          regionName: 'El Tarf',
        },
        {
          fullCode: 'DZ-47',
          regionCode: '47',
          regionName: 'Ghardaïa',
        },
        {
          fullCode: 'DZ-24',
          regionCode: '24',
          regionName: 'Guelma',
        },
        {
          fullCode: 'DZ-33',
          regionCode: '33',
          regionName: 'Illizi',
        },
        {
          fullCode: 'DZ-54',
          regionCode: '54',
          regionName: 'In Guezzam',
        },
        {
          fullCode: 'DZ-53',
          regionCode: '53',
          regionName: 'In Salah',
        },
        {
          fullCode: 'DZ-18',
          regionCode: '18',
          regionName: 'Jijel',
        },
        {
          fullCode: 'DZ-40',
          regionCode: '40',
          regionName: 'Khenchela',
        },
        {
          fullCode: 'DZ-03',
          regionCode: '03',
          regionName: 'Laghouat',
        },
        {
          fullCode: 'DZ-28',
          regionCode: '28',
          regionName: "M\\'sila",
        },
        {
          fullCode: 'DZ-29',
          regionCode: '29',
          regionName: 'Mascara',
        },
        {
          fullCode: 'DZ-26',
          regionCode: '26',
          regionName: 'Médéa',
        },
        {
          fullCode: 'DZ-43',
          regionCode: '43',
          regionName: 'Mila',
        },
        {
          fullCode: 'DZ-27',
          regionCode: '27',
          regionName: 'Mostaganem',
        },
        {
          fullCode: 'DZ-45',
          regionCode: '45',
          regionName: 'Naama',
        },
        {
          fullCode: 'DZ-31',
          regionCode: '31',
          regionName: 'Oran',
        },
        {
          fullCode: 'DZ-30',
          regionCode: '30',
          regionName: 'Ouargla',
        },
        {
          fullCode: 'DZ-51',
          regionCode: '51',
          regionName: 'Ouled Djellal',
        },
        {
          fullCode: 'DZ-04',
          regionCode: '04',
          regionName: 'Oum el Bouaghi',
        },
        {
          fullCode: 'DZ-48',
          regionCode: '48',
          regionName: 'Relizane',
        },
        {
          fullCode: 'DZ-20',
          regionCode: '20',
          regionName: 'Saïda',
        },
        {
          fullCode: 'DZ-19',
          regionCode: '19',
          regionName: 'Sétif',
        },
        {
          fullCode: 'DZ-22',
          regionCode: '22',
          regionName: 'Sidi Bel Abbès',
        },
        {
          fullCode: 'DZ-21',
          regionCode: '21',
          regionName: 'Skikda',
        },
        {
          fullCode: 'DZ-41',
          regionCode: '41',
          regionName: 'Souk Ahras',
        },
        {
          fullCode: 'DZ-11',
          regionCode: '11',
          regionName: 'Tamanrasset',
        },
        {
          fullCode: 'DZ-12',
          regionCode: '12',
          regionName: 'Tébessa',
        },
        {
          fullCode: 'DZ-14',
          regionCode: '14',
          regionName: 'Tiaret',
        },
        {
          fullCode: 'DZ-49',
          regionCode: '49',
          regionName: 'Timimoun',
        },
        {
          fullCode: 'DZ-37',
          regionCode: '37',
          regionName: 'Tindouf',
        },
        {
          fullCode: 'DZ-42',
          regionCode: '42',
          regionName: 'Tipaza',
        },
        {
          fullCode: 'DZ-38',
          regionCode: '38',
          regionName: 'Tissemsilt',
        },
        {
          fullCode: 'DZ-15',
          regionCode: '15',
          regionName: 'Tizi Ouzou',
        },
        {
          fullCode: 'DZ-13',
          regionCode: '13',
          regionName: 'Tlemcen',
        },
        {
          fullCode: 'DZ-55',
          regionCode: '55',
          regionName: 'Touggourt',
        },
      ],
    },
  ],
  [
    'andorra',
    {
      countryCode: 'AD',
      countryName: 'Andorra',
      regions: [
        {
          fullCode: 'AD-07',
          regionCode: '07',
          regionName: 'Andorra la Vella',
        },
        {
          fullCode: 'AD-02',
          regionCode: '02',
          regionName: 'Canillo',
        },
        {
          fullCode: 'AD-03',
          regionCode: '03',
          regionName: 'Encamp',
        },
        {
          fullCode: 'AD-08',
          regionCode: '08',
          regionName: 'Escaldes-Engordany',
        },
        {
          fullCode: 'AD-04',
          regionCode: '04',
          regionName: 'La Massana',
        },
        {
          fullCode: 'AD-05',
          regionCode: '05',
          regionName: 'Ordino',
        },
        {
          fullCode: 'AD-06',
          regionCode: '06',
          regionName: 'Sant Julià de Lòria',
        },
      ],
    },
  ],
  [
    'angola',
    {
      countryCode: 'AO',
      countryName: 'Angola',
      regions: [
        {
          fullCode: 'AO-BGO',
          regionCode: 'BGO',
          regionName: 'Bengo',
        },
        {
          fullCode: 'AO-BGU',
          regionCode: 'BGU',
          regionName: 'Benguela',
        },
        {
          fullCode: 'AO-BIE',
          regionCode: 'BIE',
          regionName: 'Bie',
        },
        {
          fullCode: 'AO-CAB',
          regionCode: 'CAB',
          regionName: 'Cabinda',
        },
        {
          fullCode: 'AO-CCU',
          regionCode: 'CCU',
          regionName: 'Cuando Cubango',
        },
        {
          fullCode: 'AO-CNO',
          regionCode: 'CNO',
          regionName: 'North Cuanza',
        },
        {
          fullCode: 'AO-CUS',
          regionCode: 'CUS',
          regionName: 'South Cuanza',
        },
        {
          fullCode: 'AO-CNN',
          regionCode: 'CNN',
          regionName: 'Cunene',
        },
        {
          fullCode: 'AO-HUA',
          regionCode: 'HUA',
          regionName: 'Huambo',
        },
        {
          fullCode: 'AO-HUI',
          regionCode: 'HUI',
          regionName: 'Huila',
        },
        {
          fullCode: 'AO-LUA',
          regionCode: 'LUA',
          regionName: 'Luanda',
        },
        {
          fullCode: 'AO-LNO',
          regionCode: 'LNO',
          regionName: 'North Lunda',
        },
        {
          fullCode: 'AO-LSU',
          regionCode: 'LSU',
          regionName: 'South Lunda',
        },
        {
          fullCode: 'AO-MAL',
          regionCode: 'MAL',
          regionName: 'Malanje',
        },
        {
          fullCode: 'AO-MOX',
          regionCode: 'MOX',
          regionName: 'Moxico',
        },
        {
          fullCode: 'AO-NAM',
          regionCode: 'NAM',
          regionName: 'Namibe',
        },
        {
          fullCode: 'AO-UIG',
          regionCode: 'UIG',
          regionName: 'Uige',
        },
        {
          fullCode: 'AO-ZAI',
          regionCode: 'ZAI',
          regionName: 'Zaire',
        },
      ],
    },
  ],
  [
    'antigua and barbuda',
    {
      countryCode: 'AG',
      countryName: 'Antigua and Barbuda',
      regions: [
        {
          fullCode: 'AG-03',
          regionCode: '03',
          regionName: 'Saint George',
        },
        {
          fullCode: 'AG-04',
          regionCode: '04',
          regionName: 'Saint John',
        },
        {
          fullCode: 'AG-05',
          regionCode: '05',
          regionName: 'Saint Mary',
        },
        {
          fullCode: 'AG-06',
          regionCode: '06',
          regionName: 'Saint Paul',
        },
        {
          fullCode: 'AG-07',
          regionCode: '07',
          regionName: 'Saint Peter',
        },
        {
          fullCode: 'AG-08',
          regionCode: '08',
          regionName: 'Saint Philip',
        },
        {
          fullCode: 'AG-10',
          regionCode: '10',
          regionName: 'Barbuda',
        },
        {
          fullCode: 'AG-11',
          regionCode: '11',
          regionName: 'Redonda',
        },
      ],
    },
  ],
  [
    'argentina',
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      regions: [
        {
          fullCode: 'AR-B',
          regionCode: 'B',
          regionName: 'Buenos Aires',
        },
        {
          fullCode: 'AR-K',
          regionCode: 'K',
          regionName: 'Catamarca',
        },
        {
          fullCode: 'AR-H',
          regionCode: 'H',
          regionName: 'Chaco',
        },
        {
          fullCode: 'AR-U',
          regionCode: 'U',
          regionName: 'Chubut',
        },
        {
          fullCode: 'AR-C',
          regionCode: 'C',
          regionName: 'Ciudad Autónoma de Buenos Aires',
        },
        {
          fullCode: 'AR-X',
          regionCode: 'X',
          regionName: 'Córdoba',
        },
        {
          fullCode: 'AR-W',
          regionCode: 'W',
          regionName: 'Corrientes',
        },
        {
          fullCode: 'AR-E',
          regionCode: 'E',
          regionName: 'Entre Ríos',
        },
        {
          fullCode: 'AR-P',
          regionCode: 'P',
          regionName: 'Formosa',
        },
        {
          fullCode: 'AR-Y',
          regionCode: 'Y',
          regionName: 'Jujuy',
        },
        {
          fullCode: 'AR-L',
          regionCode: 'L',
          regionName: 'La Pampa',
        },
        {
          fullCode: 'AR-F',
          regionCode: 'F',
          regionName: 'La Rioja',
        },
        {
          fullCode: 'AR-M',
          regionCode: 'M',
          regionName: 'Mendoza',
        },
        {
          fullCode: 'AR-N',
          regionCode: 'N',
          regionName: 'Misiones',
        },
        {
          fullCode: 'AR-Q',
          regionCode: 'Q',
          regionName: 'Neuquén',
        },
        {
          fullCode: 'AR-R',
          regionCode: 'R',
          regionName: 'Río Negro',
        },
        {
          fullCode: 'AR-A',
          regionCode: 'A',
          regionName: 'Salta',
        },
        {
          fullCode: 'AR-J',
          regionCode: 'J',
          regionName: 'San Juan',
        },
        {
          fullCode: 'AR-D',
          regionCode: 'D',
          regionName: 'San Luis',
        },
        {
          fullCode: 'AR-Z',
          regionCode: 'Z',
          regionName: 'Santa Cruz',
        },
        {
          fullCode: 'AR-S',
          regionCode: 'S',
          regionName: 'Santa Fe',
        },
        {
          fullCode: 'AR-G',
          regionCode: 'G',
          regionName: 'Santiago del Estero',
        },
        {
          fullCode: 'AR-V',
          regionCode: 'V',
          regionName: 'Tierra del Fuego',
        },
        {
          fullCode: 'AR-T',
          regionCode: 'T',
          regionName: 'Tucumán',
        },
      ],
    },
  ],
  [
    'armenia',
    {
      countryCode: 'AM',
      countryName: 'Armenia',
      regions: [
        {
          fullCode: 'AM-AG',
          regionCode: 'AG',
          regionName: 'Aragac̣otn',
        },
        {
          fullCode: 'AM-AR',
          regionCode: 'AR',
          regionName: 'Ararat',
        },
        {
          fullCode: 'AM-AV',
          regionCode: 'AV',
          regionName: 'Armavir',
        },
        {
          fullCode: 'AM-ER',
          regionCode: 'ER',
          regionName: 'Erevan',
        },
        {
          fullCode: 'AM-GR',
          regionCode: 'GR',
          regionName: "Geġark\\'unik\\'",
        },
        {
          fullCode: 'AM-KT',
          regionCode: 'KT',
          regionName: "Kotayk\\'",
        },
        {
          fullCode: 'AM-LO',
          regionCode: 'LO',
          regionName: 'Loṙi',
        },
        {
          fullCode: 'AM-SH',
          regionCode: 'SH',
          regionName: 'Širak',
        },
        {
          fullCode: 'AM-SU',
          regionCode: 'SU',
          regionName: "Syunik\\'",
        },
        {
          fullCode: 'AM-TV',
          regionCode: 'TV',
          regionName: 'Tavuš',
        },
        {
          fullCode: 'AM-VD',
          regionCode: 'VD',
          regionName: 'Vayoć Jor',
        },
      ],
    },
  ],
  [
    'australia',
    {
      countryCode: 'AU',
      countryName: 'Australia',
      regions: [
        {
          fullCode: 'AU-NSW',
          regionCode: 'NSW',
          regionName: 'New South Wales',
        },
        {
          fullCode: 'AU-QLD',
          regionCode: 'QLD',
          regionName: 'Queensland',
        },
        {
          fullCode: 'AU-SA',
          regionCode: 'SA',
          regionName: 'South Australia',
        },
        {
          fullCode: 'AU-TAS',
          regionCode: 'TAS',
          regionName: 'Tasmania',
        },
        {
          fullCode: 'AU-VIC',
          regionCode: 'VIC',
          regionName: 'Victoria',
        },
        {
          fullCode: 'AU-WA',
          regionCode: 'WA',
          regionName: 'Western Australia',
        },
        {
          fullCode: 'AU-ACT',
          regionCode: 'ACT',
          regionName: 'Australian Capital Territory',
        },
        {
          fullCode: 'AU-NT',
          regionCode: 'NT',
          regionName: 'Northern Territory',
        },
      ],
    },
  ],
  [
    'austria',
    {
      countryCode: 'AT',
      countryName: 'Austria',
      regions: [
        {
          fullCode: 'AT-1',
          regionCode: '1',
          regionName: 'Burgenland',
        },
        {
          fullCode: 'AT-2',
          regionCode: '2',
          regionName: 'Carinthia',
        },
        {
          fullCode: 'AT-3',
          regionCode: '3',
          regionName: 'Lower Austria',
        },
        {
          fullCode: 'AT-4',
          regionCode: '4',
          regionName: 'Upper Austria',
        },
        {
          fullCode: 'AT-5',
          regionCode: '5',
          regionName: 'Salzburg',
        },
        {
          fullCode: 'AT-6',
          regionCode: '6',
          regionName: 'Styria',
        },
        {
          fullCode: 'AT-7',
          regionCode: '7',
          regionName: 'Tyrol',
        },
        {
          fullCode: 'AT-8',
          regionCode: '8',
          regionName: 'Vorarlberg',
        },
        {
          fullCode: 'AT-9',
          regionCode: '9',
          regionName: 'Vienna',
        },
      ],
    },
  ],
  [
    'azerbaijan',
    {
      countryCode: 'AZ',
      countryName: 'Azerbaijan',
      regions: [
        {
          fullCode: 'AZ-NX',
          regionCode: 'NX',
          regionName: 'Nakhchivan',
        },
        {
          fullCode: 'AZ-ABS',
          regionCode: 'ABS',
          regionName: 'Absheron',
        },
        {
          fullCode: 'AZ-AGC',
          regionCode: 'AGC',
          regionName: 'Aghjabadi',
        },
        {
          fullCode: 'AZ-AGM',
          regionCode: 'AGM',
          regionName: 'Aghdam',
        },
        {
          fullCode: 'AZ-AGS',
          regionCode: 'AGS',
          regionName: 'Agdash',
        },
        {
          fullCode: 'AZ-AGA',
          regionCode: 'AGA',
          regionName: 'Aghstafa',
        },
        {
          fullCode: 'AZ-AGU',
          regionCode: 'AGU',
          regionName: 'Aghsu',
        },
        {
          fullCode: 'AZ-AST',
          regionCode: 'AST',
          regionName: 'Astara',
        },
        {
          fullCode: 'AZ-BAB',
          regionCode: 'BAB',
          regionName: 'Babek',
        },
        {
          fullCode: 'AZ-BA',
          regionCode: 'BA',
          regionName: 'Baku',
        },
        {
          fullCode: 'AZ-BAL',
          regionCode: 'BAL',
          regionName: 'Balakan',
        },
        {
          fullCode: 'AZ-BAR',
          regionCode: 'BAR',
          regionName: 'Barda',
        },
        {
          fullCode: 'AZ-BEY',
          regionCode: 'BEY',
          regionName: 'Beylagan',
        },
        {
          fullCode: 'AZ-BIL',
          regionCode: 'BIL',
          regionName: 'Bilasuvar',
        },
        {
          fullCode: 'AZ-CAB',
          regionCode: 'CAB',
          regionName: 'Jabrayil',
        },
        {
          fullCode: 'AZ-CAL',
          regionCode: 'CAL',
          regionName: 'Jalilabad',
        },
        {
          fullCode: 'AZ-CUL',
          regionCode: 'CUL',
          regionName: 'Julfa',
        },
        {
          fullCode: 'AZ-DAS',
          regionCode: 'DAS',
          regionName: 'Dashkasan',
        },
        {
          fullCode: 'AZ-FUZ',
          regionCode: 'FUZ',
          regionName: 'Fuzuli',
        },
        {
          fullCode: 'AZ-GAD',
          regionCode: 'GAD',
          regionName: 'Gadabay',
        },
        {
          fullCode: 'AZ-GA',
          regionCode: 'GA',
          regionName: 'Ganja',
        },
        {
          fullCode: 'AZ-GOR',
          regionCode: 'GOR',
          regionName: 'Goranboy',
        },
        {
          fullCode: 'AZ-GOY',
          regionCode: 'GOY',
          regionName: 'Goychay',
        },
        {
          fullCode: 'AZ-GYG',
          regionCode: 'GYG',
          regionName: 'Goygol',
        },
        {
          fullCode: 'AZ-HAC',
          regionCode: 'HAC',
          regionName: 'Hajigabul',
        },
        {
          fullCode: 'AZ-IMI',
          regionCode: 'IMI',
          regionName: 'Imishli',
        },
        {
          fullCode: 'AZ-ISM',
          regionCode: 'ISM',
          regionName: 'Ismayilli',
        },
        {
          fullCode: 'AZ-KAL',
          regionCode: 'KAL',
          regionName: 'Kalbajar',
        },
        {
          fullCode: 'AZ-KAN',
          regionCode: 'KAN',
          regionName: 'Kangarli',
        },
        {
          fullCode: 'AZ-KUR',
          regionCode: 'KUR',
          regionName: 'Kurdamir',
        },
        {
          fullCode: 'AZ-LAC',
          regionCode: 'LAC',
          regionName: 'Lachin',
        },
        {
          fullCode: 'AZ-LA',
          regionCode: 'LA',
          regionName: 'Lankaran City',
        },
        {
          fullCode: 'AZ-LAN',
          regionCode: 'LAN',
          regionName: 'Lankaran',
        },
        {
          fullCode: 'AZ-LER',
          regionCode: 'LER',
          regionName: 'Lerik',
        },
        {
          fullCode: 'AZ-MAS',
          regionCode: 'MAS',
          regionName: 'Masally',
        },
        {
          fullCode: 'AZ-MI',
          regionCode: 'MI',
          regionName: 'Mingachevir',
        },
        {
          fullCode: 'AZ-NA',
          regionCode: 'NA',
          regionName: 'Naftalan',
        },
        {
          fullCode: 'AZ-NV',
          regionCode: 'NV',
          regionName: 'Nakhchivan City',
        },
        {
          fullCode: 'AZ-NEF',
          regionCode: 'NEF',
          regionName: 'Neftchala',
        },
        {
          fullCode: 'AZ-OGU',
          regionCode: 'OGU',
          regionName: 'Oghuz',
        },
        {
          fullCode: 'AZ-ORD',
          regionCode: 'ORD',
          regionName: 'Ordubad',
        },
        {
          fullCode: 'AZ-QAX',
          regionCode: 'QAX',
          regionName: 'Gakh',
        },
        {
          fullCode: 'AZ-QAZ',
          regionCode: 'QAZ',
          regionName: 'Gazakh',
        },
        {
          fullCode: 'AZ-QAB',
          regionCode: 'QAB',
          regionName: 'Gabala',
        },
        {
          fullCode: 'AZ-QOB',
          regionCode: 'QOB',
          regionName: 'Gobustan',
        },
        {
          fullCode: 'AZ-QBA',
          regionCode: 'QBA',
          regionName: 'Guba',
        },
        {
          fullCode: 'AZ-QBI',
          regionCode: 'QBI',
          regionName: 'Gubadlı',
        },
        {
          fullCode: 'AZ-QUS',
          regionCode: 'QUS',
          regionName: 'Gusar',
        },
        {
          fullCode: 'AZ-SAT',
          regionCode: 'SAT',
          regionName: 'Saatly',
        },
        {
          fullCode: 'AZ-SAB',
          regionCode: 'SAB',
          regionName: 'Sabirabad',
        },
        {
          fullCode: 'AZ-SBN',
          regionCode: 'SBN',
          regionName: 'Shabran',
        },
        {
          fullCode: 'AZ-SAH',
          regionCode: 'SAH',
          regionName: 'Shahbuz',
        },
        {
          fullCode: 'AZ-SAL',
          regionCode: 'SAL',
          regionName: 'Salyan',
        },
        {
          fullCode: 'AZ-SMI',
          regionCode: 'SMI',
          regionName: 'Shamakhi',
        },
        {
          fullCode: 'AZ-SMX',
          regionCode: 'SMX',
          regionName: 'Samukh',
        },
        {
          fullCode: 'AZ-SAD',
          regionCode: 'SAD',
          regionName: 'Sadarak',
        },
        {
          fullCode: 'AZ-SA',
          regionCode: 'SA',
          regionName: 'Shaki City',
        },
        {
          fullCode: 'AZ-SAK',
          regionCode: 'SAK',
          regionName: 'Shaki',
        },
        {
          fullCode: 'AZ-SKR',
          regionCode: 'SKR',
          regionName: 'Shamkir',
        },
        {
          fullCode: 'AZ-SAR',
          regionCode: 'SAR',
          regionName: 'Sharur',
        },
        {
          fullCode: 'AZ-SR',
          regionCode: 'SR',
          regionName: 'Shirvan',
        },
        {
          fullCode: 'AZ-SIY',
          regionCode: 'SIY',
          regionName: 'Siyazan',
        },
        {
          fullCode: 'AZ-SM',
          regionCode: 'SM',
          regionName: 'Sumgayit',
        },
        {
          fullCode: 'AZ-SUS',
          regionCode: 'SUS',
          regionName: 'Shusha',
        },
        {
          fullCode: 'AZ-TAR',
          regionCode: 'TAR',
          regionName: 'Tartar',
        },
        {
          fullCode: 'AZ-TOV',
          regionCode: 'TOV',
          regionName: 'Tovuz',
        },
        {
          fullCode: 'AZ-UCA',
          regionCode: 'UCA',
          regionName: 'Ujar',
        },
        {
          fullCode: 'AZ-XAC',
          regionCode: 'XAC',
          regionName: 'Khachmaz',
        },
        {
          fullCode: 'AZ-XA',
          regionCode: 'XA',
          regionName: 'Khankendi',
        },
        {
          fullCode: 'AZ-XIZ',
          regionCode: 'XIZ',
          regionName: 'Khizi',
        },
        {
          fullCode: 'AZ-XCI',
          regionCode: 'XCI',
          regionName: 'Khojaly',
        },
        {
          fullCode: 'AZ-XVD',
          regionCode: 'XVD',
          regionName: 'Khojavend',
        },
        {
          fullCode: 'AZ-YAR',
          regionCode: 'YAR',
          regionName: 'Yardimli',
        },
        {
          fullCode: 'AZ-YE',
          regionCode: 'YE',
          regionName: 'Yevlakh City',
        },
        {
          fullCode: 'AZ-YEV',
          regionCode: 'YEV',
          regionName: 'Yevlakh',
        },
        {
          fullCode: 'AZ-ZAQ',
          regionCode: 'ZAQ',
          regionName: 'Zagatala',
        },
        {
          fullCode: 'AZ-ZAN',
          regionCode: 'ZAN',
          regionName: 'Zangilan',
        },
        {
          fullCode: 'AZ-ZAR',
          regionCode: 'ZAR',
          regionName: 'Zardab',
        },
      ],
    },
  ],
  [
    'bahamas',
    {
      countryCode: 'BS',
      countryName: 'Bahamas',
      regions: [
        {
          fullCode: 'BS-AK',
          regionCode: 'AK',
          regionName: 'Acklins',
        },
        {
          fullCode: 'BS-BY',
          regionCode: 'BY',
          regionName: 'Berry Islands',
        },
        {
          fullCode: 'BS-BI',
          regionCode: 'BI',
          regionName: 'Bimini',
        },
        {
          fullCode: 'BS-BP',
          regionCode: 'BP',
          regionName: 'Black Point',
        },
        {
          fullCode: 'BS-CI',
          regionCode: 'CI',
          regionName: 'Cat Island',
        },
        {
          fullCode: 'BS-CO',
          regionCode: 'CO',
          regionName: 'Central Abaco',
        },
        {
          fullCode: 'BS-CS',
          regionCode: 'CS',
          regionName: 'Central Andros',
        },
        {
          fullCode: 'BS-CE',
          regionCode: 'CE',
          regionName: 'Central Eleuthera',
        },
        {
          fullCode: 'BS-FP',
          regionCode: 'FP',
          regionName: 'City of Freeport',
        },
        {
          fullCode: 'BS-CK',
          regionCode: 'CK',
          regionName: 'Crooked Island and Long Cay',
        },
        {
          fullCode: 'BS-EG',
          regionCode: 'EG',
          regionName: 'East Grand Bahama',
        },
        {
          fullCode: 'BS-EX',
          regionCode: 'EX',
          regionName: 'Exuma',
        },
        {
          fullCode: 'BS-GC',
          regionCode: 'GC',
          regionName: 'Grand Cay',
        },
        {
          fullCode: 'BS-HI',
          regionCode: 'HI',
          regionName: 'Harbour Island',
        },
        {
          fullCode: 'BS-HT',
          regionCode: 'HT',
          regionName: 'Hope Town',
        },
        {
          fullCode: 'BS-IN',
          regionCode: 'IN',
          regionName: 'Inagua',
        },
        {
          fullCode: 'BS-LI',
          regionCode: 'LI',
          regionName: 'Long Island',
        },
        {
          fullCode: 'BS-MC',
          regionCode: 'MC',
          regionName: 'Mangrove Cay',
        },
        {
          fullCode: 'BS-MG',
          regionCode: 'MG',
          regionName: 'Mayaguana',
        },
        {
          fullCode: 'BS-MI',
          regionCode: 'MI',
          regionName: "Moore\\'s Island",
        },
        {
          fullCode: 'BS-NP',
          regionCode: 'NP',
          regionName: 'New Providence',
        },
        {
          fullCode: 'BS-NO',
          regionCode: 'NO',
          regionName: 'North Abaco',
        },
        {
          fullCode: 'BS-NS',
          regionCode: 'NS',
          regionName: 'North Andros',
        },
        {
          fullCode: 'BS-NE',
          regionCode: 'NE',
          regionName: 'North Eleuthera',
        },
        {
          fullCode: 'BS-RI',
          regionCode: 'RI',
          regionName: 'Ragged Island',
        },
        {
          fullCode: 'BS-RC',
          regionCode: 'RC',
          regionName: 'Rum Cay',
        },
        {
          fullCode: 'BS-SS',
          regionCode: 'SS',
          regionName: 'San Salvador',
        },
        {
          fullCode: 'BS-SO',
          regionCode: 'SO',
          regionName: 'South Abaco',
        },
        {
          fullCode: 'BS-SA',
          regionCode: 'SA',
          regionName: 'South Andros',
        },
        {
          fullCode: 'BS-SE',
          regionCode: 'SE',
          regionName: 'South Eleuthera',
        },
        {
          fullCode: 'BS-SW',
          regionCode: 'SW',
          regionName: 'Spanish Wells',
        },
        {
          fullCode: 'BS-WG',
          regionCode: 'WG',
          regionName: 'West Grand Bahama',
        },
        {
          fullCode: 'BS-AC',
          regionCode: 'AC',
          regionName: 'Acklins and Crooked Islands',
        },
        {
          fullCode: 'BS-BI',
          regionCode: 'BI',
          regionName: 'Bimini',
        },
        {
          fullCode: 'BS-CI',
          regionCode: 'CI',
          regionName: 'Cat Island',
        },
        {
          fullCode: 'BS-EX',
          regionCode: 'EX',
          regionName: 'Exuma',
        },
        {
          fullCode: 'BS-FP',
          regionCode: 'FP',
          regionName: 'Freeport',
        },
        {
          fullCode: 'BS-FC',
          regionCode: 'FC',
          regionName: 'Fresh Creek',
        },
        {
          fullCode: 'BS-GH',
          regionCode: 'GH',
          regionName: "Governor\\'s Harbour",
        },
        {
          fullCode: 'BS-GT',
          regionCode: 'GT',
          regionName: 'Green Turtle Cay',
        },
        {
          fullCode: 'BS-HI',
          regionCode: 'HI',
          regionName: 'Harbour Island',
        },
        {
          fullCode: 'BS-HR',
          regionCode: 'HR',
          regionName: 'High Rock',
        },
        {
          fullCode: 'BS-IN',
          regionCode: 'IN',
          regionName: 'Inagua',
        },
        {
          fullCode: 'BS-KB',
          regionCode: 'KB',
          regionName: 'Kemps Bay',
        },
        {
          fullCode: 'BS-LI',
          regionCode: 'LI',
          regionName: 'Long Island',
        },
        {
          fullCode: 'BS-MH',
          regionCode: 'MH',
          regionName: 'Marsh Harbour',
        },
        {
          fullCode: 'BS-MG',
          regionCode: 'MG',
          regionName: 'Mayaguana',
        },
        {
          fullCode: 'BS-NP',
          regionCode: 'NP',
          regionName: 'New Providence',
        },
        {
          fullCode: 'BS-NB',
          regionCode: 'NB',
          regionName: 'Nicholls Town and Berry Islands',
        },
        {
          fullCode: 'BS-RI',
          regionCode: 'RI',
          regionName: 'Ragged Island',
        },
        {
          fullCode: 'BS-RS',
          regionCode: 'RS',
          regionName: 'Rock Sound',
        },
        {
          fullCode: 'BS-SP',
          regionCode: 'SP',
          regionName: 'Sandy Point',
        },
        {
          fullCode: 'BS-SR',
          regionCode: 'SR',
          regionName: 'San Salvador and Rum Cay',
        },
      ],
    },
  ],
  [
    'bahrain',
    {
      countryCode: 'BH',
      countryName: 'Bahrain',
      regions: [
        {
          fullCode: 'BH-13',
          regionCode: '13',
          regionName: 'Capital Governorate',
        },
        {
          fullCode: 'BH-14',
          regionCode: '14',
          regionName: 'Southern Governorate',
        },
        {
          fullCode: 'BH-15',
          regionCode: '15',
          regionName: 'Muharraq Governorate',
        },
        {
          fullCode: 'BH-17',
          regionCode: '17',
          regionName: 'Northern Governorate',
        },
        {
          fullCode: 'BH-01',
          regionCode: '01',
          regionName: 'Al Ḩadd',
        },
        {
          fullCode: 'BH-03',
          regionCode: '03',
          regionName: 'Al Manāmah',
        },
        {
          fullCode: 'BH-10',
          regionCode: '10',
          regionName: 'Al Minţaqah al Gharbīyah',
        },
        {
          fullCode: 'BH-07',
          regionCode: '07',
          regionName: 'Al Minţaqah al Wusţá',
        },
        {
          fullCode: 'BH-05',
          regionCode: '05',
          regionName: 'Al Minţaqah ash Shamālīyah',
        },
        {
          fullCode: 'BH-02',
          regionCode: '02',
          regionName: 'Al Muḩarraq',
        },
        {
          fullCode: 'BH-09',
          regionCode: '09',
          regionName: 'Ar Rifāٰ',
        },
        {
          fullCode: 'BH-04',
          regionCode: '04',
          regionName: 'Jidd Ḩafş',
        },
        {
          fullCode: 'BH-12',
          regionCode: '12',
          regionName: 'Madīnat Ḩamad',
        },
        {
          fullCode: 'BH-08',
          regionCode: '08',
          regionName: 'Madīnat ٰĪsá',
        },
        {
          fullCode: 'BH-11',
          regionCode: '11',
          regionName: 'Minţaqat Juzur Ḩawār',
        },
        {
          fullCode: 'BH-06',
          regionCode: '06',
          regionName: 'Sitrah',
        },
      ],
    },
  ],
  [
    'bangladesh',
    {
      countryCode: 'BD',
      countryName: 'Bangladesh',
      regions: [
        {
          fullCode: 'BD-A',
          regionCode: 'A',
          regionName: 'Barishal',
        },
        {
          fullCode: 'BD-B',
          regionCode: 'B',
          regionName: 'Chittagong',
        },
        {
          fullCode: 'BD-C',
          regionCode: 'C',
          regionName: 'Dhaka',
        },
        {
          fullCode: 'BD-D',
          regionCode: 'D',
          regionName: 'Khulna',
        },
        {
          fullCode: 'BD-H',
          regionCode: 'H',
          regionName: 'Mymensingh',
        },
        {
          fullCode: 'BD-E',
          regionCode: 'E',
          regionName: 'Rajshahi',
        },
        {
          fullCode: 'BD-F',
          regionCode: 'F',
          regionName: 'Rangpur',
        },
        {
          fullCode: 'BD-G',
          regionCode: 'G',
          regionName: 'Sylhet',
        },
      ],
    },
  ],
  [
    'barbados',
    {
      countryCode: 'BB',
      countryName: 'Barbados',
      regions: [
        {
          fullCode: 'BB-01',
          regionCode: '01',
          regionName: 'Christ Church',
        },
        {
          fullCode: 'BB-02',
          regionCode: '02',
          regionName: 'Saint Andrew',
        },
        {
          fullCode: 'BB-03',
          regionCode: '03',
          regionName: 'Saint George',
        },
        {
          fullCode: 'BB-04',
          regionCode: '04',
          regionName: 'Saint James',
        },
        {
          fullCode: 'BB-05',
          regionCode: '05',
          regionName: 'Saint John',
        },
        {
          fullCode: 'BB-06',
          regionCode: '06',
          regionName: 'Saint Joseph',
        },
        {
          fullCode: 'BB-07',
          regionCode: '07',
          regionName: 'Saint Lucy',
        },
        {
          fullCode: 'BB-08',
          regionCode: '08',
          regionName: 'Saint Michael',
        },
        {
          fullCode: 'BB-09',
          regionCode: '09',
          regionName: 'Saint Peter',
        },
        {
          fullCode: 'BB-10',
          regionCode: '10',
          regionName: 'Saint Philip',
        },
        {
          fullCode: 'BB-11',
          regionCode: '11',
          regionName: 'Saint Thomas',
        },
      ],
    },
  ],
  [
    'belarus',
    {
      countryCode: 'BY',
      countryName: 'Belarus',
      regions: [
        {
          fullCode: 'BY-BR',
          regionCode: 'BR',
          regionName: 'Brest',
        },
        {
          fullCode: 'BY-HO',
          regionCode: 'HO',
          regionName: 'Gomel',
        },
        {
          fullCode: 'BY-HM',
          regionCode: 'HM',
          regionName: 'Minsk City',
        },
        {
          fullCode: 'BY-HR',
          regionCode: 'HR',
          regionName: 'Grodno',
        },
        {
          fullCode: 'BY-MA',
          regionCode: 'MA',
          regionName: 'Mogilev',
        },
        {
          fullCode: 'BY-MI',
          regionCode: 'MI',
          regionName: 'Minsk',
        },
        {
          fullCode: 'BY-VI',
          regionCode: 'VI',
          regionName: 'Vitebsk',
        },
      ],
    },
  ],
  [
    'belgium',
    {
      countryCode: 'BE',
      countryName: 'Belgium',
      regions: [
        {
          fullCode: 'BE-BRU',
          regionCode: 'BRU',
          regionName: 'Brussels',
        },
        {
          fullCode: 'BE-VLG',
          regionCode: 'VLG',
          regionName: 'Flanders',
        },
        {
          fullCode: 'BE-WAL',
          regionCode: 'WAL',
          regionName: 'Wallonia',
        },
        {
          fullCode: 'BE-VAN',
          regionCode: 'VAN',
          regionName: 'Antwerp',
        },
        {
          fullCode: 'BE-WBR',
          regionCode: 'WBR',
          regionName: 'Walloon Brabant',
        },
        {
          fullCode: 'BE-WHT',
          regionCode: 'WHT',
          regionName: 'Hainaut',
        },
        {
          fullCode: 'BE-WLG',
          regionCode: 'WLG',
          regionName: 'Liège',
        },
        {
          fullCode: 'BE-VLI',
          regionCode: 'VLI',
          regionName: 'Limburg',
        },
        {
          fullCode: 'BE-WLX',
          regionCode: 'WLX',
          regionName: 'Luxembourg',
        },
        {
          fullCode: 'BE-WNA',
          regionCode: 'WNA',
          regionName: 'Namur',
        },
        {
          fullCode: 'BE-VOV',
          regionCode: 'VOV',
          regionName: 'East Flanders',
        },
        {
          fullCode: 'BE-VBR',
          regionCode: 'VBR',
          regionName: 'Flemish Brabant',
        },
        {
          fullCode: 'BE-VWV',
          regionCode: 'VWV',
          regionName: 'West Flanders',
        },
      ],
    },
  ],
  [
    'belize',
    {
      countryCode: 'BZ',
      countryName: 'Belize',
      regions: [
        {
          fullCode: 'BZ-BZ',
          regionCode: 'BZ',
          regionName: 'Belize',
        },
        {
          fullCode: 'BZ-CY',
          regionCode: 'CY',
          regionName: 'Cayo',
        },
        {
          fullCode: 'BZ-CZL',
          regionCode: 'CZL',
          regionName: 'Corozal',
        },
        {
          fullCode: 'BZ-OW',
          regionCode: 'OW',
          regionName: 'Orange Walk',
        },
        {
          fullCode: 'BZ-SC',
          regionCode: 'SC',
          regionName: 'Stann Creek',
        },
        {
          fullCode: 'BZ-TOL',
          regionCode: 'TOL',
          regionName: 'Toledo',
        },
      ],
    },
  ],
  [
    'benin',
    {
      countryCode: 'BJ',
      countryName: 'Benin',
      regions: [
        {
          fullCode: 'BJ-AL',
          regionCode: 'AL',
          regionName: 'Alibori',
        },
        {
          fullCode: 'BJ-AK',
          regionCode: 'AK',
          regionName: 'Atacora',
        },
        {
          fullCode: 'BJ-AQ',
          regionCode: 'AQ',
          regionName: 'Atlantique',
        },
        {
          fullCode: 'BJ-BO',
          regionCode: 'BO',
          regionName: 'Borgou',
        },
        {
          fullCode: 'BJ-CO',
          regionCode: 'CO',
          regionName: 'Collines',
        },
        {
          fullCode: 'BJ-KO',
          regionCode: 'KO',
          regionName: 'Couffo',
        },
        {
          fullCode: 'BJ-DO',
          regionCode: 'DO',
          regionName: 'Donga',
        },
        {
          fullCode: 'BJ-LI',
          regionCode: 'LI',
          regionName: 'Littoral',
        },
        {
          fullCode: 'BJ-MO',
          regionCode: 'MO',
          regionName: 'Mono',
        },
        {
          fullCode: 'BJ-OU',
          regionCode: 'OU',
          regionName: 'Ouémé',
        },
        {
          fullCode: 'BJ-PL',
          regionCode: 'PL',
          regionName: 'Plateau',
        },
        {
          fullCode: 'BJ-ZO',
          regionCode: 'ZO',
          regionName: 'Zou',
        },
      ],
    },
  ],
  [
    'bhutan',
    {
      countryCode: 'BT',
      countryName: 'Bhutan',
      regions: [
        {
          fullCode: 'BT-33',
          regionCode: '33',
          regionName: 'Bumthang',
        },
        {
          fullCode: 'BT-12',
          regionCode: '12',
          regionName: 'Chhukha',
        },
        {
          fullCode: 'BT-22',
          regionCode: '22',
          regionName: 'Dagana',
        },
        {
          fullCode: 'BT-GA',
          regionCode: 'GA',
          regionName: 'Gasa',
        },
        {
          fullCode: 'BT-13',
          regionCode: '13',
          regionName: 'Haa',
        },
        {
          fullCode: 'BT-44',
          regionCode: '44',
          regionName: 'Lhuentse',
        },
        {
          fullCode: 'BT-42',
          regionCode: '42',
          regionName: 'Monggar',
        },
        {
          fullCode: 'BT-11',
          regionCode: '11',
          regionName: 'Paro',
        },
        {
          fullCode: 'BT-43',
          regionCode: '43',
          regionName: 'Pema Gatshel',
        },
        {
          fullCode: 'BT-23',
          regionCode: '23',
          regionName: 'Punakha',
        },
        {
          fullCode: 'BT-45',
          regionCode: '45',
          regionName: 'Samdrup Jongkhar',
        },
        {
          fullCode: 'BT-14',
          regionCode: '14',
          regionName: 'Samtse',
        },
        {
          fullCode: 'BT-31',
          regionCode: '31',
          regionName: 'Sarpang',
        },
        {
          fullCode: 'BT-15',
          regionCode: '15',
          regionName: 'Thimphu',
        },
        {
          fullCode: 'BT-41',
          regionCode: '41',
          regionName: 'Trashigang',
        },
        {
          fullCode: 'BT-TY',
          regionCode: 'TY',
          regionName: 'Trashi Yangtse',
        },
        {
          fullCode: 'BT-32',
          regionCode: '32',
          regionName: 'Trongsa',
        },
        {
          fullCode: 'BT-21',
          regionCode: '21',
          regionName: 'Tsirang',
        },
        {
          fullCode: 'BT-24',
          regionCode: '24',
          regionName: 'Wangdue Phodrang',
        },
        {
          fullCode: 'BT-34',
          regionCode: '34',
          regionName: 'Zhemgang',
        },
      ],
    },
  ],
  [
    'bolivia, plurinational state of',
    {
      countryCode: 'BO',
      countryName: 'Bolivia, Plurinational State of',
      regions: [
        {
          fullCode: 'BO-C',
          regionCode: 'C',
          regionName: 'Cochabamba',
        },
        {
          fullCode: 'BO-H',
          regionCode: 'H',
          regionName: 'Chuquisaca',
        },
        {
          fullCode: 'BO-B',
          regionCode: 'B',
          regionName: 'El Beni',
        },
        {
          fullCode: 'BO-L',
          regionCode: 'L',
          regionName: 'La Paz',
        },
        {
          fullCode: 'BO-O',
          regionCode: 'O',
          regionName: 'Oruro',
        },
        {
          fullCode: 'BO-N',
          regionCode: 'N',
          regionName: 'Pando',
        },
        {
          fullCode: 'BO-P',
          regionCode: 'P',
          regionName: 'Potosí',
        },
        {
          fullCode: 'BO-S',
          regionCode: 'S',
          regionName: 'Santa Cruz',
        },
        {
          fullCode: 'BO-T',
          regionCode: 'T',
          regionName: 'Tarija',
        },
      ],
    },
  ],
  [
    'bonaire, sint eustatius and saba',
    {
      countryCode: 'BQ',
      countryName: 'Bonaire, Sint Eustatius and Saba',
      regions: [
        {
          fullCode: 'BQ-BO',
          regionCode: 'BO',
          regionName: 'Bonaire',
        },
        {
          fullCode: 'BQ-SA',
          regionCode: 'SA',
          regionName: 'Saba',
        },
        {
          fullCode: 'BQ-SE',
          regionCode: 'SE',
          regionName: 'Sint Eustatius',
        },
      ],
    },
  ],
  [
    'bosnia and herzegovina',
    {
      countryCode: 'BA',
      countryName: 'Bosnia and Herzegovina',
      regions: [
        {
          fullCode: 'BA-BIH',
          regionCode: 'BIH',
          regionName: 'Federation of Bosnia and Herzegovina',
        },
        {
          fullCode: 'BA-SRP',
          regionCode: 'SRP',
          regionName: 'Republika Srpska',
        },
        {
          fullCode: 'BA-BRC',
          regionCode: 'BRC',
          regionName: 'Brčko District',
        },
        {
          fullCode: 'BA-05',
          regionCode: '05',
          regionName: 'Bosansko-podrinjski kanton',
        },
        {
          fullCode: 'BA-07',
          regionCode: '07',
          regionName: 'Hercegovačko-neretvanski kanton',
        },
        {
          fullCode: 'BA-10',
          regionCode: '10',
          regionName: 'Kanton br. 10',
        },
        {
          fullCode: 'BA-09',
          regionCode: '09',
          regionName: 'Kanton Sarajevo',
        },
        {
          fullCode: 'BA-02',
          regionCode: '02',
          regionName: 'Posavski kanton',
        },
        {
          fullCode: 'BA-06',
          regionCode: '06',
          regionName: 'Srednjobosanski kanton',
        },
        {
          fullCode: 'BA-03',
          regionCode: '03',
          regionName: 'Tuzlanski kanton',
        },
        {
          fullCode: 'BA-01',
          regionCode: '01',
          regionName: 'Unsko-sanski kanton',
        },
        {
          fullCode: 'BA-08',
          regionCode: '08',
          regionName: 'Zapadnohercegovački kanton',
        },
        {
          fullCode: 'BA-04',
          regionCode: '04',
          regionName: 'Zeničko-dobojski kanton',
        },
      ],
    },
  ],
  [
    'botswana',
    {
      countryCode: 'BW',
      countryName: 'Botswana',
      regions: [
        {
          fullCode: 'BW-CE',
          regionCode: 'CE',
          regionName: 'Central',
        },
        {
          fullCode: 'BW-CH',
          regionCode: 'CH',
          regionName: 'Chobe',
        },
        {
          fullCode: 'BW-FR',
          regionCode: 'FR',
          regionName: 'Francistown',
        },
        {
          fullCode: 'BW-GA',
          regionCode: 'GA',
          regionName: 'Gaborone',
        },
        {
          fullCode: 'BW-GH',
          regionCode: 'GH',
          regionName: 'Ghanzi',
        },
        {
          fullCode: 'BW-JW',
          regionCode: 'JW',
          regionName: 'Jwaneng',
        },
        {
          fullCode: 'BW-KG',
          regionCode: 'KG',
          regionName: 'Kgalagadi',
        },
        {
          fullCode: 'BW-KL',
          regionCode: 'KL',
          regionName: 'Kgatleng',
        },
        {
          fullCode: 'BW-KW',
          regionCode: 'KW',
          regionName: 'Kweneng',
        },
        {
          fullCode: 'BW-LO',
          regionCode: 'LO',
          regionName: 'Lobatse',
        },
        {
          fullCode: 'BW-NE',
          regionCode: 'NE',
          regionName: 'North East',
        },
        {
          fullCode: 'BW-NW',
          regionCode: 'NW',
          regionName: 'North West',
        },
        {
          fullCode: 'BW-SP',
          regionCode: 'SP',
          regionName: 'Selibe Phikwe',
        },
        {
          fullCode: 'BW-SE',
          regionCode: 'SE',
          regionName: 'South East',
        },
        {
          fullCode: 'BW-SO',
          regionCode: 'SO',
          regionName: 'Southern',
        },
        {
          fullCode: 'BW-ST',
          regionCode: 'ST',
          regionName: 'Sowa Town',
        },
      ],
    },
  ],
  [
    'brazil',
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      regions: [
        {
          fullCode: 'BR-AC',
          regionCode: 'AC',
          regionName: 'Acre',
        },
        {
          fullCode: 'BR-AL',
          regionCode: 'AL',
          regionName: 'Alagoas',
        },
        {
          fullCode: 'BR-AP',
          regionCode: 'AP',
          regionName: 'Amapá',
        },
        {
          fullCode: 'BR-AM',
          regionCode: 'AM',
          regionName: 'Amazonas',
        },
        {
          fullCode: 'BR-BA',
          regionCode: 'BA',
          regionName: 'Bahia',
        },
        {
          fullCode: 'BR-CE',
          regionCode: 'CE',
          regionName: 'Ceará',
        },
        {
          fullCode: 'BR-DF',
          regionCode: 'DF',
          regionName: 'Distrito Federal',
        },
        {
          fullCode: 'BR-ES',
          regionCode: 'ES',
          regionName: 'Espírito Santo',
        },
        {
          fullCode: 'BR-GO',
          regionCode: 'GO',
          regionName: 'Goiás',
        },
        {
          fullCode: 'BR-MA',
          regionCode: 'MA',
          regionName: 'Maranhão',
        },
        {
          fullCode: 'BR-MT',
          regionCode: 'MT',
          regionName: 'Mato Grosso',
        },
        {
          fullCode: 'BR-MS',
          regionCode: 'MS',
          regionName: 'Mato Grosso do Sul',
        },
        {
          fullCode: 'BR-MG',
          regionCode: 'MG',
          regionName: 'Minas Gerais',
        },
        {
          fullCode: 'BR-PA',
          regionCode: 'PA',
          regionName: 'Pará',
        },
        {
          fullCode: 'BR-PB',
          regionCode: 'PB',
          regionName: 'Paraíba',
        },
        {
          fullCode: 'BR-PR',
          regionCode: 'PR',
          regionName: 'Paraná',
        },
        {
          fullCode: 'BR-PE',
          regionCode: 'PE',
          regionName: 'Pernambuco',
        },
        {
          fullCode: 'BR-PI',
          regionCode: 'PI',
          regionName: 'Piauí',
        },
        {
          fullCode: 'BR-RJ',
          regionCode: 'RJ',
          regionName: 'Rio de Janeiro',
        },
        {
          fullCode: 'BR-RN',
          regionCode: 'RN',
          regionName: 'Rio Grande do Norte',
        },
        {
          fullCode: 'BR-RS',
          regionCode: 'RS',
          regionName: 'Rio Grande do Sul',
        },
        {
          fullCode: 'BR-RO',
          regionCode: 'RO',
          regionName: 'Rondônia',
        },
        {
          fullCode: 'BR-RR',
          regionCode: 'RR',
          regionName: 'Roraima',
        },
        {
          fullCode: 'BR-SC',
          regionCode: 'SC',
          regionName: 'Santa Catarina',
        },
        {
          fullCode: 'BR-SP',
          regionCode: 'SP',
          regionName: 'São Paulo',
        },
        {
          fullCode: 'BR-SE',
          regionCode: 'SE',
          regionName: 'Sergipe',
        },
        {
          fullCode: 'BR-TO',
          regionCode: 'TO',
          regionName: 'Tocantins',
        },
      ],
    },
  ],
  [
    'brunei darussalam',
    {
      countryCode: 'BN',
      countryName: 'Brunei Darussalam',
      regions: [
        {
          fullCode: 'BN-BE',
          regionCode: 'BE',
          regionName: 'Belait',
        },
        {
          fullCode: 'BN-BM',
          regionCode: 'BM',
          regionName: 'Brunei-Muara',
        },
        {
          fullCode: 'BN-TE',
          regionCode: 'TE',
          regionName: 'Temburong',
        },
        {
          fullCode: 'BN-TU',
          regionCode: 'TU',
          regionName: 'Tutong',
        },
      ],
    },
  ],
  [
    'bulgaria',
    {
      countryCode: 'BG',
      countryName: 'Bulgaria',
      regions: [
        {
          fullCode: 'BG-01',
          regionCode: '01',
          regionName: 'Blagoevgrad',
        },
        {
          fullCode: 'BG-02',
          regionCode: '02',
          regionName: 'Burgas',
        },
        {
          fullCode: 'BG-03',
          regionCode: '03',
          regionName: 'Varna',
        },
        {
          fullCode: 'BG-04',
          regionCode: '04',
          regionName: 'Veliko Tarnovo',
        },
        {
          fullCode: 'BG-05',
          regionCode: '05',
          regionName: 'Vidin',
        },
        {
          fullCode: 'BG-06',
          regionCode: '06',
          regionName: 'Vratsa',
        },
        {
          fullCode: 'BG-07',
          regionCode: '07',
          regionName: 'Gabrovo',
        },
        {
          fullCode: 'BG-08',
          regionCode: '08',
          regionName: 'Dobrich',
        },
        {
          fullCode: 'BG-09',
          regionCode: '09',
          regionName: 'Kardzhali',
        },
        {
          fullCode: 'BG-10',
          regionCode: '10',
          regionName: 'Kyustendil',
        },
        {
          fullCode: 'BG-11',
          regionCode: '11',
          regionName: 'Lovech',
        },
        {
          fullCode: 'BG-12',
          regionCode: '12',
          regionName: 'Montana',
        },
        {
          fullCode: 'BG-13',
          regionCode: '13',
          regionName: 'Pazardzhik',
        },
        {
          fullCode: 'BG-14',
          regionCode: '14',
          regionName: 'Pernik',
        },
        {
          fullCode: 'BG-15',
          regionCode: '15',
          regionName: 'Pleven',
        },
        {
          fullCode: 'BG-16',
          regionCode: '16',
          regionName: 'Plovdiv',
        },
        {
          fullCode: 'BG-17',
          regionCode: '17',
          regionName: 'Razgrad',
        },
        {
          fullCode: 'BG-18',
          regionCode: '18',
          regionName: 'Ruse',
        },
        {
          fullCode: 'BG-19',
          regionCode: '19',
          regionName: 'Silistra',
        },
        {
          fullCode: 'BG-20',
          regionCode: '20',
          regionName: 'Sliven',
        },
        {
          fullCode: 'BG-21',
          regionCode: '21',
          regionName: 'Smolyan',
        },
        {
          fullCode: 'BG-22',
          regionCode: '22',
          regionName: 'Sofia (stolitsa)',
        },
        {
          fullCode: 'BG-23',
          regionCode: '23',
          regionName: 'Sofia',
        },
        {
          fullCode: 'BG-24',
          regionCode: '24',
          regionName: 'Stara Zagora',
        },
        {
          fullCode: 'BG-25',
          regionCode: '25',
          regionName: 'Targovishte',
        },
        {
          fullCode: 'BG-26',
          regionCode: '26',
          regionName: 'Haskovo',
        },
        {
          fullCode: 'BG-27',
          regionCode: '27',
          regionName: 'Shumen',
        },
        {
          fullCode: 'BG-28',
          regionCode: '28',
          regionName: 'Yambol',
        },
      ],
    },
  ],
  [
    'burkina faso',
    {
      countryCode: 'BF',
      countryName: 'Burkina Faso',
      regions: [
        {
          fullCode: 'BF-01',
          regionCode: '01',
          regionName: 'Boucle du Mouhoun',
        },
        {
          fullCode: 'BF-02',
          regionCode: '02',
          regionName: 'Cascades',
        },
        {
          fullCode: 'BF-03',
          regionCode: '03',
          regionName: 'Centre',
        },
        {
          fullCode: 'BF-04',
          regionCode: '04',
          regionName: 'Centre-Est',
        },
        {
          fullCode: 'BF-05',
          regionCode: '05',
          regionName: 'Centre-Nord',
        },
        {
          fullCode: 'BF-06',
          regionCode: '06',
          regionName: 'Centre-Ouest',
        },
        {
          fullCode: 'BF-07',
          regionCode: '07',
          regionName: 'Centre-Sud',
        },
        {
          fullCode: 'BF-08',
          regionCode: '08',
          regionName: 'Est',
        },
        {
          fullCode: 'BF-09',
          regionCode: '09',
          regionName: 'Hauts-Bassins',
        },
        {
          fullCode: 'BF-10',
          regionCode: '10',
          regionName: 'Nord',
        },
        {
          fullCode: 'BF-11',
          regionCode: '11',
          regionName: 'Plateau-Central',
        },
        {
          fullCode: 'BF-12',
          regionCode: '12',
          regionName: 'Sahel',
        },
        {
          fullCode: 'BF-13',
          regionCode: '13',
          regionName: 'Sud-Ouest',
        },
        {
          fullCode: 'BF-BAL',
          regionCode: 'BAL',
          regionName: 'Balé',
        },
        {
          fullCode: 'BF-BAM',
          regionCode: 'BAM',
          regionName: 'Bam',
        },
        {
          fullCode: 'BF-BAN',
          regionCode: 'BAN',
          regionName: 'Banwa',
        },
        {
          fullCode: 'BF-BAZ',
          regionCode: 'BAZ',
          regionName: 'Bazèga',
        },
        {
          fullCode: 'BF-BGR',
          regionCode: 'BGR',
          regionName: 'Bougouriba',
        },
        {
          fullCode: 'BF-BLG',
          regionCode: 'BLG',
          regionName: 'Boulgou',
        },
        {
          fullCode: 'BF-BLK',
          regionCode: 'BLK',
          regionName: 'Boulkiemdé',
        },
        {
          fullCode: 'BF-COM',
          regionCode: 'COM',
          regionName: 'Comoé',
        },
        {
          fullCode: 'BF-GAN',
          regionCode: 'GAN',
          regionName: 'Ganzourgou',
        },
        {
          fullCode: 'BF-GNA',
          regionCode: 'GNA',
          regionName: 'Gnagna',
        },
        {
          fullCode: 'BF-GOU',
          regionCode: 'GOU',
          regionName: 'Gourma',
        },
        {
          fullCode: 'BF-HOU',
          regionCode: 'HOU',
          regionName: 'Houet',
        },
        {
          fullCode: 'BF-IOB',
          regionCode: 'IOB',
          regionName: 'Ioba',
        },
        {
          fullCode: 'BF-KAD',
          regionCode: 'KAD',
          regionName: 'Kadiogo',
        },
        {
          fullCode: 'BF-KEN',
          regionCode: 'KEN',
          regionName: 'Kénédougou',
        },
        {
          fullCode: 'BF-KMD',
          regionCode: 'KMD',
          regionName: 'Komondjari',
        },
        {
          fullCode: 'BF-KMP',
          regionCode: 'KMP',
          regionName: 'Kompienga',
        },
        {
          fullCode: 'BF-KOS',
          regionCode: 'KOS',
          regionName: 'Kossi',
        },
        {
          fullCode: 'BF-KOP',
          regionCode: 'KOP',
          regionName: 'Koulpélogo',
        },
        {
          fullCode: 'BF-KOT',
          regionCode: 'KOT',
          regionName: 'Kouritenga',
        },
        {
          fullCode: 'BF-KOW',
          regionCode: 'KOW',
          regionName: 'Kourwéogo',
        },
        {
          fullCode: 'BF-LER',
          regionCode: 'LER',
          regionName: 'Léraba',
        },
        {
          fullCode: 'BF-LOR',
          regionCode: 'LOR',
          regionName: 'Loroum',
        },
        {
          fullCode: 'BF-MOU',
          regionCode: 'MOU',
          regionName: 'Mouhoun',
        },
        {
          fullCode: 'BF-NAO',
          regionCode: 'NAO',
          regionName: 'Nahouri',
        },
        {
          fullCode: 'BF-NAM',
          regionCode: 'NAM',
          regionName: 'Namentenga',
        },
        {
          fullCode: 'BF-NAY',
          regionCode: 'NAY',
          regionName: 'Nayala',
        },
        {
          fullCode: 'BF-NOU',
          regionCode: 'NOU',
          regionName: 'Noumbiel',
        },
        {
          fullCode: 'BF-OUB',
          regionCode: 'OUB',
          regionName: 'Oubritenga',
        },
        {
          fullCode: 'BF-OUD',
          regionCode: 'OUD',
          regionName: 'Oudalan',
        },
        {
          fullCode: 'BF-PAS',
          regionCode: 'PAS',
          regionName: 'Passoré',
        },
        {
          fullCode: 'BF-PON',
          regionCode: 'PON',
          regionName: 'Poni',
        },
        {
          fullCode: 'BF-SNG',
          regionCode: 'SNG',
          regionName: 'Sanguié',
        },
        {
          fullCode: 'BF-SMT',
          regionCode: 'SMT',
          regionName: 'Sanmatenga',
        },
        {
          fullCode: 'BF-SEN',
          regionCode: 'SEN',
          regionName: 'Séno',
        },
        {
          fullCode: 'BF-SIS',
          regionCode: 'SIS',
          regionName: 'Sissili',
        },
        {
          fullCode: 'BF-SOM',
          regionCode: 'SOM',
          regionName: 'Soum',
        },
        {
          fullCode: 'BF-SOR',
          regionCode: 'SOR',
          regionName: 'Sourou',
        },
        {
          fullCode: 'BF-TAP',
          regionCode: 'TAP',
          regionName: 'Tapoa',
        },
        {
          fullCode: 'BF-TUI',
          regionCode: 'TUI',
          regionName: 'Tuy',
        },
        {
          fullCode: 'BF-YAG',
          regionCode: 'YAG',
          regionName: 'Yagha',
        },
        {
          fullCode: 'BF-YAT',
          regionCode: 'YAT',
          regionName: 'Yatenga',
        },
        {
          fullCode: 'BF-ZIR',
          regionCode: 'ZIR',
          regionName: 'Ziro',
        },
        {
          fullCode: 'BF-ZON',
          regionCode: 'ZON',
          regionName: 'Zondoma',
        },
        {
          fullCode: 'BF-ZOU',
          regionCode: 'ZOU',
          regionName: 'Zoundwéogo',
        },
      ],
    },
  ],
  [
    'burundi',
    {
      countryCode: 'BI',
      countryName: 'Burundi',
      regions: [
        {
          fullCode: 'BI-BB',
          regionCode: 'BB',
          regionName: 'Bubanza',
        },
        {
          fullCode: 'BI-BM',
          regionCode: 'BM',
          regionName: 'Bujumbura Mairie',
        },
        {
          fullCode: 'BI-BL',
          regionCode: 'BL',
          regionName: 'Bujumbura Rural',
        },
        {
          fullCode: 'BI-BR',
          regionCode: 'BR',
          regionName: 'Bururi',
        },
        {
          fullCode: 'BI-CA',
          regionCode: 'CA',
          regionName: 'Cankuzo',
        },
        {
          fullCode: 'BI-CI',
          regionCode: 'CI',
          regionName: 'Cibitoke',
        },
        {
          fullCode: 'BI-GI',
          regionCode: 'GI',
          regionName: 'Gitega',
        },
        {
          fullCode: 'BI-KR',
          regionCode: 'KR',
          regionName: 'Karuzi',
        },
        {
          fullCode: 'BI-KY',
          regionCode: 'KY',
          regionName: 'Kayanza',
        },
        {
          fullCode: 'BI-KI',
          regionCode: 'KI',
          regionName: 'Kirundo',
        },
        {
          fullCode: 'BI-MA',
          regionCode: 'MA',
          regionName: 'Makamba',
        },
        {
          fullCode: 'BI-MU',
          regionCode: 'MU',
          regionName: 'Muramvya',
        },
        {
          fullCode: 'BI-MY',
          regionCode: 'MY',
          regionName: 'Muyinga',
        },
        {
          fullCode: 'BI-MW',
          regionCode: 'MW',
          regionName: 'Mwaro',
        },
        {
          fullCode: 'BI-NG',
          regionCode: 'NG',
          regionName: 'Ngozi',
        },
        {
          fullCode: 'BI-RM',
          regionCode: 'RM',
          regionName: 'Rumonge',
        },
        {
          fullCode: 'BI-RT',
          regionCode: 'RT',
          regionName: 'Rutana',
        },
        {
          fullCode: 'BI-RY',
          regionCode: 'RY',
          regionName: 'Ruyigi',
        },
      ],
    },
  ],
  [
    'cabo verde',
    {
      countryCode: 'CV',
      countryName: 'Cabo Verde',
      regions: [
        {
          fullCode: 'CV-B',
          regionCode: 'B',
          regionName: 'Ilhas de Barlavento',
        },
        {
          fullCode: 'CV-S',
          regionCode: 'S',
          regionName: 'Ilhas de Sotavento',
        },
        {
          fullCode: 'CV-BV',
          regionCode: 'BV',
          regionName: 'Boa Vista',
        },
        {
          fullCode: 'CV-BR',
          regionCode: 'BR',
          regionName: 'Brava',
        },
        {
          fullCode: 'CV-MA',
          regionCode: 'MA',
          regionName: 'Maio',
        },
        {
          fullCode: 'CV-MO',
          regionCode: 'MO',
          regionName: 'Mosteiros',
        },
        {
          fullCode: 'CV-PA',
          regionCode: 'PA',
          regionName: 'Paul',
        },
        {
          fullCode: 'CV-PN',
          regionCode: 'PN',
          regionName: 'Porto Novo',
        },
        {
          fullCode: 'CV-PR',
          regionCode: 'PR',
          regionName: 'Praia',
        },
        {
          fullCode: 'CV-RB',
          regionCode: 'RB',
          regionName: 'Ribeira Brava',
        },
        {
          fullCode: 'CV-RG',
          regionCode: 'RG',
          regionName: 'Ribeira Grande',
        },
        {
          fullCode: 'CV-RS',
          regionCode: 'RS',
          regionName: 'Ribeira Grande de Santiago',
        },
        {
          fullCode: 'CV-SL',
          regionCode: 'SL',
          regionName: 'Sal',
        },
        {
          fullCode: 'CV-CA',
          regionCode: 'CA',
          regionName: 'Santa Catarina',
        },
        {
          fullCode: 'CV-CF',
          regionCode: 'CF',
          regionName: 'Santa Catarina do Fogo',
        },
        {
          fullCode: 'CV-CR',
          regionCode: 'CR',
          regionName: 'Santa Cruz',
        },
        {
          fullCode: 'CV-SD',
          regionCode: 'SD',
          regionName: 'São Domingos',
        },
        {
          fullCode: 'CV-SF',
          regionCode: 'SF',
          regionName: 'São Filipe',
        },
        {
          fullCode: 'CV-SO',
          regionCode: 'SO',
          regionName: 'São Lourenço dos Órgãos',
        },
        {
          fullCode: 'CV-SM',
          regionCode: 'SM',
          regionName: 'São Miguel',
        },
        {
          fullCode: 'CV-SS',
          regionCode: 'SS',
          regionName: 'São Salvador do Mundo',
        },
        {
          fullCode: 'CV-SV',
          regionCode: 'SV',
          regionName: 'São Vicente',
        },
        {
          fullCode: 'CV-TA',
          regionCode: 'TA',
          regionName: 'Tarrafal',
        },
        {
          fullCode: 'CV-TS',
          regionCode: 'TS',
          regionName: 'Tarrafal de São Nicolau',
        },
      ],
    },
  ],
  [
    'cambodia',
    {
      countryCode: 'KH',
      countryName: 'Cambodia',
      regions: [
        {
          fullCode: 'KH-2',
          regionCode: '2',
          regionName: 'Battambang',
        },
        {
          fullCode: 'KH-1',
          regionCode: '1',
          regionName: 'Banteay Meanchey',
        },
        {
          fullCode: 'KH-23',
          regionCode: '23',
          regionName: 'Kep',
        },
        {
          fullCode: 'KH-3',
          regionCode: '3',
          regionName: 'Kampong Cham',
        },
        {
          fullCode: 'KH-4',
          regionCode: '4',
          regionName: 'Kampong Chhnang',
        },
        {
          fullCode: 'KH-5',
          regionCode: '5',
          regionName: 'Kampong Speu',
        },
        {
          fullCode: 'KH-6',
          regionCode: '6',
          regionName: 'Kampong Thom',
        },
        {
          fullCode: 'KH-7',
          regionCode: '7',
          regionName: 'Kampot',
        },
        {
          fullCode: 'KH-8',
          regionCode: '8',
          regionName: 'Kandal',
        },
        {
          fullCode: 'KH-9',
          regionCode: '9',
          regionName: 'Koh Kong',
        },
        {
          fullCode: 'KH-10',
          regionCode: '10',
          regionName: 'Kratie',
        },
        {
          fullCode: 'KH-11',
          regionCode: '11',
          regionName: 'Mondolkiri',
        },
        {
          fullCode: 'KH-22',
          regionCode: '22',
          regionName: 'Oddar Meanchey',
        },
        {
          fullCode: 'KH-24',
          regionCode: '24',
          regionName: 'Pailin',
        },
        {
          fullCode: 'KH-12',
          regionCode: '12',
          regionName: 'Phnom Penh',
        },
        {
          fullCode: 'KH-15',
          regionCode: '15',
          regionName: 'Pursat',
        },
        {
          fullCode: 'KH-18',
          regionCode: '18',
          regionName: 'Sihanoukville',
        },
        {
          fullCode: 'KH-13',
          regionCode: '13',
          regionName: 'Preah Vihear',
        },
        {
          fullCode: 'KH-14',
          regionCode: '14',
          regionName: 'Prey Veng',
        },
        {
          fullCode: 'KH-16',
          regionCode: '16',
          regionName: 'Ratanakiri',
        },
        {
          fullCode: 'KH-17',
          regionCode: '17',
          regionName: 'Siem Reap',
        },
        {
          fullCode: 'KH-19',
          regionCode: '19',
          regionName: 'Stung Treng',
        },
        {
          fullCode: 'KH-20',
          regionCode: '20',
          regionName: 'Svay Rieng',
        },
        {
          fullCode: 'KH-21',
          regionCode: '21',
          regionName: 'Takeo',
        },
        {
          fullCode: 'KH-25',
          regionCode: '25',
          regionName: 'Tbong Khmum',
        },
      ],
    },
  ],
  [
    'cameroon',
    {
      countryCode: 'CM',
      countryName: 'Cameroon',
      regions: [
        {
          fullCode: 'CM-AD',
          regionCode: 'AD',
          regionName: 'Adamaoua',
        },
        {
          fullCode: 'CM-CE',
          regionCode: 'CE',
          regionName: 'Centre',
        },
        {
          fullCode: 'CM-ES',
          regionCode: 'ES',
          regionName: 'East',
        },
        {
          fullCode: 'CM-EN',
          regionCode: 'EN',
          regionName: 'Far North',
        },
        {
          fullCode: 'CM-LT',
          regionCode: 'LT',
          regionName: 'Littoral',
        },
        {
          fullCode: 'CM-NO',
          regionCode: 'NO',
          regionName: 'North',
        },
        {
          fullCode: 'CM-NW',
          regionCode: 'NW',
          regionName: 'North-West',
        },
        {
          fullCode: 'CM-SU',
          regionCode: 'SU',
          regionName: 'South',
        },
        {
          fullCode: 'CM-SW',
          regionCode: 'SW',
          regionName: 'South-West',
        },
        {
          fullCode: 'CM-OU',
          regionCode: 'OU',
          regionName: 'West',
        },
      ],
    },
  ],
  [
    'central african republic',
    {
      countryCode: 'CF',
      countryName: 'Central African Republic',
      regions: [
        {
          fullCode: 'CF-BB',
          regionCode: 'BB',
          regionName: 'Bamingui-Bangoran',
        },
        {
          fullCode: 'CF-BGF',
          regionCode: 'BGF',
          regionName: 'Bangui',
        },
        {
          fullCode: 'CF-BK',
          regionCode: 'BK',
          regionName: 'Basse-Kotto',
        },
        {
          fullCode: 'CF-KB',
          regionCode: 'KB',
          regionName: 'Gribingui',
        },
        {
          fullCode: 'CF-HM',
          regionCode: 'HM',
          regionName: 'Haut-Mbomou',
        },
        {
          fullCode: 'CF-HK',
          regionCode: 'HK',
          regionName: 'Haute-Kotto',
        },
        {
          fullCode: 'CF-HS',
          regionCode: 'HS',
          regionName: 'Haute-Sangha / Mambéré-Kadéï',
        },
        {
          fullCode: 'CF-KG',
          regionCode: 'KG',
          regionName: 'Kémo-Gribingui',
        },
        {
          fullCode: 'CF-LB',
          regionCode: 'LB',
          regionName: 'Lobaye',
        },
        {
          fullCode: 'CF-MB',
          regionCode: 'MB',
          regionName: 'Mbomou',
        },
        {
          fullCode: 'CF-NM',
          regionCode: 'NM',
          regionName: 'Nana-Mambéré',
        },
        {
          fullCode: 'CF-MP',
          regionCode: 'MP',
          regionName: 'Ombella-Mpoko',
        },
        {
          fullCode: 'CF-UK',
          regionCode: 'UK',
          regionName: 'Ouaka',
        },
        {
          fullCode: 'CF-AC',
          regionCode: 'AC',
          regionName: 'Ouham',
        },
        {
          fullCode: 'CF-OP',
          regionCode: 'OP',
          regionName: 'Ouham-Pendé',
        },
        {
          fullCode: 'CF-SE',
          regionCode: 'SE',
          regionName: 'Sangha',
        },
        {
          fullCode: 'CF-VK',
          regionCode: 'VK',
          regionName: 'Vakaga',
        },
      ],
    },
  ],
  [
    'chad',
    {
      countryCode: 'TD',
      countryName: 'Chad',
      regions: [
        {
          fullCode: 'TD-BG',
          regionCode: 'BG',
          regionName: 'Bahr El Gazal',
        },
        {
          fullCode: 'TD-BA',
          regionCode: 'BA',
          regionName: 'Batha',
        },
        {
          fullCode: 'TD-BO',
          regionCode: 'BO',
          regionName: 'Borkou',
        },
        {
          fullCode: 'TD-CB',
          regionCode: 'CB',
          regionName: 'Chari-Baguirmi',
        },
        {
          fullCode: 'TD-EE',
          regionCode: 'EE',
          regionName: 'East Ennedi',
        },
        {
          fullCode: 'TD-EO',
          regionCode: 'EO',
          regionName: 'West Ennedi',
        },
        {
          fullCode: 'TD-GR',
          regionCode: 'GR',
          regionName: 'Guera',
        },
        {
          fullCode: 'TD-HL',
          regionCode: 'HL',
          regionName: 'Hadjer Lamis',
        },
        {
          fullCode: 'TD-KA',
          regionCode: 'KA',
          regionName: 'Kanem',
        },
        {
          fullCode: 'TD-LC',
          regionCode: 'LC',
          regionName: 'Lake',
        },
        {
          fullCode: 'TD-LO',
          regionCode: 'LO',
          regionName: 'Western Logone',
        },
        {
          fullCode: 'TD-LR',
          regionCode: 'LR',
          regionName: 'Eastern Logone',
        },
        {
          fullCode: 'TD-MA',
          regionCode: 'MA',
          regionName: 'Mandoul',
        },
        {
          fullCode: 'TD-ME',
          regionCode: 'ME',
          regionName: 'East Mayo-Kebbi',
        },
        {
          fullCode: 'TD-MO',
          regionCode: 'MO',
          regionName: 'West Mayo-Kebbi',
        },
        {
          fullCode: 'TD-MC',
          regionCode: 'MC',
          regionName: 'Middle Chari',
        },
        {
          fullCode: 'TD-OD',
          regionCode: 'OD',
          regionName: 'Wadai',
        },
        {
          fullCode: 'TD-SA',
          regionCode: 'SA',
          regionName: 'Salamat',
        },
        {
          fullCode: 'TD-SI',
          regionCode: 'SI',
          regionName: 'Sila',
        },
        {
          fullCode: 'TD-TA',
          regionCode: 'TA',
          regionName: 'Tandjile',
        },
        {
          fullCode: 'TD-TI',
          regionCode: 'TI',
          regionName: 'Tibesti',
        },
        {
          fullCode: 'TD-ND',
          regionCode: 'ND',
          regionName: "N\\'Djamena City",
        },
        {
          fullCode: 'TD-WF',
          regionCode: 'WF',
          regionName: 'Wadi Fira',
        },
        {
          fullCode: 'TD-BA',
          regionCode: 'BA',
          regionName: 'Batha',
        },
        {
          fullCode: 'TD-BI',
          regionCode: 'BI',
          regionName: 'Biltine',
        },
        {
          fullCode: 'TD-BET',
          regionCode: 'BET',
          regionName: 'Borkou-Ennedi-Tibesti',
        },
        {
          fullCode: 'TD-CB',
          regionCode: 'CB',
          regionName: 'Chari-Baguirmi',
        },
        {
          fullCode: 'TD-GR',
          regionCode: 'GR',
          regionName: 'Guéra',
        },
        {
          fullCode: 'TD-KA',
          regionCode: 'KA',
          regionName: 'Kanem',
        },
        {
          fullCode: 'TD-LC',
          regionCode: 'LC',
          regionName: 'Lac',
        },
        {
          fullCode: 'TD-LO',
          regionCode: 'LO',
          regionName: 'Logone-Occidental',
        },
        {
          fullCode: 'TD-LR',
          regionCode: 'LR',
          regionName: 'Logone-Oriental',
        },
        {
          fullCode: 'TD-MK',
          regionCode: 'MK',
          regionName: 'Mayo-Kébbi',
        },
        {
          fullCode: 'TD-MC',
          regionCode: 'MC',
          regionName: 'Moyen-Chari',
        },
        {
          fullCode: 'TD-OD',
          regionCode: 'OD',
          regionName: 'Ouaddaï',
        },
        {
          fullCode: 'TD-SA',
          regionCode: 'SA',
          regionName: 'Salamat',
        },
        {
          fullCode: 'TD-TA',
          regionCode: 'TA',
          regionName: 'Tandjilé',
        },
      ],
    },
  ],
  [
    'chile',
    {
      countryCode: 'CL',
      countryName: 'Chile',
      regions: [
        {
          fullCode: 'CL-AI',
          regionCode: 'AI',
          regionName: 'Aisen',
        },
        {
          fullCode: 'CL-AN',
          regionCode: 'AN',
          regionName: 'Antofagasta',
        },
        {
          fullCode: 'CL-AP',
          regionCode: 'AP',
          regionName: 'Arica and Parinacota',
        },
        {
          fullCode: 'CL-AT',
          regionCode: 'AT',
          regionName: 'Atacama',
        },
        {
          fullCode: 'CL-BI',
          regionCode: 'BI',
          regionName: 'Biobio',
        },
        {
          fullCode: 'CL-CO',
          regionCode: 'CO',
          regionName: 'Coquimbo',
        },
        {
          fullCode: 'CL-AR',
          regionCode: 'AR',
          regionName: 'La Araucania',
        },
        {
          fullCode: 'CL-LI',
          regionCode: 'LI',
          regionName: "O\\'Higgins",
        },
        {
          fullCode: 'CL-LL',
          regionCode: 'LL',
          regionName: 'The Lakes',
        },
        {
          fullCode: 'CL-LR',
          regionCode: 'LR',
          regionName: 'The Rivers',
        },
        {
          fullCode: 'CL-MA',
          regionCode: 'MA',
          regionName: 'Magellan',
        },
        {
          fullCode: 'CL-ML',
          regionCode: 'ML',
          regionName: 'Maule',
        },
        {
          fullCode: 'CL-NB',
          regionCode: 'NB',
          regionName: 'Nuble',
        },
        {
          fullCode: 'CL-RM',
          regionCode: 'RM',
          regionName: 'Santiago Metropolitan Region',
        },
        {
          fullCode: 'CL-TA',
          regionCode: 'TA',
          regionName: 'Tarapaca',
        },
        {
          fullCode: 'CL-VS',
          regionCode: 'VS',
          regionName: 'Valparaiso',
        },
      ],
    },
  ],
  [
    'china',
    {
      countryCode: 'CN',
      countryName: 'China',
      regions: [
        {
          fullCode: 'CN-AH',
          regionCode: 'AH',
          regionName: 'Anhui',
        },
        {
          fullCode: 'CN-BJ',
          regionCode: 'BJ',
          regionName: 'Beijing',
        },
        {
          fullCode: 'CN-CQ',
          regionCode: 'CQ',
          regionName: 'Chongqing',
        },
        {
          fullCode: 'CN-FJ',
          regionCode: 'FJ',
          regionName: 'Fujian',
        },
        {
          fullCode: 'CN-GS',
          regionCode: 'GS',
          regionName: 'Gansu',
        },
        {
          fullCode: 'CN-GD',
          regionCode: 'GD',
          regionName: 'Guangdong',
        },
        {
          fullCode: 'CN-GX',
          regionCode: 'GX',
          regionName: 'Guangxi',
        },
        {
          fullCode: 'CN-GZ',
          regionCode: 'GZ',
          regionName: 'Guizhou',
        },
        {
          fullCode: 'CN-HI',
          regionCode: 'HI',
          regionName: 'Hainan',
        },
        {
          fullCode: 'CN-HE',
          regionCode: 'HE',
          regionName: 'Hebei',
        },
        {
          fullCode: 'CN-HL',
          regionCode: 'HL',
          regionName: 'Heilongjiang',
        },
        {
          fullCode: 'CN-HA',
          regionCode: 'HA',
          regionName: 'Henan',
        },
        {
          fullCode: 'CN-HK',
          regionCode: 'HK',
          regionName: 'Hong Kong',
        },
        {
          fullCode: 'CN-HB',
          regionCode: 'HB',
          regionName: 'Hubei',
        },
        {
          fullCode: 'CN-HN',
          regionCode: 'HN',
          regionName: 'Hunan',
        },
        {
          fullCode: 'CN-JS',
          regionCode: 'JS',
          regionName: 'Jiangsu',
        },
        {
          fullCode: 'CN-JX',
          regionCode: 'JX',
          regionName: 'Jiangxi',
        },
        {
          fullCode: 'CN-JL',
          regionCode: 'JL',
          regionName: 'Jilin',
        },
        {
          fullCode: 'CN-LN',
          regionCode: 'LN',
          regionName: 'Liaoning',
        },
        {
          fullCode: 'CN-MO',
          regionCode: 'MO',
          regionName: 'Macao',
        },
        {
          fullCode: 'CN-NM',
          regionCode: 'NM',
          regionName: 'Inner Mongolia',
        },
        {
          fullCode: 'CN-NX',
          regionCode: 'NX',
          regionName: 'Ningxia',
        },
        {
          fullCode: 'CN-QH',
          regionCode: 'QH',
          regionName: 'Qinghai',
        },
        {
          fullCode: 'CN-SN',
          regionCode: 'SN',
          regionName: 'Shaanxi',
        },
        {
          fullCode: 'CN-SD',
          regionCode: 'SD',
          regionName: 'Shandong',
        },
        {
          fullCode: 'CN-SH',
          regionCode: 'SH',
          regionName: 'Shanghai',
        },
        {
          fullCode: 'CN-SX',
          regionCode: 'SX',
          regionName: 'Shanxi',
        },
        {
          fullCode: 'CN-SC',
          regionCode: 'SC',
          regionName: 'Sichuan',
        },
        {
          fullCode: 'CN-TW',
          regionCode: 'TW',
          regionName: 'Taiwan',
        },
        {
          fullCode: 'CN-TJ',
          regionCode: 'TJ',
          regionName: 'Tianjin',
        },
        {
          fullCode: 'CN-XJ',
          regionCode: 'XJ',
          regionName: 'Xinjiang',
        },
        {
          fullCode: 'CN-XZ',
          regionCode: 'XZ',
          regionName: 'Tibet',
        },
        {
          fullCode: 'CN-YN',
          regionCode: 'YN',
          regionName: 'Yunnan',
        },
        {
          fullCode: 'CN-ZJ',
          regionCode: 'ZJ',
          regionName: 'Zhejiang',
        },
      ],
    },
  ],
  [
    'colombia',
    {
      countryCode: 'CO',
      countryName: 'Colombia',
      regions: [
        {
          fullCode: 'CO-AMA',
          regionCode: 'AMA',
          regionName: 'Amazonia',
        },
        {
          fullCode: 'CO-ANT',
          regionCode: 'ANT',
          regionName: 'Antioquia',
        },
        {
          fullCode: 'CO-ARA',
          regionCode: 'ARA',
          regionName: 'Arauca',
        },
        {
          fullCode: 'CO-ATL',
          regionCode: 'ATL',
          regionName: 'Atlantico',
        },
        {
          fullCode: 'CO-BOL',
          regionCode: 'BOL',
          regionName: 'Bolivar',
        },
        {
          fullCode: 'CO-BOY',
          regionCode: 'BOY',
          regionName: 'Boyaca',
        },
        {
          fullCode: 'CO-CAL',
          regionCode: 'CAL',
          regionName: 'Caldas',
        },
        {
          fullCode: 'CO-CAQ',
          regionCode: 'CAQ',
          regionName: 'Caqueta',
        },
        {
          fullCode: 'CO-CAS',
          regionCode: 'CAS',
          regionName: 'Casanare',
        },
        {
          fullCode: 'CO-CAU',
          regionCode: 'CAU',
          regionName: 'Cauca',
        },
        {
          fullCode: 'CO-CES',
          regionCode: 'CES',
          regionName: 'Cesar',
        },
        {
          fullCode: 'CO-COR',
          regionCode: 'COR',
          regionName: 'Cordoba',
        },
        {
          fullCode: 'CO-CUN',
          regionCode: 'CUN',
          regionName: 'Cundinamarca',
        },
        {
          fullCode: 'CO-CHO',
          regionCode: 'CHO',
          regionName: 'Choco',
        },
        {
          fullCode: 'CO-DC',
          regionCode: 'DC',
          regionName: 'Bogota',
        },
        {
          fullCode: 'CO-GUA',
          regionCode: 'GUA',
          regionName: 'Guainia',
        },
        {
          fullCode: 'CO-GUV',
          regionCode: 'GUV',
          regionName: 'Guaviare',
        },
        {
          fullCode: 'CO-HUI',
          regionCode: 'HUI',
          regionName: 'Huila',
        },
        {
          fullCode: 'CO-LAG',
          regionCode: 'LAG',
          regionName: 'La Guajira',
        },
        {
          fullCode: 'CO-MAG',
          regionCode: 'MAG',
          regionName: 'Magdalena',
        },
        {
          fullCode: 'CO-MET',
          regionCode: 'MET',
          regionName: 'Meta',
        },
        {
          fullCode: 'CO-NAR',
          regionCode: 'NAR',
          regionName: 'Narino',
        },
        {
          fullCode: 'CO-NSA',
          regionCode: 'NSA',
          regionName: 'North Santander',
        },
        {
          fullCode: 'CO-PUT',
          regionCode: 'PUT',
          regionName: 'Putumayo',
        },
        {
          fullCode: 'CO-QUI',
          regionCode: 'QUI',
          regionName: 'Quindio',
        },
        {
          fullCode: 'CO-RIS',
          regionCode: 'RIS',
          regionName: 'Risaralda',
        },
        {
          fullCode: 'CO-SAP',
          regionCode: 'SAP',
          regionName: 'San Andres',
        },
        {
          fullCode: 'CO-SAN',
          regionCode: 'SAN',
          regionName: 'Santander',
        },
        {
          fullCode: 'CO-SUC',
          regionCode: 'SUC',
          regionName: 'Sucre',
        },
        {
          fullCode: 'CO-TOL',
          regionCode: 'TOL',
          regionName: 'Tolima',
        },
        {
          fullCode: 'CO-VAC',
          regionCode: 'VAC',
          regionName: 'Cauca Valley',
        },
        {
          fullCode: 'CO-VAU',
          regionCode: 'VAU',
          regionName: 'Vaupes',
        },
        {
          fullCode: 'CO-VID',
          regionCode: 'VID',
          regionName: 'Vichada',
        },
      ],
    },
  ],
  [
    'comoros',
    {
      countryCode: 'KM',
      countryName: 'Comoros',
      regions: [
        {
          fullCode: 'KM-G',
          regionCode: 'G',
          regionName: 'Grande Comore',
        },
        {
          fullCode: 'KM-A',
          regionCode: 'A',
          regionName: 'Anjouan',
        },
        {
          fullCode: 'KM-M',
          regionCode: 'M',
          regionName: 'Mohéli',
        },
      ],
    },
  ],
  [
    'congo',
    {
      countryCode: 'CG',
      countryName: 'Congo',
      regions: [
        {
          fullCode: 'CG-11',
          regionCode: '11',
          regionName: 'Bouenza',
        },
        {
          fullCode: 'CG-BZV',
          regionCode: 'BZV',
          regionName: 'Brazzaville',
        },
        {
          fullCode: 'CG-8',
          regionCode: '8',
          regionName: 'Cuvette',
        },
        {
          fullCode: 'CG-15',
          regionCode: '15',
          regionName: 'West Cuvette',
        },
        {
          fullCode: 'CG-5',
          regionCode: '5',
          regionName: 'Kouilou',
        },
        {
          fullCode: 'CG-2',
          regionCode: '2',
          regionName: 'Lekoumou',
        },
        {
          fullCode: 'CG-7',
          regionCode: '7',
          regionName: 'Likouala',
        },
        {
          fullCode: 'CG-9',
          regionCode: '9',
          regionName: 'Niari',
        },
        {
          fullCode: 'CG-14',
          regionCode: '14',
          regionName: 'Plateaux',
        },
        {
          fullCode: 'CG-16',
          regionCode: '16',
          regionName: 'Black Point',
        },
        {
          fullCode: 'CG-12',
          regionCode: '12',
          regionName: 'Pool',
        },
        {
          fullCode: 'CG-13',
          regionCode: '13',
          regionName: 'Sangha',
        },
      ],
    },
  ],
  [
    'congo, democratic republic of the',
    {
      countryCode: 'CD',
      countryName: 'Congo, Democratic Republic of the',
      regions: [
        {
          fullCode: 'CD-BU',
          regionCode: 'BU',
          regionName: 'Lower Uele',
        },
        {
          fullCode: 'CD-EQ',
          regionCode: 'EQ',
          regionName: 'Equator',
        },
        {
          fullCode: 'CD-HK',
          regionCode: 'HK',
          regionName: 'Upper Katanga',
        },
        {
          fullCode: 'CD-HL',
          regionCode: 'HL',
          regionName: 'Upper Lomami',
        },
        {
          fullCode: 'CD-HU',
          regionCode: 'HU',
          regionName: 'Upper Uele',
        },
        {
          fullCode: 'CD-IT',
          regionCode: 'IT',
          regionName: 'Ituri',
        },
        {
          fullCode: 'CD-KS',
          regionCode: 'KS',
          regionName: 'Kasai',
        },
        {
          fullCode: 'CD-KC',
          regionCode: 'KC',
          regionName: 'Central Kasai',
        },
        {
          fullCode: 'CD-KE',
          regionCode: 'KE',
          regionName: 'Eastern Kasai',
        },
        {
          fullCode: 'CD-KN',
          regionCode: 'KN',
          regionName: 'Kinshasa',
        },
        {
          fullCode: 'CD-BC',
          regionCode: 'BC',
          regionName: 'Central Kongo',
        },
        {
          fullCode: 'CD-KG',
          regionCode: 'KG',
          regionName: 'Kwango',
        },
        {
          fullCode: 'CD-KL',
          regionCode: 'KL',
          regionName: 'Kwilu',
        },
        {
          fullCode: 'CD-LO',
          regionCode: 'LO',
          regionName: 'Lomami',
        },
        {
          fullCode: 'CD-LU',
          regionCode: 'LU',
          regionName: 'Lualaba',
        },
        {
          fullCode: 'CD-MN',
          regionCode: 'MN',
          regionName: 'Mai-Ndombe',
        },
        {
          fullCode: 'CD-MA',
          regionCode: 'MA',
          regionName: 'Maniema',
        },
        {
          fullCode: 'CD-MO',
          regionCode: 'MO',
          regionName: 'Mongala',
        },
        {
          fullCode: 'CD-NK',
          regionCode: 'NK',
          regionName: 'North Kivu',
        },
        {
          fullCode: 'CD-NU',
          regionCode: 'NU',
          regionName: 'North Ubangi',
        },
        {
          fullCode: 'CD-SA',
          regionCode: 'SA',
          regionName: 'Sankuru',
        },
        {
          fullCode: 'CD-SK',
          regionCode: 'SK',
          regionName: 'South Kivu',
        },
        {
          fullCode: 'CD-SU',
          regionCode: 'SU',
          regionName: 'South Ubangi',
        },
        {
          fullCode: 'CD-TA',
          regionCode: 'TA',
          regionName: 'Tanganyika',
        },
        {
          fullCode: 'CD-TO',
          regionCode: 'TO',
          regionName: 'Tshopo',
        },
        {
          fullCode: 'CD-TU',
          regionCode: 'TU',
          regionName: 'Tshuapa',
        },
      ],
    },
  ],
  [
    'costa rica',
    {
      countryCode: 'CR',
      countryName: 'Costa Rica',
      regions: [
        {
          fullCode: 'CR-A',
          regionCode: 'A',
          regionName: 'Alajuela',
        },
        {
          fullCode: 'CR-C',
          regionCode: 'C',
          regionName: 'Cartago',
        },
        {
          fullCode: 'CR-G',
          regionCode: 'G',
          regionName: 'Guanacaste',
        },
        {
          fullCode: 'CR-H',
          regionCode: 'H',
          regionName: 'Heredia',
        },
        {
          fullCode: 'CR-L',
          regionCode: 'L',
          regionName: 'Limón',
        },
        {
          fullCode: 'CR-P',
          regionCode: 'P',
          regionName: 'Puntarenas',
        },
        {
          fullCode: 'CR-SJ',
          regionCode: 'SJ',
          regionName: 'San José',
        },
      ],
    },
  ],
  [
    "Côte d'Ivoire",
    {
      countryCode: 'CI',
      countryName: "Côte d'Ivoire",
      regions: [
        {
          fullCode: 'CI-AB',
          regionCode: 'AB',
          regionName: 'Abidjan',
        },
        {
          fullCode: 'CI-BS',
          regionCode: 'BS',
          regionName: 'Lower Sassandra',
        },
        {
          fullCode: 'CI-CM',
          regionCode: 'CM',
          regionName: 'Comoé',
        },
        {
          fullCode: 'CI-DN',
          regionCode: 'DN',
          regionName: 'Denguélé',
        },
        {
          fullCode: 'CI-GD',
          regionCode: 'GD',
          regionName: 'Gôh-Djiboua',
        },
        {
          fullCode: 'CI-LC',
          regionCode: 'LC',
          regionName: 'Lakes',
        },
        {
          fullCode: 'CI-LG',
          regionCode: 'LG',
          regionName: 'Lagoons',
        },
        {
          fullCode: 'CI-MG',
          regionCode: 'MG',
          regionName: 'Mountains',
        },
        {
          fullCode: 'CI-SM',
          regionCode: 'SM',
          regionName: 'Sassandra-Marahoué',
        },
        {
          fullCode: 'CI-SV',
          regionCode: 'SV',
          regionName: 'Savannahs',
        },
        {
          fullCode: 'CI-VB',
          regionCode: 'VB',
          regionName: 'Bandama Valley',
        },
        {
          fullCode: 'CI-WR',
          regionCode: 'WR',
          regionName: 'Woroba',
        },
        {
          fullCode: 'CI-YM',
          regionCode: 'YM',
          regionName: 'Yamoussoukro',
        },
        {
          fullCode: 'CI-ZZ',
          regionCode: 'ZZ',
          regionName: 'Zanzan',
        },
        {
          fullCode: 'CI-06',
          regionCode: '06',
          regionName: '18 Montagnes (Région des)',
        },
        {
          fullCode: 'CI-16',
          regionCode: '16',
          regionName: "Agnébi (Région de l\\')",
        },
        {
          fullCode: 'CI-17',
          regionCode: '17',
          regionName: 'Bafing (Région du)',
        },
        {
          fullCode: 'CI-09',
          regionCode: '09',
          regionName: 'Bas-Sassandra (Région du)',
        },
        {
          fullCode: 'CI-10',
          regionCode: '10',
          regionName: 'Denguélé (Région du)',
        },
        {
          fullCode: 'CI-18',
          regionCode: '18',
          regionName: 'Fromager (Région du)',
        },
        {
          fullCode: 'CI-02',
          regionCode: '02',
          regionName: 'Haut-Sassandra (Région du)',
        },
        {
          fullCode: 'CI-07',
          regionCode: '07',
          regionName: 'Lacs (Région des)',
        },
        {
          fullCode: 'CI-01',
          regionCode: '01',
          regionName: 'Lagunes (Région des)',
        },
        {
          fullCode: 'CI-12',
          regionCode: '12',
          regionName: 'Marahoué (Région de la)',
        },
        {
          fullCode: 'CI-19',
          regionCode: '19',
          regionName: 'Moyen-Cavally (Région du)',
        },
        {
          fullCode: 'CI-05',
          regionCode: '05',
          regionName: 'Moyen-Comoé (Région du)',
        },
        {
          fullCode: 'CI-11',
          regionCode: '11',
          regionName: 'Nzi-Comoé (Région)',
        },
        {
          fullCode: 'CI-03',
          regionCode: '03',
          regionName: 'Savanes (Région des)',
        },
        {
          fullCode: 'CI-15',
          regionCode: '15',
          regionName: 'Sud-Bandama (Région du)',
        },
        {
          fullCode: 'CI-13',
          regionCode: '13',
          regionName: 'Sud-Comoé (Région du)',
        },
        {
          fullCode: 'CI-04',
          regionCode: '04',
          regionName: 'Vallée du Bandama (Région de la)',
        },
        {
          fullCode: 'CI-14',
          regionCode: '14',
          regionName: 'Worodougou (Région du)',
        },
        {
          fullCode: 'CI-08',
          regionCode: '08',
          regionName: 'Zanzan (Région du)',
        },
      ],
    },
  ],
  [
    'croatia',
    {
      countryCode: 'HR',
      countryName: 'Croatia',
      regions: [
        {
          fullCode: 'HR-01',
          regionCode: '01',
          regionName: 'Zagreb County',
        },
        {
          fullCode: 'HR-02',
          regionCode: '02',
          regionName: 'Krapina-Zagorje',
        },
        {
          fullCode: 'HR-03',
          regionCode: '03',
          regionName: 'Sisak-Moslavina',
        },
        {
          fullCode: 'HR-04',
          regionCode: '04',
          regionName: 'Karlovac',
        },
        {
          fullCode: 'HR-05',
          regionCode: '05',
          regionName: 'Varazdin',
        },
        {
          fullCode: 'HR-06',
          regionCode: '06',
          regionName: 'Koprivnica-Krizevci',
        },
        {
          fullCode: 'HR-07',
          regionCode: '07',
          regionName: 'Bjelovar-Bilogora',
        },
        {
          fullCode: 'HR-08',
          regionCode: '08',
          regionName: 'Primorje-Gorski Kotar',
        },
        {
          fullCode: 'HR-09',
          regionCode: '09',
          regionName: 'Lika-Senj',
        },
        {
          fullCode: 'HR-10',
          regionCode: '10',
          regionName: 'Virovitica-Podravina',
        },
        {
          fullCode: 'HR-11',
          regionCode: '11',
          regionName: 'Pozega-Slavonia',
        },
        {
          fullCode: 'HR-12',
          regionCode: '12',
          regionName: 'Brod-Posavina',
        },
        {
          fullCode: 'HR-13',
          regionCode: '13',
          regionName: 'Zadar',
        },
        {
          fullCode: 'HR-14',
          regionCode: '14',
          regionName: 'Osijek-Baranja',
        },
        {
          fullCode: 'HR-15',
          regionCode: '15',
          regionName: 'Sibenik-Knin',
        },
        {
          fullCode: 'HR-16',
          regionCode: '16',
          regionName: 'Vukovar-Srijem',
        },
        {
          fullCode: 'HR-17',
          regionCode: '17',
          regionName: 'Split-Dalmatia',
        },
        {
          fullCode: 'HR-18',
          regionCode: '18',
          regionName: 'Istria',
        },
        {
          fullCode: 'HR-19',
          regionCode: '19',
          regionName: 'Dubrovnik-Neretva',
        },
        {
          fullCode: 'HR-20',
          regionCode: '20',
          regionName: 'Medimurje',
        },
        {
          fullCode: 'HR-21',
          regionCode: '21',
          regionName: 'Zagreb City',
        },
      ],
    },
  ],
  [
    'cuba',
    {
      countryCode: 'CU',
      countryName: 'Cuba',
      regions: [
        {
          fullCode: 'CU-15',
          regionCode: '15',
          regionName: 'Artemisa',
        },
        {
          fullCode: 'CU-09',
          regionCode: '09',
          regionName: 'Camaguey',
        },
        {
          fullCode: 'CU-08',
          regionCode: '08',
          regionName: 'Ciego de Avila',
        },
        {
          fullCode: 'CU-06',
          regionCode: '06',
          regionName: 'Cienfuegos',
        },
        {
          fullCode: 'CU-12',
          regionCode: '12',
          regionName: 'Granma',
        },
        {
          fullCode: 'CU-14',
          regionCode: '14',
          regionName: 'Guantanamo',
        },
        {
          fullCode: 'CU-11',
          regionCode: '11',
          regionName: 'Holguin',
        },
        {
          fullCode: 'CU-03',
          regionCode: '03',
          regionName: 'Havana',
        },
        {
          fullCode: 'CU-10',
          regionCode: '10',
          regionName: 'Las Tunas',
        },
        {
          fullCode: 'CU-04',
          regionCode: '04',
          regionName: 'Matanzas',
        },
        {
          fullCode: 'CU-16',
          regionCode: '16',
          regionName: 'Mayabeque',
        },
        {
          fullCode: 'CU-01',
          regionCode: '01',
          regionName: 'Pinar del Rio',
        },
        {
          fullCode: 'CU-07',
          regionCode: '07',
          regionName: 'Holy Spirit',
        },
        {
          fullCode: 'CU-13',
          regionCode: '13',
          regionName: 'Santiago',
        },
        {
          fullCode: 'CU-05',
          regionCode: '05',
          regionName: 'Clara City',
        },
        {
          fullCode: 'CU-99',
          regionCode: '99',
          regionName: 'Isle of Youth',
        },
      ],
    },
  ],
  [
    'cyprus',
    {
      countryCode: 'CY',
      countryName: 'Cyprus',
      regions: [
        {
          fullCode: 'CY-04',
          regionCode: '04',
          regionName: 'Famagusta',
        },
        {
          fullCode: 'CY-06',
          regionCode: '06',
          regionName: 'Kyrenia',
        },
        {
          fullCode: 'CY-03',
          regionCode: '03',
          regionName: 'Larnaca',
        },
        {
          fullCode: 'CY-01',
          regionCode: '01',
          regionName: 'Nicosia',
        },
        {
          fullCode: 'CY-02',
          regionCode: '02',
          regionName: 'Limassol',
        },
        {
          fullCode: 'CY-05',
          regionCode: '05',
          regionName: 'Paphos',
        },
      ],
    },
  ],
  [
    'czechia',
    {
      countryCode: 'CZ',
      countryName: 'Czechia',
      regions: [
        {
          fullCode: 'CZ-31',
          regionCode: '31',
          regionName: 'South Bohemia',
        },
        {
          fullCode: 'CZ-64',
          regionCode: '64',
          regionName: 'South Moravia',
        },
        {
          fullCode: 'CZ-41',
          regionCode: '41',
          regionName: 'Karlovy Vary',
        },
        {
          fullCode: 'CZ-52',
          regionCode: '52',
          regionName: 'Hradec Králové',
        },
        {
          fullCode: 'CZ-51',
          regionCode: '51',
          regionName: 'Liberec',
        },
        {
          fullCode: 'CZ-80',
          regionCode: '80',
          regionName: 'Moravia-Silesia',
        },
        {
          fullCode: 'CZ-71',
          regionCode: '71',
          regionName: 'Olomouc',
        },
        {
          fullCode: 'CZ-53',
          regionCode: '53',
          regionName: 'Pardubice',
        },
        {
          fullCode: 'CZ-32',
          regionCode: '32',
          regionName: 'Plzeň',
        },
        {
          fullCode: 'CZ-10',
          regionCode: '10',
          regionName: 'Prague',
        },
        {
          fullCode: 'CZ-20',
          regionCode: '20',
          regionName: 'Central Bohemia',
        },
        {
          fullCode: 'CZ-42',
          regionCode: '42',
          regionName: 'Ústí nad Labem',
        },
        {
          fullCode: 'CZ-63',
          regionCode: '63',
          regionName: 'Vysočina',
        },
        {
          fullCode: 'CZ-72',
          regionCode: '72',
          regionName: 'Zlín',
        },
      ],
    },
  ],
  [
    'denmark',
    {
      countryCode: 'DK',
      countryName: 'Denmark',
      regions: [
        {
          fullCode: 'DK-84',
          regionCode: '84',
          regionName: 'Capital Region',
        },
        {
          fullCode: 'DK-82',
          regionCode: '82',
          regionName: 'Central Denmark',
        },
        {
          fullCode: 'DK-81',
          regionCode: '81',
          regionName: 'North Denmark',
        },
        {
          fullCode: 'DK-85',
          regionCode: '85',
          regionName: 'Zealand',
        },
        {
          fullCode: 'DK-83',
          regionCode: '83',
          regionName: 'Southern Denmark',
        },
        {
          fullCode: 'DK-015',
          regionCode: '015',
          regionName: 'København',
        },
        {
          fullCode: 'DK-020',
          regionCode: '020',
          regionName: 'Frederiksborg',
        },
        {
          fullCode: 'DK-025',
          regionCode: '025',
          regionName: 'Roskilde',
        },
        {
          fullCode: 'DK-030',
          regionCode: '030',
          regionName: 'Vestsjælland',
        },
        {
          fullCode: 'DK-035',
          regionCode: '035',
          regionName: 'Storstrøm',
        },
        {
          fullCode: 'DK-040',
          regionCode: '040',
          regionName: 'Bornholm',
        },
        {
          fullCode: 'DK-042',
          regionCode: '042',
          regionName: 'Fyn',
        },
        {
          fullCode: 'DK-050',
          regionCode: '050',
          regionName: 'Sønderjylland',
        },
        {
          fullCode: 'DK-055',
          regionCode: '055',
          regionName: 'Ribe',
        },
        {
          fullCode: 'DK-060',
          regionCode: '060',
          regionName: 'Vejle',
        },
        {
          fullCode: 'DK-065',
          regionCode: '065',
          regionName: 'Ringkøbing',
        },
        {
          fullCode: 'DK-070',
          regionCode: '070',
          regionName: 'Århus',
        },
        {
          fullCode: 'DK-076',
          regionCode: '076',
          regionName: 'Viborg',
        },
        {
          fullCode: 'DK-080',
          regionCode: '080',
          regionName: 'Nordjylland',
        },
        {
          fullCode: 'DK-101',
          regionCode: '101',
          regionName: 'København',
        },
        {
          fullCode: 'DK-147',
          regionCode: '147',
          regionName: 'Frederiksberg',
        },
      ],
    },
  ],
  [
    'djibouti',
    {
      countryCode: 'DJ',
      countryName: 'Djibouti',
      regions: [
        {
          fullCode: 'DJ-AS',
          regionCode: 'AS',
          regionName: 'Ali Sabieh',
        },
        {
          fullCode: 'DJ-AR',
          regionCode: 'AR',
          regionName: 'Arta',
        },
        {
          fullCode: 'DJ-DI',
          regionCode: 'DI',
          regionName: 'Dikhil',
        },
        {
          fullCode: 'DJ-DJ',
          regionCode: 'DJ',
          regionName: 'Djibouti',
        },
        {
          fullCode: 'DJ-OB',
          regionCode: 'OB',
          regionName: 'Obock',
        },
        {
          fullCode: 'DJ-TA',
          regionCode: 'TA',
          regionName: 'Tadjourah',
        },
      ],
    },
  ],
  [
    'dominica',
    {
      countryCode: 'DM',
      countryName: 'Dominica',
      regions: [
        {
          fullCode: 'DM-02',
          regionCode: '02',
          regionName: 'Saint Andrew',
        },
        {
          fullCode: 'DM-03',
          regionCode: '03',
          regionName: 'Saint David',
        },
        {
          fullCode: 'DM-04',
          regionCode: '04',
          regionName: 'Saint George',
        },
        {
          fullCode: 'DM-05',
          regionCode: '05',
          regionName: 'Saint John',
        },
        {
          fullCode: 'DM-06',
          regionCode: '06',
          regionName: 'Saint Joseph',
        },
        {
          fullCode: 'DM-07',
          regionCode: '07',
          regionName: 'Saint Luke',
        },
        {
          fullCode: 'DM-08',
          regionCode: '08',
          regionName: 'Saint Mark',
        },
        {
          fullCode: 'DM-09',
          regionCode: '09',
          regionName: 'Saint Patrick',
        },
        {
          fullCode: 'DM-10',
          regionCode: '10',
          regionName: 'Saint Paul',
        },
        {
          fullCode: 'DM-11',
          regionCode: '11',
          regionName: 'Saint Peter',
        },
      ],
    },
  ],
  [
    'dominican republic',
    {
      countryCode: 'DO',
      countryName: 'Dominican Republic',
      regions: [
        {
          fullCode: 'DO-33',
          regionCode: '33',
          regionName: 'Northeast Cibao',
        },
        {
          fullCode: 'DO-34',
          regionCode: '34',
          regionName: 'Northwest Cibao',
        },
        {
          fullCode: 'DO-35',
          regionCode: '35',
          regionName: 'North Cibao',
        },
        {
          fullCode: 'DO-36',
          regionCode: '36',
          regionName: 'South Cibao',
        },
        {
          fullCode: 'DO-37',
          regionCode: '37',
          regionName: 'The Valley',
        },
        {
          fullCode: 'DO-38',
          regionCode: '38',
          regionName: 'Enriquillo',
        },
        {
          fullCode: 'DO-39',
          regionCode: '39',
          regionName: 'Higuamo',
        },
        {
          fullCode: 'DO-40',
          regionCode: '40',
          regionName: 'Ozama',
        },
        {
          fullCode: 'DO-41',
          regionCode: '41',
          regionName: 'Valdesia',
        },
        {
          fullCode: 'DO-42',
          regionCode: '42',
          regionName: 'Yuma',
        },
        {
          fullCode: 'DO-02',
          regionCode: '02',
          regionName: 'Azua',
        },
        {
          fullCode: 'DO-03',
          regionCode: '03',
          regionName: 'Baoruco',
        },
        {
          fullCode: 'DO-04',
          regionCode: '04',
          regionName: 'Barahona',
        },
        {
          fullCode: 'DO-05',
          regionCode: '05',
          regionName: 'Dajabon',
        },
        {
          fullCode: 'DO-01',
          regionCode: '01',
          regionName: 'Santo Domingo City',
        },
        {
          fullCode: 'DO-06',
          regionCode: '06',
          regionName: 'Duarte',
        },
        {
          fullCode: 'DO-08',
          regionCode: '08',
          regionName: 'El Seibo',
        },
        {
          fullCode: 'DO-07',
          regionCode: '07',
          regionName: 'Elias Pina',
        },
        {
          fullCode: 'DO-09',
          regionCode: '09',
          regionName: 'Espaillat',
        },
        {
          fullCode: 'DO-30',
          regionCode: '30',
          regionName: 'Great Herd',
        },
        {
          fullCode: 'DO-19',
          regionCode: '19',
          regionName: 'Mirabal Sisters',
        },
        {
          fullCode: 'DO-10',
          regionCode: '10',
          regionName: 'Independence',
        },
        {
          fullCode: 'DO-11',
          regionCode: '11',
          regionName: 'La Altagracia',
        },
        {
          fullCode: 'DO-12',
          regionCode: '12',
          regionName: 'La Romana',
        },
        {
          fullCode: 'DO-13',
          regionCode: '13',
          regionName: 'La Vega',
        },
        {
          fullCode: 'DO-14',
          regionCode: '14',
          regionName: 'Maria Trinidad Sanchez',
        },
        {
          fullCode: 'DO-28',
          regionCode: '28',
          regionName: 'Monsignor Nouel',
        },
        {
          fullCode: 'DO-15',
          regionCode: '15',
          regionName: 'Christ Mountain',
        },
        {
          fullCode: 'DO-29',
          regionCode: '29',
          regionName: 'Silver Mountain',
        },
        {
          fullCode: 'DO-16',
          regionCode: '16',
          regionName: 'Pedernales',
        },
        {
          fullCode: 'DO-17',
          regionCode: '17',
          regionName: 'Peravia',
        },
        {
          fullCode: 'DO-18',
          regionCode: '18',
          regionName: 'Silver Port',
        },
        {
          fullCode: 'DO-20',
          regionCode: '20',
          regionName: 'Samana',
        },
        {
          fullCode: 'DO-21',
          regionCode: '21',
          regionName: 'Saint Christopher',
        },
        {
          fullCode: 'DO-31',
          regionCode: '31',
          regionName: 'Saint Joseph',
        },
        {
          fullCode: 'DO-22',
          regionCode: '22',
          regionName: 'Saint John',
        },
        {
          fullCode: 'DO-23',
          regionCode: '23',
          regionName: 'Saint Peter',
        },
        {
          fullCode: 'DO-24',
          regionCode: '24',
          regionName: 'Sanchez Ramirez',
        },
        {
          fullCode: 'DO-25',
          regionCode: '25',
          regionName: 'Santiago',
        },
        {
          fullCode: 'DO-26',
          regionCode: '26',
          regionName: 'Santiago Rodriguez',
        },
        {
          fullCode: 'DO-32',
          regionCode: '32',
          regionName: 'Santo Domingo',
        },
        {
          fullCode: 'DO-27',
          regionCode: '27',
          regionName: 'Valverde',
        },
        {
          fullCode: 'DO-DN',
          regionCode: 'DN',
          regionName: 'Distrito Nacional (Santo Domingo)',
        },
        {
          fullCode: 'DO-AZ',
          regionCode: 'AZ',
          regionName: 'Azua',
        },
        {
          fullCode: 'DO-BR',
          regionCode: 'BR',
          regionName: 'Bahoruco',
        },
        {
          fullCode: 'DO-BH',
          regionCode: 'BH',
          regionName: 'Barahona',
        },
        {
          fullCode: 'DO-DA',
          regionCode: 'DA',
          regionName: 'Dajabón',
        },
        {
          fullCode: 'DO-DU',
          regionCode: 'DU',
          regionName: 'Duarte',
        },
        {
          fullCode: 'DO-SE',
          regionCode: 'SE',
          regionName: 'El Seybo [El Seibo]',
        },
        {
          fullCode: 'DO-EP',
          regionCode: 'EP',
          regionName: 'Espaillat',
        },
        {
          fullCode: 'DO-HM',
          regionCode: 'HM',
          regionName: 'Hato Mayor',
        },
        {
          fullCode: 'DO-IN',
          regionCode: 'IN',
          regionName: 'Independencia',
        },
        {
          fullCode: 'DO-AL',
          regionCode: 'AL',
          regionName: 'La Altagracia',
        },
        {
          fullCode: 'DO-EP',
          regionCode: 'EP',
          regionName: 'La Estrelleta [Elías Piña]',
        },
        {
          fullCode: 'DO-RO',
          regionCode: 'RO',
          regionName: 'La Romana',
        },
        {
          fullCode: 'DO-VE',
          regionCode: 'VE',
          regionName: 'La Vega',
        },
        {
          fullCode: 'DO-MT',
          regionCode: 'MT',
          regionName: 'María Trinidad Sánchez',
        },
        {
          fullCode: 'DO-MN',
          regionCode: 'MN',
          regionName: 'Monseñor Nouel',
        },
        {
          fullCode: 'DO-MC',
          regionCode: 'MC',
          regionName: 'Monte Cristi',
        },
        {
          fullCode: 'DO-MP',
          regionCode: 'MP',
          regionName: 'Monte Plata',
        },
        {
          fullCode: 'DO-PN',
          regionCode: 'PN',
          regionName: 'Pedernales',
        },
        {
          fullCode: 'DO-PR',
          regionCode: 'PR',
          regionName: 'Peravia',
        },
        {
          fullCode: 'DO-PP',
          regionCode: 'PP',
          regionName: 'Puerto Plata',
        },
        {
          fullCode: 'DO-SC',
          regionCode: 'SC',
          regionName: 'Salcedo',
        },
        {
          fullCode: 'DO-SM',
          regionCode: 'SM',
          regionName: 'Samaná',
        },
        {
          fullCode: 'DO-CR',
          regionCode: 'CR',
          regionName: 'San Cristóbal',
        },
        {
          fullCode: 'DO-JU',
          regionCode: 'JU',
          regionName: 'San Juan',
        },
        {
          fullCode: 'DO-PM',
          regionCode: 'PM',
          regionName: 'San Pedro de Macorís',
        },
        {
          fullCode: 'DO-SZ',
          regionCode: 'SZ',
          regionName: 'Sánchez Ramírez',
        },
        {
          fullCode: 'DO-ST',
          regionCode: 'ST',
          regionName: 'Santiago',
        },
        {
          fullCode: 'DO-SR',
          regionCode: 'SR',
          regionName: 'Santiago Rodríguez',
        },
        {
          fullCode: 'DO-VA',
          regionCode: 'VA',
          regionName: 'Valverde',
        },
      ],
    },
  ],
  [
    'ecuador',
    {
      countryCode: 'EC',
      countryName: 'Ecuador',
      regions: [
        {
          fullCode: 'EC-A',
          regionCode: 'A',
          regionName: 'Azuay',
        },
        {
          fullCode: 'EC-B',
          regionCode: 'B',
          regionName: 'Bolivar',
        },
        {
          fullCode: 'EC-F',
          regionCode: 'F',
          regionName: 'Canar',
        },
        {
          fullCode: 'EC-C',
          regionCode: 'C',
          regionName: 'Carchi',
        },
        {
          fullCode: 'EC-H',
          regionCode: 'H',
          regionName: 'Chimborazo',
        },
        {
          fullCode: 'EC-X',
          regionCode: 'X',
          regionName: 'Cotopaxi',
        },
        {
          fullCode: 'EC-O',
          regionCode: 'O',
          regionName: 'The Gold',
        },
        {
          fullCode: 'EC-E',
          regionCode: 'E',
          regionName: 'Esmeraldas',
        },
        {
          fullCode: 'EC-W',
          regionCode: 'W',
          regionName: 'Galapagos',
        },
        {
          fullCode: 'EC-G',
          regionCode: 'G',
          regionName: 'Guayas',
        },
        {
          fullCode: 'EC-I',
          regionCode: 'I',
          regionName: 'Imbabura',
        },
        {
          fullCode: 'EC-L',
          regionCode: 'L',
          regionName: 'Loja',
        },
        {
          fullCode: 'EC-R',
          regionCode: 'R',
          regionName: 'The Rivers',
        },
        {
          fullCode: 'EC-M',
          regionCode: 'M',
          regionName: 'Manabi',
        },
        {
          fullCode: 'EC-S',
          regionCode: 'S',
          regionName: 'Morona Santiago',
        },
        {
          fullCode: 'EC-N',
          regionCode: 'N',
          regionName: 'Napo',
        },
        {
          fullCode: 'EC-D',
          regionCode: 'D',
          regionName: 'Orellana',
        },
        {
          fullCode: 'EC-Y',
          regionCode: 'Y',
          regionName: 'Pastaza',
        },
        {
          fullCode: 'EC-P',
          regionCode: 'P',
          regionName: 'Pichincha',
        },
        {
          fullCode: 'EC-SE',
          regionCode: 'SE',
          regionName: 'Saint Helen',
        },
        {
          fullCode: 'EC-SD',
          regionCode: 'SD',
          regionName: 'Saint Dominic',
        },
        {
          fullCode: 'EC-U',
          regionCode: 'U',
          regionName: 'Sucumbios',
        },
        {
          fullCode: 'EC-T',
          regionCode: 'T',
          regionName: 'Tungurahua',
        },
        {
          fullCode: 'EC-Z',
          regionCode: 'Z',
          regionName: 'Zamora Chinchipe',
        },
      ],
    },
  ],
  [
    'egypt',
    {
      countryCode: 'EG',
      countryName: 'Egypt',
      regions: [
        {
          fullCode: 'EG-DK',
          regionCode: 'DK',
          regionName: 'Dakahlia',
        },
        {
          fullCode: 'EG-BA',
          regionCode: 'BA',
          regionName: 'Red Sea',
        },
        {
          fullCode: 'EG-BH',
          regionCode: 'BH',
          regionName: 'Beheira',
        },
        {
          fullCode: 'EG-FYM',
          regionCode: 'FYM',
          regionName: 'Faiyum',
        },
        {
          fullCode: 'EG-GH',
          regionCode: 'GH',
          regionName: 'Gharbia',
        },
        {
          fullCode: 'EG-ALX',
          regionCode: 'ALX',
          regionName: 'Alexandria',
        },
        {
          fullCode: 'EG-IS',
          regionCode: 'IS',
          regionName: 'Ismailia',
        },
        {
          fullCode: 'EG-GZ',
          regionCode: 'GZ',
          regionName: 'Giza',
        },
        {
          fullCode: 'EG-MNF',
          regionCode: 'MNF',
          regionName: 'Monufia',
        },
        {
          fullCode: 'EG-MN',
          regionCode: 'MN',
          regionName: 'Minya',
        },
        {
          fullCode: 'EG-C',
          regionCode: 'C',
          regionName: 'Cairo',
        },
        {
          fullCode: 'EG-KB',
          regionCode: 'KB',
          regionName: 'Qalyubia',
        },
        {
          fullCode: 'EG-LX',
          regionCode: 'LX',
          regionName: 'Luxor',
        },
        {
          fullCode: 'EG-WAD',
          regionCode: 'WAD',
          regionName: 'New Valley',
        },
        {
          fullCode: 'EG-SUZ',
          regionCode: 'SUZ',
          regionName: 'Suez',
        },
        {
          fullCode: 'EG-SHR',
          regionCode: 'SHR',
          regionName: 'Al Sharqia',
        },
        {
          fullCode: 'EG-ASN',
          regionCode: 'ASN',
          regionName: 'Aswan',
        },
        {
          fullCode: 'EG-AST',
          regionCode: 'AST',
          regionName: 'Asyut',
        },
        {
          fullCode: 'EG-BNS',
          regionCode: 'BNS',
          regionName: 'Beni Suef',
        },
        {
          fullCode: 'EG-PTS',
          regionCode: 'PTS',
          regionName: 'Port Said',
        },
        {
          fullCode: 'EG-DT',
          regionCode: 'DT',
          regionName: 'Damietta',
        },
        {
          fullCode: 'EG-JS',
          regionCode: 'JS',
          regionName: 'South Sinai',
        },
        {
          fullCode: 'EG-KFS',
          regionCode: 'KFS',
          regionName: 'Kafr el-Sheikh',
        },
        {
          fullCode: 'EG-MT',
          regionCode: 'MT',
          regionName: 'Matrouh',
        },
        {
          fullCode: 'EG-KN',
          regionCode: 'KN',
          regionName: 'Qena',
        },
        {
          fullCode: 'EG-SIN',
          regionCode: 'SIN',
          regionName: 'North Sinai',
        },
        {
          fullCode: 'EG-SHG',
          regionCode: 'SHG',
          regionName: 'Sohag',
        },
      ],
    },
  ],
  [
    'el salvador',
    {
      countryCode: 'SV',
      countryName: 'El Salvador',
      regions: [
        {
          fullCode: 'SV-AH',
          regionCode: 'AH',
          regionName: 'Ahuachapán',
        },
        {
          fullCode: 'SV-CA',
          regionCode: 'CA',
          regionName: 'Cabañas',
        },
        {
          fullCode: 'SV-CH',
          regionCode: 'CH',
          regionName: 'Chalatenango',
        },
        {
          fullCode: 'SV-CU',
          regionCode: 'CU',
          regionName: 'Cuscatlán',
        },
        {
          fullCode: 'SV-LI',
          regionCode: 'LI',
          regionName: 'La Libertad',
        },
        {
          fullCode: 'SV-PA',
          regionCode: 'PA',
          regionName: 'La Paz',
        },
        {
          fullCode: 'SV-UN',
          regionCode: 'UN',
          regionName: 'La Unión',
        },
        {
          fullCode: 'SV-MO',
          regionCode: 'MO',
          regionName: 'Morazán',
        },
        {
          fullCode: 'SV-SM',
          regionCode: 'SM',
          regionName: 'San Miguel',
        },
        {
          fullCode: 'SV-SS',
          regionCode: 'SS',
          regionName: 'San Salvador',
        },
        {
          fullCode: 'SV-SV',
          regionCode: 'SV',
          regionName: 'San Vicente',
        },
        {
          fullCode: 'SV-SA',
          regionCode: 'SA',
          regionName: 'Santa Ana',
        },
        {
          fullCode: 'SV-SO',
          regionCode: 'SO',
          regionName: 'Sonsonate',
        },
        {
          fullCode: 'SV-US',
          regionCode: 'US',
          regionName: 'Usulután',
        },
      ],
    },
  ],
  [
    'equatorial guinea',
    {
      countryCode: 'GQ',
      countryName: 'Equatorial Guinea',
      regions: [
        {
          fullCode: 'GQ-C',
          regionCode: 'C',
          regionName: 'Continental',
        },
        {
          fullCode: 'GQ-I',
          regionCode: 'I',
          regionName: 'Insular',
        },
        {
          fullCode: 'GQ-AN',
          regionCode: 'AN',
          regionName: 'Annobon',
        },
        {
          fullCode: 'GQ-BN',
          regionCode: 'BN',
          regionName: 'North Bioko',
        },
        {
          fullCode: 'GQ-BS',
          regionCode: 'BS',
          regionName: 'South Bioko',
        },
        {
          fullCode: 'GQ-CS',
          regionCode: 'CS',
          regionName: 'South Center',
        },
        {
          fullCode: 'GQ-DJ',
          regionCode: 'DJ',
          regionName: 'Djibloho',
        },
        {
          fullCode: 'GQ-KN',
          regionCode: 'KN',
          regionName: 'Kie-Ntem',
        },
        {
          fullCode: 'GQ-LI',
          regionCode: 'LI',
          regionName: 'Coast',
        },
        {
          fullCode: 'GQ-WN',
          regionCode: 'WN',
          regionName: 'Wele-Nzas',
        },
      ],
    },
  ],
  [
    'eritrea',
    {
      countryCode: 'ER',
      countryName: 'Eritrea',
      regions: [
        {
          fullCode: 'ER-MA',
          regionCode: 'MA',
          regionName: 'Central',
        },
        {
          fullCode: 'ER-DU',
          regionCode: 'DU',
          regionName: 'Southern',
        },
        {
          fullCode: 'ER-AN',
          regionCode: 'AN',
          regionName: 'Anseba',
        },
        {
          fullCode: 'ER-DK',
          regionCode: 'DK',
          regionName: 'Southern Red Sea',
        },
        {
          fullCode: 'ER-GB',
          regionCode: 'GB',
          regionName: 'Gash-Barka',
        },
        {
          fullCode: 'ER-SK',
          regionCode: 'SK',
          regionName: 'Northern Red Sea',
        },
        {
          fullCode: 'ER-AG',
          regionCode: 'AG',
          regionName: 'Akele Guzai [Akalä Guzay]',
        },
        {
          fullCode: 'ER-AS',
          regionCode: 'AS',
          regionName: 'Asmara [Asmära]',
        },
        {
          fullCode: 'ER-BA',
          regionCode: 'BA',
          regionName: 'Barka',
        },
        {
          fullCode: 'ER-DE',
          regionCode: 'DE',
          regionName: 'Denkalia [Dänkali]',
        },
        {
          fullCode: 'ER-GS',
          regionCode: 'GS',
          regionName: 'Gash-Setit [Gaš enna Sätit]',
        },
        {
          fullCode: 'ER-HA',
          regionCode: 'HA',
          regionName: 'Hamasien [Hamasén]',
        },
        {
          fullCode: 'ER-SA',
          regionCode: 'SA',
          regionName: 'Sahel',
        },
        {
          fullCode: 'ER-SM',
          regionCode: 'SM',
          regionName: 'Semhar [Sämhar]',
        },
        {
          fullCode: 'ER-SN',
          regionCode: 'SN',
          regionName: 'Senhit [Sänhet]',
        },
        {
          fullCode: 'ER-SR',
          regionCode: 'SR',
          regionName: 'Seraye [Särayé]',
        },
      ],
    },
  ],
  [
    'estonia',
    {
      countryCode: 'EE',
      countryName: 'Estonia',
      regions: [
        {
          fullCode: 'EE-37',
          regionCode: '37',
          regionName: 'Harju',
        },
        {
          fullCode: 'EE-39',
          regionCode: '39',
          regionName: 'Hiiu',
        },
        {
          fullCode: 'EE-45',
          regionCode: '45',
          regionName: 'Ida-Viru',
        },
        {
          fullCode: 'EE-50',
          regionCode: '50',
          regionName: 'Jõgeva',
        },
        {
          fullCode: 'EE-52',
          regionCode: '52',
          regionName: 'Järva',
        },
        {
          fullCode: 'EE-56',
          regionCode: '56',
          regionName: 'Lääne',
        },
        {
          fullCode: 'EE-60',
          regionCode: '60',
          regionName: 'Lääne-Viru',
        },
        {
          fullCode: 'EE-64',
          regionCode: '64',
          regionName: 'Põlva',
        },
        {
          fullCode: 'EE-68',
          regionCode: '68',
          regionName: 'Pärnu',
        },
        {
          fullCode: 'EE-71',
          regionCode: '71',
          regionName: 'Rapla',
        },
        {
          fullCode: 'EE-74',
          regionCode: '74',
          regionName: 'Saare',
        },
        {
          fullCode: 'EE-79',
          regionCode: '79',
          regionName: 'Tartu',
        },
        {
          fullCode: 'EE-81',
          regionCode: '81',
          regionName: 'Valga',
        },
        {
          fullCode: 'EE-84',
          regionCode: '84',
          regionName: 'Viljandi',
        },
        {
          fullCode: 'EE-87',
          regionCode: '87',
          regionName: 'Võru',
        },
      ],
    },
  ],
  [
    'eswatini',
    {
      countryCode: 'SZ',
      countryName: 'Eswatini',
      regions: [
        {
          fullCode: 'SZ-HH',
          regionCode: 'HH',
          regionName: 'Hhohho',
        },
        {
          fullCode: 'SZ-LU',
          regionCode: 'LU',
          regionName: 'Lubombo',
        },
        {
          fullCode: 'SZ-MA',
          regionCode: 'MA',
          regionName: 'Manzini',
        },
        {
          fullCode: 'SZ-SH',
          regionCode: 'SH',
          regionName: 'Shiselweni',
        },
      ],
    },
  ],
  [
    'ethiopia',
    {
      countryCode: 'ET',
      countryName: 'Ethiopia',
      regions: [
        {
          fullCode: 'ET-AA',
          regionCode: 'AA',
          regionName: 'Addis Ababa',
        },
        {
          fullCode: 'ET-DD',
          regionCode: 'DD',
          regionName: 'Dire Dawa',
        },
        {
          fullCode: 'ET-AF',
          regionCode: 'AF',
          regionName: 'Afar',
        },
        {
          fullCode: 'ET-AM',
          regionCode: 'AM',
          regionName: 'Amhara',
        },
        {
          fullCode: 'ET-BE',
          regionCode: 'BE',
          regionName: 'Benishangul-Gumuz',
        },
        {
          fullCode: 'ET-GA',
          regionCode: 'GA',
          regionName: 'Gambela',
        },
        {
          fullCode: 'ET-HA',
          regionCode: 'HA',
          regionName: 'Harari',
        },
        {
          fullCode: 'ET-OR',
          regionCode: 'OR',
          regionName: 'Oromia',
        },
        {
          fullCode: 'ET-SI',
          regionCode: 'SI',
          regionName: 'Sidama',
        },
        {
          fullCode: 'ET-SN',
          regionCode: 'SN',
          regionName: "Southern Nations, Nationalities, and Peoples' Region",
        },
        {
          fullCode: 'ET-SO',
          regionCode: 'SO',
          regionName: 'Somali',
        },
        {
          fullCode: 'ET-TI',
          regionCode: 'TI',
          regionName: 'Tigray',
        },
        {
          fullCode: 'ET-SW',
          regionCode: 'SW',
          regionName: "South West Ethiopia Peoples' Region",
        },
      ],
    },
  ],
  [
    'fiji',
    {
      countryCode: 'FJ',
      countryName: 'Fiji',
      regions: [
        {
          fullCode: 'FJ-C',
          regionCode: 'C',
          regionName: 'Central',
        },
        {
          fullCode: 'FJ-E',
          regionCode: 'E',
          regionName: 'Eastern',
        },
        {
          fullCode: 'FJ-N',
          regionCode: 'N',
          regionName: 'Northern',
        },
        {
          fullCode: 'FJ-W',
          regionCode: 'W',
          regionName: 'Western',
        },
        {
          fullCode: 'FJ-R',
          regionCode: 'R',
          regionName: 'Rotuma',
        },
        {
          fullCode: 'FJ-01',
          regionCode: '01',
          regionName: 'Ba',
        },
        {
          fullCode: 'FJ-02',
          regionCode: '02',
          regionName: 'Bua',
        },
        {
          fullCode: 'FJ-03',
          regionCode: '03',
          regionName: 'Cakaudrove',
        },
        {
          fullCode: 'FJ-04',
          regionCode: '04',
          regionName: 'Kadavu',
        },
        {
          fullCode: 'FJ-05',
          regionCode: '05',
          regionName: 'Lau',
        },
        {
          fullCode: 'FJ-06',
          regionCode: '06',
          regionName: 'Lomaiviti',
        },
        {
          fullCode: 'FJ-07',
          regionCode: '07',
          regionName: 'Macuata',
        },
        {
          fullCode: 'FJ-08',
          regionCode: '08',
          regionName: 'Nadroga and Navosa',
        },
        {
          fullCode: 'FJ-09',
          regionCode: '09',
          regionName: 'Naitasiri',
        },
        {
          fullCode: 'FJ-10',
          regionCode: '10',
          regionName: 'Namosi',
        },
        {
          fullCode: 'FJ-11',
          regionCode: '11',
          regionName: 'Ra',
        },
        {
          fullCode: 'FJ-12',
          regionCode: '12',
          regionName: 'Rewa',
        },
        {
          fullCode: 'FJ-13',
          regionCode: '13',
          regionName: 'Serua',
        },
        {
          fullCode: 'FJ-14',
          regionCode: '14',
          regionName: 'Tailevu',
        },
      ],
    },
  ],
  [
    'finland',
    {
      countryCode: 'FI',
      countryName: 'Finland',
      regions: [
        {
          fullCode: 'FI-01',
          regionCode: '01',
          regionName: 'Åland',
        },
        {
          fullCode: 'FI-02',
          regionCode: '02',
          regionName: 'South Karelia',
        },
        {
          fullCode: 'FI-03',
          regionCode: '03',
          regionName: 'Southern Ostrobothnia',
        },
        {
          fullCode: 'FI-04',
          regionCode: '04',
          regionName: 'Southern Savonia',
        },
        {
          fullCode: 'FI-05',
          regionCode: '05',
          regionName: 'Kainuu',
        },
        {
          fullCode: 'FI-06',
          regionCode: '06',
          regionName: 'Tavastia Proper',
        },
        {
          fullCode: 'FI-07',
          regionCode: '07',
          regionName: 'Central Ostrobothnia',
        },
        {
          fullCode: 'FI-08',
          regionCode: '08',
          regionName: 'Central Finland',
        },
        {
          fullCode: 'FI-09',
          regionCode: '09',
          regionName: 'Kymenlaakso',
        },
        {
          fullCode: 'FI-10',
          regionCode: '10',
          regionName: 'Lapland',
        },
        {
          fullCode: 'FI-11',
          regionCode: '11',
          regionName: 'Pirkanmaa',
        },
        {
          fullCode: 'FI-12',
          regionCode: '12',
          regionName: 'Ostrobothnia',
        },
        {
          fullCode: 'FI-13',
          regionCode: '13',
          regionName: 'North Karelia',
        },
        {
          fullCode: 'FI-14',
          regionCode: '14',
          regionName: 'Northern Ostrobothnia',
        },
        {
          fullCode: 'FI-15',
          regionCode: '15',
          regionName: 'Northern Savonia',
        },
        {
          fullCode: 'FI-16',
          regionCode: '16',
          regionName: 'Päijänne Tavastia',
        },
        {
          fullCode: 'FI-17',
          regionCode: '17',
          regionName: 'Satakunta',
        },
        {
          fullCode: 'FI-18',
          regionCode: '18',
          regionName: 'Uusimaa',
        },
        {
          fullCode: 'FI-19',
          regionCode: '19',
          regionName: 'Southwest Finland',
        },
        {
          fullCode: 'FI-AL',
          regionCode: 'AL',
          regionName: 'Ahvenanmaan lääni',
        },
        {
          fullCode: 'FI-ES',
          regionCode: 'ES',
          regionName: 'Etelä-Suomen lääni',
        },
        {
          fullCode: 'FI-IS',
          regionCode: 'IS',
          regionName: 'Itä-Suomen lääni',
        },
        {
          fullCode: 'FI-LL',
          regionCode: 'LL',
          regionName: 'Lapin lääni',
        },
        {
          fullCode: 'FI-LS',
          regionCode: 'LS',
          regionName: 'Länsi-Suomen lääni',
        },
        {
          fullCode: 'FI-OL',
          regionCode: 'OL',
          regionName: 'Oulun lääni',
        },
      ],
    },
  ],
  [
    'france',
    {
      countryCode: 'FR',
      countryName: 'France',
      regions: [
        {
          fullCode: 'FR-01',
          regionCode: '01',
          regionName: 'Ain',
        },
        {
          fullCode: 'FR-02',
          regionCode: '02',
          regionName: 'Aisne',
        },
        {
          fullCode: 'FR-03',
          regionCode: '03',
          regionName: 'Allier',
        },
        {
          fullCode: 'FR-6AE',
          regionCode: '6AE',
          regionName: 'Alsace',
        },
        {
          fullCode: 'FR-07',
          regionCode: '07',
          regionName: 'Ardeche',
        },
        {
          fullCode: 'FR-08',
          regionCode: '08',
          regionName: 'Ardennes',
        },
        {
          fullCode: 'FR-09',
          regionCode: '09',
          regionName: 'Ariege',
        },
        {
          fullCode: 'FR-44',
          regionCode: '44',
          regionName: 'Atlantic Loire',
        },
        {
          fullCode: 'FR-64',
          regionCode: '64',
          regionName: 'Atlantic Pyrenees',
        },
        {
          fullCode: 'FR-10',
          regionCode: '10',
          regionName: 'Aube',
        },
        {
          fullCode: 'FR-11',
          regionCode: '11',
          regionName: 'Aude',
        },
        {
          fullCode: 'FR-ARA',
          regionCode: 'ARA',
          regionName: 'Auvergne-Rhone-Alps',
        },
        {
          fullCode: 'FR-12',
          regionCode: '12',
          regionName: 'Aveyron',
        },
        {
          fullCode: 'FR-90',
          regionCode: '90',
          regionName: 'Belfort Territory',
        },
        {
          fullCode: 'FR-BRE',
          regionCode: 'BRE',
          regionName: 'Brittany',
        },
        {
          fullCode: 'FR-BFC',
          regionCode: 'BFC',
          regionName: 'Burgundy-Free County',
        },
        {
          fullCode: 'FR-62',
          regionCode: '62',
          regionName: 'Calais Passage',
        },
        {
          fullCode: 'FR-14',
          regionCode: '14',
          regionName: 'Calvados',
        },
        {
          fullCode: 'FR-15',
          regionCode: '15',
          regionName: 'Cantal',
        },
        {
          fullCode: 'FR-CVL',
          regionCode: 'CVL',
          regionName: 'Center-Loire Valley',
        },
        {
          fullCode: 'FR-16',
          regionCode: '16',
          regionName: 'Charente',
        },
        {
          fullCode: 'FR-18',
          regionCode: '18',
          regionName: 'Cher',
        },
        {
          fullCode: 'FR-CP',
          regionCode: 'CP',
          regionName: 'Clipperton',
        },
        {
          fullCode: 'FR-19',
          regionCode: '19',
          regionName: 'Correze',
        },
        {
          fullCode: 'FR-20R',
          regionCode: '20R',
          regionName: 'Corsica',
        },
        {
          fullCode: 'FR-23',
          regionCode: '23',
          regionName: 'Creuse',
        },
        {
          fullCode: 'FR-63',
          regionCode: '63',
          regionName: 'Dome Hill',
        },
        {
          fullCode: 'FR-24',
          regionCode: '24',
          regionName: 'Dordogne',
        },
        {
          fullCode: 'FR-25',
          regionCode: '25',
          regionName: 'Doubs',
        },
        {
          fullCode: 'FR-26',
          regionCode: '26',
          regionName: 'Drome',
        },
        {
          fullCode: 'FR-91',
          regionCode: '91',
          regionName: 'Essonne',
        },
        {
          fullCode: 'FR-27',
          regionCode: '27',
          regionName: 'Eure',
        },
        {
          fullCode: 'FR-28',
          regionCode: '28',
          regionName: 'Eure and Loir',
        },
        {
          fullCode: 'FR-29',
          regionCode: '29',
          regionName: 'Finistere',
        },
        {
          fullCode: 'FR-973',
          regionCode: '973',
          regionName: 'French Guiana',
        },
        {
          fullCode: 'FR-PF',
          regionCode: 'PF',
          regionName: 'French Polynesia',
        },
        {
          fullCode: 'FR-TF',
          regionCode: 'TF',
          regionName: 'French Southern Territories',
        },
        {
          fullCode: 'FR-30',
          regionCode: '30',
          regionName: 'Gard',
        },
        {
          fullCode: 'FR-32',
          regionCode: '32',
          regionName: 'Gers',
        },
        {
          fullCode: 'FR-33',
          regionCode: '33',
          regionName: 'Gironde',
        },
        {
          fullCode: 'FR-21',
          regionCode: '21',
          regionName: 'Gold Coast',
        },
        {
          fullCode: 'FR-GES',
          regionCode: 'GES',
          regionName: 'Great East',
        },
        {
          fullCode: 'FR-971',
          regionCode: '971',
          regionName: 'Guadeloupe',
        },
        {
          fullCode: 'FR-34',
          regionCode: '34',
          regionName: 'Herault',
        },
        {
          fullCode: 'FR-35',
          regionCode: '35',
          regionName: 'Ille and Vilaine',
        },
        {
          fullCode: 'FR-36',
          regionCode: '36',
          regionName: 'Indre',
        },
        {
          fullCode: 'FR-37',
          regionCode: '37',
          regionName: 'Indre and Loire',
        },
        {
          fullCode: 'FR-38',
          regionCode: '38',
          regionName: 'Isere',
        },
        {
          fullCode: 'FR-39',
          regionCode: '39',
          regionName: 'Jura',
        },
        {
          fullCode: 'FR-40',
          regionCode: '40',
          regionName: 'Landes',
        },
        {
          fullCode: 'FR-41',
          regionCode: '41',
          regionName: 'Loir and Cher',
        },
        {
          fullCode: 'FR-42',
          regionCode: '42',
          regionName: 'Loire',
        },
        {
          fullCode: 'FR-PDL',
          regionCode: 'PDL',
          regionName: 'Loire Country',
        },
        {
          fullCode: 'FR-45',
          regionCode: '45',
          regionName: 'Loiret',
        },
        {
          fullCode: 'FR-46',
          regionCode: '46',
          regionName: 'Lot',
        },
        {
          fullCode: 'FR-47',
          regionCode: '47',
          regionName: 'Lot and Garonne',
        },
        {
          fullCode: 'FR-67',
          regionCode: '67',
          regionName: 'Lower Rhine',
        },
        {
          fullCode: 'FR-48',
          regionCode: '48',
          regionName: 'Lozere',
        },
        {
          fullCode: 'FR-69M',
          regionCode: '69M',
          regionName: 'Lyon',
        },
        {
          fullCode: 'FR-49',
          regionCode: '49',
          regionName: 'Maine and Loire',
        },
        {
          fullCode: 'FR-50',
          regionCode: '50',
          regionName: 'Manche',
        },
        {
          fullCode: 'FR-06',
          regionCode: '06',
          regionName: 'Maritime Alps',
        },
        {
          fullCode: 'FR-17',
          regionCode: '17',
          regionName: 'Maritime Charente',
        },
        {
          fullCode: 'FR-76',
          regionCode: '76',
          regionName: 'Maritime Seine',
        },
        {
          fullCode: 'FR-51',
          regionCode: '51',
          regionName: 'Marne',
        },
        {
          fullCode: 'FR-94',
          regionCode: '94',
          regionName: 'Marne Valley',
        },
        {
          fullCode: 'FR-972',
          regionCode: '972',
          regionName: 'Martinique',
        },
        {
          fullCode: 'FR-53',
          regionCode: '53',
          regionName: 'Mayenne',
        },
        {
          fullCode: 'FR-976',
          regionCode: '976',
          regionName: 'Mayotte',
        },
        {
          fullCode: 'FR-54',
          regionCode: '54',
          regionName: 'Meurthe and Moselle',
        },
        {
          fullCode: 'FR-55',
          regionCode: '55',
          regionName: 'Meuse',
        },
        {
          fullCode: 'FR-56',
          regionCode: '56',
          regionName: 'Morbihan',
        },
        {
          fullCode: 'FR-57',
          regionCode: '57',
          regionName: 'Moselle',
        },
        {
          fullCode: 'FR-NAQ',
          regionCode: 'NAQ',
          regionName: 'New Aquitania',
        },
        {
          fullCode: 'FR-NC',
          regionCode: 'NC',
          regionName: 'New Caledonia',
        },
        {
          fullCode: 'FR-58',
          regionCode: '58',
          regionName: 'Nievre',
        },
        {
          fullCode: 'FR-NOR',
          regionCode: 'NOR',
          regionName: 'Normandy',
        },
        {
          fullCode: 'FR-59',
          regionCode: '59',
          regionName: 'North',
        },
        {
          fullCode: 'FR-OCC',
          regionCode: 'OCC',
          regionName: 'Occitania',
        },
        {
          fullCode: 'FR-60',
          regionCode: '60',
          regionName: 'Oise',
        },
        {
          fullCode: 'FR-95',
          regionCode: '95',
          regionName: 'Oise Valley',
        },
        {
          fullCode: 'FR-66',
          regionCode: '66',
          regionName: 'Oriental Pyrenees',
        },
        {
          fullCode: 'FR-61',
          regionCode: '61',
          regionName: 'Orne',
        },
        {
          fullCode: 'FR-IDF',
          regionCode: 'IDF',
          regionName: 'Paris',
        },
        {
          fullCode: 'FR-75C',
          regionCode: '75C',
          regionName: 'Paris City',
        },
        {
          fullCode: 'FR-PAC',
          regionCode: 'PAC',
          regionName: 'Provence-Alps-French Riviera',
        },
        {
          fullCode: 'FR-974',
          regionCode: '974',
          regionName: 'Reunion',
        },
        {
          fullCode: 'FR-69',
          regionCode: '69',
          regionName: 'Rhone',
        },
        {
          fullCode: 'FR-13',
          regionCode: '13',
          regionName: 'Rhone Delta',
        },
        {
          fullCode: 'FR-BL',
          regionCode: 'BL',
          regionName: 'Saint Barts',
        },
        {
          fullCode: 'FR-93',
          regionCode: '93',
          regionName: 'Saint Denis Seine',
        },
        {
          fullCode: 'FR-MF',
          regionCode: 'MF',
          regionName: 'Saint Martin',
        },
        {
          fullCode: 'FR-PM',
          regionCode: 'PM',
          regionName: 'Saint Peter and Miquelon',
        },
        {
          fullCode: 'FR-71',
          regionCode: '71',
          regionName: 'Saone and Loire',
        },
        {
          fullCode: 'FR-72',
          regionCode: '72',
          regionName: 'Sarthe',
        },
        {
          fullCode: 'FR-73',
          regionCode: '73',
          regionName: 'Savoy',
        },
        {
          fullCode: 'FR-22',
          regionCode: '22',
          regionName: 'Sea Coasts',
        },
        {
          fullCode: 'FR-77',
          regionCode: '77',
          regionName: 'Seine and Marne',
        },
        {
          fullCode: 'FR-80',
          regionCode: '80',
          regionName: 'Somme',
        },
        {
          fullCode: 'FR-2A',
          regionCode: '2A',
          regionName: 'Southern Corsica',
        },
        {
          fullCode: 'FR-81',
          regionCode: '81',
          regionName: 'Tarn',
        },
        {
          fullCode: 'FR-82',
          regionCode: '82',
          regionName: 'Tarn and Garonne',
        },
        {
          fullCode: 'FR-79',
          regionCode: '79',
          regionName: 'Two Sevres',
        },
        {
          fullCode: 'FR-05',
          regionCode: '05',
          regionName: 'Upper Alps',
        },
        {
          fullCode: 'FR-2B',
          regionCode: '2B',
          regionName: 'Upper Corsica',
        },
        {
          fullCode: 'FR-HDF',
          regionCode: 'HDF',
          regionName: 'Upper France',
        },
        {
          fullCode: 'FR-31',
          regionCode: '31',
          regionName: 'Upper Garonne',
        },
        {
          fullCode: 'FR-43',
          regionCode: '43',
          regionName: 'Upper Loire',
        },
        {
          fullCode: 'FR-52',
          regionCode: '52',
          regionName: 'Upper Marne',
        },
        {
          fullCode: 'FR-04',
          regionCode: '04',
          regionName: 'Upper Provence Alps',
        },
        {
          fullCode: 'FR-65',
          regionCode: '65',
          regionName: 'Upper Pyrenees',
        },
        {
          fullCode: 'FR-68',
          regionCode: '68',
          regionName: 'Upper Rhine',
        },
        {
          fullCode: 'FR-70',
          regionCode: '70',
          regionName: 'Upper Saone',
        },
        {
          fullCode: 'FR-74',
          regionCode: '74',
          regionName: 'Upper Savoy',
        },
        {
          fullCode: 'FR-92',
          regionCode: '92',
          regionName: 'Upper Seine',
        },
        {
          fullCode: 'FR-87',
          regionCode: '87',
          regionName: 'Upper Vienne',
        },
        {
          fullCode: 'FR-83',
          regionCode: '83',
          regionName: 'Var',
        },
        {
          fullCode: 'FR-84',
          regionCode: '84',
          regionName: 'Vaucluse',
        },
        {
          fullCode: 'FR-85',
          regionCode: '85',
          regionName: 'Vendee',
        },
        {
          fullCode: 'FR-86',
          regionCode: '86',
          regionName: 'Vienne',
        },
        {
          fullCode: 'FR-88',
          regionCode: '88',
          regionName: 'Vosges',
        },
        {
          fullCode: 'FR-WF',
          regionCode: 'WF',
          regionName: 'Wallis and Futuna',
        },
        {
          fullCode: 'FR-89',
          regionCode: '89',
          regionName: 'Yonne',
        },
        {
          fullCode: 'FR-78',
          regionCode: '78',
          regionName: 'Yvelines',
        },
      ],
    },
  ],
  [
    'gabon',
    {
      countryCode: 'GA',
      countryName: 'Gabon',
      regions: [
        {
          fullCode: 'GA-1',
          regionCode: '1',
          regionName: 'Estuary',
        },
        {
          fullCode: 'GA-2',
          regionCode: '2',
          regionName: 'Upper Ogooué',
        },
        {
          fullCode: 'GA-3',
          regionCode: '3',
          regionName: 'Middle Ogooué',
        },
        {
          fullCode: 'GA-4',
          regionCode: '4',
          regionName: 'Ngounié',
        },
        {
          fullCode: 'GA-5',
          regionCode: '5',
          regionName: 'Nyanga',
        },
        {
          fullCode: 'GA-6',
          regionCode: '6',
          regionName: 'Ogooué-Ivindo',
        },
        {
          fullCode: 'GA-7',
          regionCode: '7',
          regionName: 'Ogooué-Lolo',
        },
        {
          fullCode: 'GA-8',
          regionCode: '8',
          regionName: 'Maritime Ogooué',
        },
        {
          fullCode: 'GA-9',
          regionCode: '9',
          regionName: 'Woleu-Ntem',
        },
      ],
    },
  ],
  [
    'gambia',
    {
      countryCode: 'GM',
      countryName: 'Gambia',
      regions: [
        {
          fullCode: 'GM-B',
          regionCode: 'B',
          regionName: 'Banjul',
        },
        {
          fullCode: 'GM-M',
          regionCode: 'M',
          regionName: 'Central River',
        },
        {
          fullCode: 'GM-L',
          regionCode: 'L',
          regionName: 'Lower River',
        },
        {
          fullCode: 'GM-N',
          regionCode: 'N',
          regionName: 'North Bank',
        },
        {
          fullCode: 'GM-U',
          regionCode: 'U',
          regionName: 'Upper River',
        },
        {
          fullCode: 'GM-W',
          regionCode: 'W',
          regionName: 'Western',
        },
      ],
    },
  ],
  [
    'georgia',
    {
      countryCode: 'GE',
      countryName: 'Georgia',
      regions: [
        {
          fullCode: 'GE-AB',
          regionCode: 'AB',
          regionName: 'Abkhazia',
        },
        {
          fullCode: 'GE-AJ',
          regionCode: 'AJ',
          regionName: 'Adjara',
        },
        {
          fullCode: 'GE-GU',
          regionCode: 'GU',
          regionName: 'Guria',
        },
        {
          fullCode: 'GE-IM',
          regionCode: 'IM',
          regionName: 'Imereti',
        },
        {
          fullCode: 'GE-KA',
          regionCode: 'KA',
          regionName: 'Kakheti',
        },
        {
          fullCode: 'GE-KK',
          regionCode: 'KK',
          regionName: 'Kvemo Kartli',
        },
        {
          fullCode: 'GE-MM',
          regionCode: 'MM',
          regionName: 'Mtskheta-Mtianeti',
        },
        {
          fullCode: 'GE-RL',
          regionCode: 'RL',
          regionName: 'Racha-Lechkhumi and Kvemo Svaneti',
        },
        {
          fullCode: 'GE-SZ',
          regionCode: 'SZ',
          regionName: 'Samegrelo-Zemo Svaneti',
        },
        {
          fullCode: 'GE-SJ',
          regionCode: 'SJ',
          regionName: 'Samtskhe-Javakheti',
        },
        {
          fullCode: 'GE-SK',
          regionCode: 'SK',
          regionName: 'Shida Kartli',
        },
        {
          fullCode: 'GE-TB',
          regionCode: 'TB',
          regionName: 'Tbilisi',
        },
      ],
    },
  ],
  [
    'germany',
    {
      countryCode: 'DE',
      countryName: 'Germany',
      regions: [
        {
          fullCode: 'DE-BW',
          regionCode: 'BW',
          regionName: 'Baden-Württemberg',
        },
        {
          fullCode: 'DE-BY',
          regionCode: 'BY',
          regionName: 'Bavaria',
        },
        {
          fullCode: 'DE-BE',
          regionCode: 'BE',
          regionName: 'Berlin',
        },
        {
          fullCode: 'DE-BB',
          regionCode: 'BB',
          regionName: 'Brandenburg',
        },
        {
          fullCode: 'DE-HB',
          regionCode: 'HB',
          regionName: 'Bremen',
        },
        {
          fullCode: 'DE-HH',
          regionCode: 'HH',
          regionName: 'Hamburg',
        },
        {
          fullCode: 'DE-HE',
          regionCode: 'HE',
          regionName: 'Hesse',
        },
        {
          fullCode: 'DE-MV',
          regionCode: 'MV',
          regionName: 'Mecklenburg-Western Pomerania',
        },
        {
          fullCode: 'DE-NI',
          regionCode: 'NI',
          regionName: 'Lower Saxony',
        },
        {
          fullCode: 'DE-NW',
          regionCode: 'NW',
          regionName: 'North Rhine-Westphalia',
        },
        {
          fullCode: 'DE-RP',
          regionCode: 'RP',
          regionName: 'Rhineland-Palatinate',
        },
        {
          fullCode: 'DE-SL',
          regionCode: 'SL',
          regionName: 'Saarland',
        },
        {
          fullCode: 'DE-SN',
          regionCode: 'SN',
          regionName: 'Saxony',
        },
        {
          fullCode: 'DE-ST',
          regionCode: 'ST',
          regionName: 'Saxony-Anhalt',
        },
        {
          fullCode: 'DE-SH',
          regionCode: 'SH',
          regionName: 'Schleswig-Holstein',
        },
        {
          fullCode: 'DE-TH',
          regionCode: 'TH',
          regionName: 'Thuringia',
        },
      ],
    },
  ],
  [
    'ghana',
    {
      countryCode: 'GH',
      countryName: 'Ghana',
      regions: [
        {
          fullCode: 'GH-AF',
          regionCode: 'AF',
          regionName: 'Ahafo',
        },
        {
          fullCode: 'GH-AH',
          regionCode: 'AH',
          regionName: 'Ashanti',
        },
        {
          fullCode: 'GH-BO',
          regionCode: 'BO',
          regionName: 'Bono',
        },
        {
          fullCode: 'GH-BE',
          regionCode: 'BE',
          regionName: 'Bono East',
        },
        {
          fullCode: 'GH-CP',
          regionCode: 'CP',
          regionName: 'Central',
        },
        {
          fullCode: 'GH-EP',
          regionCode: 'EP',
          regionName: 'Eastern',
        },
        {
          fullCode: 'GH-AA',
          regionCode: 'AA',
          regionName: 'Greater Accra',
        },
        {
          fullCode: 'GH-NE',
          regionCode: 'NE',
          regionName: 'North East',
        },
        {
          fullCode: 'GH-NP',
          regionCode: 'NP',
          regionName: 'Northern',
        },
        {
          fullCode: 'GH-OT',
          regionCode: 'OT',
          regionName: 'Oti',
        },
        {
          fullCode: 'GH-SV',
          regionCode: 'SV',
          regionName: 'Savannah',
        },
        {
          fullCode: 'GH-UE',
          regionCode: 'UE',
          regionName: 'Upper East',
        },
        {
          fullCode: 'GH-UW',
          regionCode: 'UW',
          regionName: 'Upper West',
        },
        {
          fullCode: 'GH-TV',
          regionCode: 'TV',
          regionName: 'Volta',
        },
        {
          fullCode: 'GH-WP',
          regionCode: 'WP',
          regionName: 'Western',
        },
        {
          fullCode: 'GH-WN',
          regionCode: 'WN',
          regionName: 'Western North',
        },
        {
          fullCode: 'GH-BA',
          regionCode: 'BA',
          regionName: 'Brong-Ahafo',
        },
      ],
    },
  ],
  [
    'greece',
    {
      countryCode: 'GR',
      countryName: 'Greece',
      regions: [
        {
          fullCode: 'GR-A',
          regionCode: 'A',
          regionName: 'Eastern Macedonia and Thrace',
        },
        {
          fullCode: 'GR-B',
          regionCode: 'B',
          regionName: 'Central Macedonia',
        },
        {
          fullCode: 'GR-C',
          regionCode: 'C',
          regionName: 'Western Macedonia',
        },
        {
          fullCode: 'GR-D',
          regionCode: 'D',
          regionName: 'Epirus',
        },
        {
          fullCode: 'GR-E',
          regionCode: 'E',
          regionName: 'Thessaly',
        },
        {
          fullCode: 'GR-F',
          regionCode: 'F',
          regionName: 'Ionian Islands',
        },
        {
          fullCode: 'GR-G',
          regionCode: 'G',
          regionName: 'Western Greece',
        },
        {
          fullCode: 'GR-H',
          regionCode: 'H',
          regionName: 'Central Greece',
        },
        {
          fullCode: 'GR-I',
          regionCode: 'I',
          regionName: 'Attica',
        },
        {
          fullCode: 'GR-J',
          regionCode: 'J',
          regionName: 'Peloponnese',
        },
        {
          fullCode: 'GR-K',
          regionCode: 'K',
          regionName: 'North Aegean',
        },
        {
          fullCode: 'GR-L',
          regionCode: 'L',
          regionName: 'South Aegean',
        },
        {
          fullCode: 'GR-M',
          regionCode: 'M',
          regionName: 'Crete',
        },
        {
          fullCode: 'GR-69',
          regionCode: '69',
          regionName: 'Mount Athos',
        },
      ],
    },
  ],
  [
    'greenland',
    {
      countryCode: 'GL',
      countryName: 'Greenland',
      regions: [
        {
          fullCode: 'GL-AV',
          regionCode: 'AV',
          regionName: 'Avannaata',
        },
        {
          fullCode: 'GL-KU',
          regionCode: 'KU',
          regionName: 'Kujalleq',
        },
        {
          fullCode: 'GL-QT',
          regionCode: 'QT',
          regionName: 'Qeqertalik',
        },
        {
          fullCode: 'GL-SM',
          regionCode: 'SM',
          regionName: 'Sermersooq',
        },
        {
          fullCode: 'GL-QE',
          regionCode: 'QE',
          regionName: 'Qeqqata',
        },
      ],
    },
  ],
  [
    'grenada',
    {
      countryCode: 'GD',
      countryName: 'Grenada',
      regions: [
        {
          fullCode: 'GD-01',
          regionCode: '01',
          regionName: 'Saint Andrew',
        },
        {
          fullCode: 'GD-02',
          regionCode: '02',
          regionName: 'Saint David',
        },
        {
          fullCode: 'GD-03',
          regionCode: '03',
          regionName: 'Saint George',
        },
        {
          fullCode: 'GD-04',
          regionCode: '04',
          regionName: 'Saint John',
        },
        {
          fullCode: 'GD-05',
          regionCode: '05',
          regionName: 'Saint Mark',
        },
        {
          fullCode: 'GD-06',
          regionCode: '06',
          regionName: 'Saint Patrick',
        },
        {
          fullCode: 'GD-10',
          regionCode: '10',
          regionName: 'Southern Grenadine Islands',
        },
      ],
    },
  ],
  [
    'guatemala',
    {
      countryCode: 'GT',
      countryName: 'Guatemala',
      regions: [
        {
          fullCode: 'GT-16',
          regionCode: '16',
          regionName: 'Upper Verapaz',
        },
        {
          fullCode: 'GT-15',
          regionCode: '15',
          regionName: 'Lower Verapaz',
        },
        {
          fullCode: 'GT-04',
          regionCode: '04',
          regionName: 'Chimaltenango',
        },
        {
          fullCode: 'GT-20',
          regionCode: '20',
          regionName: 'Chiquimula',
        },
        {
          fullCode: 'GT-02',
          regionCode: '02',
          regionName: 'El Progreso',
        },
        {
          fullCode: 'GT-05',
          regionCode: '05',
          regionName: 'Escuintla',
        },
        {
          fullCode: 'GT-01',
          regionCode: '01',
          regionName: 'Guatemala',
        },
        {
          fullCode: 'GT-13',
          regionCode: '13',
          regionName: 'Huehuetenango',
        },
        {
          fullCode: 'GT-18',
          regionCode: '18',
          regionName: 'Izabal',
        },
        {
          fullCode: 'GT-21',
          regionCode: '21',
          regionName: 'Jalapa',
        },
        {
          fullCode: 'GT-22',
          regionCode: '22',
          regionName: 'Jutiapa',
        },
        {
          fullCode: 'GT-17',
          regionCode: '17',
          regionName: 'Petén',
        },
        {
          fullCode: 'GT-09',
          regionCode: '09',
          regionName: 'Quetzaltenango',
        },
        {
          fullCode: 'GT-14',
          regionCode: '14',
          regionName: 'Quiché',
        },
        {
          fullCode: 'GT-11',
          regionCode: '11',
          regionName: 'Retalhuleu',
        },
        {
          fullCode: 'GT-03',
          regionCode: '03',
          regionName: 'Sacatepéquez',
        },
        {
          fullCode: 'GT-12',
          regionCode: '12',
          regionName: 'Saint Mark',
        },
        {
          fullCode: 'GT-06',
          regionCode: '06',
          regionName: 'Saint Rose',
        },
        {
          fullCode: 'GT-07',
          regionCode: '07',
          regionName: 'Sololá',
        },
        {
          fullCode: 'GT-10',
          regionCode: '10',
          regionName: 'Suchitepéquez',
        },
        {
          fullCode: 'GT-08',
          regionCode: '08',
          regionName: 'Totonicapán',
        },
        {
          fullCode: 'GT-19',
          regionCode: '19',
          regionName: 'Zacapa',
        },
      ],
    },
  ],
  [
    'guinea',
    {
      countryCode: 'GN',
      countryName: 'Guinea',
      regions: [
        {
          fullCode: 'GN-B',
          regionCode: 'B',
          regionName: 'Boké',
        },
        {
          fullCode: 'GN-F',
          regionCode: 'F',
          regionName: 'Faranah',
        },
        {
          fullCode: 'GN-K',
          regionCode: 'K',
          regionName: 'Kankan',
        },
        {
          fullCode: 'GN-D',
          regionCode: 'D',
          regionName: 'Kindia',
        },
        {
          fullCode: 'GN-L',
          regionCode: 'L',
          regionName: 'Labé',
        },
        {
          fullCode: 'GN-M',
          regionCode: 'M',
          regionName: 'Mamou',
        },
        {
          fullCode: 'GN-N',
          regionCode: 'N',
          regionName: 'Nzérékoré',
        },
        {
          fullCode: 'GN-C',
          regionCode: 'C',
          regionName: 'Conakry',
        },
        {
          fullCode: 'GN-BE',
          regionCode: 'BE',
          regionName: 'Beyla',
        },
        {
          fullCode: 'GN-BF',
          regionCode: 'BF',
          regionName: 'Boffa',
        },
        {
          fullCode: 'GN-BK',
          regionCode: 'BK',
          regionName: 'Boké',
        },
        {
          fullCode: 'GN-CO',
          regionCode: 'CO',
          regionName: 'Coyah',
        },
        {
          fullCode: 'GN-DB',
          regionCode: 'DB',
          regionName: 'Dabola',
        },
        {
          fullCode: 'GN-DL',
          regionCode: 'DL',
          regionName: 'Dalaba',
        },
        {
          fullCode: 'GN-DI',
          regionCode: 'DI',
          regionName: 'Dinguiraye',
        },
        {
          fullCode: 'GN-DU',
          regionCode: 'DU',
          regionName: 'Dubréka',
        },
        {
          fullCode: 'GN-FA',
          regionCode: 'FA',
          regionName: 'Faranah',
        },
        {
          fullCode: 'GN-FO',
          regionCode: 'FO',
          regionName: 'Forécariah',
        },
        {
          fullCode: 'GN-FR',
          regionCode: 'FR',
          regionName: 'Fria',
        },
        {
          fullCode: 'GN-GA',
          regionCode: 'GA',
          regionName: 'Gaoual',
        },
        {
          fullCode: 'GN-GU',
          regionCode: 'GU',
          regionName: 'Guékédou',
        },
        {
          fullCode: 'GN-KA',
          regionCode: 'KA',
          regionName: 'Kankan',
        },
        {
          fullCode: 'GN-KE',
          regionCode: 'KE',
          regionName: 'Kérouané',
        },
        {
          fullCode: 'GN-KD',
          regionCode: 'KD',
          regionName: 'Kindia',
        },
        {
          fullCode: 'GN-KS',
          regionCode: 'KS',
          regionName: 'Kissidougou',
        },
        {
          fullCode: 'GN-KB',
          regionCode: 'KB',
          regionName: 'Koubia',
        },
        {
          fullCode: 'GN-KN',
          regionCode: 'KN',
          regionName: 'Koundara',
        },
        {
          fullCode: 'GN-KO',
          regionCode: 'KO',
          regionName: 'Kouroussa',
        },
        {
          fullCode: 'GN-LA',
          regionCode: 'LA',
          regionName: 'Labé',
        },
        {
          fullCode: 'GN-LE',
          regionCode: 'LE',
          regionName: 'Lélouma',
        },
        {
          fullCode: 'GN-LO',
          regionCode: 'LO',
          regionName: 'Lola',
        },
        {
          fullCode: 'GN-MC',
          regionCode: 'MC',
          regionName: 'Macenta',
        },
        {
          fullCode: 'GN-ML',
          regionCode: 'ML',
          regionName: 'Mali',
        },
        {
          fullCode: 'GN-MM',
          regionCode: 'MM',
          regionName: 'Mamou',
        },
        {
          fullCode: 'GN-MD',
          regionCode: 'MD',
          regionName: 'Mandiana',
        },
        {
          fullCode: 'GN-NZ',
          regionCode: 'NZ',
          regionName: 'Nzérékoré',
        },
        {
          fullCode: 'GN-PI',
          regionCode: 'PI',
          regionName: 'Pita',
        },
        {
          fullCode: 'GN-SI',
          regionCode: 'SI',
          regionName: 'Siguiri',
        },
        {
          fullCode: 'GN-TE',
          regionCode: 'TE',
          regionName: 'Télimélé',
        },
        {
          fullCode: 'GN-TO',
          regionCode: 'TO',
          regionName: 'Tougué',
        },
        {
          fullCode: 'GN-YO',
          regionCode: 'YO',
          regionName: 'Yomou',
        },
      ],
    },
  ],
  [
    'guinea-bissau',
    {
      countryCode: 'GW',
      countryName: 'Guinea-Bissau',
      regions: [
        {
          fullCode: 'GW-L',
          regionCode: 'L',
          regionName: 'East',
        },
        {
          fullCode: 'GW-N',
          regionCode: 'N',
          regionName: 'North',
        },
        {
          fullCode: 'GW-S',
          regionCode: 'S',
          regionName: 'South',
        },
        {
          fullCode: 'GW-BA',
          regionCode: 'BA',
          regionName: 'Bafatá',
        },
        {
          fullCode: 'GW-BM',
          regionCode: 'BM',
          regionName: 'Biombo',
        },
        {
          fullCode: 'GW-BS',
          regionCode: 'BS',
          regionName: 'SAB',
        },
        {
          fullCode: 'GW-BL',
          regionCode: 'BL',
          regionName: 'Bolama / Bijagós',
        },
        {
          fullCode: 'GW-CA',
          regionCode: 'CA',
          regionName: 'Cacheu',
        },
        {
          fullCode: 'GW-GA',
          regionCode: 'GA',
          regionName: 'Gabú',
        },
        {
          fullCode: 'GW-OI',
          regionCode: 'OI',
          regionName: 'Oio',
        },
        {
          fullCode: 'GW-QU',
          regionCode: 'QU',
          regionName: 'Quinara',
        },
        {
          fullCode: 'GW-TO',
          regionCode: 'TO',
          regionName: 'Tombali',
        },
      ],
    },
  ],
  [
    'guyana',
    {
      countryCode: 'GY',
      countryName: 'Guyana',
      regions: [
        {
          fullCode: 'GY-BA',
          regionCode: 'BA',
          regionName: 'Barima-Waini',
        },
        {
          fullCode: 'GY-CU',
          regionCode: 'CU',
          regionName: 'Cuyuni-Mazaruni',
        },
        {
          fullCode: 'GY-DE',
          regionCode: 'DE',
          regionName: 'Demerara-Mahaica',
        },
        {
          fullCode: 'GY-EB',
          regionCode: 'EB',
          regionName: 'East Berbice-Corentyne',
        },
        {
          fullCode: 'GY-ES',
          regionCode: 'ES',
          regionName: 'Essequibo Islands-West Demerara',
        },
        {
          fullCode: 'GY-MA',
          regionCode: 'MA',
          regionName: 'Mahaica-Berbice',
        },
        {
          fullCode: 'GY-PM',
          regionCode: 'PM',
          regionName: 'Pomeroon-Supenaam',
        },
        {
          fullCode: 'GY-PT',
          regionCode: 'PT',
          regionName: 'Potaro-Siparuni',
        },
        {
          fullCode: 'GY-UD',
          regionCode: 'UD',
          regionName: 'Upper Demerara-Berbice',
        },
        {
          fullCode: 'GY-UT',
          regionCode: 'UT',
          regionName: 'Upper Takutu-Upper Essequibo',
        },
      ],
    },
  ],
  [
    'haiti',
    {
      countryCode: 'HT',
      countryName: 'Haiti',
      regions: [
        {
          fullCode: 'HT-AR',
          regionCode: 'AR',
          regionName: 'Artibonite',
        },
        {
          fullCode: 'HT-CE',
          regionCode: 'CE',
          regionName: 'Center',
        },
        {
          fullCode: 'HT-GA',
          regionCode: 'GA',
          regionName: 'Big Cove',
        },
        {
          fullCode: 'HT-NI',
          regionCode: 'NI',
          regionName: 'Nippes',
        },
        {
          fullCode: 'HT-ND',
          regionCode: 'ND',
          regionName: 'North',
        },
        {
          fullCode: 'HT-NE',
          regionCode: 'NE',
          regionName: 'Northeast',
        },
        {
          fullCode: 'HT-NO',
          regionCode: 'NO',
          regionName: 'Northwest',
        },
        {
          fullCode: 'HT-OU',
          regionCode: 'OU',
          regionName: 'West',
        },
        {
          fullCode: 'HT-SD',
          regionCode: 'SD',
          regionName: 'South',
        },
        {
          fullCode: 'HT-SE',
          regionCode: 'SE',
          regionName: 'Southeast',
        },
      ],
    },
  ],
  [
    'honduras',
    {
      countryCode: 'HN',
      countryName: 'Honduras',
      regions: [
        {
          fullCode: 'HN-AT',
          regionCode: 'AT',
          regionName: 'Atlántida',
        },
        {
          fullCode: 'HN-CH',
          regionCode: 'CH',
          regionName: 'Choluteca',
        },
        {
          fullCode: 'HN-CL',
          regionCode: 'CL',
          regionName: 'Colón',
        },
        {
          fullCode: 'HN-CM',
          regionCode: 'CM',
          regionName: 'Comayagua',
        },
        {
          fullCode: 'HN-CP',
          regionCode: 'CP',
          regionName: 'Copán',
        },
        {
          fullCode: 'HN-CR',
          regionCode: 'CR',
          regionName: 'Cortés',
        },
        {
          fullCode: 'HN-EP',
          regionCode: 'EP',
          regionName: 'El Paraíso',
        },
        {
          fullCode: 'HN-FM',
          regionCode: 'FM',
          regionName: 'Francisco Morazán',
        },
        {
          fullCode: 'HN-GD',
          regionCode: 'GD',
          regionName: 'Gracias a Dios',
        },
        {
          fullCode: 'HN-IN',
          regionCode: 'IN',
          regionName: 'Intibucá',
        },
        {
          fullCode: 'HN-IB',
          regionCode: 'IB',
          regionName: 'Islas de la Bahía',
        },
        {
          fullCode: 'HN-LP',
          regionCode: 'LP',
          regionName: 'La Paz',
        },
        {
          fullCode: 'HN-LE',
          regionCode: 'LE',
          regionName: 'Lempira',
        },
        {
          fullCode: 'HN-OC',
          regionCode: 'OC',
          regionName: 'Ocotepeque',
        },
        {
          fullCode: 'HN-OL',
          regionCode: 'OL',
          regionName: 'Olancho',
        },
        {
          fullCode: 'HN-SB',
          regionCode: 'SB',
          regionName: 'Santa Bárbara',
        },
        {
          fullCode: 'HN-VA',
          regionCode: 'VA',
          regionName: 'Valle',
        },
        {
          fullCode: 'HN-YO',
          regionCode: 'YO',
          regionName: 'Yoro',
        },
      ],
    },
  ],
  [
    'hungary',
    {
      countryCode: 'HU',
      countryName: 'Hungary',
      regions: [
        {
          fullCode: 'HU-BK',
          regionCode: 'BK',
          regionName: 'Bács-Kiskun',
        },
        {
          fullCode: 'HU-BA',
          regionCode: 'BA',
          regionName: 'Baranya',
        },
        {
          fullCode: 'HU-BE',
          regionCode: 'BE',
          regionName: 'Békés',
        },
        {
          fullCode: 'HU-BC',
          regionCode: 'BC',
          regionName: 'Békéscsaba',
        },
        {
          fullCode: 'HU-BZ',
          regionCode: 'BZ',
          regionName: 'Borsod-Abaúj-Zemplén',
        },
        {
          fullCode: 'HU-BU',
          regionCode: 'BU',
          regionName: 'Budapest',
        },
        {
          fullCode: 'HU-CS',
          regionCode: 'CS',
          regionName: 'Csongrád-Csanád',
        },
        {
          fullCode: 'HU-DE',
          regionCode: 'DE',
          regionName: 'Debrecen',
        },
        {
          fullCode: 'HU-DU',
          regionCode: 'DU',
          regionName: 'Dunaújváros',
        },
        {
          fullCode: 'HU-EG',
          regionCode: 'EG',
          regionName: 'Eger',
        },
        {
          fullCode: 'HU-ER',
          regionCode: 'ER',
          regionName: 'Érd',
        },
        {
          fullCode: 'HU-FE',
          regionCode: 'FE',
          regionName: 'Fejér',
        },
        {
          fullCode: 'HU-GY',
          regionCode: 'GY',
          regionName: 'Győr',
        },
        {
          fullCode: 'HU-GS',
          regionCode: 'GS',
          regionName: 'Győr-Moson-Sopron',
        },
        {
          fullCode: 'HU-HB',
          regionCode: 'HB',
          regionName: 'Hajdú-Bihar',
        },
        {
          fullCode: 'HU-HE',
          regionCode: 'HE',
          regionName: 'Heves',
        },
        {
          fullCode: 'HU-HV',
          regionCode: 'HV',
          regionName: 'Hódmezővásárhely',
        },
        {
          fullCode: 'HU-JN',
          regionCode: 'JN',
          regionName: 'Jász-Nagykun-Szolnok',
        },
        {
          fullCode: 'HU-KV',
          regionCode: 'KV',
          regionName: 'Kaposvár',
        },
        {
          fullCode: 'HU-KM',
          regionCode: 'KM',
          regionName: 'Kecskemét',
        },
        {
          fullCode: 'HU-KE',
          regionCode: 'KE',
          regionName: 'Komárom-Esztergom',
        },
        {
          fullCode: 'HU-MI',
          regionCode: 'MI',
          regionName: 'Miskolc',
        },
        {
          fullCode: 'HU-NK',
          regionCode: 'NK',
          regionName: 'Nagykanizsa',
        },
        {
          fullCode: 'HU-NO',
          regionCode: 'NO',
          regionName: 'Nógrád',
        },
        {
          fullCode: 'HU-NY',
          regionCode: 'NY',
          regionName: 'Nyíregyháza',
        },
        {
          fullCode: 'HU-PS',
          regionCode: 'PS',
          regionName: 'Pécs',
        },
        {
          fullCode: 'HU-PE',
          regionCode: 'PE',
          regionName: 'Pest',
        },
        {
          fullCode: 'HU-ST',
          regionCode: 'ST',
          regionName: 'Salgótarján',
        },
        {
          fullCode: 'HU-SO',
          regionCode: 'SO',
          regionName: 'Somogy',
        },
        {
          fullCode: 'HU-SN',
          regionCode: 'SN',
          regionName: 'Sopron',
        },
        {
          fullCode: 'HU-SZ',
          regionCode: 'SZ',
          regionName: 'Szabolcs-Szatmár-Bereg',
        },
        {
          fullCode: 'HU-SD',
          regionCode: 'SD',
          regionName: 'Szeged',
        },
        {
          fullCode: 'HU-SF',
          regionCode: 'SF',
          regionName: 'Székesfehérvár',
        },
        {
          fullCode: 'HU-SS',
          regionCode: 'SS',
          regionName: 'Szekszárd',
        },
        {
          fullCode: 'HU-SK',
          regionCode: 'SK',
          regionName: 'Szolnok',
        },
        {
          fullCode: 'HU-SH',
          regionCode: 'SH',
          regionName: 'Szombathely',
        },
        {
          fullCode: 'HU-TB',
          regionCode: 'TB',
          regionName: 'Tatabánya',
        },
        {
          fullCode: 'HU-TO',
          regionCode: 'TO',
          regionName: 'Tolna',
        },
        {
          fullCode: 'HU-VA',
          regionCode: 'VA',
          regionName: 'Vas',
        },
        {
          fullCode: 'HU-VM',
          regionCode: 'VM',
          regionName: 'Veszprém',
        },
        {
          fullCode: 'HU-VE',
          regionCode: 'VE',
          regionName: 'Veszprém',
        },
        {
          fullCode: 'HU-ZA',
          regionCode: 'ZA',
          regionName: 'Zala',
        },
        {
          fullCode: 'HU-ZE',
          regionCode: 'ZE',
          regionName: 'Zalaegerszeg',
        },
      ],
    },
  ],
  [
    'iceland',
    {
      countryCode: 'IS',
      countryName: 'Iceland',
      regions: [
        {
          fullCode: 'IS-7',
          regionCode: '7',
          regionName: 'Eastern Region',
        },
        {
          fullCode: 'IS-1',
          regionCode: '1',
          regionName: 'Capital Region',
        },
        {
          fullCode: 'IS-6',
          regionCode: '6',
          regionName: 'Northeastern Region',
        },
        {
          fullCode: 'IS-5',
          regionCode: '5',
          regionName: 'Northwestern Region',
        },
        {
          fullCode: 'IS-8',
          regionCode: '8',
          regionName: 'Southern Region',
        },
        {
          fullCode: 'IS-2',
          regionCode: '2',
          regionName: 'Southern Peninsula',
        },
        {
          fullCode: 'IS-4',
          regionCode: '4',
          regionName: 'Westfjords',
        },
        {
          fullCode: 'IS-3',
          regionCode: '3',
          regionName: 'Western Region',
        },
        {
          fullCode: 'IS-AKN',
          regionCode: 'AKN',
          regionName: 'Akranes (Market) Town',
        },
        {
          fullCode: 'IS-AKU',
          regionCode: 'AKU',
          regionName: 'Akureyri Town',
        },
        {
          fullCode: 'IS-ARN',
          regionCode: 'ARN',
          regionName: 'Arnes District',
        },
        {
          fullCode: 'IS-ASA',
          regionCode: 'ASA',
          regionName: 'Asa District',
        },
        {
          fullCode: 'IS-BLA',
          regionCode: 'BLA',
          regionName: 'Blaskoga Settlement',
        },
        {
          fullCode: 'IS-BOL',
          regionCode: 'BOL',
          regionName: 'Bolungarvik (Market) Town',
        },
        {
          fullCode: 'IS-BOG',
          regionCode: 'BOG',
          regionName: 'Borgar Settlement',
        },
        {
          fullCode: 'IS-DAB',
          regionCode: 'DAB',
          regionName: 'Dala Settlement',
        },
        {
          fullCode: 'IS-DAV',
          regionCode: 'DAV',
          regionName: 'Dalvik Settlement',
        },
        {
          fullCode: 'IS-EOM',
          regionCode: 'EOM',
          regionName: 'Eyja and Miklaholt District',
        },
        {
          fullCode: 'IS-EYF',
          regionCode: 'EYF',
          regionName: 'Eyjafjordur Village',
        },
        {
          fullCode: 'IS-FJL',
          regionCode: 'FJL',
          regionName: 'Fjalla Settlement',
        },
        {
          fullCode: 'IS-FJD',
          regionCode: 'FJD',
          regionName: 'Fjarda Settlement',
        },
        {
          fullCode: 'IS-FLR',
          regionCode: 'FLR',
          regionName: 'Fljotsdalur District',
        },
        {
          fullCode: 'IS-FLA',
          regionCode: 'FLA',
          regionName: 'Floa District',
        },
        {
          fullCode: 'IS-GAR',
          regionCode: 'GAR',
          regionName: 'Garda Town',
        },
        {
          fullCode: 'IS-GOG',
          regionCode: 'GOG',
          regionName: 'Grimsnes and Grafnings District',
        },
        {
          fullCode: 'IS-GRN',
          regionCode: 'GRN',
          regionName: 'Grindavik Town',
        },
        {
          fullCode: 'IS-GRU',
          regionCode: 'GRU',
          regionName: 'Grundarfjordur Town',
        },
        {
          fullCode: 'IS-GRY',
          regionCode: 'GRY',
          regionName: 'Grytubakka District',
        },
        {
          fullCode: 'IS-HAF',
          regionCode: 'HAF',
          regionName: 'Hafnarfjordur (Market) Town',
        },
        {
          fullCode: 'IS-HRG',
          regionCode: 'HRG',
          regionName: 'Horgar Village',
        },
        {
          fullCode: 'IS-HRU',
          regionCode: 'HRU',
          regionName: 'Hrunamanna District',
        },
        {
          fullCode: 'IS-HUG',
          regionCode: 'HUG',
          regionName: 'Huna Settlement',
        },
        {
          fullCode: 'IS-HUV',
          regionCode: 'HUV',
          regionName: 'Western Hunathing',
        },
        {
          fullCode: 'IS-HVA',
          regionCode: 'HVA',
          regionName: 'Hvalfjordur Village',
        },
        {
          fullCode: 'IS-HVE',
          regionCode: 'HVE',
          regionName: 'Hveragerdi Town',
        },
        {
          fullCode: 'IS-ISA',
          regionCode: 'ISA',
          regionName: 'Isafjordur Town',
        },
        {
          fullCode: 'IS-KAL',
          regionCode: 'KAL',
          regionName: 'Kaldrananes District',
        },
        {
          fullCode: 'IS-KJO',
          regionCode: 'KJO',
          regionName: 'Kjosar District',
        },
        {
          fullCode: 'IS-KOP',
          regionCode: 'KOP',
          regionName: 'Kopavogur Town',
        },
        {
          fullCode: 'IS-LAN',
          regionCode: 'LAN',
          regionName: 'Langanes Settlement',
        },
        {
          fullCode: 'IS-MOS',
          regionCode: 'MOS',
          regionName: 'Mosfell Town',
        },
        {
          fullCode: 'IS-MUL',
          regionCode: 'MUL',
          regionName: 'Mulathing',
        },
        {
          fullCode: 'IS-MYR',
          regionCode: 'MYR',
          regionName: 'Myrdalur District',
        },
        {
          fullCode: 'IS-NOR',
          regionCode: 'NOR',
          regionName: 'Nordurthing',
        },
        {
          fullCode: 'IS-RGE',
          regionCode: 'RGE',
          regionName: 'Eastern Rangarthing',
        },
        {
          fullCode: 'IS-RGY',
          regionCode: 'RGY',
          regionName: 'Outer Rangarthing',
        },
        {
          fullCode: 'IS-RHH',
          regionCode: 'RHH',
          regionName: 'Reykhola District',
        },
        {
          fullCode: 'IS-RKN',
          regionCode: 'RKN',
          regionName: 'Reykjanes Town',
        },
        {
          fullCode: 'IS-RKV',
          regionCode: 'RKV',
          regionName: 'Reykjavik City',
        },
        {
          fullCode: 'IS-SEL',
          regionCode: 'SEL',
          regionName: 'Seltjarnarnes Town',
        },
        {
          fullCode: 'IS-SKF',
          regionCode: 'SKF',
          regionName: 'Skaftar District',
        },
        {
          fullCode: 'IS-SKG',
          regionCode: 'SKG',
          regionName: 'Skaga Settlement',
        },
        {
          fullCode: 'IS-SKR',
          regionCode: 'SKR',
          regionName: 'Skagafjordur',
        },
        {
          fullCode: 'IS-SOG',
          regionCode: 'SOG',
          regionName: 'Skeida and Gnupverja District',
        },
        {
          fullCode: 'IS-SKO',
          regionCode: 'SKO',
          regionName: 'Skorradalur District',
        },
        {
          fullCode: 'IS-SNF',
          regionCode: 'SNF',
          regionName: 'Snaefell Town',
        },
        {
          fullCode: 'IS-STR',
          regionCode: 'STR',
          regionName: 'Stranda Settlement',
        },
        {
          fullCode: 'IS-STY',
          regionCode: 'STY',
          regionName: 'Stykkisholmur Town',
        },
        {
          fullCode: 'IS-SDV',
          regionCode: 'SDV',
          regionName: 'Sudavik District',
        },
        {
          fullCode: 'IS-SDN',
          regionCode: 'SDN',
          regionName: 'Sudurnesja Town',
        },
        {
          fullCode: 'IS-SBT',
          regionCode: 'SBT',
          regionName: 'Svalbardsstrond District',
        },
        {
          fullCode: 'IS-SFA',
          regionCode: 'SFA',
          regionName: 'Arborg Township',
        },
        {
          fullCode: 'IS-SHF',
          regionCode: 'SHF',
          regionName: 'Hornafjordur Township',
        },
        {
          fullCode: 'IS-SOL',
          regionCode: 'SOL',
          regionName: 'Olfus Township',
        },
        {
          fullCode: 'IS-SSS',
          regionCode: 'SSS',
          regionName: 'Skagastrond Township',
        },
        {
          fullCode: 'IS-SVG',
          regionCode: 'SVG',
          regionName: 'Vogar Township',
        },
        {
          fullCode: 'IS-TAL',
          regionCode: 'TAL',
          regionName: 'Talknafjordur District',
        },
        {
          fullCode: 'IS-TJO',
          regionCode: 'TJO',
          regionName: 'Tjornes District',
        },
        {
          fullCode: 'IS-VEM',
          regionCode: 'VEM',
          regionName: 'Vestmannaeyja Town',
        },
        {
          fullCode: 'IS-VER',
          regionCode: 'VER',
          regionName: 'Western Settlement',
        },
        {
          fullCode: 'IS-VOP',
          regionCode: 'VOP',
          regionName: 'Vopnafjordur District',
        },
        {
          fullCode: 'IS-THG',
          regionCode: 'THG',
          regionName: 'Thingeyjar Village',
        },
      ],
    },
  ],
  [
    'india',
    {
      countryCode: 'IN',
      countryName: 'India',
      regions: [
        {
          fullCode: 'IN-AN',
          regionCode: 'AN',
          regionName: 'Andaman and Nicobar Islands',
        },
        {
          fullCode: 'IN-AP',
          regionCode: 'AP',
          regionName: 'Andhra Pradesh',
        },
        {
          fullCode: 'IN-AR',
          regionCode: 'AR',
          regionName: 'Arunāchal Pradesh',
        },
        {
          fullCode: 'IN-AS',
          regionCode: 'AS',
          regionName: 'Assam',
        },
        {
          fullCode: 'IN-BR',
          regionCode: 'BR',
          regionName: 'Bihār',
        },
        {
          fullCode: 'IN-CH',
          regionCode: 'CH',
          regionName: 'Chandīgarh',
        },
        {
          fullCode: 'IN-CG',
          regionCode: 'CG',
          regionName: 'Chhattīsgarh',
        },
        {
          fullCode: 'IN-DH',
          regionCode: 'DH',
          regionName: 'Dādra and Nagar Haveli and Damān and Diu[2]',
        },
        {
          fullCode: 'IN-DL',
          regionCode: 'DL',
          regionName: 'Delhi',
        },
        {
          fullCode: 'IN-GA',
          regionCode: 'GA',
          regionName: 'Goa',
        },
        {
          fullCode: 'IN-GJ',
          regionCode: 'GJ',
          regionName: 'Gujarāt',
        },
        {
          fullCode: 'IN-HR',
          regionCode: 'HR',
          regionName: 'Haryāna',
        },
        {
          fullCode: 'IN-HP',
          regionCode: 'HP',
          regionName: 'Himāchal Pradesh',
        },
        {
          fullCode: 'IN-JK',
          regionCode: 'JK',
          regionName: 'Jammu and Kashmīr',
        },
        {
          fullCode: 'IN-JH',
          regionCode: 'JH',
          regionName: 'Jhārkhand',
        },
        {
          fullCode: 'IN-KA',
          regionCode: 'KA',
          regionName: 'Karnātaka',
        },
        {
          fullCode: 'IN-KL',
          regionCode: 'KL',
          regionName: 'Kerala',
        },
        {
          fullCode: 'IN-LA',
          regionCode: 'LA',
          regionName: 'Ladākh',
        },
        {
          fullCode: 'IN-LD',
          regionCode: 'LD',
          regionName: 'Lakshadweep',
        },
        {
          fullCode: 'IN-MP',
          regionCode: 'MP',
          regionName: 'Madhya Pradesh',
        },
        {
          fullCode: 'IN-MH',
          regionCode: 'MH',
          regionName: 'Mahārāshtra',
        },
        {
          fullCode: 'IN-MN',
          regionCode: 'MN',
          regionName: 'Manipur',
        },
        {
          fullCode: 'IN-ML',
          regionCode: 'ML',
          regionName: 'Meghālaya',
        },
        {
          fullCode: 'IN-MZ',
          regionCode: 'MZ',
          regionName: 'Mizoram',
        },
        {
          fullCode: 'IN-NL',
          regionCode: 'NL',
          regionName: 'Nāgāland',
        },
        {
          fullCode: 'IN-OD',
          regionCode: 'OD',
          regionName: 'Odisha',
        },
        {
          fullCode: 'IN-PY',
          regionCode: 'PY',
          regionName: 'Puducherry (Local variant: Pondicherry)',
        },
        {
          fullCode: 'IN-PB',
          regionCode: 'PB',
          regionName: 'Punjab',
        },
        {
          fullCode: 'IN-RJ',
          regionCode: 'RJ',
          regionName: 'Rājasthān',
        },
        {
          fullCode: 'IN-SK',
          regionCode: 'SK',
          regionName: 'Sikkim',
        },
        {
          fullCode: 'IN-TN',
          regionCode: 'TN',
          regionName: 'Tamil Nādu',
        },
        {
          fullCode: 'IN-TS',
          regionCode: 'TS',
          regionName: 'Telangāna[3]',
        },
        {
          fullCode: 'IN-TR',
          regionCode: 'TR',
          regionName: 'Tripura',
        },
        {
          fullCode: 'IN-UP',
          regionCode: 'UP',
          regionName: 'Uttar Pradesh',
        },
        {
          fullCode: 'IN-UK',
          regionCode: 'UK',
          regionName: 'Uttarākhand',
        },
        {
          fullCode: 'IN-WB',
          regionCode: 'WB',
          regionName: 'West Bengal',
        },
      ],
    },
  ],
  [
    'indonesia',
    {
      countryCode: 'ID',
      countryName: 'Indonesia',
      regions: [
        {
          fullCode: 'ID-JW',
          regionCode: 'JW',
          regionName: 'Java',
        },
        {
          fullCode: 'ID-KA',
          regionCode: 'KA',
          regionName: 'Kalimantan',
        },
        {
          fullCode: 'ID-ML',
          regionCode: 'ML',
          regionName: 'Moluccas',
        },
        {
          fullCode: 'ID-NU',
          regionCode: 'NU',
          regionName: 'Lesser Sunda Islands',
        },
        {
          fullCode: 'ID-PP',
          regionCode: 'PP',
          regionName: 'Western New Guinea',
        },
        {
          fullCode: 'ID-SL',
          regionCode: 'SL',
          regionName: 'Sulawesi',
        },
        {
          fullCode: 'ID-SM',
          regionCode: 'SM',
          regionName: 'Sumatra',
        },
        {
          fullCode: 'ID-AC',
          regionCode: 'AC',
          regionName: 'Aceh',
        },
        {
          fullCode: 'ID-BA',
          regionCode: 'BA',
          regionName: 'Bali',
        },
        {
          fullCode: 'ID-BT',
          regionCode: 'BT',
          regionName: 'Banten',
        },
        {
          fullCode: 'ID-BE',
          regionCode: 'BE',
          regionName: 'Bengkulu',
        },
        {
          fullCode: 'ID-GO',
          regionCode: 'GO',
          regionName: 'Gorontalo',
        },
        {
          fullCode: 'ID-JK',
          regionCode: 'JK',
          regionName: 'DKI Jakarta; DKI',
        },
        {
          fullCode: 'ID-JA',
          regionCode: 'JA',
          regionName: 'Jambi',
        },
        {
          fullCode: 'ID-JB',
          regionCode: 'JB',
          regionName: 'Jawa Barat',
        },
        {
          fullCode: 'ID-JT',
          regionCode: 'JT',
          regionName: 'Jawa Tengah',
        },
        {
          fullCode: 'ID-JI',
          regionCode: 'JI',
          regionName: 'Jawa Timur',
        },
        {
          fullCode: 'ID-KB',
          regionCode: 'KB',
          regionName: 'Kalimantan Barat',
        },
        {
          fullCode: 'ID-KS',
          regionCode: 'KS',
          regionName: 'Kalimantan Selatan',
        },
        {
          fullCode: 'ID-KT',
          regionCode: 'KT',
          regionName: 'Kalimantan Tengah',
        },
        {
          fullCode: 'ID-KI',
          regionCode: 'KI',
          regionName: 'Kalimantan Timur',
        },
        {
          fullCode: 'ID-KU',
          regionCode: 'KU',
          regionName: 'Kalimantan Utara',
        },
        {
          fullCode: 'ID-BB',
          regionCode: 'BB',
          regionName: 'Bangka Belitung Islands',
        },
        {
          fullCode: 'ID-KR',
          regionCode: 'KR',
          regionName: 'Kepulauan Riau',
        },
        {
          fullCode: 'ID-LA',
          regionCode: 'LA',
          regionName: 'Lampung',
        },
        {
          fullCode: 'ID-MA',
          regionCode: 'MA',
          regionName: 'Maluku',
        },
        {
          fullCode: 'ID-MU',
          regionCode: 'MU',
          regionName: 'Maluku Utara',
        },
        {
          fullCode: 'ID-NB',
          regionCode: 'NB',
          regionName: 'Nusa Tenggara Barat',
        },
        {
          fullCode: 'ID-NT',
          regionCode: 'NT',
          regionName: 'Nusa Tenggara Timur',
        },
        {
          fullCode: 'ID-PA',
          regionCode: 'PA',
          regionName: 'Papua',
        },
        {
          fullCode: 'ID-PB',
          regionCode: 'PB',
          regionName: 'Papua Barat',
        },
        {
          fullCode: 'ID-PD',
          regionCode: 'PD',
          regionName: 'Papua Barat Daya',
        },
        {
          fullCode: 'ID-PE',
          regionCode: 'PE',
          regionName: 'Papua Pengunungan',
        },
        {
          fullCode: 'ID-PS',
          regionCode: 'PS',
          regionName: 'Papua Selatan',
        },
        {
          fullCode: 'ID-PT',
          regionCode: 'PT',
          regionName: 'Papua Tengah',
        },
        {
          fullCode: 'ID-RI',
          regionCode: 'RI',
          regionName: 'Riau',
        },
        {
          fullCode: 'ID-SR',
          regionCode: 'SR',
          regionName: 'Sulawesi Barat',
        },
        {
          fullCode: 'ID-SN',
          regionCode: 'SN',
          regionName: 'Sulawesi Selatan',
        },
        {
          fullCode: 'ID-ST',
          regionCode: 'ST',
          regionName: 'Sulawesi Tengah',
        },
        {
          fullCode: 'ID-SG',
          regionCode: 'SG',
          regionName: 'Sulawesi Tenggara',
        },
        {
          fullCode: 'ID-SA',
          regionCode: 'SA',
          regionName: 'Sulawesi Utara',
        },
        {
          fullCode: 'ID-SB',
          regionCode: 'SB',
          regionName: 'Sumatera Barat',
        },
        {
          fullCode: 'ID-SS',
          regionCode: 'SS',
          regionName: 'Sumatera Selatan',
        },
        {
          fullCode: 'ID-SU',
          regionCode: 'SU',
          regionName: 'Sumatera Utara',
        },
        {
          fullCode: 'ID-YO',
          regionCode: 'YO',
          regionName: 'DI Yogya; DIY',
        },
      ],
    },
  ],
  [
    'iran, islamic republic of',
    {
      countryCode: 'IR',
      countryName: 'Iran, Islamic Republic of',
      regions: [
        {
          fullCode: 'IR-30',
          regionCode: '30',
          regionName: 'Alborz',
        },
        {
          fullCode: 'IR-24',
          regionCode: '24',
          regionName: 'Ardabil',
        },
        {
          fullCode: 'IR-04',
          regionCode: '04',
          regionName: 'West Azerbaijan',
        },
        {
          fullCode: 'IR-03',
          regionCode: '03',
          regionName: 'East Azerbaijan',
        },
        {
          fullCode: 'IR-18',
          regionCode: '18',
          regionName: 'Bushehr',
        },
        {
          fullCode: 'IR-14',
          regionCode: '14',
          regionName: 'Chaharmahal and Bakhtiari',
        },
        {
          fullCode: 'IR-10',
          regionCode: '10',
          regionName: 'Isfahan',
        },
        {
          fullCode: 'IR-07',
          regionCode: '07',
          regionName: 'Fars',
        },
        {
          fullCode: 'IR-01',
          regionCode: '01',
          regionName: 'Gilan',
        },
        {
          fullCode: 'IR-27',
          regionCode: '27',
          regionName: 'Golestan',
        },
        {
          fullCode: 'IR-13',
          regionCode: '13',
          regionName: 'Hamadan',
        },
        {
          fullCode: 'IR-22',
          regionCode: '22',
          regionName: 'Hormozgan',
        },
        {
          fullCode: 'IR-16',
          regionCode: '16',
          regionName: 'Ilam',
        },
        {
          fullCode: 'IR-08',
          regionCode: '08',
          regionName: 'Kerman',
        },
        {
          fullCode: 'IR-05',
          regionCode: '05',
          regionName: 'Kermanshah',
        },
        {
          fullCode: 'IR-29',
          regionCode: '29',
          regionName: 'South Khorasan',
        },
        {
          fullCode: 'IR-09',
          regionCode: '09',
          regionName: 'Central Khorasan',
        },
        {
          fullCode: 'IR-28',
          regionCode: '28',
          regionName: 'North Khorasan',
        },
        {
          fullCode: 'IR-06',
          regionCode: '06',
          regionName: 'Khuzestan',
        },
        {
          fullCode: 'IR-17',
          regionCode: '17',
          regionName: 'Kohgiluyeh and Boyer-Ahmad',
        },
        {
          fullCode: 'IR-12',
          regionCode: '12',
          regionName: 'Kurdistan',
        },
        {
          fullCode: 'IR-15',
          regionCode: '15',
          regionName: 'Lorestan',
        },
        {
          fullCode: 'IR-00',
          regionCode: '00',
          regionName: 'Markazi',
        },
        {
          fullCode: 'IR-02',
          regionCode: '02',
          regionName: 'Mazandaran',
        },
        {
          fullCode: 'IR-26',
          regionCode: '26',
          regionName: 'Qazvin',
        },
        {
          fullCode: 'IR-25',
          regionCode: '25',
          regionName: 'Qom',
        },
        {
          fullCode: 'IR-20',
          regionCode: '20',
          regionName: 'Semnan',
        },
        {
          fullCode: 'IR-11',
          regionCode: '11',
          regionName: 'Sistan and Baluchestan',
        },
        {
          fullCode: 'IR-23',
          regionCode: '23',
          regionName: 'Tehran',
        },
        {
          fullCode: 'IR-21',
          regionCode: '21',
          regionName: 'Yazd',
        },
        {
          fullCode: 'IR-19',
          regionCode: '19',
          regionName: 'Zanjan',
        },
      ],
    },
  ],
  [
    'iraq',
    {
      countryCode: 'IQ',
      countryName: 'Iraq',
      regions: [
        {
          fullCode: 'IQ-AN',
          regionCode: 'AN',
          regionName: 'Anbar',
        },
        {
          fullCode: 'IQ-BA',
          regionCode: 'BA',
          regionName: 'Basra',
        },
        {
          fullCode: 'IQ-MU',
          regionCode: 'MU',
          regionName: 'Muthanna',
        },
        {
          fullCode: 'IQ-QA',
          regionCode: 'QA',
          regionName: 'Qadisiya',
        },
        {
          fullCode: 'IQ-NA',
          regionCode: 'NA',
          regionName: 'Najaf',
        },
        {
          fullCode: 'IQ-AR',
          regionCode: 'AR',
          regionName: 'Erbil',
        },
        {
          fullCode: 'IQ-SU',
          regionCode: 'SU',
          regionName: 'Sulaymaniya',
        },
        {
          fullCode: 'IQ-BB',
          regionCode: 'BB',
          regionName: 'Babylon',
        },
        {
          fullCode: 'IQ-BG',
          regionCode: 'BG',
          regionName: 'Bagdad',
        },
        {
          fullCode: 'IQ-DA',
          regionCode: 'DA',
          regionName: 'Duhok',
        },
        {
          fullCode: 'IQ-DQ',
          regionCode: 'DQ',
          regionName: 'Dhi Qar',
        },
        {
          fullCode: 'IQ-DI',
          regionCode: 'DI',
          regionName: 'Diyala',
        },
        {
          fullCode: 'IQ-KR',
          regionCode: 'KR',
          regionName: 'Kurdistan',
        },
        {
          fullCode: 'IQ-KA',
          regionCode: 'KA',
          regionName: 'Karbala',
        },
        {
          fullCode: 'IQ-KI',
          regionCode: 'KI',
          regionName: 'Kirkuk',
        },
        {
          fullCode: 'IQ-MA',
          regionCode: 'MA',
          regionName: 'Maysan',
        },
        {
          fullCode: 'IQ-NI',
          regionCode: 'NI',
          regionName: 'Nineveh',
        },
        {
          fullCode: 'IQ-SD',
          regionCode: 'SD',
          regionName: 'Saladin',
        },
        {
          fullCode: 'IQ-WA',
          regionCode: 'WA',
          regionName: 'Wasit',
        },
      ],
    },
  ],
  [
    'ireland',
    {
      countryCode: 'IE',
      countryName: 'Ireland',
      regions: [
        {
          fullCode: 'IE-C',
          regionCode: 'C',
          regionName: 'Connaught',
        },
        {
          fullCode: 'IE-L',
          regionCode: 'L',
          regionName: 'Leinster',
        },
        {
          fullCode: 'IE-M',
          regionCode: 'M',
          regionName: 'Munster',
        },
        {
          fullCode: 'IE-U',
          regionCode: 'U',
          regionName: 'Ulster',
        },
        {
          fullCode: 'IE-CW',
          regionCode: 'CW',
          regionName: 'Carlow',
        },
        {
          fullCode: 'IE-CN',
          regionCode: 'CN',
          regionName: 'Cavan',
        },
        {
          fullCode: 'IE-CE',
          regionCode: 'CE',
          regionName: 'Clare',
        },
        {
          fullCode: 'IE-CO',
          regionCode: 'CO',
          regionName: 'Cork',
        },
        {
          fullCode: 'IE-DL',
          regionCode: 'DL',
          regionName: 'Donegal',
        },
        {
          fullCode: 'IE-D',
          regionCode: 'D',
          regionName: 'Dublin',
        },
        {
          fullCode: 'IE-G',
          regionCode: 'G',
          regionName: 'Galway',
        },
        {
          fullCode: 'IE-KY',
          regionCode: 'KY',
          regionName: 'Kerry',
        },
        {
          fullCode: 'IE-KE',
          regionCode: 'KE',
          regionName: 'Kildare',
        },
        {
          fullCode: 'IE-KK',
          regionCode: 'KK',
          regionName: 'Kilkenny',
        },
        {
          fullCode: 'IE-LS',
          regionCode: 'LS',
          regionName: 'Laois',
        },
        {
          fullCode: 'IE-LM',
          regionCode: 'LM',
          regionName: 'Leitrim',
        },
        {
          fullCode: 'IE-LK',
          regionCode: 'LK',
          regionName: 'Limerick',
        },
        {
          fullCode: 'IE-LD',
          regionCode: 'LD',
          regionName: 'Longford',
        },
        {
          fullCode: 'IE-LH',
          regionCode: 'LH',
          regionName: 'Louth',
        },
        {
          fullCode: 'IE-MO',
          regionCode: 'MO',
          regionName: 'Mayo',
        },
        {
          fullCode: 'IE-MH',
          regionCode: 'MH',
          regionName: 'Meath',
        },
        {
          fullCode: 'IE-MN',
          regionCode: 'MN',
          regionName: 'Monaghan',
        },
        {
          fullCode: 'IE-OY',
          regionCode: 'OY',
          regionName: 'Offaly',
        },
        {
          fullCode: 'IE-RN',
          regionCode: 'RN',
          regionName: 'Roscommon',
        },
        {
          fullCode: 'IE-SO',
          regionCode: 'SO',
          regionName: 'Sligo',
        },
        {
          fullCode: 'IE-TA',
          regionCode: 'TA',
          regionName: 'Tipperary',
        },
        {
          fullCode: 'IE-WD',
          regionCode: 'WD',
          regionName: 'Waterford',
        },
        {
          fullCode: 'IE-WH',
          regionCode: 'WH',
          regionName: 'Westmeath',
        },
        {
          fullCode: 'IE-WX',
          regionCode: 'WX',
          regionName: 'Wexford',
        },
        {
          fullCode: 'IE-WW',
          regionCode: 'WW',
          regionName: 'Wicklow',
        },
      ],
    },
  ],
  [
    'israel',
    {
      countryCode: 'IL',
      countryName: 'Israel',
      regions: [
        {
          fullCode: 'IL-D',
          regionCode: 'D',
          regionName: 'لواء الجنوب',
        },
        {
          fullCode: 'IL-M',
          regionCode: 'M',
          regionName: 'المنطقة الوسطى',
        },
        {
          fullCode: 'IL-Z',
          regionCode: 'Z',
          regionName: 'منطقة الشمال',
        },
        {
          fullCode: 'IL-HA',
          regionCode: 'HA',
          regionName: 'منطقة حيفا',
        },
        {
          fullCode: 'IL-TA',
          regionCode: 'TA',
          regionName: 'منطقة تل أبيب',
        },
        {
          fullCode: 'IL-JM',
          regionCode: 'JM',
          regionName: 'منطقة القدس',
        },
      ],
    },
  ],
  [
    'italy',
    {
      countryCode: 'IT',
      countryName: 'Italy',
      regions: [
        {
          fullCode: 'IT-21',
          regionCode: '21',
          regionName: 'Piemonte',
        },
        {
          fullCode: 'IT-23',
          regionCode: '23',
          regionName: "Valle d'Aosta/Vallée d'Aoste",
        },
        {
          fullCode: 'IT-25',
          regionCode: '25',
          regionName: 'Lombardia',
        },
        {
          fullCode: 'IT-32',
          regionCode: '32',
          regionName: 'Trentino-Alto Adige/Südtirol',
        },
        {
          fullCode: 'IT-34',
          regionCode: '34',
          regionName: 'Veneto',
        },
        {
          fullCode: 'IT-36',
          regionCode: '36',
          regionName: 'Friuli Venezia Giulia',
        },
        {
          fullCode: 'IT-42',
          regionCode: '42',
          regionName: 'Liguria',
        },
        {
          fullCode: 'IT-45',
          regionCode: '45',
          regionName: 'Emilia-Romagna',
        },
        {
          fullCode: 'IT-52',
          regionCode: '52',
          regionName: 'Toscana',
        },
        {
          fullCode: 'IT-55',
          regionCode: '55',
          regionName: 'Umbria',
        },
        {
          fullCode: 'IT-57',
          regionCode: '57',
          regionName: 'Marche',
        },
        {
          fullCode: 'IT-62',
          regionCode: '62',
          regionName: 'Lazio',
        },
        {
          fullCode: 'IT-65',
          regionCode: '65',
          regionName: 'Abruzzo',
        },
        {
          fullCode: 'IT-67',
          regionCode: '67',
          regionName: 'Molise',
        },
        {
          fullCode: 'IT-72',
          regionCode: '72',
          regionName: 'Campania',
        },
        {
          fullCode: 'IT-75',
          regionCode: '75',
          regionName: 'Puglia',
        },
        {
          fullCode: 'IT-77',
          regionCode: '77',
          regionName: 'Basilicata',
        },
        {
          fullCode: 'IT-78',
          regionCode: '78',
          regionName: 'Calabria',
        },
        {
          fullCode: 'IT-82',
          regionCode: '82',
          regionName: 'Sicilia',
        },
        {
          fullCode: 'IT-88',
          regionCode: '88',
          regionName: 'Sardegna',
        },
        {
          fullCode: 'IT-AG',
          regionCode: 'AG',
          regionName: 'Agrigento',
        },
        {
          fullCode: 'IT-AL',
          regionCode: 'AL',
          regionName: 'Alessandria',
        },
        {
          fullCode: 'IT-AN',
          regionCode: 'AN',
          regionName: 'Ancona',
        },
        {
          fullCode: 'IT-AO',
          regionCode: 'AO',
          regionName: 'Aosta',
        },
        {
          fullCode: 'IT-AR',
          regionCode: 'AR',
          regionName: 'Arezzo',
        },
        {
          fullCode: 'IT-AP',
          regionCode: 'AP',
          regionName: 'Ascoli Piceno',
        },
        {
          fullCode: 'IT-AT',
          regionCode: 'AT',
          regionName: 'Asti',
        },
        {
          fullCode: 'IT-AV',
          regionCode: 'AV',
          regionName: 'Avellino',
        },
        {
          fullCode: 'IT-BA',
          regionCode: 'BA',
          regionName: 'Bari',
        },
        {
          fullCode: 'IT-BT',
          regionCode: 'BT',
          regionName: 'Barletta-Andria-Trani',
        },
        {
          fullCode: 'IT-BL',
          regionCode: 'BL',
          regionName: 'Belluno',
        },
        {
          fullCode: 'IT-BN',
          regionCode: 'BN',
          regionName: 'Benevento',
        },
        {
          fullCode: 'IT-BG',
          regionCode: 'BG',
          regionName: 'Bergamo',
        },
        {
          fullCode: 'IT-BI',
          regionCode: 'BI',
          regionName: 'Biella',
        },
        {
          fullCode: 'IT-BO',
          regionCode: 'BO',
          regionName: 'Bologna',
        },
        {
          fullCode: 'IT-BZ',
          regionCode: 'BZ',
          regionName: 'Bolzano/Bozen',
        },
        {
          fullCode: 'IT-BS',
          regionCode: 'BS',
          regionName: 'Brescia',
        },
        {
          fullCode: 'IT-BR',
          regionCode: 'BR',
          regionName: 'Brindisi',
        },
        {
          fullCode: 'IT-CA',
          regionCode: 'CA',
          regionName: 'Cagliari',
        },
        {
          fullCode: 'IT-CL',
          regionCode: 'CL',
          regionName: 'Caltanissetta',
        },
        {
          fullCode: 'IT-CB',
          regionCode: 'CB',
          regionName: 'Campobasso',
        },
        {
          fullCode: 'IT-CI',
          regionCode: 'CI',
          regionName: 'Carbonia-Iglesias',
        },
        {
          fullCode: 'IT-CE',
          regionCode: 'CE',
          regionName: 'Caserta',
        },
        {
          fullCode: 'IT-CT',
          regionCode: 'CT',
          regionName: 'Catania',
        },
        {
          fullCode: 'IT-CZ',
          regionCode: 'CZ',
          regionName: 'Catanzaro',
        },
        {
          fullCode: 'IT-CH',
          regionCode: 'CH',
          regionName: 'Chieti',
        },
        {
          fullCode: 'IT-CO',
          regionCode: 'CO',
          regionName: 'Como',
        },
        {
          fullCode: 'IT-CS',
          regionCode: 'CS',
          regionName: 'Cosenza',
        },
        {
          fullCode: 'IT-CR',
          regionCode: 'CR',
          regionName: 'Cremona',
        },
        {
          fullCode: 'IT-KR',
          regionCode: 'KR',
          regionName: 'Crotone',
        },
        {
          fullCode: 'IT-CN',
          regionCode: 'CN',
          regionName: 'Cuneo',
        },
        {
          fullCode: 'IT-EN',
          regionCode: 'EN',
          regionName: 'Enna',
        },
        {
          fullCode: 'IT-FM',
          regionCode: 'FM',
          regionName: 'Fermo',
        },
        {
          fullCode: 'IT-FE',
          regionCode: 'FE',
          regionName: 'Ferrara',
        },
        {
          fullCode: 'IT-FI',
          regionCode: 'FI',
          regionName: 'Firenze',
        },
        {
          fullCode: 'IT-FG',
          regionCode: 'FG',
          regionName: 'Foggia',
        },
        {
          fullCode: 'IT-FO',
          regionCode: 'FO',
          regionName: 'Forlì-Cesena',
        },
        {
          fullCode: 'IT-FR',
          regionCode: 'FR',
          regionName: 'Frosinone',
        },
        {
          fullCode: 'IT-GE',
          regionCode: 'GE',
          regionName: 'Genova',
        },
        {
          fullCode: 'IT-GO',
          regionCode: 'GO',
          regionName: 'Gorizia',
        },
        {
          fullCode: 'IT-GR',
          regionCode: 'GR',
          regionName: 'Grosseto',
        },
        {
          fullCode: 'IT-IM',
          regionCode: 'IM',
          regionName: 'Imperia',
        },
        {
          fullCode: 'IT-IS',
          regionCode: 'IS',
          regionName: 'Isernia',
        },
        {
          fullCode: 'IT-SP',
          regionCode: 'SP',
          regionName: 'La Spezia',
        },
        {
          fullCode: 'IT-AQ',
          regionCode: 'AQ',
          regionName: "L'Aquila",
        },
        {
          fullCode: 'IT-LT',
          regionCode: 'LT',
          regionName: 'Latina',
        },
        {
          fullCode: 'IT-LE',
          regionCode: 'LE',
          regionName: 'Lecce',
        },
        {
          fullCode: 'IT-LC',
          regionCode: 'LC',
          regionName: 'Lecco',
        },
        {
          fullCode: 'IT-LI',
          regionCode: 'LI',
          regionName: 'Livorno',
        },
        {
          fullCode: 'IT-LO',
          regionCode: 'LO',
          regionName: 'Lodi',
        },
        {
          fullCode: 'IT-LU',
          regionCode: 'LU',
          regionName: 'Lucca',
        },
        {
          fullCode: 'IT-MC',
          regionCode: 'MC',
          regionName: 'Macerata',
        },
        {
          fullCode: 'IT-MN',
          regionCode: 'MN',
          regionName: 'Mantova',
        },
        {
          fullCode: 'IT-MS',
          regionCode: 'MS',
          regionName: 'Massa-Carrara',
        },
        {
          fullCode: 'IT-MT',
          regionCode: 'MT',
          regionName: 'Matera',
        },
        {
          fullCode: 'IT-ME',
          regionCode: 'ME',
          regionName: 'Messina',
        },
        {
          fullCode: 'IT-MI',
          regionCode: 'MI',
          regionName: 'Milano',
        },
        {
          fullCode: 'IT-MO',
          regionCode: 'MO',
          regionName: 'Modena',
        },
        {
          fullCode: 'IT-NA',
          regionCode: 'NA',
          regionName: 'Napoli',
        },
        {
          fullCode: 'IT-NO',
          regionCode: 'NO',
          regionName: 'Novara',
        },
        {
          fullCode: 'IT-NU',
          regionCode: 'NU',
          regionName: 'Nuoro',
        },
        {
          fullCode: 'IT-OT',
          regionCode: 'OT',
          regionName: 'Olbia-Tempio',
        },
        {
          fullCode: 'IT-OR',
          regionCode: 'OR',
          regionName: 'Oristano',
        },
        {
          fullCode: 'IT-PD',
          regionCode: 'PD',
          regionName: 'Padova',
        },
        {
          fullCode: 'IT-PA',
          regionCode: 'PA',
          regionName: 'Palermo',
        },
        {
          fullCode: 'IT-PR',
          regionCode: 'PR',
          regionName: 'Parma',
        },
        {
          fullCode: 'IT-PV',
          regionCode: 'PV',
          regionName: 'Pavia',
        },
        {
          fullCode: 'IT-PG',
          regionCode: 'PG',
          regionName: 'Perugia',
        },
        {
          fullCode: 'IT-PE',
          regionCode: 'PE',
          regionName: 'Pescara',
        },
        {
          fullCode: 'IT-PC',
          regionCode: 'PC',
          regionName: 'Piacenza',
        },
        {
          fullCode: 'IT-PI',
          regionCode: 'PI',
          regionName: 'Pisa',
        },
        {
          fullCode: 'IT-PT',
          regionCode: 'PT',
          regionName: 'Pistoia',
        },
        {
          fullCode: 'IT-PN',
          regionCode: 'PN',
          regionName: 'Pordenone',
        },
        {
          fullCode: 'IT-PZ',
          regionCode: 'PZ',
          regionName: 'Potenza',
        },
        {
          fullCode: 'IT-PO',
          regionCode: 'PO',
          regionName: 'Prato',
        },
        {
          fullCode: 'IT-RG',
          regionCode: 'RG',
          regionName: 'Ragusa',
        },
        {
          fullCode: 'IT-RA',
          regionCode: 'RA',
          regionName: 'Ravenna',
        },
        {
          fullCode: 'IT-RC',
          regionCode: 'RC',
          regionName: 'Reggio Calabria',
        },
        {
          fullCode: 'IT-RE',
          regionCode: 'RE',
          regionName: 'Reggio Emilia',
        },
        {
          fullCode: 'IT-RI',
          regionCode: 'RI',
          regionName: 'Rieti',
        },
        {
          fullCode: 'IT-RN',
          regionCode: 'RN',
          regionName: 'Rimini',
        },
        {
          fullCode: 'IT-RM',
          regionCode: 'RM',
          regionName: 'Roma',
        },
        {
          fullCode: 'IT-RO',
          regionCode: 'RO',
          regionName: 'Rovigo',
        },
        {
          fullCode: 'IT-SA',
          regionCode: 'SA',
          regionName: 'Salerno',
        },
        {
          fullCode: 'IT-SS',
          regionCode: 'SS',
          regionName: 'Sassari',
        },
        {
          fullCode: 'IT-SV',
          regionCode: 'SV',
          regionName: 'Savona',
        },
        {
          fullCode: 'IT-SI',
          regionCode: 'SI',
          regionName: 'Siena',
        },
        {
          fullCode: 'IT-SR',
          regionCode: 'SR',
          regionName: 'Siracusa',
        },
        {
          fullCode: 'IT-SO',
          regionCode: 'SO',
          regionName: 'Sondrio',
        },
        {
          fullCode: 'IT-TA',
          regionCode: 'TA',
          regionName: 'Taranto',
        },
        {
          fullCode: 'IT-TE',
          regionCode: 'TE',
          regionName: 'Teramo',
        },
        {
          fullCode: 'IT-TR',
          regionCode: 'TR',
          regionName: 'Terni',
        },
        {
          fullCode: 'IT-TO',
          regionCode: 'TO',
          regionName: 'Torino',
        },
        {
          fullCode: 'IT-TP',
          regionCode: 'TP',
          regionName: 'Trapani',
        },
        {
          fullCode: 'IT-TN',
          regionCode: 'TN',
          regionName: 'Trento',
        },
        {
          fullCode: 'IT-TV',
          regionCode: 'TV',
          regionName: 'Treviso',
        },
        {
          fullCode: 'IT-TS',
          regionCode: 'TS',
          regionName: 'Trieste',
        },
        {
          fullCode: 'IT-UD',
          regionCode: 'UD',
          regionName: 'Udine',
        },
        {
          fullCode: 'IT-VA',
          regionCode: 'VA',
          regionName: 'Varese',
        },
        {
          fullCode: 'IT-VE',
          regionCode: 'VE',
          regionName: 'Venezia',
        },
        {
          fullCode: 'IT-VB',
          regionCode: 'VB',
          regionName: 'Verbano-Cusio-Ossola',
        },
        {
          fullCode: 'IT-VC',
          regionCode: 'VC',
          regionName: 'Vercelli',
        },
        {
          fullCode: 'IT-VR',
          regionCode: 'VR',
          regionName: 'Verona',
        },
        {
          fullCode: 'IT-VV',
          regionCode: 'VV',
          regionName: 'Vibo Valentia',
        },
        {
          fullCode: 'IT-VI',
          regionCode: 'VI',
          regionName: 'Vicenza',
        },
        {
          fullCode: 'IT-VT',
          regionCode: 'VT',
          regionName: 'Viterbo',
        },
      ],
    },
  ],
  [
    'jamaica',
    {
      countryCode: 'JM',
      countryName: 'Jamaica',
      regions: [
        {
          fullCode: 'JM-13',
          regionCode: '13',
          regionName: 'Clarendon',
        },
        {
          fullCode: 'JM-09',
          regionCode: '09',
          regionName: 'Hanover',
        },
        {
          fullCode: 'JM-01',
          regionCode: '01',
          regionName: 'Kingston',
        },
        {
          fullCode: 'JM-12',
          regionCode: '12',
          regionName: 'Manchester',
        },
        {
          fullCode: 'JM-04',
          regionCode: '04',
          regionName: 'Portland',
        },
        {
          fullCode: 'JM-02',
          regionCode: '02',
          regionName: 'Saint Andrew',
        },
        {
          fullCode: 'JM-06',
          regionCode: '06',
          regionName: 'Saint Ann',
        },
        {
          fullCode: 'JM-14',
          regionCode: '14',
          regionName: 'Saint Catherine',
        },
        {
          fullCode: 'JM-11',
          regionCode: '11',
          regionName: 'Saint Elizabeth',
        },
        {
          fullCode: 'JM-08',
          regionCode: '08',
          regionName: 'Saint James',
        },
        {
          fullCode: 'JM-05',
          regionCode: '05',
          regionName: 'Saint Mary',
        },
        {
          fullCode: 'JM-03',
          regionCode: '03',
          regionName: 'Saint Thomas',
        },
        {
          fullCode: 'JM-07',
          regionCode: '07',
          regionName: 'Trelawny',
        },
        {
          fullCode: 'JM-10',
          regionCode: '10',
          regionName: 'Westmoreland',
        },
      ],
    },
  ],
  [
    'japan',
    {
      countryCode: 'JP',
      countryName: 'Japan',
      regions: [
        {
          fullCode: 'JP-23',
          regionCode: '23',
          regionName: 'Aichi',
        },
        {
          fullCode: 'JP-05',
          regionCode: '05',
          regionName: 'Akita',
        },
        {
          fullCode: 'JP-02',
          regionCode: '02',
          regionName: 'Aomori',
        },
        {
          fullCode: 'JP-38',
          regionCode: '38',
          regionName: 'Ehime',
        },
        {
          fullCode: 'JP-21',
          regionCode: '21',
          regionName: 'Gifu',
        },
        {
          fullCode: 'JP-10',
          regionCode: '10',
          regionName: 'Gunma',
        },
        {
          fullCode: 'JP-34',
          regionCode: '34',
          regionName: 'Hiroshima',
        },
        {
          fullCode: 'JP-01',
          regionCode: '01',
          regionName: 'Hokkaido',
        },
        {
          fullCode: 'JP-18',
          regionCode: '18',
          regionName: 'Fukui',
        },
        {
          fullCode: 'JP-40',
          regionCode: '40',
          regionName: 'Fukuoka',
        },
        {
          fullCode: 'JP-07',
          regionCode: '07',
          regionName: 'Fukushima',
        },
        {
          fullCode: 'JP-28',
          regionCode: '28',
          regionName: 'Hyogo',
        },
        {
          fullCode: 'JP-08',
          regionCode: '08',
          regionName: 'Ibaraki',
        },
        {
          fullCode: 'JP-17',
          regionCode: '17',
          regionName: 'Ishikawa',
        },
        {
          fullCode: 'JP-03',
          regionCode: '03',
          regionName: 'Iwate',
        },
        {
          fullCode: 'JP-37',
          regionCode: '37',
          regionName: 'Kagawa',
        },
        {
          fullCode: 'JP-46',
          regionCode: '46',
          regionName: 'Kagoshima',
        },
        {
          fullCode: 'JP-14',
          regionCode: '14',
          regionName: 'Kanagawa',
        },
        {
          fullCode: 'JP-39',
          regionCode: '39',
          regionName: 'Kochi',
        },
        {
          fullCode: 'JP-43',
          regionCode: '43',
          regionName: 'Kumamoto',
        },
        {
          fullCode: 'JP-26',
          regionCode: '26',
          regionName: 'Kyoto',
        },
        {
          fullCode: 'JP-24',
          regionCode: '24',
          regionName: 'Mie',
        },
        {
          fullCode: 'JP-04',
          regionCode: '04',
          regionName: 'Miyagi',
        },
        {
          fullCode: 'JP-45',
          regionCode: '45',
          regionName: 'Miyazaki',
        },
        {
          fullCode: 'JP-20',
          regionCode: '20',
          regionName: 'Nagano',
        },
        {
          fullCode: 'JP-42',
          regionCode: '42',
          regionName: 'Nagasaki',
        },
        {
          fullCode: 'JP-29',
          regionCode: '29',
          regionName: 'Nara',
        },
        {
          fullCode: 'JP-15',
          regionCode: '15',
          regionName: 'Niigata',
        },
        {
          fullCode: 'JP-44',
          regionCode: '44',
          regionName: 'Oita',
        },
        {
          fullCode: 'JP-33',
          regionCode: '33',
          regionName: 'Okayama',
        },
        {
          fullCode: 'JP-47',
          regionCode: '47',
          regionName: 'Okinawa',
        },
        {
          fullCode: 'JP-27',
          regionCode: '27',
          regionName: 'Osaka',
        },
        {
          fullCode: 'JP-41',
          regionCode: '41',
          regionName: 'Saga',
        },
        {
          fullCode: 'JP-11',
          regionCode: '11',
          regionName: 'Saitama',
        },
        {
          fullCode: 'JP-25',
          regionCode: '25',
          regionName: 'Shiga',
        },
        {
          fullCode: 'JP-32',
          regionCode: '32',
          regionName: 'Shimane',
        },
        {
          fullCode: 'JP-22',
          regionCode: '22',
          regionName: 'Shizuoka',
        },
        {
          fullCode: 'JP-12',
          regionCode: '12',
          regionName: 'Chiba',
        },
        {
          fullCode: 'JP-36',
          regionCode: '36',
          regionName: 'Tokushima',
        },
        {
          fullCode: 'JP-13',
          regionCode: '13',
          regionName: 'Tokyo',
        },
        {
          fullCode: 'JP-09',
          regionCode: '09',
          regionName: 'Tochigi',
        },
        {
          fullCode: 'JP-31',
          regionCode: '31',
          regionName: 'Tottori',
        },
        {
          fullCode: 'JP-16',
          regionCode: '16',
          regionName: 'Toyama',
        },
        {
          fullCode: 'JP-30',
          regionCode: '30',
          regionName: 'Wakayama',
        },
        {
          fullCode: 'JP-06',
          regionCode: '06',
          regionName: 'Yamagata',
        },
        {
          fullCode: 'JP-35',
          regionCode: '35',
          regionName: 'Yamaguchi',
        },
        {
          fullCode: 'JP-19',
          regionCode: '19',
          regionName: 'Yamanashi',
        },
      ],
    },
  ],
  [
    'jordan',
    {
      countryCode: 'JO',
      countryName: 'Jordan',
      regions: [
        {
          fullCode: 'JO-AJ',
          regionCode: 'AJ',
          regionName: 'Ajloun',
        },
        {
          fullCode: 'JO-AQ',
          regionCode: 'AQ',
          regionName: 'Aqaba',
        },
        {
          fullCode: 'JO-AM',
          regionCode: 'AM',
          regionName: 'Amman',
        },
        {
          fullCode: 'JO-BA',
          regionCode: 'BA',
          regionName: 'Balqa',
        },
        {
          fullCode: 'JO-KA',
          regionCode: 'KA',
          regionName: 'Karak',
        },
        {
          fullCode: 'JO-MA',
          regionCode: 'MA',
          regionName: 'Mafraq',
        },
        {
          fullCode: 'JO-AT',
          regionCode: 'AT',
          regionName: 'Tafilah',
        },
        {
          fullCode: 'JO-AZ',
          regionCode: 'AZ',
          regionName: 'Zarqa',
        },
        {
          fullCode: 'JO-IR',
          regionCode: 'IR',
          regionName: 'Irbid',
        },
        {
          fullCode: 'JO-JA',
          regionCode: 'JA',
          regionName: 'Jerash',
        },
        {
          fullCode: 'JO-MN',
          regionCode: 'MN',
          regionName: "Ma\\'an",
        },
        {
          fullCode: 'JO-MD',
          regionCode: 'MD',
          regionName: 'Madaba',
        },
      ],
    },
  ],
  [
    'kazakhstan',
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan',
      regions: [
        {
          fullCode: 'KZ-10',
          regionCode: '10',
          regionName: 'Abai',
        },
        {
          fullCode: 'KZ-75',
          regionCode: '75',
          regionName: 'Almaty City',
        },
        {
          fullCode: 'KZ-19',
          regionCode: '19',
          regionName: 'Almaty',
        },
        {
          fullCode: 'KZ-11',
          regionCode: '11',
          regionName: 'Akmola',
        },
        {
          fullCode: 'KZ-15',
          regionCode: '15',
          regionName: 'Aktobe',
        },
        {
          fullCode: 'KZ-71',
          regionCode: '71',
          regionName: 'Astana',
        },
        {
          fullCode: 'KZ-23',
          regionCode: '23',
          regionName: 'Atyrau',
        },
        {
          fullCode: 'KZ-27',
          regionCode: '27',
          regionName: 'West Kazakhstan',
        },
        {
          fullCode: 'KZ-47',
          regionCode: '47',
          regionName: 'Mangystau',
        },
        {
          fullCode: 'KZ-55',
          regionCode: '55',
          regionName: 'Pavlodar',
        },
        {
          fullCode: 'KZ-35',
          regionCode: '35',
          regionName: 'Karaganda',
        },
        {
          fullCode: 'KZ-39',
          regionCode: '39',
          regionName: 'Kostanay',
        },
        {
          fullCode: 'KZ-43',
          regionCode: '43',
          regionName: 'Kyzylorda',
        },
        {
          fullCode: 'KZ-63',
          regionCode: '63',
          regionName: 'East Kazakhstan',
        },
        {
          fullCode: 'KZ-79',
          regionCode: '79',
          regionName: 'Shymkent',
        },
        {
          fullCode: 'KZ-59',
          regionCode: '59',
          regionName: 'North Kazakhstan',
        },
        {
          fullCode: 'KZ-61',
          regionCode: '61',
          regionName: 'Turkistan',
        },
        {
          fullCode: 'KZ-62',
          regionCode: '62',
          regionName: 'Ulytau',
        },
        {
          fullCode: 'KZ-31',
          regionCode: '31',
          regionName: 'Jambyl',
        },
        {
          fullCode: 'KZ-33',
          regionCode: '33',
          regionName: 'Jetisu',
        },
      ],
    },
  ],
  [
    'kenya',
    {
      countryCode: 'KE',
      countryName: 'Kenya',
      regions: [
        {
          fullCode: 'KE-01',
          regionCode: '01',
          regionName: 'Baringo',
        },
        {
          fullCode: 'KE-02',
          regionCode: '02',
          regionName: 'Bomet',
        },
        {
          fullCode: 'KE-03',
          regionCode: '03',
          regionName: 'Bungoma',
        },
        {
          fullCode: 'KE-04',
          regionCode: '04',
          regionName: 'Busia',
        },
        {
          fullCode: 'KE-05',
          regionCode: '05',
          regionName: 'Elgeyo/Marakwet',
        },
        {
          fullCode: 'KE-06',
          regionCode: '06',
          regionName: 'Embu',
        },
        {
          fullCode: 'KE-07',
          regionCode: '07',
          regionName: 'Garissa',
        },
        {
          fullCode: 'KE-08',
          regionCode: '08',
          regionName: 'Homa Bay',
        },
        {
          fullCode: 'KE-09',
          regionCode: '09',
          regionName: 'Isiolo',
        },
        {
          fullCode: 'KE-10',
          regionCode: '10',
          regionName: 'Kajiado',
        },
        {
          fullCode: 'KE-11',
          regionCode: '11',
          regionName: 'Kakamega',
        },
        {
          fullCode: 'KE-12',
          regionCode: '12',
          regionName: 'Kericho',
        },
        {
          fullCode: 'KE-13',
          regionCode: '13',
          regionName: 'Kiambu',
        },
        {
          fullCode: 'KE-14',
          regionCode: '14',
          regionName: 'Kilifi',
        },
        {
          fullCode: 'KE-15',
          regionCode: '15',
          regionName: 'Kirinyaga',
        },
        {
          fullCode: 'KE-16',
          regionCode: '16',
          regionName: 'Kisii',
        },
        {
          fullCode: 'KE-17',
          regionCode: '17',
          regionName: 'Kisumu',
        },
        {
          fullCode: 'KE-18',
          regionCode: '18',
          regionName: 'Kitui',
        },
        {
          fullCode: 'KE-19',
          regionCode: '19',
          regionName: 'Kwale',
        },
        {
          fullCode: 'KE-20',
          regionCode: '20',
          regionName: 'Laikipia',
        },
        {
          fullCode: 'KE-21',
          regionCode: '21',
          regionName: 'Lamu',
        },
        {
          fullCode: 'KE-22',
          regionCode: '22',
          regionName: 'Machakos',
        },
        {
          fullCode: 'KE-23',
          regionCode: '23',
          regionName: 'Makueni',
        },
        {
          fullCode: 'KE-24',
          regionCode: '24',
          regionName: 'Mandera',
        },
        {
          fullCode: 'KE-25',
          regionCode: '25',
          regionName: 'Marsabit',
        },
        {
          fullCode: 'KE-26',
          regionCode: '26',
          regionName: 'Meru',
        },
        {
          fullCode: 'KE-27',
          regionCode: '27',
          regionName: 'Migori',
        },
        {
          fullCode: 'KE-28',
          regionCode: '28',
          regionName: 'Mombasa',
        },
        {
          fullCode: 'KE-29',
          regionCode: '29',
          regionName: "Murang\\'a",
        },
        {
          fullCode: 'KE-30',
          regionCode: '30',
          regionName: 'Nairobi City',
        },
        {
          fullCode: 'KE-31',
          regionCode: '31',
          regionName: 'Nakuru',
        },
        {
          fullCode: 'KE-32',
          regionCode: '32',
          regionName: 'Nandi',
        },
        {
          fullCode: 'KE-33',
          regionCode: '33',
          regionName: 'Narok',
        },
        {
          fullCode: 'KE-34',
          regionCode: '34',
          regionName: 'Nyamira',
        },
        {
          fullCode: 'KE-35',
          regionCode: '35',
          regionName: 'Nyandarua',
        },
        {
          fullCode: 'KE-36',
          regionCode: '36',
          regionName: 'Nyeri',
        },
        {
          fullCode: 'KE-37',
          regionCode: '37',
          regionName: 'Samburu',
        },
        {
          fullCode: 'KE-38',
          regionCode: '38',
          regionName: 'Siaya',
        },
        {
          fullCode: 'KE-39',
          regionCode: '39',
          regionName: 'Taita/Taveta',
        },
        {
          fullCode: 'KE-40',
          regionCode: '40',
          regionName: 'Tana River',
        },
        {
          fullCode: 'KE-41',
          regionCode: '41',
          regionName: 'Tharaka-Nithi',
        },
        {
          fullCode: 'KE-42',
          regionCode: '42',
          regionName: 'Trans Nzoia',
        },
        {
          fullCode: 'KE-43',
          regionCode: '43',
          regionName: 'Turkana',
        },
        {
          fullCode: 'KE-44',
          regionCode: '44',
          regionName: 'Uasin Gishu',
        },
        {
          fullCode: 'KE-45',
          regionCode: '45',
          regionName: 'Vihiga',
        },
        {
          fullCode: 'KE-46',
          regionCode: '46',
          regionName: 'Wajir',
        },
        {
          fullCode: 'KE-47',
          regionCode: '47',
          regionName: 'West Pokot',
        },
        {
          fullCode: 'KE-110',
          regionCode: '110',
          regionName: 'Nairobi',
        },
        {
          fullCode: 'KE-200',
          regionCode: '200',
          regionName: 'Central',
        },
        {
          fullCode: 'KE-300',
          regionCode: '300',
          regionName: 'Coast',
        },
        {
          fullCode: 'KE-400',
          regionCode: '400',
          regionName: 'Eastern',
        },
        {
          fullCode: 'KE-500',
          regionCode: '500',
          regionName: 'North-Eastern',
        },
        {
          fullCode: 'KE-600',
          regionCode: '600',
          regionName: 'Nyanza',
        },
        {
          fullCode: 'KE-700',
          regionCode: '700',
          regionName: 'Rift Valley',
        },
        {
          fullCode: 'KE-800',
          regionCode: '800',
          regionName: 'Western',
        },
      ],
    },
  ],
  [
    'kiribati',
    {
      countryCode: 'KI',
      countryName: 'Kiribati',
      regions: [
        {
          fullCode: 'KI-G',
          regionCode: 'G',
          regionName: 'Gilbert Islands',
        },
        {
          fullCode: 'KI-L',
          regionCode: 'L',
          regionName: 'Line Islands',
        },
        {
          fullCode: 'KI-P',
          regionCode: 'P',
          regionName: 'Phoenix Islands',
        },
      ],
    },
  ],
  [
    "Korea, Democratic People's Republic of",
    {
      countryCode: 'KP',
      countryName: "Korea, Democratic People's Republic of",
      regions: [
        {
          fullCode: 'KP-04',
          regionCode: '04',
          regionName: 'Chagang',
        },
        {
          fullCode: 'KP-09',
          regionCode: '09',
          regionName: 'North Hamgyong',
        },
        {
          fullCode: 'KP-08',
          regionCode: '08',
          regionName: 'South Hamgyong',
        },
        {
          fullCode: 'KP-06',
          regionCode: '06',
          regionName: 'North Hwanghae',
        },
        {
          fullCode: 'KP-05',
          regionCode: '05',
          regionName: 'South Hwanghae',
        },
        {
          fullCode: 'KP-15',
          regionCode: '15',
          regionName: 'Kaesong',
        },
        {
          fullCode: 'KP-07',
          regionCode: '07',
          regionName: 'Kangwon',
        },
        {
          fullCode: 'KP-14',
          regionCode: '14',
          regionName: 'Nampo',
        },
        {
          fullCode: 'KP-03',
          regionCode: '03',
          regionName: 'North Pyongan',
        },
        {
          fullCode: 'KP-02',
          regionCode: '02',
          regionName: 'South Pyongan',
        },
        {
          fullCode: 'KP-01',
          regionCode: '01',
          regionName: 'Pyongyang',
        },
        {
          fullCode: 'KP-13',
          regionCode: '13',
          regionName: 'Rason',
        },
        {
          fullCode: 'KP-10',
          regionCode: '10',
          regionName: 'Ryanggang',
        },
      ],
    },
  ],
  [
    'korea, republic of',
    {
      countryCode: 'KR',
      countryName: 'Korea, Republic of',
      regions: [
        {
          fullCode: 'KR-42',
          regionCode: '42',
          regionName: 'Gangwon-do',
        },
        {
          fullCode: 'KR-45',
          regionCode: '45',
          regionName: 'Jeollabuk-do',
        },
        {
          fullCode: 'KR-46',
          regionCode: '46',
          regionName: 'Jeollanam-do',
        },
        {
          fullCode: 'KR-49',
          regionCode: '49',
          regionName: 'Jeju-do',
        },
        {
          fullCode: 'KR-43',
          regionCode: '43',
          regionName: 'Chungcheongbuk-do',
        },
        {
          fullCode: 'KR-44',
          regionCode: '44',
          regionName: 'Chungcheongnam-do',
        },
        {
          fullCode: 'KR-30',
          regionCode: '30',
          regionName: 'Daejeon',
        },
        {
          fullCode: 'KR-27',
          regionCode: '27',
          regionName: 'Daegu',
        },
        {
          fullCode: 'KR-26',
          regionCode: '26',
          regionName: 'Busan',
        },
        {
          fullCode: 'KR-28',
          regionCode: '28',
          regionName: 'Incheon',
        },
        {
          fullCode: 'KR-50',
          regionCode: '50',
          regionName: 'Sejong',
        },
        {
          fullCode: 'KR-11',
          regionCode: '11',
          regionName: 'Seoul',
        },
        {
          fullCode: 'KR-29',
          regionCode: '29',
          regionName: 'Gwangju',
        },
        {
          fullCode: 'KR-41',
          regionCode: '41',
          regionName: 'Gyeonggi-do',
        },
        {
          fullCode: 'KR-47',
          regionCode: '47',
          regionName: 'Gyeongsangbuk-do',
        },
        {
          fullCode: 'KR-48',
          regionCode: '48',
          regionName: 'Gyeongsangnam-do',
        },
        {
          fullCode: 'KR-31',
          regionCode: '31',
          regionName: 'Ulsan',
        },
      ],
    },
  ],
  [
    'kuwait',
    {
      countryCode: 'KW',
      countryName: 'Kuwait',
      regions: [
        {
          fullCode: 'KW-AH',
          regionCode: 'AH',
          regionName: 'Ahmadi',
        },
        {
          fullCode: 'KW-FA',
          regionCode: 'FA',
          regionName: 'Farwaniya',
        },
        {
          fullCode: 'KW-JA',
          regionCode: 'JA',
          regionName: 'Jahra',
        },
        {
          fullCode: 'KW-KU',
          regionCode: 'KU',
          regionName: 'Capital',
        },
        {
          fullCode: 'KW-HA',
          regionCode: 'HA',
          regionName: 'Hawalli',
        },
        {
          fullCode: 'KW-MU',
          regionCode: 'MU',
          regionName: 'Mubarak Al-Kabeer',
        },
      ],
    },
  ],
  [
    'kyrgyzstan',
    {
      countryCode: 'KG',
      countryName: 'Kyrgyzstan',
      regions: [
        {
          fullCode: 'KG-B',
          regionCode: 'B',
          regionName: 'Batken',
        },
        {
          fullCode: 'KG-GB',
          regionCode: 'GB',
          regionName: 'Bishkek',
        },
        {
          fullCode: 'KG-C',
          regionCode: 'C',
          regionName: 'Chuy',
        },
        {
          fullCode: 'KG-J',
          regionCode: 'J',
          regionName: 'Jalal-Abad',
        },
        {
          fullCode: 'KG-N',
          regionCode: 'N',
          regionName: 'Naryn',
        },
        {
          fullCode: 'KG-O',
          regionCode: 'O',
          regionName: 'Osh',
        },
        {
          fullCode: 'KG-GO',
          regionCode: 'GO',
          regionName: 'Osh City',
        },
        {
          fullCode: 'KG-T',
          regionCode: 'T',
          regionName: 'Talas',
        },
        {
          fullCode: 'KG-Y',
          regionCode: 'Y',
          regionName: 'Issyk-Kul',
        },
      ],
    },
  ],
  [
    "Lao People's Democratic Republic",
    {
      countryCode: 'LA',
      countryName: "Lao People's Democratic Republic",
      regions: [
        {
          fullCode: 'LA-AT',
          regionCode: 'AT',
          regionName: 'Attapeu',
        },
        {
          fullCode: 'LA-BK',
          regionCode: 'BK',
          regionName: 'Bokèo',
        },
        {
          fullCode: 'LA-BL',
          regionCode: 'BL',
          regionName: 'Borikhamxay',
        },
        {
          fullCode: 'LA-CH',
          regionCode: 'CH',
          regionName: 'Champasack',
        },
        {
          fullCode: 'LA-HO',
          regionCode: 'HO',
          regionName: 'Huaphanh',
        },
        {
          fullCode: 'LA-KH',
          regionCode: 'KH',
          regionName: 'Khammuane',
        },
        {
          fullCode: 'LA-LM',
          regionCode: 'LM',
          regionName: 'Luangnamtha',
        },
        {
          fullCode: 'LA-LP',
          regionCode: 'LP',
          regionName: 'Luangprabang',
        },
        {
          fullCode: 'LA-OU',
          regionCode: 'OU',
          regionName: 'Oudomxay',
        },
        {
          fullCode: 'LA-PH',
          regionCode: 'PH',
          regionName: 'Phongsaly',
        },
        {
          fullCode: 'LA-SL',
          regionCode: 'SL',
          regionName: 'Saravane',
        },
        {
          fullCode: 'LA-SV',
          regionCode: 'SV',
          regionName: 'Savannakhet',
        },
        {
          fullCode: 'LA-VI',
          regionCode: 'VI',
          regionName: 'Vientiane',
        },
        {
          fullCode: 'LA-VT',
          regionCode: 'VT',
          regionName: 'Vientiane',
        },
        {
          fullCode: 'LA-XA',
          regionCode: 'XA',
          regionName: 'Xayabury',
        },
        {
          fullCode: 'LA-XE',
          regionCode: 'XE',
          regionName: 'Sekong',
        },
        {
          fullCode: 'LA-XI',
          regionCode: 'XI',
          regionName: 'Xiengkhuang',
        },
        {
          fullCode: 'LA-XS',
          regionCode: 'XS',
          regionName: 'Xaysomboon',
        },
      ],
    },
  ],
  [
    'latvia',
    {
      countryCode: 'LV',
      countryName: 'Latvia',
      regions: [
        {
          fullCode: 'LV-002',
          regionCode: '002',
          regionName: 'Aizkraukles novads',
        },
        {
          fullCode: 'LV-007',
          regionCode: '007',
          regionName: 'Alūksnes novads',
        },
        {
          fullCode: 'LV-111',
          regionCode: '111',
          regionName: 'Augšdaugavas novads',
        },
        {
          fullCode: 'LV-011',
          regionCode: '011',
          regionName: 'Ādažu novads',
        },
        {
          fullCode: 'LV-015',
          regionCode: '015',
          regionName: 'Balvu novads',
        },
        {
          fullCode: 'LV-016',
          regionCode: '016',
          regionName: 'Bauskas novads',
        },
        {
          fullCode: 'LV-022',
          regionCode: '022',
          regionName: 'Cēsu novads',
        },
        {
          fullCode: 'LV-DGV',
          regionCode: 'DGV',
          regionName: 'Daugavpils',
        },
        {
          fullCode: 'LV-112',
          regionCode: '112',
          regionName: 'Dienvidkurzemes novads',
        },
        {
          fullCode: 'LV-026',
          regionCode: '026',
          regionName: 'Dobeles novads',
        },
        {
          fullCode: 'LV-033',
          regionCode: '033',
          regionName: 'Gulbenes novads',
        },
        {
          fullCode: 'LV-JEL',
          regionCode: 'JEL',
          regionName: 'Jelgava',
        },
        {
          fullCode: 'LV-041',
          regionCode: '041',
          regionName: 'Jelgavas novads',
        },
        {
          fullCode: 'LV-042',
          regionCode: '042',
          regionName: 'Jēkabpils novads',
        },
        {
          fullCode: 'LV-JUR',
          regionCode: 'JUR',
          regionName: 'Jūrmala',
        },
        {
          fullCode: 'LV-047',
          regionCode: '047',
          regionName: 'Krāslavas novads',
        },
        {
          fullCode: 'LV-050',
          regionCode: '050',
          regionName: 'Kuldīgas novads',
        },
        {
          fullCode: 'LV-052',
          regionCode: '052',
          regionName: 'Ķekavas novads',
        },
        {
          fullCode: 'LV-LPX',
          regionCode: 'LPX',
          regionName: 'Liepāja',
        },
        {
          fullCode: 'LV-054',
          regionCode: '054',
          regionName: 'Limbažu novads',
        },
        {
          fullCode: 'LV-056',
          regionCode: '056',
          regionName: 'Līvānu novads',
        },
        {
          fullCode: 'LV-058',
          regionCode: '058',
          regionName: 'Ludzas novads',
        },
        {
          fullCode: 'LV-059',
          regionCode: '059',
          regionName: 'Madonas novads',
        },
        {
          fullCode: 'LV-062',
          regionCode: '062',
          regionName: 'Mārupes novads',
        },
        {
          fullCode: 'LV-067',
          regionCode: '067',
          regionName: 'Ogres novads',
        },
        {
          fullCode: 'LV-068',
          regionCode: '068',
          regionName: 'Olaines novads',
        },
        {
          fullCode: 'LV-073',
          regionCode: '073',
          regionName: 'Preiļu novads',
        },
        {
          fullCode: 'LV-077',
          regionCode: '077',
          regionName: 'Rēzeknes novads',
        },
        {
          fullCode: 'LV-REZ',
          regionCode: 'REZ',
          regionName: 'Rēzekne',
        },
        {
          fullCode: 'LV-078',
          regionCode: '078',
          regionName: 'Ropažu novads',
        },
        {
          fullCode: 'LV-RIX',
          regionCode: 'RIX',
          regionName: 'Rīga',
        },
        {
          fullCode: 'LV-079',
          regionCode: '079',
          regionName: 'Rundāles novads',
        },
        {
          fullCode: 'LV-083',
          regionCode: '083',
          regionName: 'Salaspils novads',
        },
        {
          fullCode: 'LV-084',
          regionCode: '084',
          regionName: 'Saldus novads',
        },
        {
          fullCode: 'LV-085',
          regionCode: '085',
          regionName: 'Saulkrastu novads',
        },
        {
          fullCode: 'LV-086',
          regionCode: '086',
          regionName: 'Siguldas novads',
        },
        {
          fullCode: 'LV-091',
          regionCode: '091',
          regionName: 'Smiltenes novads',
        },
        {
          fullCode: 'LV-093',
          regionCode: '093',
          regionName: 'Talsu novads',
        },
        {
          fullCode: 'LV-096',
          regionCode: '096',
          regionName: 'Tukuma novads',
        },
        {
          fullCode: 'LV-097',
          regionCode: '097',
          regionName: 'Valkas novads',
        },
        {
          fullCode: 'LV-099',
          regionCode: '099',
          regionName: 'Valmieras novads',
        },
        {
          fullCode: 'LV-VENT',
          regionCode: 'VENT',
          regionName: 'Ventspils',
        },
        {
          fullCode: 'LV-101',
          regionCode: '101',
          regionName: 'Ventspils novads',
        },
        {
          fullCode: 'LV-106',
          regionCode: '106',
          regionName: 'Zilupes novads',
        },
      ],
    },
  ],
  [
    'lebanon',
    {
      countryCode: 'LB',
      countryName: 'Lebanon',
      regions: [
        {
          fullCode: 'LB-AK',
          regionCode: 'AK',
          regionName: 'Akkar',
        },
        {
          fullCode: 'LB-BH',
          regionCode: 'BH',
          regionName: 'Baalbek-Hermel',
        },
        {
          fullCode: 'LB-BI',
          regionCode: 'BI',
          regionName: 'Beqaa',
        },
        {
          fullCode: 'LB-BA',
          regionCode: 'BA',
          regionName: 'Beirut',
        },
        {
          fullCode: 'LB-AS',
          regionCode: 'AS',
          regionName: 'North Lebanon',
        },
        {
          fullCode: 'LB-JA',
          regionCode: 'JA',
          regionName: 'South Lebanon',
        },
        {
          fullCode: 'LB-JL',
          regionCode: 'JL',
          regionName: 'Mount Lebanon',
        },
        {
          fullCode: 'LB-NA',
          regionCode: 'NA',
          regionName: 'Nabatieh',
        },
      ],
    },
  ],
  [
    'lesotho',
    {
      countryCode: 'LS',
      countryName: 'Lesotho',
      regions: [
        {
          fullCode: 'LS-D',
          regionCode: 'D',
          regionName: 'Berea',
        },
        {
          fullCode: 'LS-B',
          regionCode: 'B',
          regionName: 'Butha-Buthe',
        },
        {
          fullCode: 'LS-C',
          regionCode: 'C',
          regionName: 'Leribe',
        },
        {
          fullCode: 'LS-E',
          regionCode: 'E',
          regionName: 'Mafeteng',
        },
        {
          fullCode: 'LS-A',
          regionCode: 'A',
          regionName: 'Maseru',
        },
        {
          fullCode: 'LS-F',
          regionCode: 'F',
          regionName: "Mohale\\'s Hoek",
        },
        {
          fullCode: 'LS-J',
          regionCode: 'J',
          regionName: 'Mokhotlong',
        },
        {
          fullCode: 'LS-H',
          regionCode: 'H',
          regionName: "Qacha\\'s Nek",
        },
        {
          fullCode: 'LS-G',
          regionCode: 'G',
          regionName: 'Quthing',
        },
        {
          fullCode: 'LS-K',
          regionCode: 'K',
          regionName: 'Thaba-Tseka',
        },
      ],
    },
  ],
  [
    'liberia',
    {
      countryCode: 'LR',
      countryName: 'Liberia',
      regions: [
        {
          fullCode: 'LR-BM',
          regionCode: 'BM',
          regionName: 'Bomi',
        },
        {
          fullCode: 'LR-BG',
          regionCode: 'BG',
          regionName: 'Bong',
        },
        {
          fullCode: 'LR-GP',
          regionCode: 'GP',
          regionName: 'Gbarpolu',
        },
        {
          fullCode: 'LR-GB',
          regionCode: 'GB',
          regionName: 'Grand Bassa',
        },
        {
          fullCode: 'LR-CM',
          regionCode: 'CM',
          regionName: 'Grand Cape Mount',
        },
        {
          fullCode: 'LR-GG',
          regionCode: 'GG',
          regionName: 'Grand Gedeh',
        },
        {
          fullCode: 'LR-GK',
          regionCode: 'GK',
          regionName: 'Grand Kru',
        },
        {
          fullCode: 'LR-LO',
          regionCode: 'LO',
          regionName: 'Lofa',
        },
        {
          fullCode: 'LR-MG',
          regionCode: 'MG',
          regionName: 'Margibi',
        },
        {
          fullCode: 'LR-MY',
          regionCode: 'MY',
          regionName: 'Maryland',
        },
        {
          fullCode: 'LR-MO',
          regionCode: 'MO',
          regionName: 'Montserrado',
        },
        {
          fullCode: 'LR-NI',
          regionCode: 'NI',
          regionName: 'Nimba',
        },
        {
          fullCode: 'LR-RI',
          regionCode: 'RI',
          regionName: 'River Cess(Local variant: Rivercess)',
        },
        {
          fullCode: 'LR-RG',
          regionCode: 'RG',
          regionName: 'River Gee',
        },
        {
          fullCode: 'LR-SI',
          regionCode: 'SI',
          regionName: 'Sinoe',
        },
      ],
    },
  ],
  [
    'libya',
    {
      countryCode: 'LY',
      countryName: 'Libya',
      regions: [
        {
          fullCode: 'LY-BU',
          regionCode: 'BU',
          regionName: 'Butnan',
        },
        {
          fullCode: 'LY-JA',
          regionCode: 'JA',
          regionName: 'Green Mountain',
        },
        {
          fullCode: 'LY-JG',
          regionCode: 'JG',
          regionName: 'Western Mountain',
        },
        {
          fullCode: 'LY-JI',
          regionCode: 'JI',
          regionName: 'Jafara',
        },
        {
          fullCode: 'LY-JU',
          regionCode: 'JU',
          regionName: 'Jufra',
        },
        {
          fullCode: 'LY-KF',
          regionCode: 'KF',
          regionName: 'Kufra',
        },
        {
          fullCode: 'LY-MJ',
          regionCode: 'MJ',
          regionName: 'Meadows',
        },
        {
          fullCode: 'LY-MB',
          regionCode: 'MB',
          regionName: 'Murqub',
        },
        {
          fullCode: 'LY-WA',
          regionCode: 'WA',
          regionName: 'Oases',
        },
        {
          fullCode: 'LY-NQ',
          regionCode: 'NQ',
          regionName: 'Nuqat al Khams',
        },
        {
          fullCode: 'LY-ZA',
          regionCode: 'ZA',
          regionName: 'Zawiya',
        },
        {
          fullCode: 'LY-BA',
          regionCode: 'BA',
          regionName: 'Benghazi',
        },
        {
          fullCode: 'LY-DR',
          regionCode: 'DR',
          regionName: 'Derna',
        },
        {
          fullCode: 'LY-GT',
          regionCode: 'GT',
          regionName: 'Ghat',
        },
        {
          fullCode: 'LY-MI',
          regionCode: 'MI',
          regionName: 'Misrata',
        },
        {
          fullCode: 'LY-MQ',
          regionCode: 'MQ',
          regionName: 'Murzuq',
        },
        {
          fullCode: 'LY-NL',
          regionCode: 'NL',
          regionName: 'Nalut',
        },
        {
          fullCode: 'LY-SB',
          regionCode: 'SB',
          regionName: 'Sabha',
        },
        {
          fullCode: 'LY-SR',
          regionCode: 'SR',
          regionName: 'Sirte',
        },
        {
          fullCode: 'LY-TB',
          regionCode: 'TB',
          regionName: 'Tripoli',
        },
        {
          fullCode: 'LY-WD',
          regionCode: 'WD',
          regionName: 'Wadi al Hayaa',
        },
        {
          fullCode: 'LY-WS',
          regionCode: 'WS',
          regionName: 'Wadi ash Shati',
        },
      ],
    },
  ],
  [
    'liechtenstein',
    {
      countryCode: 'LI',
      countryName: 'Liechtenstein',
      regions: [
        {
          fullCode: 'LI-01',
          regionCode: '01',
          regionName: 'Balzers',
        },
        {
          fullCode: 'LI-02',
          regionCode: '02',
          regionName: 'Eschen',
        },
        {
          fullCode: 'LI-03',
          regionCode: '03',
          regionName: 'Gamprin',
        },
        {
          fullCode: 'LI-04',
          regionCode: '04',
          regionName: 'Mauren',
        },
        {
          fullCode: 'LI-05',
          regionCode: '05',
          regionName: 'Planken',
        },
        {
          fullCode: 'LI-06',
          regionCode: '06',
          regionName: 'Ruggell',
        },
        {
          fullCode: 'LI-07',
          regionCode: '07',
          regionName: 'Schaan',
        },
        {
          fullCode: 'LI-08',
          regionCode: '08',
          regionName: 'Schellenberg',
        },
        {
          fullCode: 'LI-09',
          regionCode: '09',
          regionName: 'Triesen',
        },
        {
          fullCode: 'LI-10',
          regionCode: '10',
          regionName: 'Triesenberg',
        },
        {
          fullCode: 'LI-11',
          regionCode: '11',
          regionName: 'Vaduz',
        },
      ],
    },
  ],
  [
    'lithuania',
    {
      countryCode: 'LT',
      countryName: 'Lithuania',
      regions: [
        {
          fullCode: 'LT-AL',
          regionCode: 'AL',
          regionName: 'Alytus County',
        },
        {
          fullCode: 'LT-KU',
          regionCode: 'KU',
          regionName: 'Kaunas County',
        },
        {
          fullCode: 'LT-KL',
          regionCode: 'KL',
          regionName: 'Klaipėda County',
        },
        {
          fullCode: 'LT-MR',
          regionCode: 'MR',
          regionName: 'Marijampolė County',
        },
        {
          fullCode: 'LT-PN',
          regionCode: 'PN',
          regionName: 'Panevėžys County',
        },
        {
          fullCode: 'LT-SA',
          regionCode: 'SA',
          regionName: 'Šiauliai County',
        },
        {
          fullCode: 'LT-TA',
          regionCode: 'TA',
          regionName: 'Tauragė County',
        },
        {
          fullCode: 'LT-TE',
          regionCode: 'TE',
          regionName: 'Telšiai County',
        },
        {
          fullCode: 'LT-UT',
          regionCode: 'UT',
          regionName: 'Utena County',
        },
        {
          fullCode: 'LT-VL',
          regionCode: 'VL',
          regionName: 'Vilnius County',
        },
        {
          fullCode: 'LT-01',
          regionCode: '01',
          regionName: 'Akmenė',
        },
        {
          fullCode: 'LT-02',
          regionCode: '02',
          regionName: 'Alytaus miestas',
        },
        {
          fullCode: 'LT-03',
          regionCode: '03',
          regionName: 'Alytus',
        },
        {
          fullCode: 'LT-04',
          regionCode: '04',
          regionName: 'Anykščiai',
        },
        {
          fullCode: 'LT-05',
          regionCode: '05',
          regionName: 'Birštonas',
        },
        {
          fullCode: 'LT-06',
          regionCode: '06',
          regionName: 'Biržai',
        },
        {
          fullCode: 'LT-07',
          regionCode: '07',
          regionName: 'Druskininkai',
        },
        {
          fullCode: 'LT-08',
          regionCode: '08',
          regionName: 'Elektrėnai',
        },
        {
          fullCode: 'LT-09',
          regionCode: '09',
          regionName: 'Ignalina',
        },
        {
          fullCode: 'LT-10',
          regionCode: '10',
          regionName: 'Jonava',
        },
        {
          fullCode: 'LT-11',
          regionCode: '11',
          regionName: 'Joniškis',
        },
        {
          fullCode: 'LT-12',
          regionCode: '12',
          regionName: 'Jurbarkas',
        },
        {
          fullCode: 'LT-13',
          regionCode: '13',
          regionName: 'Kaišiadorys',
        },
        {
          fullCode: 'LT-14',
          regionCode: '14',
          regionName: 'Kalvarija',
        },
        {
          fullCode: 'LT-15',
          regionCode: '15',
          regionName: 'Kauno miestas',
        },
        {
          fullCode: 'LT-16',
          regionCode: '16',
          regionName: 'Kaunas',
        },
        {
          fullCode: 'LT-17',
          regionCode: '17',
          regionName: 'Kazlų Rūdos',
        },
        {
          fullCode: 'LT-18',
          regionCode: '18',
          regionName: 'Kėdainiai',
        },
        {
          fullCode: 'LT-19',
          regionCode: '19',
          regionName: 'Kelmė',
        },
        {
          fullCode: 'LT-21',
          regionCode: '21',
          regionName: 'Klaipėda',
        },
        {
          fullCode: 'LT-20',
          regionCode: '20',
          regionName: 'Klaipėdos miestas',
        },
        {
          fullCode: 'LT-22',
          regionCode: '22',
          regionName: 'Kretinga',
        },
        {
          fullCode: 'LT-23',
          regionCode: '23',
          regionName: 'Kupiškis',
        },
        {
          fullCode: 'LT-24',
          regionCode: '24',
          regionName: 'Lazdijai',
        },
        {
          fullCode: 'LT-25',
          regionCode: '25',
          regionName: 'Marijampolė',
        },
        {
          fullCode: 'LT-26',
          regionCode: '26',
          regionName: 'Mažeikiai',
        },
        {
          fullCode: 'LT-27',
          regionCode: '27',
          regionName: 'Molėtai',
        },
        {
          fullCode: 'LT-28',
          regionCode: '28',
          regionName: 'Neringa',
        },
        {
          fullCode: 'LT-29',
          regionCode: '29',
          regionName: 'Pagėgiai',
        },
        {
          fullCode: 'LT-30',
          regionCode: '30',
          regionName: 'Pakruojis',
        },
        {
          fullCode: 'LT-31',
          regionCode: '31',
          regionName: 'Palanga',
        },
        {
          fullCode: 'LT-32',
          regionCode: '32',
          regionName: 'Panevėžys',
        },
        {
          fullCode: 'LT-33',
          regionCode: '33',
          regionName: 'Pasvalys',
        },
        {
          fullCode: 'LT-34',
          regionCode: '34',
          regionName: 'Plungė',
        },
        {
          fullCode: 'LT-35',
          regionCode: '35',
          regionName: 'Prienai',
        },
        {
          fullCode: 'LT-36',
          regionCode: '36',
          regionName: 'Radviliškis',
        },
        {
          fullCode: 'LT-37',
          regionCode: '37',
          regionName: 'Raseiniai',
        },
        {
          fullCode: 'LT-38',
          regionCode: '38',
          regionName: 'Rietavas',
        },
        {
          fullCode: 'LT-39',
          regionCode: '39',
          regionName: 'Rokiškis (LT-39)',
        },
        {
          fullCode: 'LT-40',
          regionCode: '40',
          regionName: 'Rokiškis (LT-40)',
        },
        {
          fullCode: 'LT-41',
          regionCode: '41',
          regionName: 'Šakiai',
        },
        {
          fullCode: 'LT-42',
          regionCode: '42',
          regionName: 'Šalčininkai',
        },
        {
          fullCode: 'LT-44',
          regionCode: '44',
          regionName: 'Šiauliai',
        },
        {
          fullCode: 'LT-43',
          regionCode: '43',
          regionName: 'Šiaulių miestas',
        },
        {
          fullCode: 'LT-45',
          regionCode: '45',
          regionName: 'Šilalė',
        },
        {
          fullCode: 'LT-46',
          regionCode: '46',
          regionName: 'Šilutė',
        },
        {
          fullCode: 'LT-47',
          regionCode: '47',
          regionName: 'Širvintos',
        },
        {
          fullCode: 'LT-48',
          regionCode: '48',
          regionName: 'Skuodas',
        },
        {
          fullCode: 'LT-49',
          regionCode: '49',
          regionName: 'Švenčionys',
        },
        {
          fullCode: 'LT-50',
          regionCode: '50',
          regionName: 'Tauragė',
        },
        {
          fullCode: 'LT-51',
          regionCode: '51',
          regionName: 'Telšiai',
        },
        {
          fullCode: 'LT-52',
          regionCode: '52',
          regionName: 'Trakai',
        },
        {
          fullCode: 'LT-53',
          regionCode: '53',
          regionName: 'Ukmergė',
        },
        {
          fullCode: 'LT-54',
          regionCode: '54',
          regionName: 'Utena',
        },
        {
          fullCode: 'LT-55',
          regionCode: '55',
          regionName: 'Varėna',
        },
        {
          fullCode: 'LT-56',
          regionCode: '56',
          regionName: 'Vilkaviškis',
        },
        {
          fullCode: 'LT-57',
          regionCode: '57',
          regionName: 'Vilniaus miestas',
        },
        {
          fullCode: 'LT-58',
          regionCode: '58',
          regionName: 'Vilnius',
        },
        {
          fullCode: 'LT-59',
          regionCode: '59',
          regionName: 'Visaginas',
        },
        {
          fullCode: 'LT-60',
          regionCode: '60',
          regionName: 'Zarasai',
        },
      ],
    },
  ],
  [
    'luxembourg',
    {
      countryCode: 'LU',
      countryName: 'Luxembourg',
      regions: [
        {
          fullCode: 'LU-CA',
          regionCode: 'CA',
          regionName: 'Capellen',
        },
        {
          fullCode: 'LU-CL',
          regionCode: 'CL',
          regionName: 'Clervaux',
        },
        {
          fullCode: 'LU-DI',
          regionCode: 'DI',
          regionName: 'Diekirch',
        },
        {
          fullCode: 'LU-EC',
          regionCode: 'EC',
          regionName: 'Echternach',
        },
        {
          fullCode: 'LU-ES',
          regionCode: 'ES',
          regionName: 'Esch-sur-Alzette',
        },
        {
          fullCode: 'LU-GR',
          regionCode: 'GR',
          regionName: 'Grevenmacher',
        },
        {
          fullCode: 'LU-LU',
          regionCode: 'LU',
          regionName: 'Luxembourg',
        },
        {
          fullCode: 'LU-ME',
          regionCode: 'ME',
          regionName: 'Mersch',
        },
        {
          fullCode: 'LU-RD',
          regionCode: 'RD',
          regionName: 'Redange',
        },
        {
          fullCode: 'LU-RM',
          regionCode: 'RM',
          regionName: 'Remich',
        },
        {
          fullCode: 'LU-VD',
          regionCode: 'VD',
          regionName: 'Vianden',
        },
        {
          fullCode: 'LU-WI',
          regionCode: 'WI',
          regionName: 'Wiltz',
        },
        {
          fullCode: 'LU-D',
          regionCode: 'D',
          regionName: 'Diekirch (de, lb)',
        },
        {
          fullCode: 'LU-G',
          regionCode: 'G',
          regionName: 'Grevenmacher (de, lb)',
        },
        {
          fullCode: 'LU-L',
          regionCode: 'L',
          regionName: 'Luxembourg (fr), Luxemburg (de), Lëtzebuerg (lb)',
        },
      ],
    },
  ],
  [
    'madagascar',
    {
      countryCode: 'MG',
      countryName: 'Madagascar',
      regions: [
        {
          fullCode: 'MG-T',
          regionCode: 'T',
          regionName: 'Antananarivo',
        },
        {
          fullCode: 'MG-D',
          regionCode: 'D',
          regionName: 'Antsiranana',
        },
        {
          fullCode: 'MG-F',
          regionCode: 'F',
          regionName: 'Fianarantsoa',
        },
        {
          fullCode: 'MG-M',
          regionCode: 'M',
          regionName: 'Mahajanga',
        },
        {
          fullCode: 'MG-A',
          regionCode: 'A',
          regionName: 'Toamasina',
        },
        {
          fullCode: 'MG-U',
          regionCode: 'U',
          regionName: 'Toliara',
        },
      ],
    },
  ],
  [
    'malawi',
    {
      countryCode: 'MW',
      countryName: 'Malawi',
      regions: [
        {
          fullCode: 'MW-N',
          regionCode: 'N',
          regionName: 'Northern Region',
        },
        {
          fullCode: 'MW-C',
          regionCode: 'C',
          regionName: 'Central Region',
        },
        {
          fullCode: 'MW-S',
          regionCode: 'S',
          regionName: 'Southern Region',
        },
        {
          fullCode: 'MW-BA',
          regionCode: 'BA',
          regionName: 'Balaka',
        },
        {
          fullCode: 'MW-BL',
          regionCode: 'BL',
          regionName: 'Blantyre',
        },
        {
          fullCode: 'MW-CK',
          regionCode: 'CK',
          regionName: 'Chikwawa',
        },
        {
          fullCode: 'MW-CR',
          regionCode: 'CR',
          regionName: 'Chiradzulu',
        },
        {
          fullCode: 'MW-CT',
          regionCode: 'CT',
          regionName: 'Chitipa',
        },
        {
          fullCode: 'MW-DE',
          regionCode: 'DE',
          regionName: 'Dedza',
        },
        {
          fullCode: 'MW-DO',
          regionCode: 'DO',
          regionName: 'Dowa',
        },
        {
          fullCode: 'MW-KR',
          regionCode: 'KR',
          regionName: 'Karonga',
        },
        {
          fullCode: 'MW-KS',
          regionCode: 'KS',
          regionName: 'Kasungu',
        },
        {
          fullCode: 'MW-LK',
          regionCode: 'LK',
          regionName: 'Likoma',
        },
        {
          fullCode: 'MW-LI',
          regionCode: 'LI',
          regionName: 'Lilongwe',
        },
        {
          fullCode: 'MW-MH',
          regionCode: 'MH',
          regionName: 'Machinga',
        },
        {
          fullCode: 'MW-MG',
          regionCode: 'MG',
          regionName: 'Mangochi',
        },
        {
          fullCode: 'MW-MC',
          regionCode: 'MC',
          regionName: 'Mchinji',
        },
        {
          fullCode: 'MW-MU',
          regionCode: 'MU',
          regionName: 'Mulanje',
        },
        {
          fullCode: 'MW-MW',
          regionCode: 'MW',
          regionName: 'Mwanza',
        },
        {
          fullCode: 'MW-MZ',
          regionCode: 'MZ',
          regionName: 'Mzimba',
        },
        {
          fullCode: 'MW-NE',
          regionCode: 'NE',
          regionName: 'Neno',
        },
        {
          fullCode: 'MW-NB',
          regionCode: 'NB',
          regionName: 'Nkhata Bay',
        },
        {
          fullCode: 'MW-NK',
          regionCode: 'NK',
          regionName: 'Nkhotakota',
        },
        {
          fullCode: 'MW-NS',
          regionCode: 'NS',
          regionName: 'Nsanje',
        },
        {
          fullCode: 'MW-NU',
          regionCode: 'NU',
          regionName: 'Ntcheu',
        },
        {
          fullCode: 'MW-NI',
          regionCode: 'NI',
          regionName: 'Ntchisi',
        },
        {
          fullCode: 'MW-PH',
          regionCode: 'PH',
          regionName: 'Phalombe',
        },
        {
          fullCode: 'MW-RU',
          regionCode: 'RU',
          regionName: 'Rumphi',
        },
        {
          fullCode: 'MW-SA',
          regionCode: 'SA',
          regionName: 'Salima',
        },
        {
          fullCode: 'MW-TH',
          regionCode: 'TH',
          regionName: 'Thyolo',
        },
        {
          fullCode: 'MW-ZO',
          regionCode: 'ZO',
          regionName: 'Zomba',
        },
      ],
    },
  ],
  [
    'malaysia',
    {
      countryCode: 'MY',
      countryName: 'Malaysia',
      regions: [
        {
          fullCode: 'MY-01',
          regionCode: '01',
          regionName: 'Johor',
        },
        {
          fullCode: 'MY-02',
          regionCode: '02',
          regionName: 'Kedah',
        },
        {
          fullCode: 'MY-03',
          regionCode: '03',
          regionName: 'Kelantan',
        },
        {
          fullCode: 'MY-04',
          regionCode: '04',
          regionName: 'Melaka',
        },
        {
          fullCode: 'MY-05',
          regionCode: '05',
          regionName: 'Negeri Sembilan',
        },
        {
          fullCode: 'MY-06',
          regionCode: '06',
          regionName: 'Pahang',
        },
        {
          fullCode: 'MY-08',
          regionCode: '08',
          regionName: 'Perak',
        },
        {
          fullCode: 'MY-09',
          regionCode: '09',
          regionName: 'Perlis',
        },
        {
          fullCode: 'MY-07',
          regionCode: '07',
          regionName: 'Pulau Pinang',
        },
        {
          fullCode: 'MY-12',
          regionCode: '12',
          regionName: 'Sabah',
        },
        {
          fullCode: 'MY-13',
          regionCode: '13',
          regionName: 'Sarawak',
        },
        {
          fullCode: 'MY-10',
          regionCode: '10',
          regionName: 'Selangor',
        },
        {
          fullCode: 'MY-11',
          regionCode: '11',
          regionName: 'Terengganu',
        },
        {
          fullCode: 'MY-14',
          regionCode: '14',
          regionName: 'Wilayah Persekutuan Kuala Lumpur',
        },
        {
          fullCode: 'MY-15',
          regionCode: '15',
          regionName: 'Wilayah Persekutuan Labuan',
        },
        {
          fullCode: 'MY-16',
          regionCode: '16',
          regionName: 'Wilayah Persekutuan Putrajaya',
        },
      ],
    },
  ],
  [
    'maldives',
    {
      countryCode: 'MV',
      countryName: 'Maldives',
      regions: [
        {
          fullCode: 'MV-01',
          regionCode: '01',
          regionName: 'Addu City',
        },
        {
          fullCode: 'MV-00',
          regionCode: '00',
          regionName: 'South Ari Atoll',
        },
        {
          fullCode: 'MV-02',
          regionCode: '02',
          regionName: 'North Ari Atoll',
        },
        {
          fullCode: 'MV-03',
          regionCode: '03',
          regionName: 'Faadhippolhu',
        },
        {
          fullCode: 'MV-04',
          regionCode: '04',
          regionName: 'Felidhu Atoll',
        },
        {
          fullCode: 'MV-29',
          regionCode: '29',
          regionName: 'Fuvammulah',
        },
        {
          fullCode: 'MV-05',
          regionCode: '05',
          regionName: 'Hahdhunmathi',
        },
        {
          fullCode: 'MV-28',
          regionCode: '28',
          regionName: 'South Huvadhu Atoll',
        },
        {
          fullCode: 'MV-27',
          regionCode: '27',
          regionName: 'North Huvadhu Atoll',
        },
        {
          fullCode: 'MV-08',
          regionCode: '08',
          regionName: 'Kolhumadulu',
        },
        {
          fullCode: 'MV-MLE',
          regionCode: 'MLE',
          regionName: 'Male',
        },
        {
          fullCode: 'MV-26',
          regionCode: '26',
          regionName: 'Male Atoll',
        },
        {
          fullCode: 'MV-20',
          regionCode: '20',
          regionName: 'South Maalhosmadulu',
        },
        {
          fullCode: 'MV-13',
          regionCode: '13',
          regionName: 'North Maalhosmadulu',
        },
        {
          fullCode: 'MV-25',
          regionCode: '25',
          regionName: 'South Miladhunmadulu',
        },
        {
          fullCode: 'MV-24',
          regionCode: '24',
          regionName: 'North Miladhunmadulu',
        },
        {
          fullCode: 'MV-12',
          regionCode: '12',
          regionName: 'Mulaku Atoll',
        },
        {
          fullCode: 'MV-17',
          regionCode: '17',
          regionName: 'South Nilandhe Atoll',
        },
        {
          fullCode: 'MV-14',
          regionCode: '14',
          regionName: 'North Nilandhe Atoll',
        },
        {
          fullCode: 'MV-23',
          regionCode: '23',
          regionName: 'South Thiladhunmathi',
        },
        {
          fullCode: 'MV-07',
          regionCode: '07',
          regionName: 'North Thiladhunmathi',
        },
        {
          fullCode: 'MV-CE',
          regionCode: 'CE',
          regionName: 'Central',
        },
        {
          fullCode: 'MV-NO',
          regionCode: 'NO',
          regionName: 'North',
        },
        {
          fullCode: 'MV-NC',
          regionCode: 'NC',
          regionName: 'North Central',
        },
        {
          fullCode: 'MV-SU',
          regionCode: 'SU',
          regionName: 'South',
        },
        {
          fullCode: 'MV-SC',
          regionCode: 'SC',
          regionName: 'South Central',
        },
        {
          fullCode: 'MV-UN',
          regionCode: 'UN',
          regionName: 'Upper North',
        },
        {
          fullCode: 'MV-US',
          regionCode: 'US',
          regionName: 'Upper South',
        },
      ],
    },
  ],
  [
    'mali',
    {
      countryCode: 'ML',
      countryName: 'Mali',
      regions: [
        {
          fullCode: 'ML-BKO',
          regionCode: 'BKO',
          regionName: 'Bamako',
        },
        {
          fullCode: 'ML-7',
          regionCode: '7',
          regionName: 'Gao',
        },
        {
          fullCode: 'ML-1',
          regionCode: '1',
          regionName: 'Kayes',
        },
        {
          fullCode: 'ML-8',
          regionCode: '8',
          regionName: 'Kidal',
        },
        {
          fullCode: 'ML-2',
          regionCode: '2',
          regionName: 'Koulikoro',
        },
        {
          fullCode: 'ML-9',
          regionCode: '9',
          regionName: 'Ménaka',
        },
        {
          fullCode: 'ML-5',
          regionCode: '5',
          regionName: 'Mopti',
        },
        {
          fullCode: 'ML-4',
          regionCode: '4',
          regionName: 'Ségou',
        },
        {
          fullCode: 'ML-3',
          regionCode: '3',
          regionName: 'Sikasso',
        },
        {
          fullCode: 'ML-10',
          regionCode: '10',
          regionName: 'Taoudénit(local variants are Taoudenni, Taoudéni)',
        },
        {
          fullCode: 'ML-6',
          regionCode: '6',
          regionName: 'Tombouctou',
        },
      ],
    },
  ],
  [
    'malta',
    {
      countryCode: 'MT',
      countryName: 'Malta',
      regions: [
        {
          fullCode: 'MT-01',
          regionCode: '01',
          regionName: 'Attard',
        },
        {
          fullCode: 'MT-02',
          regionCode: '02',
          regionName: 'Balzan',
        },
        {
          fullCode: 'MT-03',
          regionCode: '03',
          regionName: 'Birgu',
        },
        {
          fullCode: 'MT-04',
          regionCode: '04',
          regionName: 'Birkirkara',
        },
        {
          fullCode: 'MT-05',
          regionCode: '05',
          regionName: 'Birżebbuġa',
        },
        {
          fullCode: 'MT-06',
          regionCode: '06',
          regionName: 'Bormla',
        },
        {
          fullCode: 'MT-07',
          regionCode: '07',
          regionName: 'Dingli',
        },
        {
          fullCode: 'MT-08',
          regionCode: '08',
          regionName: 'Fgura',
        },
        {
          fullCode: 'MT-09',
          regionCode: '09',
          regionName: 'Floriana',
        },
        {
          fullCode: 'MT-10',
          regionCode: '10',
          regionName: 'Fontana',
        },
        {
          fullCode: 'MT-11',
          regionCode: '11',
          regionName: 'Gudja',
        },
        {
          fullCode: 'MT-12',
          regionCode: '12',
          regionName: 'Gżira',
        },
        {
          fullCode: 'MT-13',
          regionCode: '13',
          regionName: 'Għajnsielem',
        },
        {
          fullCode: 'MT-14',
          regionCode: '14',
          regionName: 'Għarb',
        },
        {
          fullCode: 'MT-15',
          regionCode: '15',
          regionName: 'Għargħur',
        },
        {
          fullCode: 'MT-16',
          regionCode: '16',
          regionName: 'Għasri',
        },
        {
          fullCode: 'MT-17',
          regionCode: '17',
          regionName: 'Għaxaq',
        },
        {
          fullCode: 'MT-18',
          regionCode: '18',
          regionName: 'Ħamrun',
        },
        {
          fullCode: 'MT-19',
          regionCode: '19',
          regionName: 'Iklin',
        },
        {
          fullCode: 'MT-20',
          regionCode: '20',
          regionName: 'Isla',
        },
        {
          fullCode: 'MT-21',
          regionCode: '21',
          regionName: 'Kalkara',
        },
        {
          fullCode: 'MT-22',
          regionCode: '22',
          regionName: 'Kerċem',
        },
        {
          fullCode: 'MT-23',
          regionCode: '23',
          regionName: 'Kirkop',
        },
        {
          fullCode: 'MT-24',
          regionCode: '24',
          regionName: 'Lija',
        },
        {
          fullCode: 'MT-25',
          regionCode: '25',
          regionName: 'Luqa',
        },
        {
          fullCode: 'MT-26',
          regionCode: '26',
          regionName: 'Marsa',
        },
        {
          fullCode: 'MT-27',
          regionCode: '27',
          regionName: 'Marsaskala',
        },
        {
          fullCode: 'MT-28',
          regionCode: '28',
          regionName: 'Marsaxlokk',
        },
        {
          fullCode: 'MT-29',
          regionCode: '29',
          regionName: 'Mdina',
        },
        {
          fullCode: 'MT-30',
          regionCode: '30',
          regionName: 'Mellieħa',
        },
        {
          fullCode: 'MT-31',
          regionCode: '31',
          regionName: 'Mġarr',
        },
        {
          fullCode: 'MT-32',
          regionCode: '32',
          regionName: 'Mosta',
        },
        {
          fullCode: 'MT-33',
          regionCode: '33',
          regionName: 'Mqabba',
        },
        {
          fullCode: 'MT-34',
          regionCode: '34',
          regionName: 'Msida',
        },
        {
          fullCode: 'MT-35',
          regionCode: '35',
          regionName: 'Mtarfa',
        },
        {
          fullCode: 'MT-36',
          regionCode: '36',
          regionName: 'Munxar',
        },
        {
          fullCode: 'MT-37',
          regionCode: '37',
          regionName: 'Nadur',
        },
        {
          fullCode: 'MT-38',
          regionCode: '38',
          regionName: 'Naxxar',
        },
        {
          fullCode: 'MT-39',
          regionCode: '39',
          regionName: 'Paola',
        },
        {
          fullCode: 'MT-40',
          regionCode: '40',
          regionName: 'Pembroke',
        },
        {
          fullCode: 'MT-41',
          regionCode: '41',
          regionName: 'Pietà',
        },
        {
          fullCode: 'MT-42',
          regionCode: '42',
          regionName: 'Qala',
        },
        {
          fullCode: 'MT-43',
          regionCode: '43',
          regionName: 'Qormi',
        },
        {
          fullCode: 'MT-44',
          regionCode: '44',
          regionName: 'Qrendi',
        },
        {
          fullCode: 'MT-45',
          regionCode: '45',
          regionName: 'Rabat Gozo',
        },
        {
          fullCode: 'MT-46',
          regionCode: '46',
          regionName: 'Rabat Malta',
        },
        {
          fullCode: 'MT-47',
          regionCode: '47',
          regionName: 'Safi',
        },
        {
          fullCode: 'MT-48',
          regionCode: '48',
          regionName: "Saint Julian\\'s",
        },
        {
          fullCode: 'MT-49',
          regionCode: '49',
          regionName: 'Saint John',
        },
        {
          fullCode: 'MT-50',
          regionCode: '50',
          regionName: 'Saint Lawrence',
        },
        {
          fullCode: 'MT-51',
          regionCode: '51',
          regionName: "Saint Paul\\'s Bay",
        },
        {
          fullCode: 'MT-52',
          regionCode: '52',
          regionName: 'Sannat',
        },
        {
          fullCode: 'MT-53',
          regionCode: '53',
          regionName: "Saint Lucia\\'s",
        },
        {
          fullCode: 'MT-54',
          regionCode: '54',
          regionName: 'Santa Venera',
        },
        {
          fullCode: 'MT-55',
          regionCode: '55',
          regionName: 'Siġġiewi',
        },
        {
          fullCode: 'MT-56',
          regionCode: '56',
          regionName: 'Sliema',
        },
        {
          fullCode: 'MT-57',
          regionCode: '57',
          regionName: 'Swieqi',
        },
        {
          fullCode: 'MT-58',
          regionCode: '58',
          regionName: "Ta\\' Xbiex",
        },
        {
          fullCode: 'MT-59',
          regionCode: '59',
          regionName: 'Tarxien',
        },
        {
          fullCode: 'MT-60',
          regionCode: '60',
          regionName: 'Valletta',
        },
        {
          fullCode: 'MT-61',
          regionCode: '61',
          regionName: 'Xagħra',
        },
        {
          fullCode: 'MT-62',
          regionCode: '62',
          regionName: 'Xewkija',
        },
        {
          fullCode: 'MT-63',
          regionCode: '63',
          regionName: 'Xgħajra',
        },
        {
          fullCode: 'MT-64',
          regionCode: '64',
          regionName: 'Żabbar',
        },
        {
          fullCode: 'MT-65',
          regionCode: '65',
          regionName: 'Żebbuġ Gozo',
        },
        {
          fullCode: 'MT-66',
          regionCode: '66',
          regionName: 'Żebbuġ Malta',
        },
        {
          fullCode: 'MT-67',
          regionCode: '67',
          regionName: 'Żejtun',
        },
        {
          fullCode: 'MT-68',
          regionCode: '68',
          regionName: 'Żurrieq',
        },
      ],
    },
  ],
  [
    'marshall islands',
    {
      countryCode: 'MH',
      countryName: 'Marshall Islands',
      regions: [
        {
          fullCode: 'MH-L',
          regionCode: 'L',
          regionName: 'Ralik chain',
        },
        {
          fullCode: 'MH-T',
          regionCode: 'T',
          regionName: 'Ratak chain',
        },
        {
          fullCode: 'MH-ALL',
          regionCode: 'ALL',
          regionName: 'Ailinglaplap',
        },
        {
          fullCode: 'MH-ALK',
          regionCode: 'ALK',
          regionName: 'Ailuk',
        },
        {
          fullCode: 'MH-ARN',
          regionCode: 'ARN',
          regionName: 'Arno',
        },
        {
          fullCode: 'MH-AUR',
          regionCode: 'AUR',
          regionName: 'Aur',
        },
        {
          fullCode: 'MH-KIL',
          regionCode: 'KIL',
          regionName: 'Bikini & Kili',
        },
        {
          fullCode: 'MH-EBO',
          regionCode: 'EBO',
          regionName: 'Ebon',
        },
        {
          fullCode: 'MH-ENI',
          regionCode: 'ENI',
          regionName: 'Enewetak & Ujelang',
        },
        {
          fullCode: 'MH-JAB',
          regionCode: 'JAB',
          regionName: 'Jabat',
        },
        {
          fullCode: 'MH-JAL',
          regionCode: 'JAL',
          regionName: 'Jaluit',
        },
        {
          fullCode: 'MH-KWA',
          regionCode: 'KWA',
          regionName: 'Kwajalein',
        },
        {
          fullCode: 'MH-LAE',
          regionCode: 'LAE',
          regionName: 'Lae',
        },
        {
          fullCode: 'MH-LIB',
          regionCode: 'LIB',
          regionName: 'Lib',
        },
        {
          fullCode: 'MH-LIK',
          regionCode: 'LIK',
          regionName: 'Likiep',
        },
        {
          fullCode: 'MH-MAJ',
          regionCode: 'MAJ',
          regionName: 'Majuro',
        },
        {
          fullCode: 'MH-MAL',
          regionCode: 'MAL',
          regionName: 'Maloelap',
        },
        {
          fullCode: 'MH-MEJ',
          regionCode: 'MEJ',
          regionName: 'Mejit',
        },
        {
          fullCode: 'MH-MIL',
          regionCode: 'MIL',
          regionName: 'Mili',
        },
        {
          fullCode: 'MH-NMK',
          regionCode: 'NMK',
          regionName: 'Namdrik',
        },
        {
          fullCode: 'MH-NMU',
          regionCode: 'NMU',
          regionName: 'Namu',
        },
        {
          fullCode: 'MH-RON',
          regionCode: 'RON',
          regionName: 'Rongelap',
        },
        {
          fullCode: 'MH-UJA',
          regionCode: 'UJA',
          regionName: 'Ujae',
        },
        {
          fullCode: 'MH-UTI',
          regionCode: 'UTI',
          regionName: 'Utrik',
        },
        {
          fullCode: 'MH-WTH',
          regionCode: 'WTH',
          regionName: 'Wotho',
        },
        {
          fullCode: 'MH-WTJ',
          regionCode: 'WTJ',
          regionName: 'Wotje',
        },
      ],
    },
  ],
  [
    'mauritania',
    {
      countryCode: 'MR',
      countryName: 'Mauritania',
      regions: [
        {
          fullCode: 'MR-01',
          regionCode: '01',
          regionName: 'Eastern Basin',
        },
        {
          fullCode: 'MR-02',
          regionCode: '02',
          regionName: 'Western Basin',
        },
        {
          fullCode: 'MR-03',
          regionCode: '03',
          regionName: 'Assaba',
        },
        {
          fullCode: 'MR-04',
          regionCode: '04',
          regionName: 'Gorgol',
        },
        {
          fullCode: 'MR-05',
          regionCode: '05',
          regionName: 'Brakna',
        },
        {
          fullCode: 'MR-06',
          regionCode: '06',
          regionName: 'Trarza',
        },
        {
          fullCode: 'MR-07',
          regionCode: '07',
          regionName: 'Adrar',
        },
        {
          fullCode: 'MR-08',
          regionCode: '08',
          regionName: 'Nouadhibou Peninsula',
        },
        {
          fullCode: 'MR-09',
          regionCode: '09',
          regionName: 'Tagant',
        },
        {
          fullCode: 'MR-10',
          regionCode: '10',
          regionName: 'Guidimaka',
        },
        {
          fullCode: 'MR-11',
          regionCode: '11',
          regionName: 'Tiris Zemmour',
        },
        {
          fullCode: 'MR-12',
          regionCode: '12',
          regionName: 'Inchiri',
        },
        {
          fullCode: 'MR-13',
          regionCode: '13',
          regionName: 'West Nouakchott',
        },
        {
          fullCode: 'MR-14',
          regionCode: '14',
          regionName: 'North Nouakchott',
        },
        {
          fullCode: 'MR-15',
          regionCode: '15',
          regionName: 'South Nouakchott',
        },
      ],
    },
  ],
  [
    'mauritius',
    {
      countryCode: 'MU',
      countryName: 'Mauritius',
      regions: [
        {
          fullCode: 'MU-AG',
          regionCode: 'AG',
          regionName: 'Agalega Islands',
        },
        {
          fullCode: 'MU-BL',
          regionCode: 'BL',
          regionName: 'Black River',
        },
        {
          fullCode: 'MU-CC',
          regionCode: 'CC',
          regionName:
            'Cargados Carajos Shoals(local variant: Saint Brandon Islands)',
        },
        {
          fullCode: 'MU-FL',
          regionCode: 'FL',
          regionName: 'Flacq',
        },
        {
          fullCode: 'MU-GP',
          regionCode: 'GP',
          regionName: 'Grand Port',
        },
        {
          fullCode: 'MU-MO',
          regionCode: 'MO',
          regionName: 'Moka',
        },
        {
          fullCode: 'MU-PA',
          regionCode: 'PA',
          regionName: 'Pamplemousses',
        },
        {
          fullCode: 'MU-PW',
          regionCode: 'PW',
          regionName: 'Plaines Wilhems',
        },
        {
          fullCode: 'MU-PL',
          regionCode: 'PL',
          regionName: 'Port Louis',
        },
        {
          fullCode: 'MU-RR',
          regionCode: 'RR',
          regionName: 'Rivière du Rempart',
        },
        {
          fullCode: 'MU-RO',
          regionCode: 'RO',
          regionName: 'Rodrigues Island',
        },
        {
          fullCode: 'MU-SA',
          regionCode: 'SA',
          regionName: 'Savanne',
        },
      ],
    },
  ],
  [
    'mexico',
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      regions: [
        {
          fullCode: 'MX-AGU',
          regionCode: 'AGU',
          regionName: 'Aguascalientes',
        },
        {
          fullCode: 'MX-BCN',
          regionCode: 'BCN',
          regionName: 'Baja California',
        },
        {
          fullCode: 'MX-BCS',
          regionCode: 'BCS',
          regionName: 'Baja California Sur',
        },
        {
          fullCode: 'MX-CAM',
          regionCode: 'CAM',
          regionName: 'Campeche',
        },
        {
          fullCode: 'MX-CMX',
          regionCode: 'CMX',
          regionName: 'Ciudad de México',
        },
        {
          fullCode: 'MX-COA',
          regionCode: 'COA',
          regionName: 'Coahuila',
        },
        {
          fullCode: 'MX-COL',
          regionCode: 'COL',
          regionName: 'Colima',
        },
        {
          fullCode: 'MX-CHP',
          regionCode: 'CHP',
          regionName: 'Chiapas',
        },
        {
          fullCode: 'MX-CHH',
          regionCode: 'CHH',
          regionName: 'Chihuahua',
        },
        {
          fullCode: 'MX-DUR',
          regionCode: 'DUR',
          regionName: 'Durango',
        },
        {
          fullCode: 'MX-GUA',
          regionCode: 'GUA',
          regionName: 'Guanajuato',
        },
        {
          fullCode: 'MX-GRO',
          regionCode: 'GRO',
          regionName: 'Guerrero',
        },
        {
          fullCode: 'MX-HID',
          regionCode: 'HID',
          regionName: 'Hidalgo',
        },
        {
          fullCode: 'MX-JAL',
          regionCode: 'JAL',
          regionName: 'Jalisco',
        },
        {
          fullCode: 'MX-MEX',
          regionCode: 'MEX',
          regionName: 'México',
        },
        {
          fullCode: 'MX-MIC',
          regionCode: 'MIC',
          regionName: 'Michoacán',
        },
        {
          fullCode: 'MX-MOR',
          regionCode: 'MOR',
          regionName: 'Morelos',
        },
        {
          fullCode: 'MX-NAY',
          regionCode: 'NAY',
          regionName: 'Nayarit',
        },
        {
          fullCode: 'MX-NLE',
          regionCode: 'NLE',
          regionName: 'Nuevo León',
        },
        {
          fullCode: 'MX-OAX',
          regionCode: 'OAX',
          regionName: 'Oaxaca',
        },
        {
          fullCode: 'MX-PUE',
          regionCode: 'PUE',
          regionName: 'Puebla',
        },
        {
          fullCode: 'MX-QUE',
          regionCode: 'QUE',
          regionName: 'Querétaro',
        },
        {
          fullCode: 'MX-ROO',
          regionCode: 'ROO',
          regionName: 'Quintana Roo',
        },
        {
          fullCode: 'MX-SLP',
          regionCode: 'SLP',
          regionName: 'San Luis Potosí',
        },
        {
          fullCode: 'MX-SIN',
          regionCode: 'SIN',
          regionName: 'Sinaloa',
        },
        {
          fullCode: 'MX-SON',
          regionCode: 'SON',
          regionName: 'Sonora',
        },
        {
          fullCode: 'MX-TAB',
          regionCode: 'TAB',
          regionName: 'Tabasco',
        },
        {
          fullCode: 'MX-TAM',
          regionCode: 'TAM',
          regionName: 'Tamaulipas',
        },
        {
          fullCode: 'MX-TLA',
          regionCode: 'TLA',
          regionName: 'Tlaxcala',
        },
        {
          fullCode: 'MX-VER',
          regionCode: 'VER',
          regionName: 'Veracruz',
        },
        {
          fullCode: 'MX-YUC',
          regionCode: 'YUC',
          regionName: 'Yucatán',
        },
        {
          fullCode: 'MX-ZAC',
          regionCode: 'ZAC',
          regionName: 'Zacatecas',
        },
      ],
    },
  ],
  [
    'micronesia, federated states of',
    {
      countryCode: 'FM',
      countryName: 'Micronesia, Federated States of',
      regions: [
        {
          fullCode: 'FM-TRK',
          regionCode: 'TRK',
          regionName: 'Chuuk',
        },
        {
          fullCode: 'FM-KSA',
          regionCode: 'KSA',
          regionName: 'Kosrae',
        },
        {
          fullCode: 'FM-PNI',
          regionCode: 'PNI',
          regionName: 'Pohnpei',
        },
        {
          fullCode: 'FM-YAP',
          regionCode: 'YAP',
          regionName: 'Yap',
        },
      ],
    },
  ],
  [
    'moldova, republic of',
    {
      countryCode: 'MD',
      countryName: 'Moldova, Republic of',
      regions: [
        {
          fullCode: 'MD-AN',
          regionCode: 'AN',
          regionName: 'Anenii Noi',
        },
        {
          fullCode: 'MD-BS',
          regionCode: 'BS',
          regionName: 'Basarabeasca',
        },
        {
          fullCode: 'MD-BA',
          regionCode: 'BA',
          regionName: 'Bălți',
        },
        {
          fullCode: 'MD-BD',
          regionCode: 'BD',
          regionName: 'Bender [Tighina]',
        },
        {
          fullCode: 'MD-BR',
          regionCode: 'BR',
          regionName: 'Briceni',
        },
        {
          fullCode: 'MD-CA',
          regionCode: 'CA',
          regionName: 'Cahul',
        },
        {
          fullCode: 'MD-CT',
          regionCode: 'CT',
          regionName: 'Cantemir',
        },
        {
          fullCode: 'MD-CL',
          regionCode: 'CL',
          regionName: 'Călărași',
        },
        {
          fullCode: 'MD-CS',
          regionCode: 'CS',
          regionName: 'Căușeni',
        },
        {
          fullCode: 'MD-CU',
          regionCode: 'CU',
          regionName: 'Chișinău',
        },
        {
          fullCode: 'MD-CM',
          regionCode: 'CM',
          regionName: 'Cimișlia',
        },
        {
          fullCode: 'MD-CR',
          regionCode: 'CR',
          regionName: 'Criuleni',
        },
        {
          fullCode: 'MD-DO',
          regionCode: 'DO',
          regionName: 'Dondușeni',
        },
        {
          fullCode: 'MD-DR',
          regionCode: 'DR',
          regionName: 'Drochia',
        },
        {
          fullCode: 'MD-DU',
          regionCode: 'DU',
          regionName: 'Dubăsari',
        },
        {
          fullCode: 'MD-ED',
          regionCode: 'ED',
          regionName: 'Edineț',
        },
        {
          fullCode: 'MD-FA',
          regionCode: 'FA',
          regionName: 'Fălești',
        },
        {
          fullCode: 'MD-FL',
          regionCode: 'FL',
          regionName: 'Florești',
        },
        {
          fullCode: 'MD-GA',
          regionCode: 'GA',
          regionName: 'Găgăuzia, Unitatea teritorială autonomă (UTAG)',
        },
        {
          fullCode: 'MD-GL',
          regionCode: 'GL',
          regionName: 'Glodeni',
        },
        {
          fullCode: 'MD-HI',
          regionCode: 'HI',
          regionName: 'Hîncești',
        },
        {
          fullCode: 'MD-IA',
          regionCode: 'IA',
          regionName: 'Ialoveni',
        },
        {
          fullCode: 'MD-LE',
          regionCode: 'LE',
          regionName: 'Leova',
        },
        {
          fullCode: 'MD-NI',
          regionCode: 'NI',
          regionName: 'Nisporeni',
        },
        {
          fullCode: 'MD-OC',
          regionCode: 'OC',
          regionName: 'Ocnița',
        },
        {
          fullCode: 'MD-OR',
          regionCode: 'OR',
          regionName: 'Orhei',
        },
        {
          fullCode: 'MD-RE',
          regionCode: 'RE',
          regionName: 'Rezina',
        },
        {
          fullCode: 'MD-RI',
          regionCode: 'RI',
          regionName: 'Rîșcani',
        },
        {
          fullCode: 'MD-SI',
          regionCode: 'SI',
          regionName: 'Sîngerei',
        },
        {
          fullCode: 'MD-SO',
          regionCode: 'SO',
          regionName: 'Soroca',
        },
        {
          fullCode: 'MD-SN',
          regionCode: 'SN',
          regionName: 'Stînga Nistrului, unitatea teritorială din',
        },
        {
          fullCode: 'MD-ST',
          regionCode: 'ST',
          regionName: 'Strășeni',
        },
        {
          fullCode: 'MD-SD',
          regionCode: 'SD',
          regionName: 'Șoldănești',
        },
        {
          fullCode: 'MD-SV',
          regionCode: 'SV',
          regionName: 'Ștefan Vodă',
        },
        {
          fullCode: 'MD-TA',
          regionCode: 'TA',
          regionName: 'Taraclia',
        },
        {
          fullCode: 'MD-TE',
          regionCode: 'TE',
          regionName: 'Telenești',
        },
        {
          fullCode: 'MD-UN',
          regionCode: 'UN',
          regionName: 'Ungheni',
        },
        {
          fullCode: 'MD-BAL',
          regionCode: 'BAL',
          regionName: 'Bălţi',
        },
        {
          fullCode: 'MD-CAH',
          regionCode: 'CAH',
          regionName: 'Cahul',
        },
        {
          fullCode: 'MD-CHI',
          regionCode: 'CHI',
          regionName: 'Chişinău',
        },
        {
          fullCode: 'MD-DUB',
          regionCode: 'DUB',
          regionName: 'Dubăsari',
        },
        {
          fullCode: 'MD-ORH',
          regionCode: 'ORH',
          regionName: 'Orhei',
        },
        {
          fullCode: 'MD-RIB',
          regionCode: 'RIB',
          regionName: 'Rîbnița',
        },
        {
          fullCode: 'MD-SOC',
          regionCode: 'SOC',
          regionName: 'Soroca',
        },
        {
          fullCode: 'MD-TIG',
          regionCode: 'TIG',
          regionName: 'Tighina',
        },
        {
          fullCode: 'MD-TIR',
          regionCode: 'TIR',
          regionName: 'Tiraspol',
        },
        {
          fullCode: 'MD-UNG',
          regionCode: 'UNG',
          regionName: 'Ungheni',
        },
        {
          fullCode: 'MD-ANE',
          regionCode: 'ANE',
          regionName: 'Anenii Noi',
        },
        {
          fullCode: 'MD-BAS',
          regionCode: 'BAS',
          regionName: 'Basarabeasca',
        },
        {
          fullCode: 'MD-BRI',
          regionCode: 'BRI',
          regionName: 'Brinceni',
        },
        {
          fullCode: 'MD-CHL',
          regionCode: 'CHL',
          regionName: 'Cahul',
        },
        {
          fullCode: 'MD-CAM',
          regionCode: 'CAM',
          regionName: 'Camenca',
        },
        {
          fullCode: 'MD-CAN',
          regionCode: 'CAN',
          regionName: 'Cantemir',
        },
        {
          fullCode: 'MD-CAI',
          regionCode: 'CAI',
          regionName: 'Căinari',
        },
        {
          fullCode: 'MD-CAL',
          regionCode: 'CAL',
          regionName: 'Călăraşi',
        },
        {
          fullCode: 'MD-CAS',
          regionCode: 'CAS',
          regionName: 'Căuşeni',
        },
        {
          fullCode: 'MD-CIA',
          regionCode: 'CIA',
          regionName: 'Ciadîr-Lunga',
        },
        {
          fullCode: 'MD-CIM',
          regionCode: 'CIM',
          regionName: 'Cimișlia',
        },
        {
          fullCode: 'MD-COM',
          regionCode: 'COM',
          regionName: 'Comrat',
        },
        {
          fullCode: 'MD-CRI',
          regionCode: 'CRI',
          regionName: 'Criuleni',
        },
        {
          fullCode: 'MD-DON',
          regionCode: 'DON',
          regionName: 'Dondușeni',
        },
        {
          fullCode: 'MD-DRO',
          regionCode: 'DRO',
          regionName: 'Drochia',
        },
        {
          fullCode: 'MD-DBI',
          regionCode: 'DBI',
          regionName: 'Dubăsari',
        },
        {
          fullCode: 'MD-EDI',
          regionCode: 'EDI',
          regionName: 'Edineț',
        },
        {
          fullCode: 'MD-FAL',
          regionCode: 'FAL',
          regionName: 'Făleşti',
        },
        {
          fullCode: 'MD-FLO',
          regionCode: 'FLO',
          regionName: 'Florești',
        },
        {
          fullCode: 'MD-GLO',
          regionCode: 'GLO',
          regionName: 'Glodeni',
        },
        {
          fullCode: 'MD-GRI',
          regionCode: 'GRI',
          regionName: 'Grigoriopol',
        },
        {
          fullCode: 'MD-HIN',
          regionCode: 'HIN',
          regionName: 'Hîncești',
        },
        {
          fullCode: 'MD-IAL',
          regionCode: 'IAL',
          regionName: 'Ialoveni',
        },
        {
          fullCode: 'MD-LEO',
          regionCode: 'LEO',
          regionName: 'Leova',
        },
        {
          fullCode: 'MD-NIS',
          regionCode: 'NIS',
          regionName: 'Nisporeni',
        },
        {
          fullCode: 'MD-OCN',
          regionCode: 'OCN',
          regionName: 'Ocnița',
        },
        {
          fullCode: 'MD-OHI',
          regionCode: 'OHI',
          regionName: 'Orhei',
        },
        {
          fullCode: 'MD-REZ',
          regionCode: 'REZ',
          regionName: 'Rezina',
        },
        {
          fullCode: 'MD-RIT',
          regionCode: 'RIT',
          regionName: 'Rîbnița',
        },
        {
          fullCode: 'MD-RIS',
          regionCode: 'RIS',
          regionName: 'Rîșcani',
        },
        {
          fullCode: 'MD-SIN',
          regionCode: 'SIN',
          regionName: 'Sîngerei',
        },
        {
          fullCode: 'MD-SLO',
          regionCode: 'SLO',
          regionName: 'Slobozia',
        },
        {
          fullCode: 'MD-SOA',
          regionCode: 'SOA',
          regionName: 'Soroca',
        },
        {
          fullCode: 'MD-STR',
          regionCode: 'STR',
          regionName: 'Strășeni',
        },
        {
          fullCode: 'MD-SOL',
          regionCode: 'SOL',
          regionName: 'Şoldăneşti',
        },
        {
          fullCode: 'MD-STE',
          regionCode: 'STE',
          regionName: 'Ştefan Vodă',
        },
        {
          fullCode: 'MD-TAR',
          regionCode: 'TAR',
          regionName: 'Taraclia',
        },
        {
          fullCode: 'MD-TEL',
          regionCode: 'TEL',
          regionName: 'Telenești',
        },
        {
          fullCode: 'MD-UGI',
          regionCode: 'UGI',
          regionName: 'Ungheni',
        },
        {
          fullCode: 'MD-VUL',
          regionCode: 'VUL',
          regionName: 'Vulcănești',
        },
        {
          fullCode: 'MD-GA',
          regionCode: 'GA',
          regionName: 'Găgăuzia, Unitate Teritorială Autonomă (UTAG)',
        },
        {
          fullCode: 'MD-CU',
          regionCode: 'CU',
          regionName: 'Chişinău',
        },
        {
          fullCode: 'MD-BA',
          regionCode: 'BA',
          regionName: 'Bălţi',
        },
        {
          fullCode: 'MD-CA',
          regionCode: 'CA',
          regionName: 'Cahul',
        },
        {
          fullCode: 'MD-CH',
          regionCode: 'CH',
          regionName: 'Chişinău',
        },
        {
          fullCode: 'MD-ED',
          regionCode: 'ED',
          regionName: 'Edineţ',
        },
        {
          fullCode: 'MD-LA',
          regionCode: 'LA',
          regionName: 'Lăpuşna',
        },
        {
          fullCode: 'MD-OR',
          regionCode: 'OR',
          regionName: 'Orhei',
        },
        {
          fullCode: 'MD-SO',
          regionCode: 'SO',
          regionName: 'Soroca',
        },
        {
          fullCode: 'MD-TA',
          regionCode: 'TA',
          regionName: 'Taraclia',
        },
        {
          fullCode: 'MD-TI',
          regionCode: 'TI',
          regionName: 'Tighina [Bender]',
        },
        {
          fullCode: 'MD-UN',
          regionCode: 'UN',
          regionName: 'Ungheni',
        },
        {
          fullCode: 'MD-SN',
          regionCode: 'SN',
          regionName: 'Stînga Nistrului, unitatea teritorială din',
        },
      ],
    },
  ],
  [
    'monaco',
    {
      countryCode: 'MC',
      countryName: 'Monaco',
      regions: [
        {
          fullCode: 'MC-FO',
          regionCode: 'FO',
          regionName: 'Fontvieille',
        },
        {
          fullCode: 'MC-JE',
          regionCode: 'JE',
          regionName: 'Jardin Exotique',
        },
        {
          fullCode: 'MC-CL',
          regionCode: 'CL',
          regionName: 'La Colle',
        },
        {
          fullCode: 'MC-CO',
          regionCode: 'CO',
          regionName: 'La Condamine',
        },
        {
          fullCode: 'MC-GA',
          regionCode: 'GA',
          regionName: 'La Gare',
        },
        {
          fullCode: 'MC-SO',
          regionCode: 'SO',
          regionName: 'La Source',
        },
        {
          fullCode: 'MC-LA',
          regionCode: 'LA',
          regionName: 'Larvotto',
        },
        {
          fullCode: 'MC-MA',
          regionCode: 'MA',
          regionName: 'Malbousquet',
        },
        {
          fullCode: 'MC-MO',
          regionCode: 'MO',
          regionName: 'Monaco-Ville',
        },
        {
          fullCode: 'MC-MG',
          regionCode: 'MG',
          regionName: 'Moneghetti',
        },
        {
          fullCode: 'MC-MC',
          regionCode: 'MC',
          regionName: 'Monte-Carlo',
        },
        {
          fullCode: 'MC-MU',
          regionCode: 'MU',
          regionName: 'Moulins',
        },
        {
          fullCode: 'MC-PH',
          regionCode: 'PH',
          regionName: 'Port-Hercule',
        },
        {
          fullCode: 'MC-SR',
          regionCode: 'SR',
          regionName: 'Saint-Roman',
        },
        {
          fullCode: 'MC-SD',
          regionCode: 'SD',
          regionName: 'Sainte-Dévote',
        },
        {
          fullCode: 'MC-SP',
          regionCode: 'SP',
          regionName: 'Spélugues',
        },
        {
          fullCode: 'MC-VR',
          regionCode: 'VR',
          regionName: 'Vallon de la Rousse',
        },
      ],
    },
  ],
  [
    'mongolia',
    {
      countryCode: 'MN',
      countryName: 'Mongolia',
      regions: [
        {
          fullCode: 'MN-073',
          regionCode: '073',
          regionName: 'Arhangay',
        },
        {
          fullCode: 'MN-069',
          regionCode: '069',
          regionName: 'Bayanhongor',
        },
        {
          fullCode: 'MN-071',
          regionCode: '071',
          regionName: 'Bayan-Ölgiy',
        },
        {
          fullCode: 'MN-067',
          regionCode: '067',
          regionName: 'Bulgan',
        },
        {
          fullCode: 'MN-037',
          regionCode: '037',
          regionName: 'Darhan uul',
        },
        {
          fullCode: 'MN-061',
          regionCode: '061',
          regionName: 'Dornod',
        },
        {
          fullCode: 'MN-063',
          regionCode: '063',
          regionName: 'Dornogovĭ',
        },
        {
          fullCode: 'MN-059',
          regionCode: '059',
          regionName: 'Dundgovĭ',
        },
        {
          fullCode: 'MN-057',
          regionCode: '057',
          regionName: 'Dzavhan',
        },
        {
          fullCode: 'MN-065',
          regionCode: '065',
          regionName: 'Govĭ-Altay',
        },
        {
          fullCode: 'MN-064',
          regionCode: '064',
          regionName: 'Govĭ-Sümber',
        },
        {
          fullCode: 'MN-039',
          regionCode: '039',
          regionName: 'Hentiy',
        },
        {
          fullCode: 'MN-043',
          regionCode: '043',
          regionName: 'Hovd',
        },
        {
          fullCode: 'MN-041',
          regionCode: '041',
          regionName: 'Hövsgöl',
        },
        {
          fullCode: 'MN-053',
          regionCode: '053',
          regionName: 'Ömnögovĭ',
        },
        {
          fullCode: 'MN-035',
          regionCode: '035',
          regionName: 'Orhon',
        },
        {
          fullCode: 'MN-055',
          regionCode: '055',
          regionName: 'Övörhangay',
        },
        {
          fullCode: 'MN-049',
          regionCode: '049',
          regionName: 'Selenge',
        },
        {
          fullCode: 'MN-051',
          regionCode: '051',
          regionName: 'Sühbaatar',
        },
        {
          fullCode: 'MN-047',
          regionCode: '047',
          regionName: 'Töv',
        },
        {
          fullCode: 'MN-1',
          regionCode: '1',
          regionName: 'Ulaanbaatar',
        },
        {
          fullCode: 'MN-046',
          regionCode: '046',
          regionName: 'Uvs',
        },
      ],
    },
  ],
  [
    'montenegro',
    {
      countryCode: 'ME',
      countryName: 'Montenegro',
      regions: [
        {
          fullCode: 'ME-01',
          regionCode: '01',
          regionName: 'Andrijevica',
        },
        {
          fullCode: 'ME-02',
          regionCode: '02',
          regionName: 'Bar',
        },
        {
          fullCode: 'ME-03',
          regionCode: '03',
          regionName: 'Berane',
        },
        {
          fullCode: 'ME-04',
          regionCode: '04',
          regionName: 'Bijelo Polje',
        },
        {
          fullCode: 'ME-05',
          regionCode: '05',
          regionName: 'Budva',
        },
        {
          fullCode: 'ME-06',
          regionCode: '06',
          regionName: 'Cetinje',
        },
        {
          fullCode: 'ME-07',
          regionCode: '07',
          regionName: 'Danilovgrad',
        },
        {
          fullCode: 'ME-22',
          regionCode: '22',
          regionName: 'Gusinje',
        },
        {
          fullCode: 'ME-08',
          regionCode: '08',
          regionName: 'Herceg-Novi',
        },
        {
          fullCode: 'ME-09',
          regionCode: '09',
          regionName: 'Kolašin',
        },
        {
          fullCode: 'ME-10',
          regionCode: '10',
          regionName: 'Kotor',
        },
        {
          fullCode: 'ME-11',
          regionCode: '11',
          regionName: 'Mojkovac',
        },
        {
          fullCode: 'ME-12',
          regionCode: '12',
          regionName: 'Nikšić',
        },
        {
          fullCode: 'ME-23',
          regionCode: '23',
          regionName: 'Petnjica',
        },
        {
          fullCode: 'ME-13',
          regionCode: '13',
          regionName: 'Plav',
        },
        {
          fullCode: 'ME-14',
          regionCode: '14',
          regionName: 'Pljevlja',
        },
        {
          fullCode: 'ME-15',
          regionCode: '15',
          regionName: 'Plužine',
        },
        {
          fullCode: 'ME-16',
          regionCode: '16',
          regionName: 'Podgorica',
        },
        {
          fullCode: 'ME-17',
          regionCode: '17',
          regionName: 'Rožaje',
        },
        {
          fullCode: 'ME-18',
          regionCode: '18',
          regionName: 'Šavnik',
        },
        {
          fullCode: 'ME-19',
          regionCode: '19',
          regionName: 'Tivat',
        },
        {
          fullCode: 'ME-24',
          regionCode: '24',
          regionName: 'Tuzi',
        },
        {
          fullCode: 'ME-20',
          regionCode: '20',
          regionName: 'Ulcinj',
        },
        {
          fullCode: 'ME-21',
          regionCode: '21',
          regionName: 'Žabljak',
        },
        {
          fullCode: 'ME-25',
          regionCode: '25',
          regionName: 'Zeta',
        },
      ],
    },
  ],
  [
    'morocco',
    {
      countryCode: 'MA',
      countryName: 'Morocco',
      regions: [
        {
          fullCode: 'MA-05',
          regionCode: '05',
          regionName: 'Béni Mellal-Khénifra',
        },
        {
          fullCode: 'MA-06',
          regionCode: '06',
          regionName: 'Casablanca-Settat',
        },
        {
          fullCode: 'MA-12',
          regionCode: '12',
          regionName: 'Dakhla-Oued Ed-Dahab (EH)',
        },
        {
          fullCode: 'MA-08',
          regionCode: '08',
          regionName: 'Drâa-Tafilalet',
        },
        {
          fullCode: 'MA-03',
          regionCode: '03',
          regionName: 'Fès-Meknès',
        },
        {
          fullCode: 'MA-10',
          regionCode: '10',
          regionName: 'Guelmim-Oued Noun (EH-partial)',
        },
        {
          fullCode: 'MA-02',
          regionCode: '02',
          regionName: "L\\'Oriental",
        },
        {
          fullCode: 'MA-11',
          regionCode: '11',
          regionName: 'Laâyoune-Sakia El Hamra (EH-partial)',
        },
        {
          fullCode: 'MA-07',
          regionCode: '07',
          regionName: 'Marrakech-Safi',
        },
        {
          fullCode: 'MA-04',
          regionCode: '04',
          regionName: 'Rabat-Salé-Kénitra',
        },
        {
          fullCode: 'MA-09',
          regionCode: '09',
          regionName: 'Souss-Massa',
        },
        {
          fullCode: 'MA-01',
          regionCode: '01',
          regionName: 'Tanger-Tétouan-Al Hoceïma',
        },
        {
          fullCode: 'MA-AGD',
          regionCode: 'AGD',
          regionName: 'Agadir-Ida-Ou-Tanane',
        },
        {
          fullCode: 'MA-HAO',
          regionCode: 'HAO',
          regionName: 'Al Haouz',
        },
        {
          fullCode: 'MA-HOC',
          regionCode: 'HOC',
          regionName: 'Al Hoceïma',
        },
        {
          fullCode: 'MA-AOU',
          regionCode: 'AOU',
          regionName: 'Aousserd (EH)',
        },
        {
          fullCode: 'MA-ASZ',
          regionCode: 'ASZ',
          regionName: 'Assa-Zag (EH-partial)',
        },
        {
          fullCode: 'MA-AZI',
          regionCode: 'AZI',
          regionName: 'Azilal',
        },
        {
          fullCode: 'MA-BEM',
          regionCode: 'BEM',
          regionName: 'Béni Mellal',
        },
        {
          fullCode: 'MA-BES',
          regionCode: 'BES',
          regionName: 'Benslimane',
        },
        {
          fullCode: 'MA-BER',
          regionCode: 'BER',
          regionName: 'Berkane',
        },
        {
          fullCode: 'MA-BRR',
          regionCode: 'BRR',
          regionName: 'Berrechid',
        },
        {
          fullCode: 'MA-BOD',
          regionCode: 'BOD',
          regionName: 'Boujdour (EH)',
        },
        {
          fullCode: 'MA-BOM',
          regionCode: 'BOM',
          regionName: 'Boulemane',
        },
        {
          fullCode: 'MA-CAS',
          regionCode: 'CAS',
          regionName: 'Casablanca(Local variant: Dar el Beïda)',
        },
        {
          fullCode: 'MA-CHE',
          regionCode: 'CHE',
          regionName: 'Chefchaouen',
        },
        {
          fullCode: 'MA-CHI',
          regionCode: 'CHI',
          regionName: 'Chichaoua',
        },
        {
          fullCode: 'MA-CHT',
          regionCode: 'CHT',
          regionName: 'Chtouka-Ait Baha',
        },
        {
          fullCode: 'MA-DRI',
          regionCode: 'DRI',
          regionName: 'Driouch',
        },
        {
          fullCode: 'MA-HAJ',
          regionCode: 'HAJ',
          regionName: 'El Hajeb',
        },
        {
          fullCode: 'MA-JDI',
          regionCode: 'JDI',
          regionName: 'El Jadida',
        },
        {
          fullCode: 'MA-KES',
          regionCode: 'KES',
          regionName: 'El Kelâa des Sraghna',
        },
        {
          fullCode: 'MA-ERR',
          regionCode: 'ERR',
          regionName: 'Errachidia',
        },
        {
          fullCode: 'MA-ESM',
          regionCode: 'ESM',
          regionName: 'Es-Semara (EH-partial)',
        },
        {
          fullCode: 'MA-ESI',
          regionCode: 'ESI',
          regionName: 'Essaouira',
        },
        {
          fullCode: 'MA-FAH',
          regionCode: 'FAH',
          regionName: 'Fahs-Anjra',
        },
        {
          fullCode: 'MA-FES',
          regionCode: 'FES',
          regionName: 'Fès',
        },
        {
          fullCode: 'MA-FIG',
          regionCode: 'FIG',
          regionName: 'Figuig',
        },
        {
          fullCode: 'MA-FQH',
          regionCode: 'FQH',
          regionName: 'Fquih Ben Salah',
        },
        {
          fullCode: 'MA-GUE',
          regionCode: 'GUE',
          regionName: 'Guelmim',
        },
        {
          fullCode: 'MA-GUF',
          regionCode: 'GUF',
          regionName: 'Guercif',
        },
        {
          fullCode: 'MA-IFR',
          regionCode: 'IFR',
          regionName: 'Ifrane',
        },
        {
          fullCode: 'MA-INE',
          regionCode: 'INE',
          regionName: 'Inezgane-Ait Melloul',
        },
        {
          fullCode: 'MA-JRA',
          regionCode: 'JRA',
          regionName: 'Jerada',
        },
        {
          fullCode: 'MA-KEN',
          regionCode: 'KEN',
          regionName: 'Kénitra',
        },
        {
          fullCode: 'MA-KHE',
          regionCode: 'KHE',
          regionName: 'Khémisset',
        },
        {
          fullCode: 'MA-KHN',
          regionCode: 'KHN',
          regionName: 'Khénifra',
        },
        {
          fullCode: 'MA-KHO',
          regionCode: 'KHO',
          regionName: 'Khouribga',
        },
        {
          fullCode: 'MA-LAA',
          regionCode: 'LAA',
          regionName: 'Laâyoune (EH)',
        },
        {
          fullCode: 'MA-LAR',
          regionCode: 'LAR',
          regionName: 'Larache',
        },
        {
          fullCode: 'MA-MDF',
          regionCode: 'MDF',
          regionName: 'M’diq-Fnideq',
        },
        {
          fullCode: 'MA-MAR',
          regionCode: 'MAR',
          regionName: 'Marrakech',
        },
        {
          fullCode: 'MA-MED',
          regionCode: 'MED',
          regionName: 'Médiouna',
        },
        {
          fullCode: 'MA-MEK',
          regionCode: 'MEK',
          regionName: 'Meknès',
        },
        {
          fullCode: 'MA-MID',
          regionCode: 'MID',
          regionName: 'Midelt',
        },
        {
          fullCode: 'MA-MOH',
          regionCode: 'MOH',
          regionName: 'Mohammadia',
        },
        {
          fullCode: 'MA-MOU',
          regionCode: 'MOU',
          regionName: 'Moulay Yacoub',
        },
        {
          fullCode: 'MA-NAD',
          regionCode: 'NAD',
          regionName: 'Nador',
        },
        {
          fullCode: 'MA-NOU',
          regionCode: 'NOU',
          regionName: 'Nouaceur',
        },
        {
          fullCode: 'MA-OUA',
          regionCode: 'OUA',
          regionName: 'Ouarzazate',
        },
        {
          fullCode: 'MA-OUD',
          regionCode: 'OUD',
          regionName: 'Oued Ed-Dahab (EH)',
        },
        {
          fullCode: 'MA-OUZ',
          regionCode: 'OUZ',
          regionName: 'Ouezzane',
        },
        {
          fullCode: 'MA-OUJ',
          regionCode: 'OUJ',
          regionName: 'Oujda-Angad',
        },
        {
          fullCode: 'MA-RAB',
          regionCode: 'RAB',
          regionName: 'Rabat',
        },
        {
          fullCode: 'MA-REH',
          regionCode: 'REH',
          regionName: 'Rehamna',
        },
        {
          fullCode: 'MA-SAF',
          regionCode: 'SAF',
          regionName: 'Safi',
        },
        {
          fullCode: 'MA-SAL',
          regionCode: 'SAL',
          regionName: 'Salé',
        },
        {
          fullCode: 'MA-SEF',
          regionCode: 'SEF',
          regionName: 'Sefrou',
        },
        {
          fullCode: 'MA-SET',
          regionCode: 'SET',
          regionName: 'Settat',
        },
        {
          fullCode: 'MA-SIB',
          regionCode: 'SIB',
          regionName: 'Sidi Bennour',
        },
        {
          fullCode: 'MA-SIF',
          regionCode: 'SIF',
          regionName: 'Sidi Ifni',
        },
        {
          fullCode: 'MA-SIK',
          regionCode: 'SIK',
          regionName: 'Sidi Kacem',
        },
        {
          fullCode: 'MA-SIL',
          regionCode: 'SIL',
          regionName: 'Sidi Slimane',
        },
        {
          fullCode: 'MA-SKH',
          regionCode: 'SKH',
          regionName: 'Skhirate-Témara',
        },
        {
          fullCode: 'MA-TNT',
          regionCode: 'TNT',
          regionName: 'Tan-Tan (EH-partial)',
        },
        {
          fullCode: 'MA-TNG',
          regionCode: 'TNG',
          regionName: 'Tanger-Assilah',
        },
        {
          fullCode: 'MA-TAO',
          regionCode: 'TAO',
          regionName: 'Taounate',
        },
        {
          fullCode: 'MA-TAI',
          regionCode: 'TAI',
          regionName: 'Taourirt',
        },
        {
          fullCode: 'MA-TAF',
          regionCode: 'TAF',
          regionName: 'Tarfaya (EH-partial)',
        },
        {
          fullCode: 'MA-TAR',
          regionCode: 'TAR',
          regionName: 'Taroudannt',
        },
        {
          fullCode: 'MA-TAT',
          regionCode: 'TAT',
          regionName: 'Tata',
        },
        {
          fullCode: 'MA-TAZ',
          regionCode: 'TAZ',
          regionName: 'Taza',
        },
        {
          fullCode: 'MA-TET',
          regionCode: 'TET',
          regionName: 'Tétouan',
        },
        {
          fullCode: 'MA-TIN',
          regionCode: 'TIN',
          regionName: 'Tinghir',
        },
        {
          fullCode: 'MA-TIZ',
          regionCode: 'TIZ',
          regionName: 'Tiznit',
        },
        {
          fullCode: 'MA-YUS',
          regionCode: 'YUS',
          regionName: 'Youssoufia',
        },
        {
          fullCode: 'MA-ZAG',
          regionCode: 'ZAG',
          regionName: 'Zagora',
        },
        {
          fullCode: 'MA-CE',
          regionCode: 'CE',
          regionName: 'Centre',
        },
        {
          fullCode: 'MA-CN',
          regionCode: 'CN',
          regionName: 'Centre-Nord',
        },
        {
          fullCode: 'MA-CS',
          regionCode: 'CS',
          regionName: 'Centre-Sud',
        },
        {
          fullCode: 'MA-ES',
          regionCode: 'ES',
          regionName: 'Est',
        },
        {
          fullCode: 'MA-NO',
          regionCode: 'NO',
          regionName: 'Nord-Ouest',
        },
        {
          fullCode: 'MA-SU',
          regionCode: 'SU',
          regionName: 'Sud',
        },
        {
          fullCode: 'MA-TS',
          regionCode: 'TS',
          regionName: 'Tensift',
        },
      ],
    },
  ],
  [
    'mozambique',
    {
      countryCode: 'MZ',
      countryName: 'Mozambique',
      regions: [
        {
          fullCode: 'MZ-P',
          regionCode: 'P',
          regionName: 'Cabo Delgado',
        },
        {
          fullCode: 'MZ-G',
          regionCode: 'G',
          regionName: 'Gaza',
        },
        {
          fullCode: 'MZ-I',
          regionCode: 'I',
          regionName: 'Inhambane',
        },
        {
          fullCode: 'MZ-B',
          regionCode: 'B',
          regionName: 'Manica',
        },
        {
          fullCode: 'MZ-MPM',
          regionCode: 'MPM',
          regionName: 'Maputo',
        },
        {
          fullCode: 'MZ-L',
          regionCode: 'L',
          regionName: 'Maputo',
        },
        {
          fullCode: 'MZ-N',
          regionCode: 'N',
          regionName: 'Nampula',
        },
        {
          fullCode: 'MZ-A',
          regionCode: 'A',
          regionName: 'Niassa',
        },
        {
          fullCode: 'MZ-S',
          regionCode: 'S',
          regionName: 'Sofala',
        },
        {
          fullCode: 'MZ-T',
          regionCode: 'T',
          regionName: 'Tete',
        },
        {
          fullCode: 'MZ-Q',
          regionCode: 'Q',
          regionName: 'Zambézia',
        },
      ],
    },
  ],
  [
    'myanmar',
    {
      countryCode: 'MM',
      countryName: 'Myanmar',
      regions: [
        {
          fullCode: 'MM-07',
          regionCode: '07',
          regionName: 'Ayeyarwady',
        },
        {
          fullCode: 'MM-02',
          regionCode: '02',
          regionName: 'Bago',
        },
        {
          fullCode: 'MM-14',
          regionCode: '14',
          regionName: 'Chin',
        },
        {
          fullCode: 'MM-11',
          regionCode: '11',
          regionName: 'Kachin',
        },
        {
          fullCode: 'MM-12',
          regionCode: '12',
          regionName: 'Kayah',
        },
        {
          fullCode: 'MM-13',
          regionCode: '13',
          regionName: 'Kayin',
        },
        {
          fullCode: 'MM-03',
          regionCode: '03',
          regionName: 'Magway',
        },
        {
          fullCode: 'MM-04',
          regionCode: '04',
          regionName: 'Mandalay',
        },
        {
          fullCode: 'MM-15',
          regionCode: '15',
          regionName: 'Mon',
        },
        {
          fullCode: 'MM-18',
          regionCode: '18',
          regionName: 'Nay Pyi Taw',
        },
        {
          fullCode: 'MM-16',
          regionCode: '16',
          regionName: 'Rakhine',
        },
        {
          fullCode: 'MM-01',
          regionCode: '01',
          regionName: 'Sagaing',
        },
        {
          fullCode: 'MM-17',
          regionCode: '17',
          regionName: 'Shan',
        },
        {
          fullCode: 'MM-05',
          regionCode: '05',
          regionName: 'Tanintharyi',
        },
        {
          fullCode: 'MM-06',
          regionCode: '06',
          regionName: 'Yangon',
        },
      ],
    },
  ],
  [
    'namibia',
    {
      countryCode: 'NA',
      countryName: 'Namibia',
      regions: [
        {
          fullCode: 'NA-ER',
          regionCode: 'ER',
          regionName: 'Erongo',
        },
        {
          fullCode: 'NA-HA',
          regionCode: 'HA',
          regionName: 'Hardap',
        },
        {
          fullCode: 'NA-KA',
          regionCode: 'KA',
          regionName: '//Karas',
        },
        {
          fullCode: 'NA-KE',
          regionCode: 'KE',
          regionName: 'Kavango East',
        },
        {
          fullCode: 'NA-KW',
          regionCode: 'KW',
          regionName: 'Kavango West',
        },
        {
          fullCode: 'NA-KH',
          regionCode: 'KH',
          regionName: 'Khomas',
        },
        {
          fullCode: 'NA-KU',
          regionCode: 'KU',
          regionName: 'Kunene',
        },
        {
          fullCode: 'NA-OW',
          regionCode: 'OW',
          regionName: 'Ohangwena',
        },
        {
          fullCode: 'NA-OH',
          regionCode: 'OH',
          regionName: 'Omaheke',
        },
        {
          fullCode: 'NA-OS',
          regionCode: 'OS',
          regionName: 'Omusati',
        },
        {
          fullCode: 'NA-ON',
          regionCode: 'ON',
          regionName: 'Oshana',
        },
        {
          fullCode: 'NA-OT',
          regionCode: 'OT',
          regionName: 'Oshikoto',
        },
        {
          fullCode: 'NA-OD',
          regionCode: 'OD',
          regionName: 'Otjozondjupa',
        },
        {
          fullCode: 'NA-CA',
          regionCode: 'CA',
          regionName: 'Zambezi',
        },
      ],
    },
  ],
  [
    'nauru',
    {
      countryCode: 'NR',
      countryName: 'Nauru',
      regions: [
        {
          fullCode: 'NR-01',
          regionCode: '01',
          regionName: 'Aiwo',
        },
        {
          fullCode: 'NR-02',
          regionCode: '02',
          regionName: 'Anabar',
        },
        {
          fullCode: 'NR-03',
          regionCode: '03',
          regionName: 'Anetan',
        },
        {
          fullCode: 'NR-04',
          regionCode: '04',
          regionName: 'Anibare',
        },
        {
          fullCode: 'NR-05',
          regionCode: '05',
          regionName: 'Baitsi (local variant is Baiti)',
        },
        {
          fullCode: 'NR-06',
          regionCode: '06',
          regionName: 'Boe',
        },
        {
          fullCode: 'NR-07',
          regionCode: '07',
          regionName: 'Buada',
        },
        {
          fullCode: 'NR-08',
          regionCode: '08',
          regionName: 'Denigomodu',
        },
        {
          fullCode: 'NR-09',
          regionCode: '09',
          regionName: 'Ewa',
        },
        {
          fullCode: 'NR-10',
          regionCode: '10',
          regionName: 'Ijuw',
        },
        {
          fullCode: 'NR-11',
          regionCode: '11',
          regionName: 'Meneng',
        },
        {
          fullCode: 'NR-12',
          regionCode: '12',
          regionName: 'Nibok',
        },
        {
          fullCode: 'NR-13',
          regionCode: '13',
          regionName: 'Uaboe',
        },
        {
          fullCode: 'NR-14',
          regionCode: '14',
          regionName: 'Yaren',
        },
      ],
    },
  ],
  [
    'nepal',
    {
      countryCode: 'NP',
      countryName: 'Nepal',
      regions: [
        {
          fullCode: 'NP-P3',
          regionCode: 'P3',
          regionName: 'Bagmati',
        },
        {
          fullCode: 'NP-P4',
          regionCode: 'P4',
          regionName: 'Gandaki',
        },
        {
          fullCode: 'NP-P6',
          regionCode: 'P6',
          regionName: 'Karnali',
        },
        {
          fullCode: 'NP-P1',
          regionCode: 'P1',
          regionName: 'Koshi',
        },
        {
          fullCode: 'NP-P5',
          regionCode: 'P5',
          regionName: 'Lumbini',
        },
        {
          fullCode: 'NP-P2',
          regionCode: 'P2',
          regionName: 'Madhesh',
        },
        {
          fullCode: 'NP-P7',
          regionCode: 'P7',
          regionName: 'Sudurpashchim',
        },
      ],
    },
  ],
  [
    'netherlands, kingdom of the',
    {
      countryCode: 'NL',
      countryName: 'Netherlands, Kingdom of the',
      regions: [
        {
          fullCode: 'NL-DR',
          regionCode: 'DR',
          regionName: 'Drenthe',
        },
        {
          fullCode: 'NL-FL',
          regionCode: 'FL',
          regionName: 'Flevoland',
        },
        {
          fullCode: 'NL-FR',
          regionCode: 'FR',
          regionName: 'Friesland',
        },
        {
          fullCode: 'NL-GE',
          regionCode: 'GE',
          regionName: 'Gelderland',
        },
        {
          fullCode: 'NL-GR',
          regionCode: 'GR',
          regionName: 'Groningen',
        },
        {
          fullCode: 'NL-LI',
          regionCode: 'LI',
          regionName: 'Limburg',
        },
        {
          fullCode: 'NL-NB',
          regionCode: 'NB',
          regionName: 'North Brabant',
        },
        {
          fullCode: 'NL-NH',
          regionCode: 'NH',
          regionName: 'North Holland',
        },
        {
          fullCode: 'NL-OV',
          regionCode: 'OV',
          regionName: 'Overijssel',
        },
        {
          fullCode: 'NL-UT',
          regionCode: 'UT',
          regionName: 'Utrecht',
        },
        {
          fullCode: 'NL-ZE',
          regionCode: 'ZE',
          regionName: 'Zeeland',
        },
        {
          fullCode: 'NL-ZH',
          regionCode: 'ZH',
          regionName: 'South Holland',
        },
        {
          fullCode: 'NL-AW',
          regionCode: 'AW',
          regionName: 'Aruba',
        },
        {
          fullCode: 'NL-CW',
          regionCode: 'CW',
          regionName: 'Curaçao',
        },
        {
          fullCode: 'NL-SX',
          regionCode: 'SX',
          regionName: 'Sint Maarten',
        },
        {
          fullCode: 'NL-BQ1',
          regionCode: 'BQ1',
          regionName: 'Bonaire',
        },
        {
          fullCode: 'NL-BQ2',
          regionCode: 'BQ2',
          regionName: 'Saba',
        },
        {
          fullCode: 'NL-BQ3',
          regionCode: 'BQ3',
          regionName: 'Sint Eustatius',
        },
      ],
    },
  ],
  [
    'new zealand',
    {
      countryCode: 'NZ',
      countryName: 'New Zealand',
      regions: [
        {
          fullCode: 'NZ-AUK',
          regionCode: 'AUK',
          regionName: 'Auckland',
        },
        {
          fullCode: 'NZ-BOP',
          regionCode: 'BOP',
          regionName: 'Bay of Plenty',
        },
        {
          fullCode: 'NZ-CAN',
          regionCode: 'CAN',
          regionName: 'Canterbury',
        },
        {
          fullCode: 'NZ-CIT',
          regionCode: 'CIT',
          regionName: 'Chatham Islands Territory',
        },
        {
          fullCode: 'NZ-GIS',
          regionCode: 'GIS',
          regionName: 'Gisborne',
        },
        {
          fullCode: 'NZ-WGN',
          regionCode: 'WGN',
          regionName: 'Greater Wellington',
        },
        {
          fullCode: 'NZ-HKB',
          regionCode: 'HKB',
          regionName: "Hawke\\'s Bay",
        },
        {
          fullCode: 'NZ-MWT',
          regionCode: 'MWT',
          regionName: 'Manawatū-Whanganui',
        },
        {
          fullCode: 'NZ-MBH',
          regionCode: 'MBH',
          regionName: 'Marlborough',
        },
        {
          fullCode: 'NZ-NSN',
          regionCode: 'NSN',
          regionName: 'Nelson',
        },
        {
          fullCode: 'NZ-NTL',
          regionCode: 'NTL',
          regionName: 'Northland',
        },
        {
          fullCode: 'NZ-OTA',
          regionCode: 'OTA',
          regionName: 'Otago',
        },
        {
          fullCode: 'NZ-STL',
          regionCode: 'STL',
          regionName: 'Southland',
        },
        {
          fullCode: 'NZ-TKI',
          regionCode: 'TKI',
          regionName: 'Taranaki',
        },
        {
          fullCode: 'NZ-TAS',
          regionCode: 'TAS',
          regionName: 'Tasman',
        },
        {
          fullCode: 'NZ-WKO',
          regionCode: 'WKO',
          regionName: 'Waikato',
        },
        {
          fullCode: 'NZ-WTC',
          regionCode: 'WTC',
          regionName: 'West Coast',
        },
      ],
    },
  ],
  [
    'nicaragua',
    {
      countryCode: 'NI',
      countryName: 'Nicaragua',
      regions: [
        {
          fullCode: 'NI-BO',
          regionCode: 'BO',
          regionName: 'Boaco',
        },
        {
          fullCode: 'NI-CA',
          regionCode: 'CA',
          regionName: 'Carazo',
        },
        {
          fullCode: 'NI-CI',
          regionCode: 'CI',
          regionName: 'Chinandega',
        },
        {
          fullCode: 'NI-CO',
          regionCode: 'CO',
          regionName: 'Chontales',
        },
        {
          fullCode: 'NI-AN',
          regionCode: 'AN',
          regionName: 'North Caribbean Coast',
        },
        {
          fullCode: 'NI-AS',
          regionCode: 'AS',
          regionName: 'South Caribbean Coast',
        },
        {
          fullCode: 'NI-ES',
          regionCode: 'ES',
          regionName: 'Estelí',
        },
        {
          fullCode: 'NI-GR',
          regionCode: 'GR',
          regionName: 'Granada',
        },
        {
          fullCode: 'NI-JI',
          regionCode: 'JI',
          regionName: 'Jinotega',
        },
        {
          fullCode: 'NI-LE',
          regionCode: 'LE',
          regionName: 'León',
        },
        {
          fullCode: 'NI-MD',
          regionCode: 'MD',
          regionName: 'Madriz',
        },
        {
          fullCode: 'NI-MN',
          regionCode: 'MN',
          regionName: 'Managua',
        },
        {
          fullCode: 'NI-MS',
          regionCode: 'MS',
          regionName: 'Masaya',
        },
        {
          fullCode: 'NI-MT',
          regionCode: 'MT',
          regionName: 'Matagalpa',
        },
        {
          fullCode: 'NI-NS',
          regionCode: 'NS',
          regionName: 'New Segovia',
        },
        {
          fullCode: 'NI-SJ',
          regionCode: 'SJ',
          regionName: 'Saint John River',
        },
        {
          fullCode: 'NI-RI',
          regionCode: 'RI',
          regionName: 'Rivas',
        },
      ],
    },
  ],
  [
    'niger',
    {
      countryCode: 'NE',
      countryName: 'Niger',
      regions: [
        {
          fullCode: 'NE-1',
          regionCode: '1',
          regionName: 'Agadez',
        },
        {
          fullCode: 'NE-2',
          regionCode: '2',
          regionName: 'Diffa',
        },
        {
          fullCode: 'NE-3',
          regionCode: '3',
          regionName: 'Dosso',
        },
        {
          fullCode: 'NE-4',
          regionCode: '4',
          regionName: 'Maradi',
        },
        {
          fullCode: 'NE-8',
          regionCode: '8',
          regionName: 'Niamey',
        },
        {
          fullCode: 'NE-5',
          regionCode: '5',
          regionName: 'Tahoua',
        },
        {
          fullCode: 'NE-6',
          regionCode: '6',
          regionName: 'Tillabéri',
        },
        {
          fullCode: 'NE-7',
          regionCode: '7',
          regionName: 'Zinder',
        },
      ],
    },
  ],
  [
    'nigeria',
    {
      countryCode: 'NG',
      countryName: 'Nigeria',
      regions: [
        {
          fullCode: 'NG-AB',
          regionCode: 'AB',
          regionName: 'Abia',
        },
        {
          fullCode: 'NG-FC',
          regionCode: 'FC',
          regionName: 'Abuja Federal Capital Territory',
        },
        {
          fullCode: 'NG-AD',
          regionCode: 'AD',
          regionName: 'Adamawa',
        },
        {
          fullCode: 'NG-AK',
          regionCode: 'AK',
          regionName: 'Akwa Ibom',
        },
        {
          fullCode: 'NG-AN',
          regionCode: 'AN',
          regionName: 'Anambra',
        },
        {
          fullCode: 'NG-BA',
          regionCode: 'BA',
          regionName: 'Bauchi',
        },
        {
          fullCode: 'NG-BY',
          regionCode: 'BY',
          regionName: 'Bayelsa',
        },
        {
          fullCode: 'NG-BE',
          regionCode: 'BE',
          regionName: 'Benue',
        },
        {
          fullCode: 'NG-BO',
          regionCode: 'BO',
          regionName: 'Borno',
        },
        {
          fullCode: 'NG-CR',
          regionCode: 'CR',
          regionName: 'Cross River',
        },
        {
          fullCode: 'NG-DE',
          regionCode: 'DE',
          regionName: 'Delta',
        },
        {
          fullCode: 'NG-EB',
          regionCode: 'EB',
          regionName: 'Ebonyi',
        },
        {
          fullCode: 'NG-ED',
          regionCode: 'ED',
          regionName: 'Edo',
        },
        {
          fullCode: 'NG-EK',
          regionCode: 'EK',
          regionName: 'Ekiti',
        },
        {
          fullCode: 'NG-EN',
          regionCode: 'EN',
          regionName: 'Enugu',
        },
        {
          fullCode: 'NG-GO',
          regionCode: 'GO',
          regionName: 'Gombe',
        },
        {
          fullCode: 'NG-IM',
          regionCode: 'IM',
          regionName: 'Imo',
        },
        {
          fullCode: 'NG-JI',
          regionCode: 'JI',
          regionName: 'Jigawa',
        },
        {
          fullCode: 'NG-KD',
          regionCode: 'KD',
          regionName: 'Kaduna',
        },
        {
          fullCode: 'NG-KN',
          regionCode: 'KN',
          regionName: 'Kano',
        },
        {
          fullCode: 'NG-KT',
          regionCode: 'KT',
          regionName: 'Katsina',
        },
        {
          fullCode: 'NG-KE',
          regionCode: 'KE',
          regionName: 'Kebbi',
        },
        {
          fullCode: 'NG-KO',
          regionCode: 'KO',
          regionName: 'Kogi',
        },
        {
          fullCode: 'NG-KW',
          regionCode: 'KW',
          regionName: 'Kwara',
        },
        {
          fullCode: 'NG-LA',
          regionCode: 'LA',
          regionName: 'Lagos',
        },
        {
          fullCode: 'NG-NA',
          regionCode: 'NA',
          regionName: 'Nasarawa',
        },
        {
          fullCode: 'NG-NI',
          regionCode: 'NI',
          regionName: 'Niger',
        },
        {
          fullCode: 'NG-OG',
          regionCode: 'OG',
          regionName: 'Ogun',
        },
        {
          fullCode: 'NG-ON',
          regionCode: 'ON',
          regionName: 'Ondo',
        },
        {
          fullCode: 'NG-OS',
          regionCode: 'OS',
          regionName: 'Osun',
        },
        {
          fullCode: 'NG-OY',
          regionCode: 'OY',
          regionName: 'Oyo',
        },
        {
          fullCode: 'NG-PL',
          regionCode: 'PL',
          regionName: 'Plateau',
        },
        {
          fullCode: 'NG-RI',
          regionCode: 'RI',
          regionName: 'Rivers',
        },
        {
          fullCode: 'NG-SO',
          regionCode: 'SO',
          regionName: 'Sokoto',
        },
        {
          fullCode: 'NG-TA',
          regionCode: 'TA',
          regionName: 'Taraba',
        },
        {
          fullCode: 'NG-YO',
          regionCode: 'YO',
          regionName: 'Yobe',
        },
        {
          fullCode: 'NG-ZA',
          regionCode: 'ZA',
          regionName: 'Zamfara',
        },
      ],
    },
  ],
  [
    'north macedonia',
    {
      countryCode: 'MK',
      countryName: 'North Macedonia',
      regions: [
        {
          fullCode: 'MK-801',
          regionCode: '801',
          regionName: 'Aerodrom',
        },
        {
          fullCode: 'MK-802',
          regionCode: '802',
          regionName: 'Aračinovo',
        },
        {
          fullCode: 'MK-201',
          regionCode: '201',
          regionName: 'Berovo',
        },
        {
          fullCode: 'MK-501',
          regionCode: '501',
          regionName: 'Bitola',
        },
        {
          fullCode: 'MK-401',
          regionCode: '401',
          regionName: 'Bogdanci',
        },
        {
          fullCode: 'MK-601',
          regionCode: '601',
          regionName: 'Bogovinje',
        },
        {
          fullCode: 'MK-402',
          regionCode: '402',
          regionName: 'Bosilovo',
        },
        {
          fullCode: 'MK-602',
          regionCode: '602',
          regionName: 'Brvenica',
        },
        {
          fullCode: 'MK-803',
          regionCode: '803',
          regionName: 'Butel',
        },
        {
          fullCode: 'MK-814',
          regionCode: '814',
          regionName: 'Centar',
        },
        {
          fullCode: 'MK-313',
          regionCode: '313',
          regionName: 'Centar Župa',
        },
        {
          fullCode: 'MK-815',
          regionCode: '815',
          regionName: 'Čair',
        },
        {
          fullCode: 'MK-109',
          regionCode: '109',
          regionName: 'Čaška',
        },
        {
          fullCode: 'MK-210',
          regionCode: '210',
          regionName: 'Češinovo-Obleševo',
        },
        {
          fullCode: 'MK-816',
          regionCode: '816',
          regionName: 'Čučer-Sandevo',
        },
        {
          fullCode: 'MK-303',
          regionCode: '303',
          regionName: 'Debar',
        },
        {
          fullCode: 'MK-304',
          regionCode: '304',
          regionName: 'Debrca',
        },
        {
          fullCode: 'MK-203',
          regionCode: '203',
          regionName: 'Delčevo',
        },
        {
          fullCode: 'MK-502',
          regionCode: '502',
          regionName: 'Demir Hisar',
        },
        {
          fullCode: 'MK-103',
          regionCode: '103',
          regionName: 'Demir Kapija',
        },
        {
          fullCode: 'MK-406',
          regionCode: '406',
          regionName: 'Dojran',
        },
        {
          fullCode: 'MK-503',
          regionCode: '503',
          regionName: 'Dolneni',
        },
        {
          fullCode: 'MK-804',
          regionCode: '804',
          regionName: 'Gazi Baba',
        },
        {
          fullCode: 'MK-405',
          regionCode: '405',
          regionName: 'Gevgelija',
        },
        {
          fullCode: 'MK-805',
          regionCode: '805',
          regionName: 'Gjorče Petrov',
        },
        {
          fullCode: 'MK-604',
          regionCode: '604',
          regionName: 'Gostivar',
        },
        {
          fullCode: 'MK-102',
          regionCode: '102',
          regionName: 'Gradsko',
        },
        {
          fullCode: 'MK-807',
          regionCode: '807',
          regionName: 'Ilinden',
        },
        {
          fullCode: 'MK-606',
          regionCode: '606',
          regionName: 'Jegunovce',
        },
        {
          fullCode: 'MK-205',
          regionCode: '205',
          regionName: 'Karbinci',
        },
        {
          fullCode: 'MK-808',
          regionCode: '808',
          regionName: 'Karpoš',
        },
        {
          fullCode: 'MK-104',
          regionCode: '104',
          regionName: 'Kavadarci',
        },
        {
          fullCode: 'MK-307',
          regionCode: '307',
          regionName: 'Kičevo',
        },
        {
          fullCode: 'MK-809',
          regionCode: '809',
          regionName: 'Kisela Voda',
        },
        {
          fullCode: 'MK-206',
          regionCode: '206',
          regionName: 'Kočani',
        },
        {
          fullCode: 'MK-407',
          regionCode: '407',
          regionName: 'Konče',
        },
        {
          fullCode: 'MK-701',
          regionCode: '701',
          regionName: 'Kratovo',
        },
        {
          fullCode: 'MK-702',
          regionCode: '702',
          regionName: 'Kriva Palanka',
        },
        {
          fullCode: 'MK-504',
          regionCode: '504',
          regionName: 'Krivogaštani',
        },
        {
          fullCode: 'MK-505',
          regionCode: '505',
          regionName: 'Kruševo',
        },
        {
          fullCode: 'MK-703',
          regionCode: '703',
          regionName: 'Kumanovo',
        },
        {
          fullCode: 'MK-704',
          regionCode: '704',
          regionName: 'Lipkovo',
        },
        {
          fullCode: 'MK-105',
          regionCode: '105',
          regionName: 'Lozovo',
        },
        {
          fullCode: 'MK-207',
          regionCode: '207',
          regionName: 'Makedonska Kamenica',
        },
        {
          fullCode: 'MK-308',
          regionCode: '308',
          regionName: 'Makedonski Brod',
        },
        {
          fullCode: 'MK-607',
          regionCode: '607',
          regionName: 'Mavrovo i Rostuše',
        },
        {
          fullCode: 'MK-506',
          regionCode: '506',
          regionName: 'Mogila',
        },
        {
          fullCode: 'MK-106',
          regionCode: '106',
          regionName: 'Negotino',
        },
        {
          fullCode: 'MK-507',
          regionCode: '507',
          regionName: 'Novaci',
        },
        {
          fullCode: 'MK-408',
          regionCode: '408',
          regionName: 'Novo Selo',
        },
        {
          fullCode: 'MK-310',
          regionCode: '310',
          regionName: 'Ohrid',
        },
        {
          fullCode: 'MK-208',
          regionCode: '208',
          regionName: 'Pehčevo',
        },
        {
          fullCode: 'MK-810',
          regionCode: '810',
          regionName: 'Petrovec',
        },
        {
          fullCode: 'MK-311',
          regionCode: '311',
          regionName: 'Plasnica',
        },
        {
          fullCode: 'MK-508',
          regionCode: '508',
          regionName: 'Prilep',
        },
        {
          fullCode: 'MK-209',
          regionCode: '209',
          regionName: 'Probištip',
        },
        {
          fullCode: 'MK-409',
          regionCode: '409',
          regionName: 'Radoviš',
        },
        {
          fullCode: 'MK-705',
          regionCode: '705',
          regionName: 'Rankovce',
        },
        {
          fullCode: 'MK-509',
          regionCode: '509',
          regionName: 'Resen',
        },
        {
          fullCode: 'MK-107',
          regionCode: '107',
          regionName: 'Rosoman',
        },
        {
          fullCode: 'MK-811',
          regionCode: '811',
          regionName: 'Saraj',
        },
        {
          fullCode: 'MK-812',
          regionCode: '812',
          regionName: 'Sopište',
        },
        {
          fullCode: 'MK-706',
          regionCode: '706',
          regionName: 'Staro Nagoričane',
        },
        {
          fullCode: 'MK-312',
          regionCode: '312',
          regionName: 'Struga',
        },
        {
          fullCode: 'MK-410',
          regionCode: '410',
          regionName: 'Strumica',
        },
        {
          fullCode: 'MK-813',
          regionCode: '813',
          regionName: 'Studeničani',
        },
        {
          fullCode: 'MK-108',
          regionCode: '108',
          regionName: 'Sveti Nikole',
        },
        {
          fullCode: 'MK-211',
          regionCode: '211',
          regionName: 'Štip',
        },
        {
          fullCode: 'MK-817',
          regionCode: '817',
          regionName: 'Šuto Orizari',
        },
        {
          fullCode: 'MK-608',
          regionCode: '608',
          regionName: 'Tearce',
        },
        {
          fullCode: 'MK-609',
          regionCode: '609',
          regionName: 'Tetovo',
        },
        {
          fullCode: 'MK-403',
          regionCode: '403',
          regionName: 'Valandovo',
        },
        {
          fullCode: 'MK-404',
          regionCode: '404',
          regionName: 'Vasilevo',
        },
        {
          fullCode: 'MK-101',
          regionCode: '101',
          regionName: 'Veles',
        },
        {
          fullCode: 'MK-301',
          regionCode: '301',
          regionName: 'Vevčani',
        },
        {
          fullCode: 'MK-202',
          regionCode: '202',
          regionName: 'Vinica',
        },
        {
          fullCode: 'MK-603',
          regionCode: '603',
          regionName: 'Vrapčište',
        },
        {
          fullCode: 'MK-806',
          regionCode: '806',
          regionName: 'Zelenikovo',
        },
        {
          fullCode: 'MK-204',
          regionCode: '204',
          regionName: 'Zrnovci',
        },
        {
          fullCode: 'MK-605',
          regionCode: '605',
          regionName: 'Želino',
        },
      ],
    },
  ],
  [
    'norway',
    {
      countryCode: 'NO',
      countryName: 'Norway',
      regions: [
        {
          fullCode: 'NO-42',
          regionCode: '42',
          regionName: 'Agder',
        },
        {
          fullCode: 'NO-34',
          regionCode: '34',
          regionName: 'Inland',
        },
        {
          fullCode: 'NO-22',
          regionCode: '22',
          regionName: 'Jan Mayen',
        },
        {
          fullCode: 'NO-15',
          regionCode: '15',
          regionName: 'More and Romsdal',
        },
        {
          fullCode: 'NO-18',
          regionCode: '18',
          regionName: 'Northland',
        },
        {
          fullCode: 'NO-03',
          regionCode: '03',
          regionName: 'Oslo',
        },
        {
          fullCode: 'NO-11',
          regionCode: '11',
          regionName: 'Rogaland',
        },
        {
          fullCode: 'NO-21',
          regionCode: '21',
          regionName: 'Svalbard',
        },
        {
          fullCode: 'NO-54',
          regionCode: '54',
          regionName: 'Troms and Finnmark',
        },
        {
          fullCode: 'NO-50',
          regionCode: '50',
          regionName: 'Trondelag',
        },
        {
          fullCode: 'NO-38',
          regionCode: '38',
          regionName: 'Vestfold and Telemark',
        },
        {
          fullCode: 'NO-46',
          regionCode: '46',
          regionName: 'Westland',
        },
        {
          fullCode: 'NO-30',
          regionCode: '30',
          regionName: 'Viken',
        },
      ],
    },
  ],
  [
    'oman',
    {
      countryCode: 'OM',
      countryName: 'Oman',
      regions: [
        {
          fullCode: 'OM-DA',
          regionCode: 'DA',
          regionName: 'Interior',
        },
        {
          fullCode: 'OM-BU',
          regionCode: 'BU',
          regionName: 'Buraymi',
        },
        {
          fullCode: 'OM-WU',
          regionCode: 'WU',
          regionName: 'Central',
        },
        {
          fullCode: 'OM-ZA',
          regionCode: 'ZA',
          regionName: 'Dhahira',
        },
        {
          fullCode: 'OM-BJ',
          regionCode: 'BJ',
          regionName: 'South Batina',
        },
        {
          fullCode: 'OM-SJ',
          regionCode: 'SJ',
          regionName: 'Southeastern',
        },
        {
          fullCode: 'OM-MA',
          regionCode: 'MA',
          regionName: 'Muscat',
        },
        {
          fullCode: 'OM-MU',
          regionCode: 'MU',
          regionName: 'Musandam',
        },
        {
          fullCode: 'OM-BS',
          regionCode: 'BS',
          regionName: 'North Batina',
        },
        {
          fullCode: 'OM-SS',
          regionCode: 'SS',
          regionName: 'Northeastern',
        },
        {
          fullCode: 'OM-ZU',
          regionCode: 'ZU',
          regionName: 'Dhofar',
        },
      ],
    },
  ],
  [
    'pakistan',
    {
      countryCode: 'PK',
      countryName: 'Pakistan',
      regions: [
        {
          fullCode: 'PK-JK',
          regionCode: 'JK',
          regionName: 'Azad Jammu and Kashmir(local variant: AJ&K)',
        },
        {
          fullCode: 'PK-BA',
          regionCode: 'BA',
          regionName: 'Balochistan',
        },
        {
          fullCode: 'PK-GB',
          regionCode: 'GB',
          regionName: 'Gilgit-Baltistan',
        },
        {
          fullCode: 'PK-IS',
          regionCode: 'IS',
          regionName: 'Islamabad',
        },
        {
          fullCode: 'PK-KP',
          regionCode: 'KP',
          regionName: 'Khyber Pakhtunkhwa',
        },
        {
          fullCode: 'PK-PB',
          regionCode: 'PB',
          regionName: 'Punjab',
        },
        {
          fullCode: 'PK-SD',
          regionCode: 'SD',
          regionName: 'Sindh',
        },
        {
          fullCode: 'PK-TA',
          regionCode: 'TA',
          regionName: 'Federally Administered Tribal Areas',
        },
      ],
    },
  ],
  [
    'palau',
    {
      countryCode: 'PW',
      countryName: 'Palau',
      regions: [
        {
          fullCode: 'PW-002',
          regionCode: '002',
          regionName: 'Aimeliik',
        },
        {
          fullCode: 'PW-004',
          regionCode: '004',
          regionName: 'Airai',
        },
        {
          fullCode: 'PW-010',
          regionCode: '010',
          regionName: 'Angaur',
        },
        {
          fullCode: 'PW-050',
          regionCode: '050',
          regionName: 'Hatohobei',
        },
        {
          fullCode: 'PW-100',
          regionCode: '100',
          regionName: 'Kayangel',
        },
        {
          fullCode: 'PW-150',
          regionCode: '150',
          regionName: 'Koror',
        },
        {
          fullCode: 'PW-212',
          regionCode: '212',
          regionName: 'Melekeok',
        },
        {
          fullCode: 'PW-214',
          regionCode: '214',
          regionName: 'Ngaraard',
        },
        {
          fullCode: 'PW-218',
          regionCode: '218',
          regionName: 'Ngarchelong',
        },
        {
          fullCode: 'PW-222',
          regionCode: '222',
          regionName: 'Ngardmau',
        },
        {
          fullCode: 'PW-224',
          regionCode: '224',
          regionName: 'Ngatpang',
        },
        {
          fullCode: 'PW-226',
          regionCode: '226',
          regionName: 'Ngchesar',
        },
        {
          fullCode: 'PW-227',
          regionCode: '227',
          regionName: 'Ngeremlengui',
        },
        {
          fullCode: 'PW-228',
          regionCode: '228',
          regionName: 'Ngiwal',
        },
        {
          fullCode: 'PW-350',
          regionCode: '350',
          regionName: 'Peleliu',
        },
        {
          fullCode: 'PW-370',
          regionCode: '370',
          regionName: 'Sonsorol',
        },
      ],
    },
  ],
  [
    'palestine, state of',
    {
      countryCode: 'PS',
      countryName: 'Palestine, State of',
      regions: [
        {
          fullCode: 'PS-BTH',
          regionCode: 'BTH',
          regionName: 'Bethlehem',
        },
        {
          fullCode: 'PS-DEB',
          regionCode: 'DEB',
          regionName: 'Deir El Balah',
        },
        {
          fullCode: 'PS-GZA',
          regionCode: 'GZA',
          regionName: 'Gaza',
        },
        {
          fullCode: 'PS-HBN',
          regionCode: 'HBN',
          regionName: 'Hebron',
        },
        {
          fullCode: 'PS-JEN',
          regionCode: 'JEN',
          regionName: 'Jenin',
        },
        {
          fullCode: 'PS-JRH',
          regionCode: 'JRH',
          regionName: 'Jericho and Al Aghwar',
        },
        {
          fullCode: 'PS-JEM',
          regionCode: 'JEM',
          regionName: 'Jerusalem',
        },
        {
          fullCode: 'PS-KYS',
          regionCode: 'KYS',
          regionName: 'Khan Yunis',
        },
        {
          fullCode: 'PS-NBS',
          regionCode: 'NBS',
          regionName: 'Nablus',
        },
        {
          fullCode: 'PS-NGZ',
          regionCode: 'NGZ',
          regionName: 'North Gaza',
        },
        {
          fullCode: 'PS-QQA',
          regionCode: 'QQA',
          regionName: 'Qalqilya',
        },
        {
          fullCode: 'PS-RFH',
          regionCode: 'RFH',
          regionName: 'Rafah',
        },
        {
          fullCode: 'PS-RBH',
          regionCode: 'RBH',
          regionName: 'Ramallah',
        },
        {
          fullCode: 'PS-SLT',
          regionCode: 'SLT',
          regionName: 'Salfit',
        },
        {
          fullCode: 'PS-TBS',
          regionCode: 'TBS',
          regionName: 'Tubas',
        },
        {
          fullCode: 'PS-TKM',
          regionCode: 'TKM',
          regionName: 'Tulkarm',
        },
      ],
    },
  ],
  [
    'panama',
    {
      countryCode: 'PA',
      countryName: 'Panama',
      regions: [
        {
          fullCode: 'PA-1',
          regionCode: '1',
          regionName: 'Bocas del Toro',
        },
        {
          fullCode: 'PA-4',
          regionCode: '4',
          regionName: 'Chiriqui',
        },
        {
          fullCode: 'PA-2',
          regionCode: '2',
          regionName: 'Cocle',
        },
        {
          fullCode: 'PA-3',
          regionCode: '3',
          regionName: 'Colon',
        },
        {
          fullCode: 'PA-5',
          regionCode: '5',
          regionName: 'Darien',
        },
        {
          fullCode: 'PA-EM',
          regionCode: 'EM',
          regionName: 'Embera',
        },
        {
          fullCode: 'PA-KY',
          regionCode: 'KY',
          regionName: 'Guna Yala',
        },
        {
          fullCode: 'PA-6',
          regionCode: '6',
          regionName: 'Herrera',
        },
        {
          fullCode: 'PA-7',
          regionCode: '7',
          regionName: 'Los Santos',
        },
        {
          fullCode: 'PA-NT',
          regionCode: 'NT',
          regionName: 'Naso Tjer Di',
        },
        {
          fullCode: 'PA-NB',
          regionCode: 'NB',
          regionName: 'Ngabe-Bugle',
        },
        {
          fullCode: 'PA-8',
          regionCode: '8',
          regionName: 'Panama',
        },
        {
          fullCode: 'PA-10',
          regionCode: '10',
          regionName: 'West Panama',
        },
        {
          fullCode: 'PA-9',
          regionCode: '9',
          regionName: 'Veraguas',
        },
      ],
    },
  ],
  [
    'papua new guinea',
    {
      countryCode: 'PG',
      countryName: 'Papua New Guinea',
      regions: [
        {
          fullCode: 'PG-NSB',
          regionCode: 'NSB',
          regionName: 'Bougainville',
        },
        {
          fullCode: 'PG-CPM',
          regionCode: 'CPM',
          regionName: 'Central',
        },
        {
          fullCode: 'PG-CPK',
          regionCode: 'CPK',
          regionName: 'Chimbu',
        },
        {
          fullCode: 'PG-EBR',
          regionCode: 'EBR',
          regionName: 'East New Britain',
        },
        {
          fullCode: 'PG-ESW',
          regionCode: 'ESW',
          regionName: 'East Sepik',
        },
        {
          fullCode: 'PG-EHG',
          regionCode: 'EHG',
          regionName: 'Eastern Highlands',
        },
        {
          fullCode: 'PG-EPW',
          regionCode: 'EPW',
          regionName: 'Enga',
        },
        {
          fullCode: 'PG-GPK',
          regionCode: 'GPK',
          regionName: 'Gulf',
        },
        {
          fullCode: 'PG-HLA',
          regionCode: 'HLA',
          regionName: 'Hela',
        },
        {
          fullCode: 'PG-JWK',
          regionCode: 'JWK',
          regionName: 'Jiwaka',
        },
        {
          fullCode: 'PG-MPM',
          regionCode: 'MPM',
          regionName: 'Madang',
        },
        {
          fullCode: 'PG-MRL',
          regionCode: 'MRL',
          regionName: 'Manus',
        },
        {
          fullCode: 'PG-MBA',
          regionCode: 'MBA',
          regionName: 'Milne Bay',
        },
        {
          fullCode: 'PG-MPL',
          regionCode: 'MPL',
          regionName: 'Morobe',
        },
        {
          fullCode: 'PG-NCD',
          regionCode: 'NCD',
          regionName: 'National Capital District (Port Moresby)',
        },
        {
          fullCode: 'PG-NIK',
          regionCode: 'NIK',
          regionName: 'New Ireland',
        },
        {
          fullCode: 'PG-NPP',
          regionCode: 'NPP',
          regionName: 'Northern',
        },
        {
          fullCode: 'PG-SHM',
          regionCode: 'SHM',
          regionName: 'Southern Highlands',
        },
        {
          fullCode: 'PG-WBK',
          regionCode: 'WBK',
          regionName: 'West New Britain',
        },
        {
          fullCode: 'PG-SAN',
          regionCode: 'SAN',
          regionName: 'West Sepik',
        },
        {
          fullCode: 'PG-WPD',
          regionCode: 'WPD',
          regionName: 'Western',
        },
        {
          fullCode: 'PG-WHM',
          regionCode: 'WHM',
          regionName: 'Western Highlands',
        },
      ],
    },
  ],
  [
    'paraguay',
    {
      countryCode: 'PY',
      countryName: 'Paraguay',
      regions: [
        {
          fullCode: 'PY-16',
          regionCode: '16',
          regionName: 'Upper Paraguay',
        },
        {
          fullCode: 'PY-10',
          regionCode: '10',
          regionName: 'Upper Parana',
        },
        {
          fullCode: 'PY-13',
          regionCode: '13',
          regionName: 'Amambay',
        },
        {
          fullCode: 'PY-ASU',
          regionCode: 'ASU',
          regionName: 'Asuncion',
        },
        {
          fullCode: 'PY-19',
          regionCode: '19',
          regionName: 'Boqueron',
        },
        {
          fullCode: 'PY-5',
          regionCode: '5',
          regionName: 'Caaguazu',
        },
        {
          fullCode: 'PY-6',
          regionCode: '6',
          regionName: 'Caazapa',
        },
        {
          fullCode: 'PY-14',
          regionCode: '14',
          regionName: 'Canindeyu',
        },
        {
          fullCode: 'PY-11',
          regionCode: '11',
          regionName: 'Central',
        },
        {
          fullCode: 'PY-1',
          regionCode: '1',
          regionName: 'Concepcion',
        },
        {
          fullCode: 'PY-3',
          regionCode: '3',
          regionName: 'Cordillera',
        },
        {
          fullCode: 'PY-4',
          regionCode: '4',
          regionName: 'Guaira',
        },
        {
          fullCode: 'PY-7',
          regionCode: '7',
          regionName: 'Itapua',
        },
        {
          fullCode: 'PY-8',
          regionCode: '8',
          regionName: 'Misiones',
        },
        {
          fullCode: 'PY-12',
          regionCode: '12',
          regionName: 'Neembucu',
        },
        {
          fullCode: 'PY-9',
          regionCode: '9',
          regionName: 'Paraguari',
        },
        {
          fullCode: 'PY-15',
          regionCode: '15',
          regionName: 'President Hayes',
        },
        {
          fullCode: 'PY-2',
          regionCode: '2',
          regionName: 'Saint Peter',
        },
      ],
    },
  ],
  [
    'peru',
    {
      countryCode: 'PE',
      countryName: 'Peru',
      regions: [
        {
          fullCode: 'PE-AMA',
          regionCode: 'AMA',
          regionName: 'Amazonas',
        },
        {
          fullCode: 'PE-ANC',
          regionCode: 'ANC',
          regionName: 'Ancash',
        },
        {
          fullCode: 'PE-APU',
          regionCode: 'APU',
          regionName: 'Apurímac',
        },
        {
          fullCode: 'PE-ARE',
          regionCode: 'ARE',
          regionName: 'Arequipa',
        },
        {
          fullCode: 'PE-AYA',
          regionCode: 'AYA',
          regionName: 'Ayacucho',
        },
        {
          fullCode: 'PE-CAJ',
          regionCode: 'CAJ',
          regionName: 'Cajamarca',
        },
        {
          fullCode: 'PE-CUS',
          regionCode: 'CUS',
          regionName: 'Cusco (local variant: Cuzco)',
        },
        {
          fullCode: 'PE-CAL',
          regionCode: 'CAL',
          regionName: 'El Callao',
        },
        {
          fullCode: 'PE-HUV',
          regionCode: 'HUV',
          regionName: 'Huancavelica',
        },
        {
          fullCode: 'PE-HUC',
          regionCode: 'HUC',
          regionName: 'Huánuco',
        },
        {
          fullCode: 'PE-ICA',
          regionCode: 'ICA',
          regionName: 'Ica',
        },
        {
          fullCode: 'PE-JUN',
          regionCode: 'JUN',
          regionName: 'Junín',
        },
        {
          fullCode: 'PE-LAL',
          regionCode: 'LAL',
          regionName: 'La Libertad',
        },
        {
          fullCode: 'PE-LAM',
          regionCode: 'LAM',
          regionName: 'Lambayeque',
        },
        {
          fullCode: 'PE-LIM',
          regionCode: 'LIM',
          regionName: 'Lima',
        },
        {
          fullCode: 'PE-LOR',
          regionCode: 'LOR',
          regionName: 'Loreto',
        },
        {
          fullCode: 'PE-MDD',
          regionCode: 'MDD',
          regionName: 'Madre de Dios',
        },
        {
          fullCode: 'PE-MOQ',
          regionCode: 'MOQ',
          regionName: 'Moquegua',
        },
        {
          fullCode: 'PE-LMA',
          regionCode: 'LMA',
          regionName: 'Municipalidad Metropolitana de Lima',
        },
        {
          fullCode: 'PE-PAS',
          regionCode: 'PAS',
          regionName: 'Pasco',
        },
        {
          fullCode: 'PE-PIU',
          regionCode: 'PIU',
          regionName: 'Piura',
        },
        {
          fullCode: 'PE-PUN',
          regionCode: 'PUN',
          regionName: 'Puno',
        },
        {
          fullCode: 'PE-SAM',
          regionCode: 'SAM',
          regionName: 'San Martín',
        },
        {
          fullCode: 'PE-TAC',
          regionCode: 'TAC',
          regionName: 'Tacna',
        },
        {
          fullCode: 'PE-TUM',
          regionCode: 'TUM',
          regionName: 'Tumbes',
        },
        {
          fullCode: 'PE-UCA',
          regionCode: 'UCA',
          regionName: 'Ucayali',
        },
      ],
    },
  ],
  [
    'philippines',
    {
      countryCode: 'PH',
      countryName: 'Philippines',
      regions: [
        {
          fullCode: 'PH-14',
          regionCode: '14',
          regionName: 'Autonomous Region in Muslim Mindanao[b]',
        },
        {
          fullCode: 'PH-05',
          regionCode: '05',
          regionName: 'Bicol',
        },
        {
          fullCode: 'PH-02',
          regionCode: '02',
          regionName: 'Cagayan Valley',
        },
        {
          fullCode: 'PH-40',
          regionCode: '40',
          regionName: 'Calabarzon',
        },
        {
          fullCode: 'PH-13',
          regionCode: '13',
          regionName: 'Caraga',
        },
        {
          fullCode: 'PH-03',
          regionCode: '03',
          regionName: 'Central Luzon',
        },
        {
          fullCode: 'PH-07',
          regionCode: '07',
          regionName: 'Central Visayas',
        },
        {
          fullCode: 'PH-15',
          regionCode: '15',
          regionName: 'Cordillera Administrative Region',
        },
        {
          fullCode: 'PH-11',
          regionCode: '11',
          regionName: 'Davao',
        },
        {
          fullCode: 'PH-08',
          regionCode: '08',
          regionName: 'Eastern Visayas',
        },
        {
          fullCode: 'PH-01',
          regionCode: '01',
          regionName: 'Ilocos',
        },
        {
          fullCode: 'PH-41',
          regionCode: '41',
          regionName: 'Mimaropa',
        },
        {
          fullCode: 'PH-00',
          regionCode: '00',
          regionName: 'National Capital Region',
        },
        {
          fullCode: 'PH-10',
          regionCode: '10',
          regionName: 'Northern Mindanao',
        },
        {
          fullCode: 'PH-12',
          regionCode: '12',
          regionName: 'Soccsksargen',
        },
        {
          fullCode: 'PH-06',
          regionCode: '06',
          regionName: 'Western Visayas',
        },
        {
          fullCode: 'PH-09',
          regionCode: '09',
          regionName: 'Zamboanga Peninsula',
        },
        {
          fullCode: 'PH-ABR',
          regionCode: 'ABR',
          regionName: 'Abra',
        },
        {
          fullCode: 'PH-AGN',
          regionCode: 'AGN',
          regionName: 'Agusan del Norte',
        },
        {
          fullCode: 'PH-AGS',
          regionCode: 'AGS',
          regionName: 'Agusan del Sur',
        },
        {
          fullCode: 'PH-AKL',
          regionCode: 'AKL',
          regionName: 'Aklan',
        },
        {
          fullCode: 'PH-ALB',
          regionCode: 'ALB',
          regionName: 'Albay',
        },
        {
          fullCode: 'PH-ANT',
          regionCode: 'ANT',
          regionName: 'Antique',
        },
        {
          fullCode: 'PH-APA',
          regionCode: 'APA',
          regionName: 'Apayao',
        },
        {
          fullCode: 'PH-AUR',
          regionCode: 'AUR',
          regionName: 'Aurora',
        },
        {
          fullCode: 'PH-BAS',
          regionCode: 'BAS',
          regionName: 'Basilan',
        },
        {
          fullCode: 'PH-BAN',
          regionCode: 'BAN',
          regionName: 'Bataan',
        },
        {
          fullCode: 'PH-BTN',
          regionCode: 'BTN',
          regionName: 'Batanes',
        },
        {
          fullCode: 'PH-BTG',
          regionCode: 'BTG',
          regionName: 'Batangas',
        },
        {
          fullCode: 'PH-BEN',
          regionCode: 'BEN',
          regionName: 'Benguet',
        },
        {
          fullCode: 'PH-BIL',
          regionCode: 'BIL',
          regionName: 'Biliran',
        },
        {
          fullCode: 'PH-BOH',
          regionCode: 'BOH',
          regionName: 'Bohol',
        },
        {
          fullCode: 'PH-BUK',
          regionCode: 'BUK',
          regionName: 'Bukidnon',
        },
        {
          fullCode: 'PH-BUL',
          regionCode: 'BUL',
          regionName: 'Bulacan',
        },
        {
          fullCode: 'PH-CAG',
          regionCode: 'CAG',
          regionName: 'Cagayan',
        },
        {
          fullCode: 'PH-CAN',
          regionCode: 'CAN',
          regionName: 'Camarines Norte',
        },
        {
          fullCode: 'PH-CAS',
          regionCode: 'CAS',
          regionName: 'Camarines Sur',
        },
        {
          fullCode: 'PH-CAM',
          regionCode: 'CAM',
          regionName: 'Camiguin',
        },
        {
          fullCode: 'PH-CAP',
          regionCode: 'CAP',
          regionName: 'Capiz',
        },
        {
          fullCode: 'PH-CAT',
          regionCode: 'CAT',
          regionName: 'Catanduanes',
        },
        {
          fullCode: 'PH-CAV',
          regionCode: 'CAV',
          regionName: 'Cavite',
        },
        {
          fullCode: 'PH-CEB',
          regionCode: 'CEB',
          regionName: 'Cebu',
        },
        {
          fullCode: 'PH-NCO',
          regionCode: 'NCO',
          regionName: 'Cotabato',
        },
        {
          fullCode: 'PH-COM',
          regionCode: 'COM',
          regionName: 'Davao de Oro',
        },
        {
          fullCode: 'PH-DAV',
          regionCode: 'DAV',
          regionName: 'Davao del Norte',
        },
        {
          fullCode: 'PH-DAS',
          regionCode: 'DAS',
          regionName: 'Davao del Sur',
        },
        {
          fullCode: 'PH-DVO',
          regionCode: 'DVO',
          regionName: 'Davao Occidental',
        },
        {
          fullCode: 'PH-DAO',
          regionCode: 'DAO',
          regionName: 'Davao Oriental',
        },
        {
          fullCode: 'PH-DIN',
          regionCode: 'DIN',
          regionName: 'Dinagat Islands',
        },
        {
          fullCode: 'PH-EAS',
          regionCode: 'EAS',
          regionName: 'Eastern Samar',
        },
        {
          fullCode: 'PH-GUI',
          regionCode: 'GUI',
          regionName: 'Guimaras',
        },
        {
          fullCode: 'PH-IFU',
          regionCode: 'IFU',
          regionName: 'Ifugao',
        },
        {
          fullCode: 'PH-ILN',
          regionCode: 'ILN',
          regionName: 'Ilocos Norte',
        },
        {
          fullCode: 'PH-ILS',
          regionCode: 'ILS',
          regionName: 'Ilocos Sur',
        },
        {
          fullCode: 'PH-ILI',
          regionCode: 'ILI',
          regionName: 'Iloilo',
        },
        {
          fullCode: 'PH-ISA',
          regionCode: 'ISA',
          regionName: 'Isabela',
        },
        {
          fullCode: 'PH-KAL',
          regionCode: 'KAL',
          regionName: 'Kalinga',
        },
        {
          fullCode: 'PH-LUN',
          regionCode: 'LUN',
          regionName: 'La Union',
        },
        {
          fullCode: 'PH-LAG',
          regionCode: 'LAG',
          regionName: 'Laguna',
        },
        {
          fullCode: 'PH-LAN',
          regionCode: 'LAN',
          regionName: 'Lanao del Norte',
        },
        {
          fullCode: 'PH-LAS',
          regionCode: 'LAS',
          regionName: 'Lanao del Sur',
        },
        {
          fullCode: 'PH-LEY',
          regionCode: 'LEY',
          regionName: 'Leyte',
        },
        {
          fullCode: 'PH-MAD',
          regionCode: 'MAD',
          regionName: 'Marinduque',
        },
        {
          fullCode: 'PH-MAS',
          regionCode: 'MAS',
          regionName: 'Masbate',
        },
        {
          fullCode: 'PH-MDC',
          regionCode: 'MDC',
          regionName: 'Mindoro Occidental',
        },
        {
          fullCode: 'PH-MDR',
          regionCode: 'MDR',
          regionName: 'Mindoro Oriental',
        },
        {
          fullCode: 'PH-MGN',
          regionCode: 'MGN',
          regionName: 'Maguindanao del Norte',
        },
        {
          fullCode: 'PH-MGS',
          regionCode: 'MGS',
          regionName: 'Maguindanao del Sur',
        },
        {
          fullCode: 'PH-MSC',
          regionCode: 'MSC',
          regionName: 'Misamis Occidental',
        },
        {
          fullCode: 'PH-MSR',
          regionCode: 'MSR',
          regionName: 'Misamis Oriental',
        },
        {
          fullCode: 'PH-MOU',
          regionCode: 'MOU',
          regionName: 'Mountain Province',
        },
        {
          fullCode: 'PH-NEC',
          regionCode: 'NEC',
          regionName: 'Negros Occidental',
        },
        {
          fullCode: 'PH-NER',
          regionCode: 'NER',
          regionName: 'Negros Oriental',
        },
        {
          fullCode: 'PH-NSA',
          regionCode: 'NSA',
          regionName: 'Northern Samar',
        },
        {
          fullCode: 'PH-NUE',
          regionCode: 'NUE',
          regionName: 'Nueva Ecija',
        },
        {
          fullCode: 'PH-NUV',
          regionCode: 'NUV',
          regionName: 'Nueva Vizcaya',
        },
        {
          fullCode: 'PH-PLW',
          regionCode: 'PLW',
          regionName: 'Palawan',
        },
        {
          fullCode: 'PH-PAM',
          regionCode: 'PAM',
          regionName: 'Pampanga',
        },
        {
          fullCode: 'PH-PAN',
          regionCode: 'PAN',
          regionName: 'Pangasinan',
        },
        {
          fullCode: 'PH-QUE',
          regionCode: 'QUE',
          regionName: 'Quezon',
        },
        {
          fullCode: 'PH-QUI',
          regionCode: 'QUI',
          regionName: 'Quirino',
        },
        {
          fullCode: 'PH-RIZ',
          regionCode: 'RIZ',
          regionName: 'Rizal',
        },
        {
          fullCode: 'PH-ROM',
          regionCode: 'ROM',
          regionName: 'Romblon',
        },
        {
          fullCode: 'PH-WSA',
          regionCode: 'WSA',
          regionName: 'Samar (local variant: Western Samar)',
        },
        {
          fullCode: 'PH-SAR',
          regionCode: 'SAR',
          regionName: 'Sarangani',
        },
        {
          fullCode: 'PH-SIG',
          regionCode: 'SIG',
          regionName: 'Siquijor',
        },
        {
          fullCode: 'PH-SOR',
          regionCode: 'SOR',
          regionName: 'Sorsogon',
        },
        {
          fullCode: 'PH-SCO',
          regionCode: 'SCO',
          regionName: 'South Cotabato',
        },
        {
          fullCode: 'PH-SLE',
          regionCode: 'SLE',
          regionName: 'Southern Leyte',
        },
        {
          fullCode: 'PH-SUK',
          regionCode: 'SUK',
          regionName: 'Sultan Kudarat',
        },
        {
          fullCode: 'PH-SLU',
          regionCode: 'SLU',
          regionName: 'Sulu',
        },
        {
          fullCode: 'PH-SUN',
          regionCode: 'SUN',
          regionName: 'Surigao del Norte',
        },
        {
          fullCode: 'PH-SUR',
          regionCode: 'SUR',
          regionName: 'Surigao del Sur',
        },
        {
          fullCode: 'PH-TAR',
          regionCode: 'TAR',
          regionName: 'Tarlac',
        },
        {
          fullCode: 'PH-TAW',
          regionCode: 'TAW',
          regionName: 'Tawi-Tawi',
        },
        {
          fullCode: 'PH-ZMB',
          regionCode: 'ZMB',
          regionName: 'Zambales',
        },
        {
          fullCode: 'PH-ZAN',
          regionCode: 'ZAN',
          regionName: 'Zamboanga del Norte',
        },
        {
          fullCode: 'PH-ZAS',
          regionCode: 'ZAS',
          regionName: 'Zamboanga del Sur',
        },
        {
          fullCode: 'PH-ZSI',
          regionCode: 'ZSI',
          regionName: 'Zamboanga Sibugay',
        },
      ],
    },
  ],
  [
    'poland',
    {
      countryCode: 'PL',
      countryName: 'Poland',
      regions: [
        {
          fullCode: 'PL-DS',
          regionCode: 'DS',
          regionName: 'Lower Silesian',
        },
        {
          fullCode: 'PL-KP',
          regionCode: 'KP',
          regionName: 'Kuyavian-Pomeranian',
        },
        {
          fullCode: 'PL-LB',
          regionCode: 'LB',
          regionName: 'Lubusz',
        },
        {
          fullCode: 'PL-LD',
          regionCode: 'LD',
          regionName: 'Łódź',
        },
        {
          fullCode: 'PL-LU',
          regionCode: 'LU',
          regionName: 'Lublin',
        },
        {
          fullCode: 'PL-MA',
          regionCode: 'MA',
          regionName: 'Lesser Poland',
        },
        {
          fullCode: 'PL-MZ',
          regionCode: 'MZ',
          regionName: 'Masovian',
        },
        {
          fullCode: 'PL-OP',
          regionCode: 'OP',
          regionName: 'Opole',
        },
        {
          fullCode: 'PL-PD',
          regionCode: 'PD',
          regionName: 'Podlaskie',
        },
        {
          fullCode: 'PL-PK',
          regionCode: 'PK',
          regionName: 'Subcarpathian',
        },
        {
          fullCode: 'PL-PM',
          regionCode: 'PM',
          regionName: 'Pomeranian',
        },
        {
          fullCode: 'PL-SK',
          regionCode: 'SK',
          regionName: 'Świętokrzyskie',
        },
        {
          fullCode: 'PL-SL',
          regionCode: 'SL',
          regionName: 'Silesian',
        },
        {
          fullCode: 'PL-WN',
          regionCode: 'WN',
          regionName: 'Warmian-Masurian',
        },
        {
          fullCode: 'PL-WP',
          regionCode: 'WP',
          regionName: 'Greater Poland',
        },
        {
          fullCode: 'PL-ZP',
          regionCode: 'ZP',
          regionName: 'West Pomeranian',
        },
      ],
    },
  ],
  [
    'portugal',
    {
      countryCode: 'PT',
      countryName: 'Portugal',
      regions: [
        {
          fullCode: 'PT-01',
          regionCode: '01',
          regionName: 'Aveiro',
        },
        {
          fullCode: 'PT-02',
          regionCode: '02',
          regionName: 'Beja',
        },
        {
          fullCode: 'PT-03',
          regionCode: '03',
          regionName: 'Braga',
        },
        {
          fullCode: 'PT-04',
          regionCode: '04',
          regionName: 'Bragança',
        },
        {
          fullCode: 'PT-05',
          regionCode: '05',
          regionName: 'White Castle',
        },
        {
          fullCode: 'PT-06',
          regionCode: '06',
          regionName: 'Coimbra',
        },
        {
          fullCode: 'PT-07',
          regionCode: '07',
          regionName: 'Evora',
        },
        {
          fullCode: 'PT-08',
          regionCode: '08',
          regionName: 'Faro',
        },
        {
          fullCode: 'PT-09',
          regionCode: '09',
          regionName: 'Guarda',
        },
        {
          fullCode: 'PT-10',
          regionCode: '10',
          regionName: 'Leiria',
        },
        {
          fullCode: 'PT-11',
          regionCode: '11',
          regionName: 'Lisbon',
        },
        {
          fullCode: 'PT-12',
          regionCode: '12',
          regionName: 'Portalegre',
        },
        {
          fullCode: 'PT-13',
          regionCode: '13',
          regionName: 'Porto',
        },
        {
          fullCode: 'PT-30',
          regionCode: '30',
          regionName: 'Madeira',
        },
        {
          fullCode: 'PT-20',
          regionCode: '20',
          regionName: 'Azores',
        },
        {
          fullCode: 'PT-14',
          regionCode: '14',
          regionName: 'Santarém',
        },
        {
          fullCode: 'PT-15',
          regionCode: '15',
          regionName: 'Setúbal',
        },
        {
          fullCode: 'PT-16',
          regionCode: '16',
          regionName: 'Viana do Castelo',
        },
        {
          fullCode: 'PT-17',
          regionCode: '17',
          regionName: 'Royal Town',
        },
        {
          fullCode: 'PT-18',
          regionCode: '18',
          regionName: 'Viseu',
        },
      ],
    },
  ],
  [
    'qatar',
    {
      countryCode: 'QA',
      countryName: 'Qatar',
      regions: [
        {
          fullCode: 'QA-DA',
          regionCode: 'DA',
          regionName: 'Doha',
        },
        {
          fullCode: 'QA-KH',
          regionCode: 'KH',
          regionName: 'Khor',
        },
        {
          fullCode: 'QA-WA',
          regionCode: 'WA',
          regionName: 'Wakra',
        },
        {
          fullCode: 'QA-RA',
          regionCode: 'RA',
          regionName: 'Rayyan',
        },
        {
          fullCode: 'QA-MS',
          regionCode: 'MS',
          regionName: 'North',
        },
        {
          fullCode: 'QA-SH',
          regionCode: 'SH',
          regionName: 'Shahaniya',
        },
        {
          fullCode: 'QA-ZA',
          regionCode: 'ZA',
          regionName: 'Daayen',
        },
        {
          fullCode: 'QA-US',
          regionCode: 'US',
          regionName: 'Umm Salal',
        },
      ],
    },
  ],
  [
    'romania',
    {
      countryCode: 'RO',
      countryName: 'Romania',
      regions: [
        {
          fullCode: 'RO-AB',
          regionCode: 'AB',
          regionName: 'Alba',
        },
        {
          fullCode: 'RO-AR',
          regionCode: 'AR',
          regionName: 'Arad',
        },
        {
          fullCode: 'RO-AG',
          regionCode: 'AG',
          regionName: 'Argeș',
        },
        {
          fullCode: 'RO-BC',
          regionCode: 'BC',
          regionName: 'Bacău',
        },
        {
          fullCode: 'RO-BH',
          regionCode: 'BH',
          regionName: 'Bihor',
        },
        {
          fullCode: 'RO-BN',
          regionCode: 'BN',
          regionName: 'Bistrița-Năsăud',
        },
        {
          fullCode: 'RO-BT',
          regionCode: 'BT',
          regionName: 'Botoșani',
        },
        {
          fullCode: 'RO-BV',
          regionCode: 'BV',
          regionName: 'Brașov',
        },
        {
          fullCode: 'RO-BR',
          regionCode: 'BR',
          regionName: 'Brăila',
        },
        {
          fullCode: 'RO-B',
          regionCode: 'B',
          regionName: 'București',
        },
        {
          fullCode: 'RO-BZ',
          regionCode: 'BZ',
          regionName: 'Buzău',
        },
        {
          fullCode: 'RO-CS',
          regionCode: 'CS',
          regionName: 'Caraș-Severin',
        },
        {
          fullCode: 'RO-CL',
          regionCode: 'CL',
          regionName: 'Călărași',
        },
        {
          fullCode: 'RO-CJ',
          regionCode: 'CJ',
          regionName: 'Cluj',
        },
        {
          fullCode: 'RO-CT',
          regionCode: 'CT',
          regionName: 'Constanța',
        },
        {
          fullCode: 'RO-CV',
          regionCode: 'CV',
          regionName: 'Covasna',
        },
        {
          fullCode: 'RO-DB',
          regionCode: 'DB',
          regionName: 'Dâmbovița',
        },
        {
          fullCode: 'RO-DJ',
          regionCode: 'DJ',
          regionName: 'Dolj',
        },
        {
          fullCode: 'RO-GL',
          regionCode: 'GL',
          regionName: 'Galați',
        },
        {
          fullCode: 'RO-GR',
          regionCode: 'GR',
          regionName: 'Giurgiu',
        },
        {
          fullCode: 'RO-GJ',
          regionCode: 'GJ',
          regionName: 'Gorj',
        },
        {
          fullCode: 'RO-HR',
          regionCode: 'HR',
          regionName: 'Harghita',
        },
        {
          fullCode: 'RO-HD',
          regionCode: 'HD',
          regionName: 'Hunedoara',
        },
        {
          fullCode: 'RO-IL',
          regionCode: 'IL',
          regionName: 'Ialomița',
        },
        {
          fullCode: 'RO-IS',
          regionCode: 'IS',
          regionName: 'Iași',
        },
        {
          fullCode: 'RO-IF',
          regionCode: 'IF',
          regionName: 'Ilfov',
        },
        {
          fullCode: 'RO-MM',
          regionCode: 'MM',
          regionName: 'Maramureș',
        },
        {
          fullCode: 'RO-MH',
          regionCode: 'MH',
          regionName: 'Mehedinți',
        },
        {
          fullCode: 'RO-MS',
          regionCode: 'MS',
          regionName: 'Mureș',
        },
        {
          fullCode: 'RO-NT',
          regionCode: 'NT',
          regionName: 'Neamț',
        },
        {
          fullCode: 'RO-OT',
          regionCode: 'OT',
          regionName: 'Olt',
        },
        {
          fullCode: 'RO-PH',
          regionCode: 'PH',
          regionName: 'Prahova',
        },
        {
          fullCode: 'RO-SM',
          regionCode: 'SM',
          regionName: 'Satu Mare',
        },
        {
          fullCode: 'RO-SJ',
          regionCode: 'SJ',
          regionName: 'Sălaj',
        },
        {
          fullCode: 'RO-SB',
          regionCode: 'SB',
          regionName: 'Sibiu',
        },
        {
          fullCode: 'RO-SV',
          regionCode: 'SV',
          regionName: 'Suceava',
        },
        {
          fullCode: 'RO-TR',
          regionCode: 'TR',
          regionName: 'Teleorman',
        },
        {
          fullCode: 'RO-TM',
          regionCode: 'TM',
          regionName: 'Timiș',
        },
        {
          fullCode: 'RO-TL',
          regionCode: 'TL',
          regionName: 'Tulcea',
        },
        {
          fullCode: 'RO-VS',
          regionCode: 'VS',
          regionName: 'Vaslui',
        },
        {
          fullCode: 'RO-VL',
          regionCode: 'VL',
          regionName: 'Vâlcea',
        },
        {
          fullCode: 'RO-VN',
          regionCode: 'VN',
          regionName: 'Vrancea',
        },
      ],
    },
  ],
  [
    'russian federation',
    {
      countryCode: 'RU',
      countryName: 'Russian Federation',
      regions: [
        {
          fullCode: 'RU-AD',
          regionCode: 'AD',
          regionName: 'Adygea',
        },
        {
          fullCode: 'RU-AL',
          regionCode: 'AL',
          regionName: 'Gorno-Altai',
        },
        {
          fullCode: 'RU-ALT',
          regionCode: 'ALT',
          regionName: 'Altai',
        },
        {
          fullCode: 'RU-AMU',
          regionCode: 'AMU',
          regionName: 'Amur',
        },
        {
          fullCode: 'RU-ARK',
          regionCode: 'ARK',
          regionName: 'Arkhangelsk',
        },
        {
          fullCode: 'RU-AST',
          regionCode: 'AST',
          regionName: 'Astrakhan',
        },
        {
          fullCode: 'RU-BA',
          regionCode: 'BA',
          regionName: 'Bashkortostan',
        },
        {
          fullCode: 'RU-BEL',
          regionCode: 'BEL',
          regionName: 'Belgorod',
        },
        {
          fullCode: 'RU-BRY',
          regionCode: 'BRY',
          regionName: 'Bryansk',
        },
        {
          fullCode: 'RU-BU',
          regionCode: 'BU',
          regionName: 'Buryatia',
        },
        {
          fullCode: 'RU-CE',
          regionCode: 'CE',
          regionName: 'Chechnya',
        },
        {
          fullCode: 'RU-CHE',
          regionCode: 'CHE',
          regionName: 'Chelyabinsk',
        },
        {
          fullCode: 'RU-CHU',
          regionCode: 'CHU',
          regionName: 'Chukotka',
        },
        {
          fullCode: 'RU-CU',
          regionCode: 'CU',
          regionName: 'Chuvashia',
        },
        {
          fullCode: 'RU-DA',
          regionCode: 'DA',
          regionName: 'Dagestan',
        },
        {
          fullCode: 'RU-IN',
          regionCode: 'IN',
          regionName: 'Ingushetia',
        },
        {
          fullCode: 'RU-IRK',
          regionCode: 'IRK',
          regionName: 'Irkutsk',
        },
        {
          fullCode: 'RU-IVA',
          regionCode: 'IVA',
          regionName: 'Ivanovo',
        },
        {
          fullCode: 'RU-KB',
          regionCode: 'KB',
          regionName: 'Kabardino-Balkaria',
        },
        {
          fullCode: 'RU-KGD',
          regionCode: 'KGD',
          regionName: 'Kaliningrad',
        },
        {
          fullCode: 'RU-KL',
          regionCode: 'KL',
          regionName: 'Kalmykia',
        },
        {
          fullCode: 'RU-KLU',
          regionCode: 'KLU',
          regionName: 'Kaluga',
        },
        {
          fullCode: 'RU-KAM',
          regionCode: 'KAM',
          regionName: 'Kamchatka',
        },
        {
          fullCode: 'RU-KC',
          regionCode: 'KC',
          regionName: 'Karachay-Cherkessia',
        },
        {
          fullCode: 'RU-KR',
          regionCode: 'KR',
          regionName: 'Karelia',
        },
        {
          fullCode: 'RU-KEM',
          regionCode: 'KEM',
          regionName: 'Kemerovo',
        },
        {
          fullCode: 'RU-KHA',
          regionCode: 'KHA',
          regionName: 'Khabarovsk',
        },
        {
          fullCode: 'RU-KK',
          regionCode: 'KK',
          regionName: 'Khakassia',
        },
        {
          fullCode: 'RU-KHM',
          regionCode: 'KHM',
          regionName: 'Yugoria',
        },
        {
          fullCode: 'RU-KIR',
          regionCode: 'KIR',
          regionName: 'Kirov',
        },
        {
          fullCode: 'RU-KO',
          regionCode: 'KO',
          regionName: 'Komi',
        },
        {
          fullCode: 'RU-KOS',
          regionCode: 'KOS',
          regionName: 'Kostroma',
        },
        {
          fullCode: 'RU-KDA',
          regionCode: 'KDA',
          regionName: 'Krasnodar',
        },
        {
          fullCode: 'RU-KYA',
          regionCode: 'KYA',
          regionName: 'Krasnoyarsk',
        },
        {
          fullCode: 'RU-KGN',
          regionCode: 'KGN',
          regionName: 'Kurgan',
        },
        {
          fullCode: 'RU-KRS',
          regionCode: 'KRS',
          regionName: 'Kursk',
        },
        {
          fullCode: 'RU-LEN',
          regionCode: 'LEN',
          regionName: 'Leningrad',
        },
        {
          fullCode: 'RU-LIP',
          regionCode: 'LIP',
          regionName: 'Lipetsk',
        },
        {
          fullCode: 'RU-MAG',
          regionCode: 'MAG',
          regionName: 'Magadan',
        },
        {
          fullCode: 'RU-ME',
          regionCode: 'ME',
          regionName: 'Mari El',
        },
        {
          fullCode: 'RU-MO',
          regionCode: 'MO',
          regionName: 'Mordovia',
        },
        {
          fullCode: 'RU-MOS',
          regionCode: 'MOS',
          regionName: 'Moscow',
        },
        {
          fullCode: 'RU-MOW',
          regionCode: 'MOW',
          regionName: 'Moscow City',
        },
        {
          fullCode: 'RU-MUR',
          regionCode: 'MUR',
          regionName: 'Murmansk',
        },
        {
          fullCode: 'RU-NEN',
          regionCode: 'NEN',
          regionName: 'Nenetsia',
        },
        {
          fullCode: 'RU-NIZ',
          regionCode: 'NIZ',
          regionName: 'Nizhny Novgorod',
        },
        {
          fullCode: 'RU-NGR',
          regionCode: 'NGR',
          regionName: 'Novgorod',
        },
        {
          fullCode: 'RU-NVS',
          regionCode: 'NVS',
          regionName: 'Novosibirsk',
        },
        {
          fullCode: 'RU-OMS',
          regionCode: 'OMS',
          regionName: 'Omsk',
        },
        {
          fullCode: 'RU-ORE',
          regionCode: 'ORE',
          regionName: 'Orenburg',
        },
        {
          fullCode: 'RU-ORL',
          regionCode: 'ORL',
          regionName: 'Oryol',
        },
        {
          fullCode: 'RU-PNZ',
          regionCode: 'PNZ',
          regionName: 'Penza',
        },
        {
          fullCode: 'RU-PER',
          regionCode: 'PER',
          regionName: 'Perm',
        },
        {
          fullCode: 'RU-PRI',
          regionCode: 'PRI',
          regionName: 'Primorye',
        },
        {
          fullCode: 'RU-PSK',
          regionCode: 'PSK',
          regionName: 'Pskov',
        },
        {
          fullCode: 'RU-ROS',
          regionCode: 'ROS',
          regionName: 'Rostov',
        },
        {
          fullCode: 'RU-RYA',
          regionCode: 'RYA',
          regionName: 'Ryazan',
        },
        {
          fullCode: 'RU-SA',
          regionCode: 'SA',
          regionName: 'Yakutia',
        },
        {
          fullCode: 'RU-SAK',
          regionCode: 'SAK',
          regionName: 'Sakhalin',
        },
        {
          fullCode: 'RU-SAM',
          regionCode: 'SAM',
          regionName: 'Samara',
        },
        {
          fullCode: 'RU-SPE',
          regionCode: 'SPE',
          regionName: 'Saint Petersburg',
        },
        {
          fullCode: 'RU-SAR',
          regionCode: 'SAR',
          regionName: 'Saratov',
        },
        {
          fullCode: 'RU-SE',
          regionCode: 'SE',
          regionName: 'North Ossetia',
        },
        {
          fullCode: 'RU-SMO',
          regionCode: 'SMO',
          regionName: 'Smolensk',
        },
        {
          fullCode: 'RU-STA',
          regionCode: 'STA',
          regionName: 'Stavropol',
        },
        {
          fullCode: 'RU-SVE',
          regionCode: 'SVE',
          regionName: 'Sverdlovsk',
        },
        {
          fullCode: 'RU-TAM',
          regionCode: 'TAM',
          regionName: 'Tambov',
        },
        {
          fullCode: 'RU-TA',
          regionCode: 'TA',
          regionName: 'Tatarstan',
        },
        {
          fullCode: 'RU-TOM',
          regionCode: 'TOM',
          regionName: 'Tomsk',
        },
        {
          fullCode: 'RU-TUL',
          regionCode: 'TUL',
          regionName: 'Tula',
        },
        {
          fullCode: 'RU-TVE',
          regionCode: 'TVE',
          regionName: 'Tver',
        },
        {
          fullCode: 'RU-TYU',
          regionCode: 'TYU',
          regionName: 'Tyumen',
        },
        {
          fullCode: 'RU-TY',
          regionCode: 'TY',
          regionName: 'Tuva',
        },
        {
          fullCode: 'RU-UD',
          regionCode: 'UD',
          regionName: 'Udmurtia',
        },
        {
          fullCode: 'RU-ULY',
          regionCode: 'ULY',
          regionName: 'Ulyanovsk',
        },
        {
          fullCode: 'RU-VLA',
          regionCode: 'VLA',
          regionName: 'Vladimir',
        },
        {
          fullCode: 'RU-VGG',
          regionCode: 'VGG',
          regionName: 'Volgograd',
        },
        {
          fullCode: 'RU-VLG',
          regionCode: 'VLG',
          regionName: 'Vologda',
        },
        {
          fullCode: 'RU-VOR',
          regionCode: 'VOR',
          regionName: 'Voronezh',
        },
        {
          fullCode: 'RU-YAN',
          regionCode: 'YAN',
          regionName: 'Yamalia',
        },
        {
          fullCode: 'RU-YAR',
          regionCode: 'YAR',
          regionName: 'Yaroslavl',
        },
        {
          fullCode: 'RU-YEV',
          regionCode: 'YEV',
          regionName: 'Jewish Autonomous Oblast',
        },
        {
          fullCode: 'RU-ZAB',
          regionCode: 'ZAB',
          regionName: 'Transbaikalia',
        },
      ],
    },
  ],
  [
    'rwanda',
    {
      countryCode: 'RW',
      countryName: 'Rwanda',
      regions: [
        {
          fullCode: 'RW-01',
          regionCode: '01',
          regionName: 'City of Kigali',
        },
        {
          fullCode: 'RW-02',
          regionCode: '02',
          regionName: 'Eastern',
        },
        {
          fullCode: 'RW-03',
          regionCode: '03',
          regionName: 'Northern',
        },
        {
          fullCode: 'RW-05',
          regionCode: '05',
          regionName: 'Southern',
        },
        {
          fullCode: 'RW-04',
          regionCode: '04',
          regionName: 'Western',
        },
        {
          fullCode: 'RW-C',
          regionCode: 'C',
          regionName: 'Butare',
        },
        {
          fullCode: 'RW-v',
          regionCode: 'v',
          regionName: 'Byumba',
        },
        {
          fullCode: 'RW-E',
          regionCode: 'E',
          regionName: 'Cyangugu',
        },
        {
          fullCode: 'RW-D',
          regionCode: 'D',
          regionName: 'Gikongoro',
        },
        {
          fullCode: 'RW-G',
          regionCode: 'G',
          regionName: 'Gisenyi',
        },
        {
          fullCode: 'RW-B',
          regionCode: 'B',
          regionName: 'Gitarama',
        },
        {
          fullCode: 'RW-J',
          regionCode: 'J',
          regionName: 'Kibungo',
        },
        {
          fullCode: 'RW-F',
          regionCode: 'F',
          regionName: 'Kibuye',
        },
        {
          fullCode: 'RW-K',
          regionCode: 'K',
          regionName: 'Kigali-Rural',
        },
        {
          fullCode: 'RW-L',
          regionCode: 'L',
          regionName: 'Kigali-Ville',
        },
        {
          fullCode: 'RW-M',
          regionCode: 'M',
          regionName: 'Mutara',
        },
        {
          fullCode: 'RW-H',
          regionCode: 'H',
          regionName: 'Ruhengeri',
        },
      ],
    },
  ],
  [
    'saint helena, ascension and tristan da cunha',
    {
      countryCode: 'SH',
      countryName: 'Saint Helena, Ascension and Tristan da Cunha',
      regions: [
        {
          fullCode: 'SH-AC',
          regionCode: 'AC',
          regionName: 'Ascension',
        },
        {
          fullCode: 'SH-HL',
          regionCode: 'HL',
          regionName: 'Saint Helena',
        },
        {
          fullCode: 'SH-TA',
          regionCode: 'TA',
          regionName: 'Tristan da Cunha',
        },
        {
          fullCode: 'SH-HL',
          regionCode: 'HL',
          regionName: 'Saint Helena',
        },
        {
          fullCode: 'SH-AC',
          regionCode: 'AC',
          regionName: 'Ascension',
        },
        {
          fullCode: 'SH-TA',
          regionCode: 'TA',
          regionName: 'Tristan da Cunha',
        },
      ],
    },
  ],
  [
    'saint kitts and nevis',
    {
      countryCode: 'KN',
      countryName: 'Saint Kitts and Nevis',
      regions: [
        {
          fullCode: 'KN-K',
          regionCode: 'K',
          regionName: 'Saint Kitts',
        },
        {
          fullCode: 'KN-N',
          regionCode: 'N',
          regionName: 'Nevis',
        },
        {
          fullCode: 'KN-01',
          regionCode: '01',
          regionName: 'Christ Church Nichola Town',
        },
        {
          fullCode: 'KN-02',
          regionCode: '02',
          regionName: 'Saint Anne Sandy Point',
        },
        {
          fullCode: 'KN-03',
          regionCode: '03',
          regionName: 'Saint George Basseterre',
        },
        {
          fullCode: 'KN-04',
          regionCode: '04',
          regionName: 'Saint George Gingerland',
        },
        {
          fullCode: 'KN-05',
          regionCode: '05',
          regionName: 'Saint James Windward',
        },
        {
          fullCode: 'KN-06',
          regionCode: '06',
          regionName: 'Saint John Capisterre',
        },
        {
          fullCode: 'KN-07',
          regionCode: '07',
          regionName: 'Saint John Figtree',
        },
        {
          fullCode: 'KN-08',
          regionCode: '08',
          regionName: 'Saint Mary Cayon',
        },
        {
          fullCode: 'KN-09',
          regionCode: '09',
          regionName: 'Saint Paul Capisterre',
        },
        {
          fullCode: 'KN-10',
          regionCode: '10',
          regionName: 'Saint Paul Charlestown',
        },
        {
          fullCode: 'KN-11',
          regionCode: '11',
          regionName: 'Saint Peter Basseterre',
        },
        {
          fullCode: 'KN-12',
          regionCode: '12',
          regionName: 'Saint Thomas Lowland',
        },
        {
          fullCode: 'KN-13',
          regionCode: '13',
          regionName: 'Saint Thomas Middle Island',
        },
        {
          fullCode: 'KN-15',
          regionCode: '15',
          regionName: 'Trinity Palmetto Point',
        },
      ],
    },
  ],
  [
    'saint lucia',
    {
      countryCode: 'LC',
      countryName: 'Saint Lucia',
      regions: [
        {
          fullCode: 'LC-01',
          regionCode: '01',
          regionName: 'Anse la Raye',
        },
        {
          fullCode: 'LC-12',
          regionCode: '12',
          regionName: 'Canaries',
        },
        {
          fullCode: 'LC-02',
          regionCode: '02',
          regionName: 'Castries',
        },
        {
          fullCode: 'LC-03',
          regionCode: '03',
          regionName: 'Choiseul',
        },
        {
          fullCode: 'LC-05',
          regionCode: '05',
          regionName: 'Dennery',
        },
        {
          fullCode: 'LC-06',
          regionCode: '06',
          regionName: 'Gros Islet',
        },
        {
          fullCode: 'LC-07',
          regionCode: '07',
          regionName: 'Laborie',
        },
        {
          fullCode: 'LC-08',
          regionCode: '08',
          regionName: 'Micoud',
        },
        {
          fullCode: 'LC-10',
          regionCode: '10',
          regionName: 'Soufrière',
        },
        {
          fullCode: 'LC-11',
          regionCode: '11',
          regionName: 'Vieux Fort',
        },
      ],
    },
  ],
  [
    'saint vincent and the grenadines',
    {
      countryCode: 'VC',
      countryName: 'Saint Vincent and the Grenadines',
      regions: [
        {
          fullCode: 'VC-01',
          regionCode: '01',
          regionName: 'Charlotte',
        },
        {
          fullCode: 'VC-06',
          regionCode: '06',
          regionName: 'Grenadines',
        },
        {
          fullCode: 'VC-02',
          regionCode: '02',
          regionName: 'Saint Andrew',
        },
        {
          fullCode: 'VC-03',
          regionCode: '03',
          regionName: 'Saint David',
        },
        {
          fullCode: 'VC-04',
          regionCode: '04',
          regionName: 'Saint George',
        },
        {
          fullCode: 'VC-05',
          regionCode: '05',
          regionName: 'Saint Patrick',
        },
      ],
    },
  ],
  [
    'samoa',
    {
      countryCode: 'WS',
      countryName: 'Samoa',
      regions: [
        {
          fullCode: 'WS-AA',
          regionCode: 'AA',
          regionName: "A\\'ana",
        },
        {
          fullCode: 'WS-AL',
          regionCode: 'AL',
          regionName: 'Aiga-i-le-Tai',
        },
        {
          fullCode: 'WS-AT',
          regionCode: 'AT',
          regionName: 'Atua',
        },
        {
          fullCode: 'WS-FA',
          regionCode: 'FA',
          regionName: "Fa\\'asaleleaga",
        },
        {
          fullCode: 'WS-GE',
          regionCode: 'GE',
          regionName: "Gaga\\'emauga",
        },
        {
          fullCode: 'WS-GI',
          regionCode: 'GI',
          regionName: 'Gagaifomauga',
        },
        {
          fullCode: 'WS-PA',
          regionCode: 'PA',
          regionName: 'Palauli',
        },
        {
          fullCode: 'WS-SA',
          regionCode: 'SA',
          regionName: "Satupa\\'itea",
        },
        {
          fullCode: 'WS-TU',
          regionCode: 'TU',
          regionName: 'Tuamasaga',
        },
        {
          fullCode: 'WS-VF',
          regionCode: 'VF',
          regionName: "Va\\'a-o-Fonoti",
        },
        {
          fullCode: 'WS-VS',
          regionCode: 'VS',
          regionName: 'Vaisigano',
        },
      ],
    },
  ],
  [
    'san marino',
    {
      countryCode: 'SM',
      countryName: 'San Marino',
      regions: [
        {
          fullCode: 'SM-01',
          regionCode: '01',
          regionName: 'Acquaviva',
        },
        {
          fullCode: 'SM-06',
          regionCode: '06',
          regionName: 'Borgo Maggiore',
        },
        {
          fullCode: 'SM-02',
          regionCode: '02',
          regionName: 'Chiesanuova',
        },
        {
          fullCode: 'SM-07',
          regionCode: '07',
          regionName: 'Città di San Marino(local variant is San Marino)',
        },
        {
          fullCode: 'SM-03',
          regionCode: '03',
          regionName: 'Domagnano',
        },
        {
          fullCode: 'SM-04',
          regionCode: '04',
          regionName: 'Faetano',
        },
        {
          fullCode: 'SM-05',
          regionCode: '05',
          regionName: 'Fiorentino',
        },
        {
          fullCode: 'SM-08',
          regionCode: '08',
          regionName: 'Montegiardino',
        },
        {
          fullCode: 'SM-09',
          regionCode: '09',
          regionName: 'Serravalle',
        },
      ],
    },
  ],
  [
    'sao tome and principe',
    {
      countryCode: 'ST',
      countryName: 'Sao Tome and Principe',
      regions: [
        {
          fullCode: 'ST-01',
          regionCode: '01',
          regionName: 'Água Grande',
        },
        {
          fullCode: 'ST-02',
          regionCode: '02',
          regionName: 'Cantagalo',
        },
        {
          fullCode: 'ST-03',
          regionCode: '03',
          regionName: 'Caué',
        },
        {
          fullCode: 'ST-04',
          regionCode: '04',
          regionName: 'Lembá',
        },
        {
          fullCode: 'ST-05',
          regionCode: '05',
          regionName: 'Lobata',
        },
        {
          fullCode: 'ST-06',
          regionCode: '06',
          regionName: 'Mé-Zóchi',
        },
        {
          fullCode: 'ST-P',
          regionCode: 'P',
          regionName: 'Príncipe',
        },
      ],
    },
  ],
  [
    'saudi arabia',
    {
      countryCode: 'SA',
      countryName: 'Saudi Arabia',
      regions: [
        {
          fullCode: 'SA-14',
          regionCode: '14',
          regionName: 'Asir',
        },
        {
          fullCode: 'SA-11',
          regionCode: '11',
          regionName: 'Baha',
        },
        {
          fullCode: 'SA-08',
          regionCode: '08',
          regionName: 'Northern Borders',
        },
        {
          fullCode: 'SA-12',
          regionCode: '12',
          regionName: 'Jouf',
        },
        {
          fullCode: 'SA-03',
          regionCode: '03',
          regionName: 'Medina',
        },
        {
          fullCode: 'SA-05',
          regionCode: '05',
          regionName: 'Qassim',
        },
        {
          fullCode: 'SA-01',
          regionCode: '01',
          regionName: 'Riyadh',
        },
        {
          fullCode: 'SA-04',
          regionCode: '04',
          regionName: 'Eastern',
        },
        {
          fullCode: 'SA-06',
          regionCode: '06',
          regionName: 'Ha’il',
        },
        {
          fullCode: 'SA-09',
          regionCode: '09',
          regionName: 'Jizan',
        },
        {
          fullCode: 'SA-02',
          regionCode: '02',
          regionName: 'Mecca',
        },
        {
          fullCode: 'SA-10',
          regionCode: '10',
          regionName: 'Najran',
        },
        {
          fullCode: 'SA-07',
          regionCode: '07',
          regionName: 'Tabuk',
        },
      ],
    },
  ],
  [
    'senegal',
    {
      countryCode: 'SN',
      countryName: 'Senegal',
      regions: [
        {
          fullCode: 'SN-DK',
          regionCode: 'DK',
          regionName: 'Dakar',
        },
        {
          fullCode: 'SN-DB',
          regionCode: 'DB',
          regionName: 'Diourbel',
        },
        {
          fullCode: 'SN-FK',
          regionCode: 'FK',
          regionName: 'Fatick',
        },
        {
          fullCode: 'SN-KA',
          regionCode: 'KA',
          regionName: 'Kaffrine',
        },
        {
          fullCode: 'SN-KL',
          regionCode: 'KL',
          regionName: 'Kaolack',
        },
        {
          fullCode: 'SN-KE',
          regionCode: 'KE',
          regionName: 'Kédougou',
        },
        {
          fullCode: 'SN-KD',
          regionCode: 'KD',
          regionName: 'Kolda',
        },
        {
          fullCode: 'SN-LG',
          regionCode: 'LG',
          regionName: 'Louga',
        },
        {
          fullCode: 'SN-MT',
          regionCode: 'MT',
          regionName: 'Matam',
        },
        {
          fullCode: 'SN-SL',
          regionCode: 'SL',
          regionName: 'Saint-Louis',
        },
        {
          fullCode: 'SN-SE',
          regionCode: 'SE',
          regionName: 'Sédhiou',
        },
        {
          fullCode: 'SN-TC',
          regionCode: 'TC',
          regionName: 'Tambacounda',
        },
        {
          fullCode: 'SN-TH',
          regionCode: 'TH',
          regionName: 'Thiès',
        },
        {
          fullCode: 'SN-ZG',
          regionCode: 'ZG',
          regionName: 'Ziguinchor',
        },
      ],
    },
  ],
  [
    'serbia',
    {
      countryCode: 'RS',
      countryName: 'Serbia',
      regions: [
        {
          fullCode: 'RS-KM',
          regionCode: 'KM',
          regionName: 'Kosovo-Metohija[1]',
        },
        {
          fullCode: 'RS-VO',
          regionCode: 'VO',
          regionName: 'Vojvodina',
        },
        {
          fullCode: 'RS-00',
          regionCode: '00',
          regionName: 'Beograd',
        },
        {
          fullCode: 'RS-14',
          regionCode: '14',
          regionName: 'Borski okrug',
        },
        {
          fullCode: 'RS-11',
          regionCode: '11',
          regionName: 'Braničevski okrug',
        },
        {
          fullCode: 'RS-23',
          regionCode: '23',
          regionName: 'Jablanički okrug',
        },
        {
          fullCode: 'RS-06',
          regionCode: '06',
          regionName: 'Južnobački okrug',
        },
        {
          fullCode: 'RS-04',
          regionCode: '04',
          regionName: 'Južnobanatski okrug',
        },
        {
          fullCode: 'RS-09',
          regionCode: '09',
          regionName: 'Kolubarski okrug',
        },
        {
          fullCode: 'RS-25',
          regionCode: '25',
          regionName: 'Kosovski okrug',
        },
        {
          fullCode: 'RS-28',
          regionCode: '28',
          regionName: 'Kosovsko-Mitrovački okrug',
        },
        {
          fullCode: 'RS-29',
          regionCode: '29',
          regionName: 'Kosovsko-Pomoravski okrug',
        },
        {
          fullCode: 'RS-08',
          regionCode: '08',
          regionName: 'Mačvanski okrug',
        },
        {
          fullCode: 'RS-17',
          regionCode: '17',
          regionName: 'Moravički okrug',
        },
        {
          fullCode: 'RS-20',
          regionCode: '20',
          regionName: 'Nišavski okrug',
        },
        {
          fullCode: 'RS-24',
          regionCode: '24',
          regionName: 'Pčinjski okrug',
        },
        {
          fullCode: 'RS-26',
          regionCode: '26',
          regionName: 'Pećki okrug',
        },
        {
          fullCode: 'RS-22',
          regionCode: '22',
          regionName: 'Pirotski okrug',
        },
        {
          fullCode: 'RS-10',
          regionCode: '10',
          regionName: 'Podunavski okrug',
        },
        {
          fullCode: 'RS-13',
          regionCode: '13',
          regionName: 'Pomoravski okrug',
        },
        {
          fullCode: 'RS-27',
          regionCode: '27',
          regionName: 'Prizrenski okrug',
        },
        {
          fullCode: 'RS-19',
          regionCode: '19',
          regionName: 'Rasinski okrug',
        },
        {
          fullCode: 'RS-18',
          regionCode: '18',
          regionName: 'Raški okrug',
        },
        {
          fullCode: 'RS-01',
          regionCode: '01',
          regionName: 'Severnobački okrug',
        },
        {
          fullCode: 'RS-03',
          regionCode: '03',
          regionName: 'Severnobanatski okrug',
        },
        {
          fullCode: 'RS-02',
          regionCode: '02',
          regionName: 'Srednjebanatski okrug',
        },
        {
          fullCode: 'RS-07',
          regionCode: '07',
          regionName: 'Sremski okrug',
        },
        {
          fullCode: 'RS-12',
          regionCode: '12',
          regionName: 'Šumadijski okrug',
        },
        {
          fullCode: 'RS-21',
          regionCode: '21',
          regionName: 'Toplički okrug',
        },
        {
          fullCode: 'RS-15',
          regionCode: '15',
          regionName: 'Zaječarski okrug',
        },
        {
          fullCode: 'RS-05',
          regionCode: '05',
          regionName: 'Zapadnobački okrug',
        },
        {
          fullCode: 'RS-16',
          regionCode: '16',
          regionName: 'Zlatiborski okrug',
        },
      ],
    },
  ],
  [
    'seychelles',
    {
      countryCode: 'SC',
      countryName: 'Seychelles',
      regions: [
        {
          fullCode: 'SC-01',
          regionCode: '01',
          regionName: 'Anse aux Pins',
        },
        {
          fullCode: 'SC-02',
          regionCode: '02',
          regionName: 'Anse Boileau',
        },
        {
          fullCode: 'SC-03',
          regionCode: '03',
          regionName: 'Anse Etoile',
        },
        {
          fullCode: 'SC-05',
          regionCode: '05',
          regionName: 'Anse Royale',
        },
        {
          fullCode: 'SC-04',
          regionCode: '04',
          regionName: 'Au Cap',
        },
        {
          fullCode: 'SC-06',
          regionCode: '06',
          regionName: 'Baie Lazare',
        },
        {
          fullCode: 'SC-07',
          regionCode: '07',
          regionName: 'Baie Sainte Anne',
        },
        {
          fullCode: 'SC-08',
          regionCode: '08',
          regionName: 'Beau Vallon',
        },
        {
          fullCode: 'SC-09',
          regionCode: '09',
          regionName: 'Bel Air',
        },
        {
          fullCode: 'SC-10',
          regionCode: '10',
          regionName: 'Bel Ombre',
        },
        {
          fullCode: 'SC-11',
          regionCode: '11',
          regionName: 'Cascade',
        },
        {
          fullCode: 'SC-16',
          regionCode: '16',
          regionName: 'English River',
        },
        {
          fullCode: 'SC-12',
          regionCode: '12',
          regionName: 'Glacis',
        },
        {
          fullCode: 'SC-13',
          regionCode: '13',
          regionName: 'Grand Anse Mahe',
        },
        {
          fullCode: 'SC-14',
          regionCode: '14',
          regionName: 'Grand Anse Praslin',
        },
        {
          fullCode: 'SC-26',
          regionCode: '26',
          regionName: 'Ile Perseverance I',
        },
        {
          fullCode: 'SC-27',
          regionCode: '27',
          regionName: 'Ile Perseverance II',
        },
        {
          fullCode: 'SC-15',
          regionCode: '15',
          regionName: 'La Digue',
        },
        {
          fullCode: 'SC-24',
          regionCode: '24',
          regionName: 'Les Mamelles',
        },
        {
          fullCode: 'SC-17',
          regionCode: '17',
          regionName: 'Mont Buxton',
        },
        {
          fullCode: 'SC-18',
          regionCode: '18',
          regionName: 'Mont Fleuri',
        },
        {
          fullCode: 'SC-19',
          regionCode: '19',
          regionName: 'Plaisance',
        },
        {
          fullCode: 'SC-20',
          regionCode: '20',
          regionName: 'Pointe Larue',
        },
        {
          fullCode: 'SC-21',
          regionCode: '21',
          regionName: 'Port Glaud',
        },
        {
          fullCode: 'SC-25',
          regionCode: '25',
          regionName: 'Roche Caiman',
        },
        {
          fullCode: 'SC-22',
          regionCode: '22',
          regionName: 'Saint Louis',
        },
        {
          fullCode: 'SC-23',
          regionCode: '23',
          regionName: 'Takamaka',
        },
      ],
    },
  ],
  [
    'sierra leone',
    {
      countryCode: 'SL',
      countryName: 'Sierra Leone',
      regions: [
        {
          fullCode: 'SL-E',
          regionCode: 'E',
          regionName: 'Eastern',
        },
        {
          fullCode: 'SL-NW',
          regionCode: 'NW',
          regionName: 'North Western',
        },
        {
          fullCode: 'SL-N',
          regionCode: 'N',
          regionName: 'Northern',
        },
        {
          fullCode: 'SL-S',
          regionCode: 'S',
          regionName: 'Southern',
        },
        {
          fullCode: 'SL-W',
          regionCode: 'W',
          regionName: 'Western Area (Freetown)',
        },
      ],
    },
  ],
  [
    'singapore',
    {
      countryCode: 'SG',
      countryName: 'Singapore',
      regions: [
        {
          fullCode: 'SG-01',
          regionCode: '01',
          regionName: 'Central Singapore',
        },
        {
          fullCode: 'SG-02',
          regionCode: '02',
          regionName: 'North East',
        },
        {
          fullCode: 'SG-03',
          regionCode: '03',
          regionName: 'North West',
        },
        {
          fullCode: 'SG-04',
          regionCode: '04',
          regionName: 'South East',
        },
        {
          fullCode: 'SG-05',
          regionCode: '05',
          regionName: 'South West',
        },
      ],
    },
  ],
  [
    'slovakia',
    {
      countryCode: 'SK',
      countryName: 'Slovakia',
      regions: [
        {
          fullCode: 'SK-BC',
          regionCode: 'BC',
          regionName: 'Banská Bystrica',
        },
        {
          fullCode: 'SK-BL',
          regionCode: 'BL',
          regionName: 'Bratislava',
        },
        {
          fullCode: 'SK-KI',
          regionCode: 'KI',
          regionName: 'Košice',
        },
        {
          fullCode: 'SK-NI',
          regionCode: 'NI',
          regionName: 'Nitra',
        },
        {
          fullCode: 'SK-PV',
          regionCode: 'PV',
          regionName: 'Prešov',
        },
        {
          fullCode: 'SK-TC',
          regionCode: 'TC',
          regionName: 'Trenčín',
        },
        {
          fullCode: 'SK-TA',
          regionCode: 'TA',
          regionName: 'Trnava',
        },
        {
          fullCode: 'SK-ZI',
          regionCode: 'ZI',
          regionName: 'Žilina',
        },
      ],
    },
  ],
  [
    'slovenia',
    {
      countryCode: 'SI',
      countryName: 'Slovenia',
      regions: [
        {
          fullCode: 'SI-001',
          regionCode: '001',
          regionName: 'Ajdovščina',
        },
        {
          fullCode: 'SI-213',
          regionCode: '213',
          regionName: 'Ancarano',
        },
        {
          fullCode: 'SI-195',
          regionCode: '195',
          regionName: 'Apače',
        },
        {
          fullCode: 'SI-002',
          regionCode: '002',
          regionName: 'Beltinci',
        },
        {
          fullCode: 'SI-148',
          regionCode: '148',
          regionName: 'Benedikt',
        },
        {
          fullCode: 'SI-149',
          regionCode: '149',
          regionName: 'Bistrica ob Sotli',
        },
        {
          fullCode: 'SI-003',
          regionCode: '003',
          regionName: 'Bled',
        },
        {
          fullCode: 'SI-150',
          regionCode: '150',
          regionName: 'Bloke',
        },
        {
          fullCode: 'SI-004',
          regionCode: '004',
          regionName: 'Bohinj',
        },
        {
          fullCode: 'SI-005',
          regionCode: '005',
          regionName: 'Borovnica',
        },
        {
          fullCode: 'SI-006',
          regionCode: '006',
          regionName: 'Bovec',
        },
        {
          fullCode: 'SI-151',
          regionCode: '151',
          regionName: 'Braslovče',
        },
        {
          fullCode: 'SI-007',
          regionCode: '007',
          regionName: 'Brda',
        },
        {
          fullCode: 'SI-008',
          regionCode: '008',
          regionName: 'Brezovica',
        },
        {
          fullCode: 'SI-009',
          regionCode: '009',
          regionName: 'Brežice',
        },
        {
          fullCode: 'SI-152',
          regionCode: '152',
          regionName: 'Cankova',
        },
        {
          fullCode: 'SI-011',
          regionCode: '011',
          regionName: 'Celje',
        },
        {
          fullCode: 'SI-012',
          regionCode: '012',
          regionName: 'Cerklje na Gorenjskem',
        },
        {
          fullCode: 'SI-013',
          regionCode: '013',
          regionName: 'Cerknica',
        },
        {
          fullCode: 'SI-014',
          regionCode: '014',
          regionName: 'Cerkno',
        },
        {
          fullCode: 'SI-153',
          regionCode: '153',
          regionName: 'Cerkvenjak',
        },
        {
          fullCode: 'SI-196',
          regionCode: '196',
          regionName: 'Cirkulane',
        },
        {
          fullCode: 'SI-015',
          regionCode: '015',
          regionName: 'Črenšovci',
        },
        {
          fullCode: 'SI-016',
          regionCode: '016',
          regionName: 'Črna na Koroškem',
        },
        {
          fullCode: 'SI-017',
          regionCode: '017',
          regionName: 'Črnomelj',
        },
        {
          fullCode: 'SI-018',
          regionCode: '018',
          regionName: 'Destrnik',
        },
        {
          fullCode: 'SI-019',
          regionCode: '019',
          regionName: 'Divača',
        },
        {
          fullCode: 'SI-154',
          regionCode: '154',
          regionName: 'Dobje',
        },
        {
          fullCode: 'SI-020',
          regionCode: '020',
          regionName: 'Dobrepolje',
        },
        {
          fullCode: 'SI-155',
          regionCode: '155',
          regionName: 'Dobrna',
        },
        {
          fullCode: 'SI-021',
          regionCode: '021',
          regionName: 'Dobrova-Polhov Gradec',
        },
        {
          fullCode: 'SI-156',
          regionCode: '156',
          regionName: 'Dobronak',
        },
        {
          fullCode: 'SI-022',
          regionCode: '022',
          regionName: 'Dol pri Ljubljani',
        },
        {
          fullCode: 'SI-157',
          regionCode: '157',
          regionName: 'Dolenjske Toplice',
        },
        {
          fullCode: 'SI-023',
          regionCode: '023',
          regionName: 'Domžale',
        },
        {
          fullCode: 'SI-024',
          regionCode: '024',
          regionName: 'Dornava',
        },
        {
          fullCode: 'SI-025',
          regionCode: '025',
          regionName: 'Dravograd',
        },
        {
          fullCode: 'SI-026',
          regionCode: '026',
          regionName: 'Duplek',
        },
        {
          fullCode: 'SI-027',
          regionCode: '027',
          regionName: 'Gorenja vas-Poljane',
        },
        {
          fullCode: 'SI-028',
          regionCode: '028',
          regionName: 'Gorišnica',
        },
        {
          fullCode: 'SI-207',
          regionCode: '207',
          regionName: 'Gorje',
        },
        {
          fullCode: 'SI-029',
          regionCode: '029',
          regionName: 'Gornja Radgona',
        },
        {
          fullCode: 'SI-030',
          regionCode: '030',
          regionName: 'Gornji Grad',
        },
        {
          fullCode: 'SI-031',
          regionCode: '031',
          regionName: 'Gornji Petrovci',
        },
        {
          fullCode: 'SI-158',
          regionCode: '158',
          regionName: 'Grad',
        },
        {
          fullCode: 'SI-032',
          regionCode: '032',
          regionName: 'Grosuplje',
        },
        {
          fullCode: 'SI-159',
          regionCode: '159',
          regionName: 'Hajdina',
        },
        {
          fullCode: 'SI-160',
          regionCode: '160',
          regionName: 'Hoče-Slivnica',
        },
        {
          fullCode: 'SI-161',
          regionCode: '161',
          regionName: 'Hodos',
        },
        {
          fullCode: 'SI-162',
          regionCode: '162',
          regionName: 'Horjul',
        },
        {
          fullCode: 'SI-034',
          regionCode: '034',
          regionName: 'Hrastnik',
        },
        {
          fullCode: 'SI-035',
          regionCode: '035',
          regionName: 'Hrpelje-Kozina',
        },
        {
          fullCode: 'SI-036',
          regionCode: '036',
          regionName: 'Idrija',
        },
        {
          fullCode: 'SI-037',
          regionCode: '037',
          regionName: 'Ig',
        },
        {
          fullCode: 'SI-038',
          regionCode: '038',
          regionName: 'Ilirska Bistrica',
        },
        {
          fullCode: 'SI-039',
          regionCode: '039',
          regionName: 'Ivančna Gorica',
        },
        {
          fullCode: 'SI-040',
          regionCode: '040',
          regionName: 'Isola',
        },
        {
          fullCode: 'SI-041',
          regionCode: '041',
          regionName: 'Jesenice',
        },
        {
          fullCode: 'SI-163',
          regionCode: '163',
          regionName: 'Jezersko',
        },
        {
          fullCode: 'SI-042',
          regionCode: '042',
          regionName: 'Juršinci',
        },
        {
          fullCode: 'SI-043',
          regionCode: '043',
          regionName: 'Kamnik',
        },
        {
          fullCode: 'SI-044',
          regionCode: '044',
          regionName: 'Kanal ob Soči',
        },
        {
          fullCode: 'SI-045',
          regionCode: '045',
          regionName: 'Kidričevo',
        },
        {
          fullCode: 'SI-046',
          regionCode: '046',
          regionName: 'Kobarid',
        },
        {
          fullCode: 'SI-047',
          regionCode: '047',
          regionName: 'Kobilje',
        },
        {
          fullCode: 'SI-048',
          regionCode: '048',
          regionName: 'Kočevje',
        },
        {
          fullCode: 'SI-049',
          regionCode: '049',
          regionName: 'Komen',
        },
        {
          fullCode: 'SI-164',
          regionCode: '164',
          regionName: 'Komenda',
        },
        {
          fullCode: 'SI-050',
          regionCode: '050',
          regionName: 'Capodistria',
        },
        {
          fullCode: 'SI-197',
          regionCode: '197',
          regionName: 'Kostanjevica na Krki',
        },
        {
          fullCode: 'SI-165',
          regionCode: '165',
          regionName: 'Kostel',
        },
        {
          fullCode: 'SI-051',
          regionCode: '051',
          regionName: 'Kozje',
        },
        {
          fullCode: 'SI-052',
          regionCode: '052',
          regionName: 'Kranj',
        },
        {
          fullCode: 'SI-053',
          regionCode: '053',
          regionName: 'Kranjska Gora',
        },
        {
          fullCode: 'SI-166',
          regionCode: '166',
          regionName: 'Križevci',
        },
        {
          fullCode: 'SI-054',
          regionCode: '054',
          regionName: 'Krško',
        },
        {
          fullCode: 'SI-055',
          regionCode: '055',
          regionName: 'Kungota',
        },
        {
          fullCode: 'SI-056',
          regionCode: '056',
          regionName: 'Kuzma',
        },
        {
          fullCode: 'SI-057',
          regionCode: '057',
          regionName: 'Laško',
        },
        {
          fullCode: 'SI-058',
          regionCode: '058',
          regionName: 'Lenart',
        },
        {
          fullCode: 'SI-059',
          regionCode: '059',
          regionName: 'Lendva',
        },
        {
          fullCode: 'SI-060',
          regionCode: '060',
          regionName: 'Litija',
        },
        {
          fullCode: 'SI-061',
          regionCode: '061',
          regionName: 'Ljubljana',
        },
        {
          fullCode: 'SI-062',
          regionCode: '062',
          regionName: 'Ljubno',
        },
        {
          fullCode: 'SI-063',
          regionCode: '063',
          regionName: 'Ljutomer',
        },
        {
          fullCode: 'SI-208',
          regionCode: '208',
          regionName: 'Log-Dragomer',
        },
        {
          fullCode: 'SI-064',
          regionCode: '064',
          regionName: 'Logatec',
        },
        {
          fullCode: 'SI-065',
          regionCode: '065',
          regionName: 'Loška dolina',
        },
        {
          fullCode: 'SI-066',
          regionCode: '066',
          regionName: 'Loški Potok',
        },
        {
          fullCode: 'SI-167',
          regionCode: '167',
          regionName: 'Lovrenc na Pohorju',
        },
        {
          fullCode: 'SI-067',
          regionCode: '067',
          regionName: 'Luče',
        },
        {
          fullCode: 'SI-068',
          regionCode: '068',
          regionName: 'Lukovica',
        },
        {
          fullCode: 'SI-069',
          regionCode: '069',
          regionName: 'Majšperk',
        },
        {
          fullCode: 'SI-198',
          regionCode: '198',
          regionName: 'Makole',
        },
        {
          fullCode: 'SI-070',
          regionCode: '070',
          regionName: 'Maribor',
        },
        {
          fullCode: 'SI-168',
          regionCode: '168',
          regionName: 'Markovci',
        },
        {
          fullCode: 'SI-071',
          regionCode: '071',
          regionName: 'Medvode',
        },
        {
          fullCode: 'SI-072',
          regionCode: '072',
          regionName: 'Mengeš',
        },
        {
          fullCode: 'SI-073',
          regionCode: '073',
          regionName: 'Metlika',
        },
        {
          fullCode: 'SI-074',
          regionCode: '074',
          regionName: 'Mežica',
        },
        {
          fullCode: 'SI-169',
          regionCode: '169',
          regionName: 'Miklavž na Dravskem polju',
        },
        {
          fullCode: 'SI-075',
          regionCode: '075',
          regionName: 'Miren-Kostanjevica',
        },
        {
          fullCode: 'SI-212',
          regionCode: '212',
          regionName: 'Mirna',
        },
        {
          fullCode: 'SI-170',
          regionCode: '170',
          regionName: 'Mirna Peč',
        },
        {
          fullCode: 'SI-076',
          regionCode: '076',
          regionName: 'Mislinja',
        },
        {
          fullCode: 'SI-199',
          regionCode: '199',
          regionName: 'Mokronog-Trebelno',
        },
        {
          fullCode: 'SI-077',
          regionCode: '077',
          regionName: 'Moravče',
        },
        {
          fullCode: 'SI-078',
          regionCode: '078',
          regionName: 'Moravske Toplice',
        },
        {
          fullCode: 'SI-079',
          regionCode: '079',
          regionName: 'Mozirje',
        },
        {
          fullCode: 'SI-080',
          regionCode: '080',
          regionName: 'Murska Sobota',
        },
        {
          fullCode: 'SI-081',
          regionCode: '081',
          regionName: 'Muta',
        },
        {
          fullCode: 'SI-082',
          regionCode: '082',
          regionName: 'Naklo',
        },
        {
          fullCode: 'SI-083',
          regionCode: '083',
          regionName: 'Nazarje',
        },
        {
          fullCode: 'SI-084',
          regionCode: '084',
          regionName: 'Nova Gorica',
        },
        {
          fullCode: 'SI-085',
          regionCode: '085',
          regionName: 'Novo Mesto',
        },
        {
          fullCode: 'SI-086',
          regionCode: '086',
          regionName: 'Odranci',
        },
        {
          fullCode: 'SI-171',
          regionCode: '171',
          regionName: 'Oplotnica',
        },
        {
          fullCode: 'SI-087',
          regionCode: '087',
          regionName: 'Ormož',
        },
        {
          fullCode: 'SI-088',
          regionCode: '088',
          regionName: 'Osilnica',
        },
        {
          fullCode: 'SI-089',
          regionCode: '089',
          regionName: 'Pesnica',
        },
        {
          fullCode: 'SI-090',
          regionCode: '090',
          regionName: 'Pirano',
        },
        {
          fullCode: 'SI-091',
          regionCode: '091',
          regionName: 'Pivka',
        },
        {
          fullCode: 'SI-092',
          regionCode: '092',
          regionName: 'Podčetrtek',
        },
        {
          fullCode: 'SI-172',
          regionCode: '172',
          regionName: 'Podlehnik',
        },
        {
          fullCode: 'SI-093',
          regionCode: '093',
          regionName: 'Podvelka',
        },
        {
          fullCode: 'SI-200',
          regionCode: '200',
          regionName: 'Poljčane',
        },
        {
          fullCode: 'SI-173',
          regionCode: '173',
          regionName: 'Polzela',
        },
        {
          fullCode: 'SI-094',
          regionCode: '094',
          regionName: 'Postojna',
        },
        {
          fullCode: 'SI-174',
          regionCode: '174',
          regionName: 'Prebold',
        },
        {
          fullCode: 'SI-095',
          regionCode: '095',
          regionName: 'Preddvor',
        },
        {
          fullCode: 'SI-175',
          regionCode: '175',
          regionName: 'Prevalje',
        },
        {
          fullCode: 'SI-096',
          regionCode: '096',
          regionName: 'Ptuj',
        },
        {
          fullCode: 'SI-097',
          regionCode: '097',
          regionName: 'Puconci',
        },
        {
          fullCode: 'SI-098',
          regionCode: '098',
          regionName: 'Rače-Fram',
        },
        {
          fullCode: 'SI-099',
          regionCode: '099',
          regionName: 'Radeče',
        },
        {
          fullCode: 'SI-100',
          regionCode: '100',
          regionName: 'Radenci',
        },
        {
          fullCode: 'SI-101',
          regionCode: '101',
          regionName: 'Radlje ob Dravi',
        },
        {
          fullCode: 'SI-102',
          regionCode: '102',
          regionName: 'Radovljica',
        },
        {
          fullCode: 'SI-103',
          regionCode: '103',
          regionName: 'Ravne na Koroškem',
        },
        {
          fullCode: 'SI-176',
          regionCode: '176',
          regionName: 'Razkrižje',
        },
        {
          fullCode: 'SI-209',
          regionCode: '209',
          regionName: 'Rečica ob Savinji',
        },
        {
          fullCode: 'SI-201',
          regionCode: '201',
          regionName: 'Renče-Vogrsko',
        },
        {
          fullCode: 'SI-104',
          regionCode: '104',
          regionName: 'Ribnica',
        },
        {
          fullCode: 'SI-177',
          regionCode: '177',
          regionName: 'Ribnica na Pohorju',
        },
        {
          fullCode: 'SI-106',
          regionCode: '106',
          regionName: 'Rogaška Slatina',
        },
        {
          fullCode: 'SI-105',
          regionCode: '105',
          regionName: 'Rogašovci',
        },
        {
          fullCode: 'SI-107',
          regionCode: '107',
          regionName: 'Rogatec',
        },
        {
          fullCode: 'SI-108',
          regionCode: '108',
          regionName: 'Ruše',
        },
        {
          fullCode: 'SI-178',
          regionCode: '178',
          regionName: 'Selnica ob Dravi',
        },
        {
          fullCode: 'SI-109',
          regionCode: '109',
          regionName: 'Semič',
        },
        {
          fullCode: 'SI-110',
          regionCode: '110',
          regionName: 'Sevnica',
        },
        {
          fullCode: 'SI-111',
          regionCode: '111',
          regionName: 'Sežana',
        },
        {
          fullCode: 'SI-112',
          regionCode: '112',
          regionName: 'Slovenj Gradec',
        },
        {
          fullCode: 'SI-113',
          regionCode: '113',
          regionName: 'Slovenska Bistrica',
        },
        {
          fullCode: 'SI-114',
          regionCode: '114',
          regionName: 'Slovenske Konjice',
        },
        {
          fullCode: 'SI-179',
          regionCode: '179',
          regionName: 'Sodražica',
        },
        {
          fullCode: 'SI-180',
          regionCode: '180',
          regionName: 'Solčava',
        },
        {
          fullCode: 'SI-202',
          regionCode: '202',
          regionName: 'Središče ob Dravi',
        },
        {
          fullCode: 'SI-115',
          regionCode: '115',
          regionName: 'Starše',
        },
        {
          fullCode: 'SI-203',
          regionCode: '203',
          regionName: 'Straža',
        },
        {
          fullCode: 'SI-181',
          regionCode: '181',
          regionName: 'Sveta Ana',
        },
        {
          fullCode: 'SI-204',
          regionCode: '204',
          regionName: 'Sveta Trojica v Slovenskih goricah',
        },
        {
          fullCode: 'SI-182',
          regionCode: '182',
          regionName: 'Sveti Andraž v Slovenskih goricah',
        },
        {
          fullCode: 'SI-116',
          regionCode: '116',
          regionName: 'Sveti Jurij ob Ščavnici',
        },
        {
          fullCode: 'SI-210',
          regionCode: '210',
          regionName: 'Sveti Jurij v Slovenskih goricah',
        },
        {
          fullCode: 'SI-205',
          regionCode: '205',
          regionName: 'Sveti Tomaž',
        },
        {
          fullCode: 'SI-033',
          regionCode: '033',
          regionName: 'Šalovci',
        },
        {
          fullCode: 'SI-183',
          regionCode: '183',
          regionName: 'Šempeter-Vrtojba',
        },
        {
          fullCode: 'SI-117',
          regionCode: '117',
          regionName: 'Šenčur',
        },
        {
          fullCode: 'SI-118',
          regionCode: '118',
          regionName: 'Šentilj',
        },
        {
          fullCode: 'SI-119',
          regionCode: '119',
          regionName: 'Šentjernej',
        },
        {
          fullCode: 'SI-120',
          regionCode: '120',
          regionName: 'Šentjur',
        },
        {
          fullCode: 'SI-211',
          regionCode: '211',
          regionName: 'Šentrupert',
        },
        {
          fullCode: 'SI-121',
          regionCode: '121',
          regionName: 'Škocjan',
        },
        {
          fullCode: 'SI-122',
          regionCode: '122',
          regionName: 'Škofja Loka',
        },
        {
          fullCode: 'SI-123',
          regionCode: '123',
          regionName: 'Škofljica',
        },
        {
          fullCode: 'SI-124',
          regionCode: '124',
          regionName: 'Šmarje pri Jelšah',
        },
        {
          fullCode: 'SI-206',
          regionCode: '206',
          regionName: 'Šmarješke Toplice',
        },
        {
          fullCode: 'SI-125',
          regionCode: '125',
          regionName: 'Šmartno ob Paki',
        },
        {
          fullCode: 'SI-194',
          regionCode: '194',
          regionName: 'Šmartno pri Litiji',
        },
        {
          fullCode: 'SI-126',
          regionCode: '126',
          regionName: 'Šoštanj',
        },
        {
          fullCode: 'SI-127',
          regionCode: '127',
          regionName: 'Štore',
        },
        {
          fullCode: 'SI-184',
          regionCode: '184',
          regionName: 'Tabor',
        },
        {
          fullCode: 'SI-010',
          regionCode: '010',
          regionName: 'Tišina',
        },
        {
          fullCode: 'SI-128',
          regionCode: '128',
          regionName: 'Tolmin',
        },
        {
          fullCode: 'SI-129',
          regionCode: '129',
          regionName: 'Trbovlje',
        },
        {
          fullCode: 'SI-130',
          regionCode: '130',
          regionName: 'Trebnje',
        },
        {
          fullCode: 'SI-185',
          regionCode: '185',
          regionName: 'Trnovska Vas',
        },
        {
          fullCode: 'SI-186',
          regionCode: '186',
          regionName: 'Trzin',
        },
        {
          fullCode: 'SI-131',
          regionCode: '131',
          regionName: 'Tržič',
        },
        {
          fullCode: 'SI-132',
          regionCode: '132',
          regionName: 'Turnišče',
        },
        {
          fullCode: 'SI-133',
          regionCode: '133',
          regionName: 'Velenje',
        },
        {
          fullCode: 'SI-187',
          regionCode: '187',
          regionName: 'Velika Polana',
        },
        {
          fullCode: 'SI-134',
          regionCode: '134',
          regionName: 'Velike Lašče',
        },
        {
          fullCode: 'SI-188',
          regionCode: '188',
          regionName: 'Veržej',
        },
        {
          fullCode: 'SI-135',
          regionCode: '135',
          regionName: 'Videm',
        },
        {
          fullCode: 'SI-136',
          regionCode: '136',
          regionName: 'Vipava',
        },
        {
          fullCode: 'SI-137',
          regionCode: '137',
          regionName: 'Vitanje',
        },
        {
          fullCode: 'SI-138',
          regionCode: '138',
          regionName: 'Vodice',
        },
        {
          fullCode: 'SI-139',
          regionCode: '139',
          regionName: 'Vojnik',
        },
        {
          fullCode: 'SI-189',
          regionCode: '189',
          regionName: 'Vransko',
        },
        {
          fullCode: 'SI-140',
          regionCode: '140',
          regionName: 'Vrhnika',
        },
        {
          fullCode: 'SI-141',
          regionCode: '141',
          regionName: 'Vuzenica',
        },
        {
          fullCode: 'SI-142',
          regionCode: '142',
          regionName: 'Zagorje ob Savi',
        },
        {
          fullCode: 'SI-143',
          regionCode: '143',
          regionName: 'Zavrč',
        },
        {
          fullCode: 'SI-144',
          regionCode: '144',
          regionName: 'Zreče',
        },
        {
          fullCode: 'SI-190',
          regionCode: '190',
          regionName: 'Žalec',
        },
        {
          fullCode: 'SI-146',
          regionCode: '146',
          regionName: 'Železniki',
        },
        {
          fullCode: 'SI-191',
          regionCode: '191',
          regionName: 'Žetale',
        },
        {
          fullCode: 'SI-147',
          regionCode: '147',
          regionName: 'Žiri',
        },
        {
          fullCode: 'SI-192',
          regionCode: '192',
          regionName: 'Žirovnica',
        },
        {
          fullCode: 'SI-193',
          regionCode: '193',
          regionName: 'Žužemberk',
        },
        {
          fullCode: 'SI-07',
          regionCode: '07',
          regionName: 'Dolenjska',
        },
        {
          fullCode: 'SI-09',
          regionCode: '09',
          regionName: 'Gorenjska',
        },
        {
          fullCode: 'SI-11',
          regionCode: '11',
          regionName: 'Goriška',
        },
        {
          fullCode: 'SI-03',
          regionCode: '03',
          regionName: 'Koroška',
        },
        {
          fullCode: 'SI-10',
          regionCode: '10',
          regionName: 'Notranjsko-kraška',
        },
        {
          fullCode: 'SI-12',
          regionCode: '12',
          regionName: 'Obalno-kraška',
        },
        {
          fullCode: 'SI-08',
          regionCode: '08',
          regionName: 'Osrednjeslovenska',
        },
        {
          fullCode: 'SI-02',
          regionCode: '02',
          regionName: 'Podravska',
        },
        {
          fullCode: 'SI-01',
          regionCode: '01',
          regionName: 'Pomurska',
        },
        {
          fullCode: 'SI-04',
          regionCode: '04',
          regionName: 'Savinjska',
        },
        {
          fullCode: 'SI-06',
          regionCode: '06',
          regionName: 'Spodnjeposavska',
        },
        {
          fullCode: 'SI-05',
          regionCode: '05',
          regionName: 'Zasavska',
        },
      ],
    },
  ],
  [
    'solomon islands',
    {
      countryCode: 'SB',
      countryName: 'Solomon Islands',
      regions: [
        {
          fullCode: 'SB-CT',
          regionCode: 'CT',
          regionName: 'Capital Territory (Honiara)',
        },
        {
          fullCode: 'SB-CE',
          regionCode: 'CE',
          regionName: 'Central',
        },
        {
          fullCode: 'SB-CH',
          regionCode: 'CH',
          regionName: 'Choiseul',
        },
        {
          fullCode: 'SB-GU',
          regionCode: 'GU',
          regionName: 'Guadalcanal',
        },
        {
          fullCode: 'SB-IS',
          regionCode: 'IS',
          regionName: 'Isabel',
        },
        {
          fullCode: 'SB-MK',
          regionCode: 'MK',
          regionName: 'Makira-Ulawa',
        },
        {
          fullCode: 'SB-ML',
          regionCode: 'ML',
          regionName: 'Malaita',
        },
        {
          fullCode: 'SB-RB',
          regionCode: 'RB',
          regionName: 'Rennell and Bellona',
        },
        {
          fullCode: 'SB-TE',
          regionCode: 'TE',
          regionName: 'Temotu',
        },
        {
          fullCode: 'SB-WE',
          regionCode: 'WE',
          regionName: 'Western',
        },
      ],
    },
  ],
  [
    'somalia',
    {
      countryCode: 'SO',
      countryName: 'Somalia',
      regions: [
        {
          fullCode: 'SO-AW',
          regionCode: 'AW',
          regionName: 'Awdal',
        },
        {
          fullCode: 'SO-BK',
          regionCode: 'BK',
          regionName: 'Bakool',
        },
        {
          fullCode: 'SO-BN',
          regionCode: 'BN',
          regionName: 'Banaadir',
        },
        {
          fullCode: 'SO-BR',
          regionCode: 'BR',
          regionName: 'Bari',
        },
        {
          fullCode: 'SO-BY',
          regionCode: 'BY',
          regionName: 'Bay',
        },
        {
          fullCode: 'SO-GA',
          regionCode: 'GA',
          regionName: 'Galguduud',
        },
        {
          fullCode: 'SO-GE',
          regionCode: 'GE',
          regionName: 'Gedo',
        },
        {
          fullCode: 'SO-HI',
          regionCode: 'HI',
          regionName: 'Hiiraan',
        },
        {
          fullCode: 'SO-JD',
          regionCode: 'JD',
          regionName: 'Middle Juba',
        },
        {
          fullCode: 'SO-JH',
          regionCode: 'JH',
          regionName: 'Lower Juba',
        },
        {
          fullCode: 'SO-MU',
          regionCode: 'MU',
          regionName: 'Mudug',
        },
        {
          fullCode: 'SO-NU',
          regionCode: 'NU',
          regionName: 'Nugaal',
        },
        {
          fullCode: 'SO-SA',
          regionCode: 'SA',
          regionName: 'Sanaag',
        },
        {
          fullCode: 'SO-SD',
          regionCode: 'SD',
          regionName: 'Middle Shabelle',
        },
        {
          fullCode: 'SO-SH',
          regionCode: 'SH',
          regionName: 'Lower Shabelle',
        },
        {
          fullCode: 'SO-SO',
          regionCode: 'SO',
          regionName: 'Sool',
        },
        {
          fullCode: 'SO-TO',
          regionCode: 'TO',
          regionName: 'Togdheer',
        },
        {
          fullCode: 'SO-WO',
          regionCode: 'WO',
          regionName: 'Northwest',
        },
      ],
    },
  ],
  [
    'south africa',
    {
      countryCode: 'ZA',
      countryName: 'South Africa',
      regions: [
        {
          fullCode: 'ZA-EC',
          regionCode: 'EC',
          regionName: 'Eastern Cape',
        },
        {
          fullCode: 'ZA-FS',
          regionCode: 'FS',
          regionName: 'Free State',
        },
        {
          fullCode: 'ZA-GP',
          regionCode: 'GP',
          regionName: 'Gauteng',
        },
        {
          fullCode: 'ZA-KZN',
          regionCode: 'KZN',
          regionName: 'Kwazulu-Natal',
        },
        {
          fullCode: 'ZA-LP',
          regionCode: 'LP',
          regionName: 'Limpopo',
        },
        {
          fullCode: 'ZA-MP',
          regionCode: 'MP',
          regionName: 'Mpumalanga',
        },
        {
          fullCode: 'ZA-NW',
          regionCode: 'NW',
          regionName: 'North-West',
        },
        {
          fullCode: 'ZA-NC',
          regionCode: 'NC',
          regionName: 'Northern Cape',
        },
        {
          fullCode: 'ZA-WC',
          regionCode: 'WC',
          regionName: 'Western Cape',
        },
      ],
    },
  ],
  [
    'south sudan',
    {
      countryCode: 'SS',
      countryName: 'South Sudan',
      regions: [
        {
          fullCode: 'SS-EC',
          regionCode: 'EC',
          regionName: 'Central Equatoria',
        },
        {
          fullCode: 'SS-EE',
          regionCode: 'EE',
          regionName: 'Eastern Equatoria',
        },
        {
          fullCode: 'SS-JG',
          regionCode: 'JG',
          regionName: 'Jonglei',
        },
        {
          fullCode: 'SS-LK',
          regionCode: 'LK',
          regionName: 'Lakes',
        },
        {
          fullCode: 'SS-BN',
          regionCode: 'BN',
          regionName: 'Northern Bahr el Ghazal',
        },
        {
          fullCode: 'SS-UY',
          regionCode: 'UY',
          regionName: 'Unity',
        },
        {
          fullCode: 'SS-NU',
          regionCode: 'NU',
          regionName: 'Upper Nile',
        },
        {
          fullCode: 'SS-WR',
          regionCode: 'WR',
          regionName: 'Warrap',
        },
        {
          fullCode: 'SS-BW',
          regionCode: 'BW',
          regionName: 'Western Bahr el Ghazal',
        },
        {
          fullCode: 'SS-EW',
          regionCode: 'EW',
          regionName: 'Western Equatoria',
        },
      ],
    },
  ],
  [
    'spain',
    {
      countryCode: 'ES',
      countryName: 'Spain',
      regions: [
        {
          fullCode: 'ES-AN',
          regionCode: 'AN',
          regionName: 'Andalusia',
        },
        {
          fullCode: 'ES-AR',
          regionCode: 'AR',
          regionName: 'Aragon',
        },
        {
          fullCode: 'ES-AS',
          regionCode: 'AS',
          regionName: 'Asturias',
        },
        {
          fullCode: 'ES-CN',
          regionCode: 'CN',
          regionName: 'Canary Islands',
        },
        {
          fullCode: 'ES-CB',
          regionCode: 'CB',
          regionName: 'Cantabria (ES-CB)',
        },
        {
          fullCode: 'ES-CM',
          regionCode: 'CM',
          regionName: 'Castile-La Mancha',
        },
        {
          fullCode: 'ES-CL',
          regionCode: 'CL',
          regionName: 'Castile and León',
        },
        {
          fullCode: 'ES-CT',
          regionCode: 'CT',
          regionName: 'Catalonia',
        },
        {
          fullCode: 'ES-EX',
          regionCode: 'EX',
          regionName: 'Extremadura',
        },
        {
          fullCode: 'ES-GA',
          regionCode: 'GA',
          regionName: 'Galicia',
        },
        {
          fullCode: 'ES-IB',
          regionCode: 'IB',
          regionName: 'Balearic Islands (ES-IB)',
        },
        {
          fullCode: 'ES-RI',
          regionCode: 'RI',
          regionName: 'La Rioja (ES-RI)',
        },
        {
          fullCode: 'ES-MD',
          regionCode: 'MD',
          regionName: 'Community of Madrid (ES-MD)',
        },
        {
          fullCode: 'ES-MC',
          regionCode: 'MC',
          regionName: 'Region of Murcia',
        },
        {
          fullCode: 'ES-NC',
          regionCode: 'NC',
          regionName: 'Chartered Community of Navarre',
        },
        {
          fullCode: 'ES-PV',
          regionCode: 'PV',
          regionName: 'Basque Country',
        },
        {
          fullCode: 'ES-VC',
          regionCode: 'VC',
          regionName: 'Valencian Community',
        },
        {
          fullCode: 'ES-CE',
          regionCode: 'CE',
          regionName: 'Ceuta',
        },
        {
          fullCode: 'ES-ML',
          regionCode: 'ML',
          regionName: 'Melilla',
        },
        {
          fullCode: 'ES-A',
          regionCode: 'A',
          regionName: 'Alicante',
        },
        {
          fullCode: 'ES-AB',
          regionCode: 'AB',
          regionName: 'Albacete',
        },
        {
          fullCode: 'ES-AL',
          regionCode: 'AL',
          regionName: 'Almería',
        },
        {
          fullCode: 'ES-O',
          regionCode: 'O',
          regionName: 'Asturias (ES-O)',
        },
        {
          fullCode: 'ES-AV',
          regionCode: 'AV',
          regionName: 'Ávila',
        },
        {
          fullCode: 'ES-B',
          regionCode: 'B',
          regionName: 'Barcelona',
        },
        {
          fullCode: 'ES-BA',
          regionCode: 'BA',
          regionName: 'Badajoz',
        },
        {
          fullCode: 'ES-BI',
          regionCode: 'BI',
          regionName: 'Biscay',
        },
        {
          fullCode: 'ES-BU',
          regionCode: 'BU',
          regionName: 'Burgos',
        },
        {
          fullCode: 'ES-C',
          regionCode: 'C',
          regionName: 'A Coruña',
        },
        {
          fullCode: 'ES-CA',
          regionCode: 'CA',
          regionName: 'Cádiz',
        },
        {
          fullCode: 'ES-CC',
          regionCode: 'CC',
          regionName: 'Cáceres',
        },
        {
          fullCode: 'ES-CO',
          regionCode: 'CO',
          regionName: 'Córdoba',
        },
        {
          fullCode: 'ES-CR',
          regionCode: 'CR',
          regionName: 'Ciudad Real',
        },
        {
          fullCode: 'ES-CS',
          regionCode: 'CS',
          regionName: 'Castellón',
        },
        {
          fullCode: 'ES-GC',
          regionCode: 'GC',
          regionName: 'Las Palmas',
        },
        {
          fullCode: 'ES-GI',
          regionCode: 'GI',
          regionName: 'Girona',
        },
        {
          fullCode: 'ES-GR',
          regionCode: 'GR',
          regionName: 'Granada',
        },
        {
          fullCode: 'ES-GU',
          regionCode: 'GU',
          regionName: 'Guadalajara',
        },
        {
          fullCode: 'ES-H',
          regionCode: 'H',
          regionName: 'Huelva',
        },
        {
          fullCode: 'ES-HU',
          regionCode: 'HU',
          regionName: 'Huesca',
        },
        {
          fullCode: 'ES-J',
          regionCode: 'J',
          regionName: 'Jaén',
        },
        {
          fullCode: 'ES-L',
          regionCode: 'L',
          regionName: 'Lleida',
        },
        {
          fullCode: 'ES-LE',
          regionCode: 'LE',
          regionName: 'León',
        },
        {
          fullCode: 'ES-LO',
          regionCode: 'LO',
          regionName: 'La Rioja (ES-LO)',
        },
        {
          fullCode: 'ES-LU',
          regionCode: 'LU',
          regionName: 'Lugo',
        },
        {
          fullCode: 'ES-M',
          regionCode: 'M',
          regionName: 'Madrid',
        },
        {
          fullCode: 'ES-MA',
          regionCode: 'MA',
          regionName: 'Málaga',
        },
        {
          fullCode: 'ES-MU',
          regionCode: 'MU',
          regionName: 'Murcia',
        },
        {
          fullCode: 'ES-NA',
          regionCode: 'NA',
          regionName: 'Navarre',
        },
        {
          fullCode: 'ES-OR',
          regionCode: 'OR',
          regionName: 'Ourense',
        },
        {
          fullCode: 'ES-P',
          regionCode: 'P',
          regionName: 'Palencia',
        },
        {
          fullCode: 'ES-PM',
          regionCode: 'PM',
          regionName: 'Balearic Islands (ES-PM)',
        },
        {
          fullCode: 'ES-PO',
          regionCode: 'PO',
          regionName: 'Pontevedra',
        },
        {
          fullCode: 'ES-S',
          regionCode: 'S',
          regionName: 'Cantabria (ES-S)',
        },
        {
          fullCode: 'ES-SA',
          regionCode: 'SA',
          regionName: 'Salamanca',
        },
        {
          fullCode: 'ES-SE',
          regionCode: 'SE',
          regionName: 'Seville',
        },
        {
          fullCode: 'ES-SG',
          regionCode: 'SG',
          regionName: 'Segovia',
        },
        {
          fullCode: 'ES-SO',
          regionCode: 'SO',
          regionName: 'Soria',
        },
        {
          fullCode: 'ES-SS',
          regionCode: 'SS',
          regionName: 'Gipuzkoa',
        },
        {
          fullCode: 'ES-T',
          regionCode: 'T',
          regionName: 'Tarragona',
        },
        {
          fullCode: 'ES-TE',
          regionCode: 'TE',
          regionName: 'Teruel',
        },
        {
          fullCode: 'ES-TO',
          regionCode: 'TO',
          regionName: 'Toledo',
        },
        {
          fullCode: 'ES-V',
          regionCode: 'V',
          regionName: 'Valencia',
        },
        {
          fullCode: 'ES-VA',
          regionCode: 'VA',
          regionName: 'Valladolid',
        },
        {
          fullCode: 'ES-VI',
          regionCode: 'VI',
          regionName: 'Álava',
        },
        {
          fullCode: 'ES-Z',
          regionCode: 'Z',
          regionName: 'Zaragoza',
        },
        {
          fullCode: 'ES-ZA',
          regionCode: 'ZA',
          regionName: 'Zamora',
        },
      ],
    },
  ],
  [
    'sri lanka',
    {
      countryCode: 'LK',
      countryName: 'Sri Lanka',
      regions: [
        {
          fullCode: 'LK-1',
          regionCode: '1',
          regionName: 'Western Province',
        },
        {
          fullCode: 'LK-3',
          regionCode: '3',
          regionName: 'Southern Province',
        },
        {
          fullCode: 'LK-2',
          regionCode: '2',
          regionName: 'Central Province',
        },
        {
          fullCode: 'LK-5',
          regionCode: '5',
          regionName: 'Eastern Province',
        },
        {
          fullCode: 'LK-9',
          regionCode: '9',
          regionName: 'Sabaragamuwa Province',
        },
        {
          fullCode: 'LK-4',
          regionCode: '4',
          regionName: 'Northern Province',
        },
        {
          fullCode: 'LK-7',
          regionCode: '7',
          regionName: 'North Central Province',
        },
        {
          fullCode: 'LK-8',
          regionCode: '8',
          regionName: 'Uva Province',
        },
        {
          fullCode: 'LK-6',
          regionCode: '6',
          regionName: 'North Western Province',
        },
        {
          fullCode: 'LK-52',
          regionCode: '52',
          regionName: 'Ampara',
        },
        {
          fullCode: 'LK-71',
          regionCode: '71',
          regionName: 'Anuradhapura',
        },
        {
          fullCode: 'LK-81',
          regionCode: '81',
          regionName: 'Badulla',
        },
        {
          fullCode: 'LK-31',
          regionCode: '31',
          regionName: 'Galle',
        },
        {
          fullCode: 'LK-12',
          regionCode: '12',
          regionName: 'Gampaha',
        },
        {
          fullCode: 'LK-33',
          regionCode: '33',
          regionName: 'Hambantota',
        },
        {
          fullCode: 'LK-92',
          regionCode: '92',
          regionName: 'Kegalla',
        },
        {
          fullCode: 'LK-13',
          regionCode: '13',
          regionName: 'Kalutara',
        },
        {
          fullCode: 'LK-42',
          regionCode: '42',
          regionName: 'Kilinochchi',
        },
        {
          fullCode: 'LK-11',
          regionCode: '11',
          regionName: 'Colombo',
        },
        {
          fullCode: 'LK-61',
          regionCode: '61',
          regionName: 'Kurunegala',
        },
        {
          fullCode: 'LK-51',
          regionCode: '51',
          regionName: 'Batticaloa',
        },
        {
          fullCode: 'LK-21',
          regionCode: '21',
          regionName: 'Kandy',
        },
        {
          fullCode: 'LK-43',
          regionCode: '43',
          regionName: 'Mannar',
        },
        {
          fullCode: 'LK-22',
          regionCode: '22',
          regionName: 'Matale',
        },
        {
          fullCode: 'LK-32',
          regionCode: '32',
          regionName: 'Matara',
        },
        {
          fullCode: 'LK-82',
          regionCode: '82',
          regionName: 'Monaragala',
        },
        {
          fullCode: 'LK-45',
          regionCode: '45',
          regionName: 'Mullaittivu',
        },
        {
          fullCode: 'LK-23',
          regionCode: '23',
          regionName: 'Nuwara Eliya',
        },
        {
          fullCode: 'LK-72',
          regionCode: '72',
          regionName: 'Polonnaruwa',
        },
        {
          fullCode: 'LK-62',
          regionCode: '62',
          regionName: 'Puttalam',
        },
        {
          fullCode: 'LK-91',
          regionCode: '91',
          regionName: 'Ratnapura',
        },
        {
          fullCode: 'LK-53',
          regionCode: '53',
          regionName: 'Trincomalee',
        },
        {
          fullCode: 'LK-44',
          regionCode: '44',
          regionName: 'Vavuniya',
        },
        {
          fullCode: 'LK-41',
          regionCode: '41',
          regionName: 'Jaffna',
        },
      ],
    },
  ],
  [
    'sudan',
    {
      countryCode: 'SD',
      countryName: 'Sudan',
      regions: [
        {
          fullCode: 'SD-DC',
          regionCode: 'DC',
          regionName: 'Central Darfur',
        },
        {
          fullCode: 'SD-DE',
          regionCode: 'DE',
          regionName: 'East Darfur',
        },
        {
          fullCode: 'SD-KA',
          regionCode: 'KA',
          regionName: 'Kassala',
        },
        {
          fullCode: 'SD-KH',
          regionCode: 'KH',
          regionName: 'Khartoum',
        },
        {
          fullCode: 'SD-DN',
          regionCode: 'DN',
          regionName: 'North Darfur',
        },
        {
          fullCode: 'SD-GZ',
          regionCode: 'GZ',
          regionName: 'Gezira',
        },
        {
          fullCode: 'SD-NO',
          regionCode: 'NO',
          regionName: 'Northern',
        },
        {
          fullCode: 'SD-NR',
          regionCode: 'NR',
          regionName: 'River Nile',
        },
        {
          fullCode: 'SD-NW',
          regionCode: 'NW',
          regionName: 'White Nile',
        },
        {
          fullCode: 'SD-GD',
          regionCode: 'GD',
          regionName: 'Gedaref',
        },
        {
          fullCode: 'SD-NB',
          regionCode: 'NB',
          regionName: 'Blue Nile',
        },
        {
          fullCode: 'SD-KS',
          regionCode: 'KS',
          regionName: 'South Darfur',
        },
        {
          fullCode: 'SD-KN',
          regionCode: 'KN',
          regionName: 'North Kordofan',
        },
        {
          fullCode: 'SD-SI',
          regionCode: 'SI',
          regionName: 'Sennar',
        },
        {
          fullCode: 'SD-DS',
          regionCode: 'DS',
          regionName: 'South Darfur',
        },
        {
          fullCode: 'SD-KO',
          regionCode: 'KO',
          regionName: 'West Kordofan',
        },
        {
          fullCode: 'SD-DW',
          regionCode: 'DW',
          regionName: 'West Darfur',
        },
        {
          fullCode: 'SD-KS',
          regionCode: 'KS',
          regionName: 'South Kordofan',
        },
      ],
    },
  ],
  [
    'suriname',
    {
      countryCode: 'SR',
      countryName: 'Suriname',
      regions: [
        {
          fullCode: 'SR-BR',
          regionCode: 'BR',
          regionName: 'Brokopondo',
        },
        {
          fullCode: 'SR-CM',
          regionCode: 'CM',
          regionName: 'Commewijne',
        },
        {
          fullCode: 'SR-CR',
          regionCode: 'CR',
          regionName: 'Coronie',
        },
        {
          fullCode: 'SR-MA',
          regionCode: 'MA',
          regionName: 'Marowijne',
        },
        {
          fullCode: 'SR-NI',
          regionCode: 'NI',
          regionName: 'Nickerie',
        },
        {
          fullCode: 'SR-PR',
          regionCode: 'PR',
          regionName: 'Para',
        },
        {
          fullCode: 'SR-PM',
          regionCode: 'PM',
          regionName: 'Paramaribo',
        },
        {
          fullCode: 'SR-SA',
          regionCode: 'SA',
          regionName: 'Saramacca',
        },
        {
          fullCode: 'SR-SI',
          regionCode: 'SI',
          regionName: 'Sipaliwini',
        },
        {
          fullCode: 'SR-WA',
          regionCode: 'WA',
          regionName: 'Wanica',
        },
      ],
    },
  ],
  [
    'sweden',
    {
      countryCode: 'SE',
      countryName: 'Sweden',
      regions: [
        {
          fullCode: 'SE-K',
          regionCode: 'K',
          regionName: 'Blekinge',
        },
        {
          fullCode: 'SE-W',
          regionCode: 'W',
          regionName: 'Dalarna',
        },
        {
          fullCode: 'SE-I',
          regionCode: 'I',
          regionName: 'Gotland',
        },
        {
          fullCode: 'SE-X',
          regionCode: 'X',
          regionName: 'Gävleborg',
        },
        {
          fullCode: 'SE-N',
          regionCode: 'N',
          regionName: 'Halland',
        },
        {
          fullCode: 'SE-Z',
          regionCode: 'Z',
          regionName: 'Jämtland',
        },
        {
          fullCode: 'SE-F',
          regionCode: 'F',
          regionName: 'Jönköping',
        },
        {
          fullCode: 'SE-H',
          regionCode: 'H',
          regionName: 'Kalmar',
        },
        {
          fullCode: 'SE-G',
          regionCode: 'G',
          regionName: 'Kronoberg',
        },
        {
          fullCode: 'SE-BD',
          regionCode: 'BD',
          regionName: 'Norrbotten',
        },
        {
          fullCode: 'SE-M',
          regionCode: 'M',
          regionName: 'Scania',
        },
        {
          fullCode: 'SE-AB',
          regionCode: 'AB',
          regionName: 'Stockholm',
        },
        {
          fullCode: 'SE-D',
          regionCode: 'D',
          regionName: 'Södermanland',
        },
        {
          fullCode: 'SE-C',
          regionCode: 'C',
          regionName: 'Uppsala',
        },
        {
          fullCode: 'SE-S',
          regionCode: 'S',
          regionName: 'Värmland',
        },
        {
          fullCode: 'SE-AC',
          regionCode: 'AC',
          regionName: 'Västerbotten',
        },
        {
          fullCode: 'SE-Y',
          regionCode: 'Y',
          regionName: 'Western Northland',
        },
        {
          fullCode: 'SE-U',
          regionCode: 'U',
          regionName: 'Västmanland',
        },
        {
          fullCode: 'SE-O',
          regionCode: 'O',
          regionName: 'Västra Götaland',
        },
        {
          fullCode: 'SE-T',
          regionCode: 'T',
          regionName: 'Örebro',
        },
        {
          fullCode: 'SE-E',
          regionCode: 'E',
          regionName: 'Östergötland',
        },
      ],
    },
  ],
  [
    'switzerland',
    {
      countryCode: 'CH',
      countryName: 'Switzerland',
      regions: [
        {
          fullCode: 'CH-AG',
          regionCode: 'AG',
          regionName: 'Aargau (de)',
        },
        {
          fullCode: 'CH-AR',
          regionCode: 'AR',
          regionName: 'Appenzell Ausserrhoden (de)',
        },
        {
          fullCode: 'CH-AI',
          regionCode: 'AI',
          regionName: 'Appenzell Innerrhoden (de)',
        },
        {
          fullCode: 'CH-BL',
          regionCode: 'BL',
          regionName: 'Basel-Landschaft (de)',
        },
        {
          fullCode: 'CH-BS',
          regionCode: 'BS',
          regionName: 'Basel-Stadt (de)',
        },
        {
          fullCode: 'CH-BE',
          regionCode: 'BE',
          regionName: 'Bern (de), Berne (fr)',
        },
        {
          fullCode: 'CH-FR',
          regionCode: 'FR',
          regionName: 'Fribourg (fr), Freiburg (de)',
        },
        {
          fullCode: 'CH-GE',
          regionCode: 'GE',
          regionName: 'Genève (fr)',
        },
        {
          fullCode: 'CH-GL',
          regionCode: 'GL',
          regionName: 'Glarus (de)',
        },
        {
          fullCode: 'CH-GR',
          regionCode: 'GR',
          regionName: 'Graubünden (de), Grigioni (it), Grischun (rm)',
        },
        {
          fullCode: 'CH-JU',
          regionCode: 'JU',
          regionName: 'Jura (fr)',
        },
        {
          fullCode: 'CH-LU',
          regionCode: 'LU',
          regionName: 'Luzern (de)',
        },
        {
          fullCode: 'CH-NE',
          regionCode: 'NE',
          regionName: 'Neuchâtel (fr)',
        },
        {
          fullCode: 'CH-NW',
          regionCode: 'NW',
          regionName: 'Nidwalden (de)',
        },
        {
          fullCode: 'CH-OW',
          regionCode: 'OW',
          regionName: 'Obwalden (de)',
        },
        {
          fullCode: 'CH-SG',
          regionCode: 'SG',
          regionName: 'Sankt Gallen (de)',
        },
        {
          fullCode: 'CH-SH',
          regionCode: 'SH',
          regionName: 'Schaffhausen (de)',
        },
        {
          fullCode: 'CH-SZ',
          regionCode: 'SZ',
          regionName: 'Schwyz (de)',
        },
        {
          fullCode: 'CH-SO',
          regionCode: 'SO',
          regionName: 'Solothurn (de)',
        },
        {
          fullCode: 'CH-TG',
          regionCode: 'TG',
          regionName: 'Thurgau (de)',
        },
        {
          fullCode: 'CH-TI',
          regionCode: 'TI',
          regionName: 'Ticino (it)',
        },
        {
          fullCode: 'CH-UR',
          regionCode: 'UR',
          regionName: 'Uri (de)',
        },
        {
          fullCode: 'CH-VS',
          regionCode: 'VS',
          regionName: 'Valais (fr), Wallis (de)',
        },
        {
          fullCode: 'CH-VD',
          regionCode: 'VD',
          regionName: 'Vaud (fr)',
        },
        {
          fullCode: 'CH-ZG',
          regionCode: 'ZG',
          regionName: 'Zug (de)',
        },
        {
          fullCode: 'CH-ZH',
          regionCode: 'ZH',
          regionName: 'Zürich (de)',
        },
      ],
    },
  ],
  [
    'syrian arab republic',
    {
      countryCode: 'SY',
      countryName: 'Syrian Arab Republic',
      regions: [
        {
          fullCode: 'SY-HA',
          regionCode: 'HA',
          regionName: 'Hasaka',
        },
        {
          fullCode: 'SY-LA',
          regionCode: 'LA',
          regionName: 'Latakia',
        },
        {
          fullCode: 'SY-QU',
          regionCode: 'QU',
          regionName: 'Quneitra',
        },
        {
          fullCode: 'SY-RA',
          regionCode: 'RA',
          regionName: 'Raqqa',
        },
        {
          fullCode: 'SY-SU',
          regionCode: 'SU',
          regionName: 'Suwayda',
        },
        {
          fullCode: 'SY-DR',
          regionCode: 'DR',
          regionName: 'Daraa',
        },
        {
          fullCode: 'SY-DY',
          regionCode: 'DY',
          regionName: 'Deir Ezzor',
        },
        {
          fullCode: 'SY-DI',
          regionCode: 'DI',
          regionName: 'Damascus',
        },
        {
          fullCode: 'SY-HL',
          regionCode: 'HL',
          regionName: 'Aleppo',
        },
        {
          fullCode: 'SY-HM',
          regionCode: 'HM',
          regionName: 'Hama',
        },
        {
          fullCode: 'SY-HI',
          regionCode: 'HI',
          regionName: 'Homs',
        },
        {
          fullCode: 'SY-ID',
          regionCode: 'ID',
          regionName: 'Idlib',
        },
        {
          fullCode: 'SY-RD',
          regionCode: 'RD',
          regionName: 'Damascus Countryside',
        },
        {
          fullCode: 'SY-TA',
          regionCode: 'TA',
          regionName: 'Tartus',
        },
      ],
    },
  ],
  [
    'taiwan',
    {
      countryCode: 'TW',
      countryName: 'Taiwan',
      regions: [
        {
          fullCode: 'TW-CHA',
          regionCode: 'CHA',
          regionName: 'Changhua',
        },
        {
          fullCode: 'TW-CYI',
          regionCode: 'CYI',
          regionName: 'Chiayi (city)',
        },
        {
          fullCode: 'TW-CYQ',
          regionCode: 'CYQ',
          regionName: 'Chiayi (county)',
        },
        {
          fullCode: 'TW-HSZ',
          regionCode: 'HSZ',
          regionName: 'Hsinchu (city)',
        },
        {
          fullCode: 'TW-HSQ',
          regionCode: 'HSQ',
          regionName: 'Hsinchu (county)',
        },
        {
          fullCode: 'TW-HUA',
          regionCode: 'HUA',
          regionName: 'Hualien',
        },
        {
          fullCode: 'TW-KHH',
          regionCode: 'KHH',
          regionName: 'Kaohsiung',
        },
        {
          fullCode: 'TW-KEE',
          regionCode: 'KEE',
          regionName: 'Keelung',
        },
        {
          fullCode: 'TW-KIN',
          regionCode: 'KIN',
          regionName: 'Kinmen',
        },
        {
          fullCode: 'TW-LIE',
          regionCode: 'LIE',
          regionName: 'Lienchiang',
        },
        {
          fullCode: 'TW-MIA',
          regionCode: 'MIA',
          regionName: 'Miaoli',
        },
        {
          fullCode: 'TW-NAN',
          regionCode: 'NAN',
          regionName: 'Nantou',
        },
        {
          fullCode: 'TW-NWT',
          regionCode: 'NWT',
          regionName: 'New Taipei',
        },
        {
          fullCode: 'TW-PEN',
          regionCode: 'PEN',
          regionName: 'Penghu',
        },
        {
          fullCode: 'TW-PIF',
          regionCode: 'PIF',
          regionName: 'Pingtung',
        },
        {
          fullCode: 'TW-TXG',
          regionCode: 'TXG',
          regionName: 'Taichung',
        },
        {
          fullCode: 'TW-TNN',
          regionCode: 'TNN',
          regionName: 'Tainan',
        },
        {
          fullCode: 'TW-TPE',
          regionCode: 'TPE',
          regionName: 'Taipei',
        },
        {
          fullCode: 'TW-TTT',
          regionCode: 'TTT',
          regionName: 'Taitung',
        },
        {
          fullCode: 'TW-TAO',
          regionCode: 'TAO',
          regionName: 'Taoyuan',
        },
        {
          fullCode: 'TW-ILA',
          regionCode: 'ILA',
          regionName: 'Yilan',
        },
        {
          fullCode: 'TW-YUN',
          regionCode: 'YUN',
          regionName: 'Yunlin',
        },
      ],
    },
  ],
  [
    'tajikistan',
    {
      countryCode: 'TJ',
      countryName: 'Tajikistan',
      regions: [
        {
          fullCode: 'TJ-DU',
          regionCode: 'DU',
          regionName: 'Dushanbe',
        },
        {
          fullCode: 'TJ-KT',
          regionCode: 'KT',
          regionName: 'Khatlon',
        },
        {
          fullCode: 'TJ-GB',
          regionCode: 'GB',
          regionName: 'Gorno-Badakhshan',
        },
        {
          fullCode: 'TJ-RA',
          regionCode: 'RA',
          regionName: 'districts under government jurisdiction',
        },
        {
          fullCode: 'TJ-SU',
          regionCode: 'SU',
          regionName: 'Sughd',
        },
      ],
    },
  ],
  [
    'tanzania, united republic of',
    {
      countryCode: 'TZ',
      countryName: 'Tanzania, United Republic of',
      regions: [
        {
          fullCode: 'TZ-01',
          regionCode: '01',
          regionName: 'Arusha',
        },
        {
          fullCode: 'TZ-02',
          regionCode: '02',
          regionName: 'Dar es Salaam',
        },
        {
          fullCode: 'TZ-03',
          regionCode: '03',
          regionName: 'Dodoma',
        },
        {
          fullCode: 'TZ-27',
          regionCode: '27',
          regionName: 'Geita',
        },
        {
          fullCode: 'TZ-04',
          regionCode: '04',
          regionName: 'Iringa',
        },
        {
          fullCode: 'TZ-05',
          regionCode: '05',
          regionName: 'Kagera',
        },
        {
          fullCode: 'TZ-06',
          regionCode: '06',
          regionName: 'Pemba North',
        },
        {
          fullCode: 'TZ-07',
          regionCode: '07',
          regionName: 'Zanzibar North',
        },
        {
          fullCode: 'TZ-28',
          regionCode: '28',
          regionName: 'Katavi',
        },
        {
          fullCode: 'TZ-08',
          regionCode: '08',
          regionName: 'Kigoma',
        },
        {
          fullCode: 'TZ-09',
          regionCode: '09',
          regionName: 'Kilimanjaro',
        },
        {
          fullCode: 'TZ-10',
          regionCode: '10',
          regionName: 'Pemba South',
        },
        {
          fullCode: 'TZ-11',
          regionCode: '11',
          regionName: 'Zanzibar South',
        },
        {
          fullCode: 'TZ-12',
          regionCode: '12',
          regionName: 'Lindi',
        },
        {
          fullCode: 'TZ-26',
          regionCode: '26',
          regionName: 'Manyara',
        },
        {
          fullCode: 'TZ-13',
          regionCode: '13',
          regionName: 'Mara',
        },
        {
          fullCode: 'TZ-14',
          regionCode: '14',
          regionName: 'Mbeya',
        },
        {
          fullCode: 'TZ-15',
          regionCode: '15',
          regionName: 'Zanzibar West',
        },
        {
          fullCode: 'TZ-16',
          regionCode: '16',
          regionName: 'Morogoro',
        },
        {
          fullCode: 'TZ-17',
          regionCode: '17',
          regionName: 'Mtwara',
        },
        {
          fullCode: 'TZ-18',
          regionCode: '18',
          regionName: 'Mwanza',
        },
        {
          fullCode: 'TZ-29',
          regionCode: '29',
          regionName: 'Njombe',
        },
        {
          fullCode: 'TZ-19',
          regionCode: '19',
          regionName: 'Coast',
        },
        {
          fullCode: 'TZ-20',
          regionCode: '20',
          regionName: 'Rukwa',
        },
        {
          fullCode: 'TZ-21',
          regionCode: '21',
          regionName: 'Ruvuma',
        },
        {
          fullCode: 'TZ-22',
          regionCode: '22',
          regionName: 'Shinyanga',
        },
        {
          fullCode: 'TZ-30',
          regionCode: '30',
          regionName: 'Simiyu',
        },
        {
          fullCode: 'TZ-23',
          regionCode: '23',
          regionName: 'Singida',
        },
        {
          fullCode: 'TZ-31',
          regionCode: '31',
          regionName: 'Songwe',
        },
        {
          fullCode: 'TZ-24',
          regionCode: '24',
          regionName: 'Tabora',
        },
        {
          fullCode: 'TZ-25',
          regionCode: '25',
          regionName: 'Tanga',
        },
      ],
    },
  ],
  [
    'thailand',
    {
      countryCode: 'TH',
      countryName: 'Thailand',
      regions: [
        {
          fullCode: 'TH-37',
          regionCode: '37',
          regionName: 'Amnat Charoen',
        },
        {
          fullCode: 'TH-15',
          regionCode: '15',
          regionName: 'Ang Thong',
        },
        {
          fullCode: 'TH-38',
          regionCode: '38',
          regionName: 'Bueng Kan',
        },
        {
          fullCode: 'TH-31',
          regionCode: '31',
          regionName: 'Buri Ram',
        },
        {
          fullCode: 'TH-24',
          regionCode: '24',
          regionName: 'Chachoengsao',
        },
        {
          fullCode: 'TH-18',
          regionCode: '18',
          regionName: 'Chai Nat',
        },
        {
          fullCode: 'TH-36',
          regionCode: '36',
          regionName: 'Chaiyaphum',
        },
        {
          fullCode: 'TH-22',
          regionCode: '22',
          regionName: 'Chanthaburi',
        },
        {
          fullCode: 'TH-50',
          regionCode: '50',
          regionName: 'Chiang Mai',
        },
        {
          fullCode: 'TH-57',
          regionCode: '57',
          regionName: 'Chiang Rai',
        },
        {
          fullCode: 'TH-20',
          regionCode: '20',
          regionName: 'Chon Buri',
        },
        {
          fullCode: 'TH-86',
          regionCode: '86',
          regionName: 'Chumphon',
        },
        {
          fullCode: 'TH-46',
          regionCode: '46',
          regionName: 'Kalasin',
        },
        {
          fullCode: 'TH-62',
          regionCode: '62',
          regionName: 'Kamphaeng Phet',
        },
        {
          fullCode: 'TH-71',
          regionCode: '71',
          regionName: 'Kanchanaburi',
        },
        {
          fullCode: 'TH-40',
          regionCode: '40',
          regionName: 'Khon Kaen',
        },
        {
          fullCode: 'TH-81',
          regionCode: '81',
          regionName: 'Krabi',
        },
        {
          fullCode: 'TH-10',
          regionCode: '10',
          regionName: 'Krung Thep Maha Nakhon(local variant: Bangkok)',
        },
        {
          fullCode: 'TH-52',
          regionCode: '52',
          regionName: 'Lampang',
        },
        {
          fullCode: 'TH-51',
          regionCode: '51',
          regionName: 'Lamphun',
        },
        {
          fullCode: 'TH-42',
          regionCode: '42',
          regionName: 'Loei',
        },
        {
          fullCode: 'TH-16',
          regionCode: '16',
          regionName: 'Lop Buri',
        },
        {
          fullCode: 'TH-58',
          regionCode: '58',
          regionName: 'Mae Hong Son',
        },
        {
          fullCode: 'TH-44',
          regionCode: '44',
          regionName: 'Maha Sarakham',
        },
        {
          fullCode: 'TH-49',
          regionCode: '49',
          regionName: 'Mukdahan',
        },
        {
          fullCode: 'TH-26',
          regionCode: '26',
          regionName: 'Nakhon Nayok',
        },
        {
          fullCode: 'TH-73',
          regionCode: '73',
          regionName: 'Nakhon Pathom',
        },
        {
          fullCode: 'TH-48',
          regionCode: '48',
          regionName: 'Nakhon Phanom',
        },
        {
          fullCode: 'TH-30',
          regionCode: '30',
          regionName: 'Nakhon Ratchasima',
        },
        {
          fullCode: 'TH-60',
          regionCode: '60',
          regionName: 'Nakhon Sawan',
        },
        {
          fullCode: 'TH-80',
          regionCode: '80',
          regionName: 'Nakhon Si Thammarat',
        },
        {
          fullCode: 'TH-55',
          regionCode: '55',
          regionName: 'Nan',
        },
        {
          fullCode: 'TH-96',
          regionCode: '96',
          regionName: 'Narathiwat',
        },
        {
          fullCode: 'TH-39',
          regionCode: '39',
          regionName: 'Nong Bua Lam Phu',
        },
        {
          fullCode: 'TH-43',
          regionCode: '43',
          regionName: 'Nong Khai',
        },
        {
          fullCode: 'TH-12',
          regionCode: '12',
          regionName: 'Nonthaburi',
        },
        {
          fullCode: 'TH-13',
          regionCode: '13',
          regionName: 'Pathum Thani',
        },
        {
          fullCode: 'TH-94',
          regionCode: '94',
          regionName: 'Pattani',
        },
        {
          fullCode: 'TH-82',
          regionCode: '82',
          regionName: 'Phangnga',
        },
        {
          fullCode: 'TH-93',
          regionCode: '93',
          regionName: 'Phatthalung',
        },
        {
          fullCode: 'TH-S',
          regionCode: 'S',
          regionName: 'Phatthaya',
        },
        {
          fullCode: 'TH-56',
          regionCode: '56',
          regionName: 'Phayao',
        },
        {
          fullCode: 'TH-67',
          regionCode: '67',
          regionName: 'Phetchabun',
        },
        {
          fullCode: 'TH-76',
          regionCode: '76',
          regionName: 'Phetchaburi',
        },
        {
          fullCode: 'TH-66',
          regionCode: '66',
          regionName: 'Phichit',
        },
        {
          fullCode: 'TH-65',
          regionCode: '65',
          regionName: 'Phitsanulok',
        },
        {
          fullCode: 'TH-14',
          regionCode: '14',
          regionName: 'Phra Nakhon Si Ayutthaya',
        },
        {
          fullCode: 'TH-54',
          regionCode: '54',
          regionName: 'Phrae',
        },
        {
          fullCode: 'TH-83',
          regionCode: '83',
          regionName: 'Phuket',
        },
        {
          fullCode: 'TH-25',
          regionCode: '25',
          regionName: 'Prachin Buri',
        },
        {
          fullCode: 'TH-77',
          regionCode: '77',
          regionName: 'Prachuap Khiri Khan',
        },
        {
          fullCode: 'TH-85',
          regionCode: '85',
          regionName: 'Ranong',
        },
        {
          fullCode: 'TH-70',
          regionCode: '70',
          regionName: 'Ratchaburi',
        },
        {
          fullCode: 'TH-21',
          regionCode: '21',
          regionName: 'Rayong',
        },
        {
          fullCode: 'TH-45',
          regionCode: '45',
          regionName: 'Roi Et',
        },
        {
          fullCode: 'TH-27',
          regionCode: '27',
          regionName: 'Sa Kaeo',
        },
        {
          fullCode: 'TH-47',
          regionCode: '47',
          regionName: 'Sakon Nakhon',
        },
        {
          fullCode: 'TH-11',
          regionCode: '11',
          regionName: 'Samut Prakan',
        },
        {
          fullCode: 'TH-74',
          regionCode: '74',
          regionName: 'Samut Sakhon',
        },
        {
          fullCode: 'TH-75',
          regionCode: '75',
          regionName: 'Samut Songkhram',
        },
        {
          fullCode: 'TH-19',
          regionCode: '19',
          regionName: 'Saraburi',
        },
        {
          fullCode: 'TH-91',
          regionCode: '91',
          regionName: 'Satun',
        },
        {
          fullCode: 'TH-33',
          regionCode: '33',
          regionName: 'Si Sa Ket',
        },
        {
          fullCode: 'TH-17',
          regionCode: '17',
          regionName: 'Sing Buri',
        },
        {
          fullCode: 'TH-90',
          regionCode: '90',
          regionName: 'Songkhla',
        },
        {
          fullCode: 'TH-64',
          regionCode: '64',
          regionName: 'Sukhothai',
        },
        {
          fullCode: 'TH-72',
          regionCode: '72',
          regionName: 'Suphan Buri',
        },
        {
          fullCode: 'TH-84',
          regionCode: '84',
          regionName: 'Surat Thani',
        },
        {
          fullCode: 'TH-32',
          regionCode: '32',
          regionName: 'Surin',
        },
        {
          fullCode: 'TH-63',
          regionCode: '63',
          regionName: 'Tak',
        },
        {
          fullCode: 'TH-92',
          regionCode: '92',
          regionName: 'Trang',
        },
        {
          fullCode: 'TH-23',
          regionCode: '23',
          regionName: 'Trat',
        },
        {
          fullCode: 'TH-34',
          regionCode: '34',
          regionName: 'Ubon Ratchathani',
        },
        {
          fullCode: 'TH-41',
          regionCode: '41',
          regionName: 'Udon Thani',
        },
        {
          fullCode: 'TH-61',
          regionCode: '61',
          regionName: 'Uthai Thani',
        },
        {
          fullCode: 'TH-53',
          regionCode: '53',
          regionName: 'Uttaradit',
        },
        {
          fullCode: 'TH-95',
          regionCode: '95',
          regionName: 'Yala',
        },
        {
          fullCode: 'TH-35',
          regionCode: '35',
          regionName: 'Yasothon',
        },
      ],
    },
  ],
  [
    'timor-leste',
    {
      countryCode: 'TL',
      countryName: 'Timor-Leste',
      regions: [
        {
          fullCode: 'TL-AL',
          regionCode: 'AL',
          regionName: 'Aileu',
        },
        {
          fullCode: 'TL-AN',
          regionCode: 'AN',
          regionName: 'Ainaro',
        },
        {
          fullCode: 'TL-BA',
          regionCode: 'BA',
          regionName: 'Baucau',
        },
        {
          fullCode: 'TL-BO',
          regionCode: 'BO',
          regionName: 'Bobonaro',
        },
        {
          fullCode: 'TL-CO',
          regionCode: 'CO',
          regionName: 'Cova Lima',
        },
        {
          fullCode: 'TL-DI',
          regionCode: 'DI',
          regionName: 'Díli',
        },
        {
          fullCode: 'TL-ER',
          regionCode: 'ER',
          regionName: 'Ermera',
        },
        {
          fullCode: 'TL-LA',
          regionCode: 'LA',
          regionName: 'Lautém',
        },
        {
          fullCode: 'TL-LI',
          regionCode: 'LI',
          regionName: 'Liquiça',
        },
        {
          fullCode: 'TL-MT',
          regionCode: 'MT',
          regionName: 'Manatuto',
        },
        {
          fullCode: 'TL-MF',
          regionCode: 'MF',
          regionName: 'Manufahi',
        },
        {
          fullCode: 'TL-OE',
          regionCode: 'OE',
          regionName: 'Oé-Cusse Ambeno(local variant is Oecussi)',
        },
        {
          fullCode: 'TL-VI',
          regionCode: 'VI',
          regionName: 'Viqueque',
        },
      ],
    },
  ],
  [
    'togo',
    {
      countryCode: 'TG',
      countryName: 'Togo',
      regions: [
        {
          fullCode: 'TG-C',
          regionCode: 'C',
          regionName: 'Central',
        },
        {
          fullCode: 'TG-K',
          regionCode: 'K',
          regionName: 'Kara',
        },
        {
          fullCode: 'TG-M',
          regionCode: 'M',
          regionName: 'Maritime',
        },
        {
          fullCode: 'TG-P',
          regionCode: 'P',
          regionName: 'Plateaus',
        },
        {
          fullCode: 'TG-S',
          regionCode: 'S',
          regionName: 'Savannahs',
        },
      ],
    },
  ],
  [
    'tonga',
    {
      countryCode: 'TO',
      countryName: 'Tonga',
      regions: [
        {
          fullCode: 'TO-01',
          regionCode: '01',
          regionName: "\\'Eua",
        },
        {
          fullCode: 'TO-02',
          regionCode: '02',
          regionName: "Ha\\'apai",
        },
        {
          fullCode: 'TO-03',
          regionCode: '03',
          regionName: 'Niuas',
        },
        {
          fullCode: 'TO-04',
          regionCode: '04',
          regionName: 'Tongatapu',
        },
        {
          fullCode: 'TO-05',
          regionCode: '05',
          regionName: "Vava\\'u",
        },
      ],
    },
  ],
  [
    'trinidad and tobago',
    {
      countryCode: 'TT',
      countryName: 'Trinidad and Tobago',
      regions: [
        {
          fullCode: 'TT-ARI',
          regionCode: 'ARI',
          regionName: 'Arima',
        },
        {
          fullCode: 'TT-CHA',
          regionCode: 'CHA',
          regionName: 'Chaguanas',
        },
        {
          fullCode: 'TT-CTT',
          regionCode: 'CTT',
          regionName: 'Couva-Tabaquite-Talparo',
        },
        {
          fullCode: 'TT-DMN',
          regionCode: 'DMN',
          regionName: 'Diego Martin',
        },
        {
          fullCode: 'TT-MRC',
          regionCode: 'MRC',
          regionName: 'Mayaro-Rio Claro',
        },
        {
          fullCode: 'TT-PED',
          regionCode: 'PED',
          regionName: 'Penal-Debe',
        },
        {
          fullCode: 'TT-POS',
          regionCode: 'POS',
          regionName: 'Port of Spain',
        },
        {
          fullCode: 'TT-PRT',
          regionCode: 'PRT',
          regionName: 'Princes Town',
        },
        {
          fullCode: 'TT-PTF',
          regionCode: 'PTF',
          regionName: 'Point Fortin',
        },
        {
          fullCode: 'TT-SFO',
          regionCode: 'SFO',
          regionName: 'San Fernando',
        },
        {
          fullCode: 'TT-SGE',
          regionCode: 'SGE',
          regionName: 'Sangre Grande',
        },
        {
          fullCode: 'TT-SIP',
          regionCode: 'SIP',
          regionName: 'Siparia',
        },
        {
          fullCode: 'TT-SJL',
          regionCode: 'SJL',
          regionName: 'San Juan-Laventille',
        },
        {
          fullCode: 'TT-TOB',
          regionCode: 'TOB',
          regionName: 'Tobago',
        },
        {
          fullCode: 'TT-TUP',
          regionCode: 'TUP',
          regionName: 'Tunapuna-Piarco',
        },
      ],
    },
  ],
  [
    'tunisia',
    {
      countryCode: 'TN',
      countryName: 'Tunisia',
      regions: [
        {
          fullCode: 'TN-31',
          regionCode: '31',
          regionName: 'Béja',
        },
        {
          fullCode: 'TN-13',
          regionCode: '13',
          regionName: 'Ben Arous',
        },
        {
          fullCode: 'TN-23',
          regionCode: '23',
          regionName: 'Bizerte',
        },
        {
          fullCode: 'TN-81',
          regionCode: '81',
          regionName: 'Gabès',
        },
        {
          fullCode: 'TN-71',
          regionCode: '71',
          regionName: 'Gafsa',
        },
        {
          fullCode: 'TN-32',
          regionCode: '32',
          regionName: 'Jendouba',
        },
        {
          fullCode: 'TN-41',
          regionCode: '41',
          regionName: 'Kairouan',
        },
        {
          fullCode: 'TN-42',
          regionCode: '42',
          regionName: 'Kasserine',
        },
        {
          fullCode: 'TN-73',
          regionCode: '73',
          regionName: 'Kébili',
        },
        {
          fullCode: 'TN-12',
          regionCode: '12',
          regionName: "L\\'Ariana",
        },
        {
          fullCode: 'TN-14',
          regionCode: '14',
          regionName: 'La Manouba',
        },
        {
          fullCode: 'TN-33',
          regionCode: '33',
          regionName: 'Le Kef',
        },
        {
          fullCode: 'TN-53',
          regionCode: '53',
          regionName: 'Mahdia',
        },
        {
          fullCode: 'TN-82',
          regionCode: '82',
          regionName: 'Médenine',
        },
        {
          fullCode: 'TN-52',
          regionCode: '52',
          regionName: 'Monastir',
        },
        {
          fullCode: 'TN-21',
          regionCode: '21',
          regionName: 'Nabeul',
        },
        {
          fullCode: 'TN-61',
          regionCode: '61',
          regionName: 'Sfax',
        },
        {
          fullCode: 'TN-43',
          regionCode: '43',
          regionName: 'Sidi Bouzid',
        },
        {
          fullCode: 'TN-34',
          regionCode: '34',
          regionName: 'Siliana',
        },
        {
          fullCode: 'TN-51',
          regionCode: '51',
          regionName: 'Sousse',
        },
        {
          fullCode: 'TN-83',
          regionCode: '83',
          regionName: 'Tataouine',
        },
        {
          fullCode: 'TN-72',
          regionCode: '72',
          regionName: 'Tozeur',
        },
        {
          fullCode: 'TN-11',
          regionCode: '11',
          regionName: 'Tunis',
        },
        {
          fullCode: 'TN-22',
          regionCode: '22',
          regionName: 'Zaghouan',
        },
      ],
    },
  ],
  [
    'türkiye',
    {
      countryCode: 'TR',
      countryName: 'Türkiye',
      regions: [
        {
          fullCode: 'TR-01',
          regionCode: '01',
          regionName: 'Adana',
        },
        {
          fullCode: 'TR-02',
          regionCode: '02',
          regionName: 'Adıyaman',
        },
        {
          fullCode: 'TR-03',
          regionCode: '03',
          regionName: 'Afyonkarahisar',
        },
        {
          fullCode: 'TR-04',
          regionCode: '04',
          regionName: 'Ağrı',
        },
        {
          fullCode: 'TR-68',
          regionCode: '68',
          regionName: 'Aksaray',
        },
        {
          fullCode: 'TR-05',
          regionCode: '05',
          regionName: 'Amasya',
        },
        {
          fullCode: 'TR-06',
          regionCode: '06',
          regionName: 'Ankara',
        },
        {
          fullCode: 'TR-07',
          regionCode: '07',
          regionName: 'Antalya',
        },
        {
          fullCode: 'TR-75',
          regionCode: '75',
          regionName: 'Ardahan',
        },
        {
          fullCode: 'TR-08',
          regionCode: '08',
          regionName: 'Artvin',
        },
        {
          fullCode: 'TR-09',
          regionCode: '09',
          regionName: 'Aydın',
        },
        {
          fullCode: 'TR-10',
          regionCode: '10',
          regionName: 'Balıkesir',
        },
        {
          fullCode: 'TR-74',
          regionCode: '74',
          regionName: 'Bartın',
        },
        {
          fullCode: 'TR-72',
          regionCode: '72',
          regionName: 'Batman',
        },
        {
          fullCode: 'TR-69',
          regionCode: '69',
          regionName: 'Bayburt',
        },
        {
          fullCode: 'TR-11',
          regionCode: '11',
          regionName: 'Bilecik',
        },
        {
          fullCode: 'TR-12',
          regionCode: '12',
          regionName: 'Bingöl',
        },
        {
          fullCode: 'TR-13',
          regionCode: '13',
          regionName: 'Bitlis',
        },
        {
          fullCode: 'TR-14',
          regionCode: '14',
          regionName: 'Bolu',
        },
        {
          fullCode: 'TR-15',
          regionCode: '15',
          regionName: 'Burdur',
        },
        {
          fullCode: 'TR-16',
          regionCode: '16',
          regionName: 'Bursa',
        },
        {
          fullCode: 'TR-17',
          regionCode: '17',
          regionName: 'Çanakkale',
        },
        {
          fullCode: 'TR-18',
          regionCode: '18',
          regionName: 'Çankırı',
        },
        {
          fullCode: 'TR-19',
          regionCode: '19',
          regionName: 'Çorum',
        },
        {
          fullCode: 'TR-20',
          regionCode: '20',
          regionName: 'Denizli',
        },
        {
          fullCode: 'TR-21',
          regionCode: '21',
          regionName: 'Diyarbakır',
        },
        {
          fullCode: 'TR-81',
          regionCode: '81',
          regionName: 'Düzce',
        },
        {
          fullCode: 'TR-22',
          regionCode: '22',
          regionName: 'Edirne',
        },
        {
          fullCode: 'TR-23',
          regionCode: '23',
          regionName: 'Elazığ',
        },
        {
          fullCode: 'TR-24',
          regionCode: '24',
          regionName: 'Erzincan',
        },
        {
          fullCode: 'TR-25',
          regionCode: '25',
          regionName: 'Erzurum',
        },
        {
          fullCode: 'TR-26',
          regionCode: '26',
          regionName: 'Eskişehir',
        },
        {
          fullCode: 'TR-27',
          regionCode: '27',
          regionName: 'Gaziantep',
        },
        {
          fullCode: 'TR-28',
          regionCode: '28',
          regionName: 'Giresun',
        },
        {
          fullCode: 'TR-29',
          regionCode: '29',
          regionName: 'Gümüşhane',
        },
        {
          fullCode: 'TR-30',
          regionCode: '30',
          regionName: 'Hakkâri',
        },
        {
          fullCode: 'TR-31',
          regionCode: '31',
          regionName: 'Hatay',
        },
        {
          fullCode: 'TR-76',
          regionCode: '76',
          regionName: 'Iğdır',
        },
        {
          fullCode: 'TR-32',
          regionCode: '32',
          regionName: 'Isparta',
        },
        {
          fullCode: 'TR-34',
          regionCode: '34',
          regionName: 'İstanbul',
        },
        {
          fullCode: 'TR-35',
          regionCode: '35',
          regionName: 'İzmir',
        },
        {
          fullCode: 'TR-46',
          regionCode: '46',
          regionName: 'Kahramanmaraş',
        },
        {
          fullCode: 'TR-78',
          regionCode: '78',
          regionName: 'Karabük',
        },
        {
          fullCode: 'TR-70',
          regionCode: '70',
          regionName: 'Karaman',
        },
        {
          fullCode: 'TR-36',
          regionCode: '36',
          regionName: 'Kars',
        },
        {
          fullCode: 'TR-37',
          regionCode: '37',
          regionName: 'Kastamonu',
        },
        {
          fullCode: 'TR-38',
          regionCode: '38',
          regionName: 'Kayseri',
        },
        {
          fullCode: 'TR-71',
          regionCode: '71',
          regionName: 'Kırıkkale',
        },
        {
          fullCode: 'TR-39',
          regionCode: '39',
          regionName: 'Kırklareli',
        },
        {
          fullCode: 'TR-40',
          regionCode: '40',
          regionName: 'Kırşehir',
        },
        {
          fullCode: 'TR-79',
          regionCode: '79',
          regionName: 'Kilis',
        },
        {
          fullCode: 'TR-41',
          regionCode: '41',
          regionName: 'Kocaeli',
        },
        {
          fullCode: 'TR-42',
          regionCode: '42',
          regionName: 'Konya',
        },
        {
          fullCode: 'TR-43',
          regionCode: '43',
          regionName: 'Kütahya',
        },
        {
          fullCode: 'TR-44',
          regionCode: '44',
          regionName: 'Malatya',
        },
        {
          fullCode: 'TR-45',
          regionCode: '45',
          regionName: 'Manisa',
        },
        {
          fullCode: 'TR-47',
          regionCode: '47',
          regionName: 'Mardin',
        },
        {
          fullCode: 'TR-33',
          regionCode: '33',
          regionName: 'Mersin',
        },
        {
          fullCode: 'TR-48',
          regionCode: '48',
          regionName: 'Muğla',
        },
        {
          fullCode: 'TR-49',
          regionCode: '49',
          regionName: 'Muş',
        },
        {
          fullCode: 'TR-50',
          regionCode: '50',
          regionName: 'Nevşehir',
        },
        {
          fullCode: 'TR-51',
          regionCode: '51',
          regionName: 'Niğde',
        },
        {
          fullCode: 'TR-52',
          regionCode: '52',
          regionName: 'Ordu',
        },
        {
          fullCode: 'TR-80',
          regionCode: '80',
          regionName: 'Osmaniye',
        },
        {
          fullCode: 'TR-53',
          regionCode: '53',
          regionName: 'Rize',
        },
        {
          fullCode: 'TR-54',
          regionCode: '54',
          regionName: 'Sakarya',
        },
        {
          fullCode: 'TR-55',
          regionCode: '55',
          regionName: 'Samsun',
        },
        {
          fullCode: 'TR-56',
          regionCode: '56',
          regionName: 'Siirt',
        },
        {
          fullCode: 'TR-57',
          regionCode: '57',
          regionName: 'Sinop',
        },
        {
          fullCode: 'TR-58',
          regionCode: '58',
          regionName: 'Sivas',
        },
        {
          fullCode: 'TR-63',
          regionCode: '63',
          regionName: 'Şanlıurfa',
        },
        {
          fullCode: 'TR-73',
          regionCode: '73',
          regionName: 'Şırnak',
        },
        {
          fullCode: 'TR-59',
          regionCode: '59',
          regionName: 'Tekirdağ',
        },
        {
          fullCode: 'TR-60',
          regionCode: '60',
          regionName: 'Tokat',
        },
        {
          fullCode: 'TR-61',
          regionCode: '61',
          regionName: 'Trabzon',
        },
        {
          fullCode: 'TR-62',
          regionCode: '62',
          regionName: 'Tunceli',
        },
        {
          fullCode: 'TR-64',
          regionCode: '64',
          regionName: 'Uşak',
        },
        {
          fullCode: 'TR-65',
          regionCode: '65',
          regionName: 'Van',
        },
        {
          fullCode: 'TR-77',
          regionCode: '77',
          regionName: 'Yalova',
        },
        {
          fullCode: 'TR-66',
          regionCode: '66',
          regionName: 'Yozgat',
        },
        {
          fullCode: 'TR-67',
          regionCode: '67',
          regionName: 'Zonguldak',
        },
      ],
    },
  ],
  [
    'turkmenistan',
    {
      countryCode: 'TM',
      countryName: 'Turkmenistan',
      regions: [
        {
          fullCode: 'TM-A',
          regionCode: 'A',
          regionName: 'Ahal',
        },
        {
          fullCode: 'TM-S',
          regionCode: 'S',
          regionName: 'Ashgabat',
        },
        {
          fullCode: 'TM-B',
          regionCode: 'B',
          regionName: 'Balkan',
        },
        {
          fullCode: 'TM-D',
          regionCode: 'D',
          regionName: 'Dashoguz',
        },
        {
          fullCode: 'TM-L',
          regionCode: 'L',
          regionName: 'Lebap',
        },
        {
          fullCode: 'TM-M',
          regionCode: 'M',
          regionName: 'Mary',
        },
      ],
    },
  ],
  [
    'tuvalu',
    {
      countryCode: 'TV',
      countryName: 'Tuvalu',
      regions: [
        {
          fullCode: 'TV-FUN',
          regionCode: 'FUN',
          regionName: 'Funafuti',
        },
        {
          fullCode: 'TV-NMG',
          regionCode: 'NMG',
          regionName: 'Nanumaga',
        },
        {
          fullCode: 'TV-NMA',
          regionCode: 'NMA',
          regionName: 'Nanumea',
        },
        {
          fullCode: 'TV-NIT',
          regionCode: 'NIT',
          regionName: 'Niutao',
        },
        {
          fullCode: 'TV-NUI',
          regionCode: 'NUI',
          regionName: 'Nui',
        },
        {
          fullCode: 'TV-NKF',
          regionCode: 'NKF',
          regionName: 'Nukufetau',
        },
        {
          fullCode: 'TV-NKL',
          regionCode: 'NKL',
          regionName: 'Nukulaelae',
        },
        {
          fullCode: 'TV-VAI',
          regionCode: 'VAI',
          regionName: 'Vaitupu',
        },
      ],
    },
  ],
  [
    'uganda',
    {
      countryCode: 'UG',
      countryName: 'Uganda',
      regions: [
        {
          fullCode: 'UG-C',
          regionCode: 'C',
          regionName: 'Central',
        },
        {
          fullCode: 'UG-E',
          regionCode: 'E',
          regionName: 'Eastern',
        },
        {
          fullCode: 'UG-N',
          regionCode: 'N',
          regionName: 'Northern',
        },
        {
          fullCode: 'UG-W',
          regionCode: 'W',
          regionName: 'Western',
        },
        {
          fullCode: 'UG-314',
          regionCode: '314',
          regionName: 'Abim',
        },
        {
          fullCode: 'UG-301',
          regionCode: '301',
          regionName: 'Adjumani',
        },
        {
          fullCode: 'UG-322',
          regionCode: '322',
          regionName: 'Agago',
        },
        {
          fullCode: 'UG-323',
          regionCode: '323',
          regionName: 'Alebtong',
        },
        {
          fullCode: 'UG-315',
          regionCode: '315',
          regionName: 'Amolatar',
        },
        {
          fullCode: 'UG-324',
          regionCode: '324',
          regionName: 'Amudat',
        },
        {
          fullCode: 'UG-216',
          regionCode: '216',
          regionName: 'Amuria',
        },
        {
          fullCode: 'UG-316',
          regionCode: '316',
          regionName: 'Amuru',
        },
        {
          fullCode: 'UG-302',
          regionCode: '302',
          regionName: 'Apac',
        },
        {
          fullCode: 'UG-303',
          regionCode: '303',
          regionName: 'Arua',
        },
        {
          fullCode: 'UG-217',
          regionCode: '217',
          regionName: 'Budaka',
        },
        {
          fullCode: 'UG-218',
          regionCode: '218',
          regionName: 'Bududa',
        },
        {
          fullCode: 'UG-201',
          regionCode: '201',
          regionName: 'Bugiri',
        },
        {
          fullCode: 'UG-235',
          regionCode: '235',
          regionName: 'Bugweri',
        },
        {
          fullCode: 'UG-420',
          regionCode: '420',
          regionName: 'Buhweju',
        },
        {
          fullCode: 'UG-117',
          regionCode: '117',
          regionName: 'Buikwe',
        },
        {
          fullCode: 'UG-219',
          regionCode: '219',
          regionName: 'Bukedea',
        },
        {
          fullCode: 'UG-118',
          regionCode: '118',
          regionName: 'Bukomansibi',
        },
        {
          fullCode: 'UG-220',
          regionCode: '220',
          regionName: 'Bukwo',
        },
        {
          fullCode: 'UG-225',
          regionCode: '225',
          regionName: 'Bulambuli',
        },
        {
          fullCode: 'UG-416',
          regionCode: '416',
          regionName: 'Buliisa',
        },
        {
          fullCode: 'UG-401',
          regionCode: '401',
          regionName: 'Bundibugyo',
        },
        {
          fullCode: 'UG-430',
          regionCode: '430',
          regionName: 'Bunyangabu',
        },
        {
          fullCode: 'UG-402',
          regionCode: '402',
          regionName: 'Bushenyi',
        },
        {
          fullCode: 'UG-202',
          regionCode: '202',
          regionName: 'Busia',
        },
        {
          fullCode: 'UG-221',
          regionCode: '221',
          regionName: 'Butaleja',
        },
        {
          fullCode: 'UG-119',
          regionCode: '119',
          regionName: 'Butambala',
        },
        {
          fullCode: 'UG-233',
          regionCode: '233',
          regionName: 'Butebo',
        },
        {
          fullCode: 'UG-120',
          regionCode: '120',
          regionName: 'Buvuma',
        },
        {
          fullCode: 'UG-226',
          regionCode: '226',
          regionName: 'Buyende',
        },
        {
          fullCode: 'UG-317',
          regionCode: '317',
          regionName: 'Dokolo',
        },
        {
          fullCode: 'UG-121',
          regionCode: '121',
          regionName: 'Gomba',
        },
        {
          fullCode: 'UG-304',
          regionCode: '304',
          regionName: 'Gulu',
        },
        {
          fullCode: 'UG-403',
          regionCode: '403',
          regionName: 'Hoima',
        },
        {
          fullCode: 'UG-417',
          regionCode: '417',
          regionName: 'Ibanda',
        },
        {
          fullCode: 'UG-203',
          regionCode: '203',
          regionName: 'Iganga',
        },
        {
          fullCode: 'UG-418',
          regionCode: '418',
          regionName: 'Isingiro',
        },
        {
          fullCode: 'UG-204',
          regionCode: '204',
          regionName: 'Jinja',
        },
        {
          fullCode: 'UG-318',
          regionCode: '318',
          regionName: 'Kaabong',
        },
        {
          fullCode: 'UG-404',
          regionCode: '404',
          regionName: 'Kabale',
        },
        {
          fullCode: 'UG-405',
          regionCode: '405',
          regionName: 'Kabarole',
        },
        {
          fullCode: 'UG-213',
          regionCode: '213',
          regionName: 'Kaberamaido',
        },
        {
          fullCode: 'UG-427',
          regionCode: '427',
          regionName: 'Kagadi',
        },
        {
          fullCode: 'UG-428',
          regionCode: '428',
          regionName: 'Kakumiro',
        },
        {
          fullCode: 'UG-237',
          regionCode: '237',
          regionName: 'Kalaki',
        },
        {
          fullCode: 'UG-101',
          regionCode: '101',
          regionName: 'Kalangala',
        },
        {
          fullCode: 'UG-222',
          regionCode: '222',
          regionName: 'Kaliro',
        },
        {
          fullCode: 'UG-122',
          regionCode: '122',
          regionName: 'Kalungu',
        },
        {
          fullCode: 'UG-102',
          regionCode: '102',
          regionName: 'Kampala',
        },
        {
          fullCode: 'UG-205',
          regionCode: '205',
          regionName: 'Kamuli',
        },
        {
          fullCode: 'UG-413',
          regionCode: '413',
          regionName: 'Kamwenge',
        },
        {
          fullCode: 'UG-414',
          regionCode: '414',
          regionName: 'Kanungu',
        },
        {
          fullCode: 'UG-206',
          regionCode: '206',
          regionName: 'Kapchorwa',
        },
        {
          fullCode: 'UG-236',
          regionCode: '236',
          regionName: 'Kapelebyong',
        },
        {
          fullCode: 'UG-335',
          regionCode: '335',
          regionName: 'Karenga',
        },
        {
          fullCode: 'UG-126',
          regionCode: '126',
          regionName: 'Kasanda',
        },
        {
          fullCode: 'UG-406',
          regionCode: '406',
          regionName: 'Kasese',
        },
        {
          fullCode: 'UG-207',
          regionCode: '207',
          regionName: 'Katakwi',
        },
        {
          fullCode: 'UG-112',
          regionCode: '112',
          regionName: 'Kayunga',
        },
        {
          fullCode: 'UG-433',
          regionCode: '433',
          regionName: 'Kazo',
        },
        {
          fullCode: 'UG-407',
          regionCode: '407',
          regionName: 'Kibaale',
        },
        {
          fullCode: 'UG-103',
          regionCode: '103',
          regionName: 'Kiboga',
        },
        {
          fullCode: 'UG-227',
          regionCode: '227',
          regionName: 'Kibuku',
        },
        {
          fullCode: 'UG-432',
          regionCode: '432',
          regionName: 'Kikuube',
        },
        {
          fullCode: 'UG-419',
          regionCode: '419',
          regionName: 'Kiruhura',
        },
        {
          fullCode: 'UG-421',
          regionCode: '421',
          regionName: 'Kiryandongo',
        },
        {
          fullCode: 'UG-408',
          regionCode: '408',
          regionName: 'Kisoro',
        },
        {
          fullCode: 'UG-434',
          regionCode: '434',
          regionName: 'Kitagwenda',
        },
        {
          fullCode: 'UG-305',
          regionCode: '305',
          regionName: 'Kitgum',
        },
        {
          fullCode: 'UG-319',
          regionCode: '319',
          regionName: 'Koboko',
        },
        {
          fullCode: 'UG-325',
          regionCode: '325',
          regionName: 'Kole',
        },
        {
          fullCode: 'UG-306',
          regionCode: '306',
          regionName: 'Kotido',
        },
        {
          fullCode: 'UG-208',
          regionCode: '208',
          regionName: 'Kumi',
        },
        {
          fullCode: 'UG-333',
          regionCode: '333',
          regionName: 'Kwania',
        },
        {
          fullCode: 'UG-228',
          regionCode: '228',
          regionName: 'Kween',
        },
        {
          fullCode: 'UG-123',
          regionCode: '123',
          regionName: 'Kyankwanzi',
        },
        {
          fullCode: 'UG-422',
          regionCode: '422',
          regionName: 'Kyegegwa',
        },
        {
          fullCode: 'UG-415',
          regionCode: '415',
          regionName: 'Kyenjojo',
        },
        {
          fullCode: 'UG-125',
          regionCode: '125',
          regionName: 'Kyotera',
        },
        {
          fullCode: 'UG-326',
          regionCode: '326',
          regionName: 'Lamwo',
        },
        {
          fullCode: 'UG-307',
          regionCode: '307',
          regionName: 'Lira',
        },
        {
          fullCode: 'UG-229',
          regionCode: '229',
          regionName: 'Luuka',
        },
        {
          fullCode: 'UG-104',
          regionCode: '104',
          regionName: 'Luwero',
        },
        {
          fullCode: 'UG-124',
          regionCode: '124',
          regionName: 'Lwengo',
        },
        {
          fullCode: 'UG-114',
          regionCode: '114',
          regionName: 'Lyantonde',
        },
        {
          fullCode: 'UG-336',
          regionCode: '336',
          regionName: 'Madi-Okollo',
        },
        {
          fullCode: 'UG-223',
          regionCode: '223',
          regionName: 'Manafwa',
        },
        {
          fullCode: 'UG-320',
          regionCode: '320',
          regionName: 'Maracha',
        },
        {
          fullCode: 'UG-105',
          regionCode: '105',
          regionName: 'Masaka',
        },
        {
          fullCode: 'UG-409',
          regionCode: '409',
          regionName: 'Masindi',
        },
        {
          fullCode: 'UG-214',
          regionCode: '214',
          regionName: 'Mayuge',
        },
        {
          fullCode: 'UG-209',
          regionCode: '209',
          regionName: 'Mbale',
        },
        {
          fullCode: 'UG-410',
          regionCode: '410',
          regionName: 'Mbarara',
        },
        {
          fullCode: 'UG-423',
          regionCode: '423',
          regionName: 'Mitooma',
        },
        {
          fullCode: 'UG-115',
          regionCode: '115',
          regionName: 'Mityana',
        },
        {
          fullCode: 'UG-308',
          regionCode: '308',
          regionName: 'Moroto',
        },
        {
          fullCode: 'UG-309',
          regionCode: '309',
          regionName: 'Moyo',
        },
        {
          fullCode: 'UG-106',
          regionCode: '106',
          regionName: 'Mpigi',
        },
        {
          fullCode: 'UG-107',
          regionCode: '107',
          regionName: 'Mubende',
        },
        {
          fullCode: 'UG-108',
          regionCode: '108',
          regionName: 'Mukono',
        },
        {
          fullCode: 'UG-334',
          regionCode: '334',
          regionName: 'Nabilatuk',
        },
        {
          fullCode: 'UG-311',
          regionCode: '311',
          regionName: 'Nakapiripirit',
        },
        {
          fullCode: 'UG-116',
          regionCode: '116',
          regionName: 'Nakaseke',
        },
        {
          fullCode: 'UG-109',
          regionCode: '109',
          regionName: 'Nakasongola',
        },
        {
          fullCode: 'UG-230',
          regionCode: '230',
          regionName: 'Namayingo',
        },
        {
          fullCode: 'UG-234',
          regionCode: '234',
          regionName: 'Namisindwa',
        },
        {
          fullCode: 'UG-224',
          regionCode: '224',
          regionName: 'Namutumba',
        },
        {
          fullCode: 'UG-327',
          regionCode: '327',
          regionName: 'Napak',
        },
        {
          fullCode: 'UG-310',
          regionCode: '310',
          regionName: 'Nebbi',
        },
        {
          fullCode: 'UG-231',
          regionCode: '231',
          regionName: 'Ngora',
        },
        {
          fullCode: 'UG-424',
          regionCode: '424',
          regionName: 'Ntoroko',
        },
        {
          fullCode: 'UG-411',
          regionCode: '411',
          regionName: 'Ntungamo',
        },
        {
          fullCode: 'UG-328',
          regionCode: '328',
          regionName: 'Nwoya',
        },
        {
          fullCode: 'UG-337',
          regionCode: '337',
          regionName: 'Obongi',
        },
        {
          fullCode: 'UG-331',
          regionCode: '331',
          regionName: 'Omoro',
        },
        {
          fullCode: 'UG-329',
          regionCode: '329',
          regionName: 'Otuke',
        },
        {
          fullCode: 'UG-321',
          regionCode: '321',
          regionName: 'Oyam',
        },
        {
          fullCode: 'UG-312',
          regionCode: '312',
          regionName: 'Pader',
        },
        {
          fullCode: 'UG-332',
          regionCode: '332',
          regionName: 'Pakwach',
        },
        {
          fullCode: 'UG-210',
          regionCode: '210',
          regionName: 'Pallisa',
        },
        {
          fullCode: 'UG-110',
          regionCode: '110',
          regionName: 'Rakai',
        },
        {
          fullCode: 'UG-429',
          regionCode: '429',
          regionName: 'Rubanda',
        },
        {
          fullCode: 'UG-425',
          regionCode: '425',
          regionName: 'Rubirizi',
        },
        {
          fullCode: 'UG-431',
          regionCode: '431',
          regionName: 'Rukiga',
        },
        {
          fullCode: 'UG-412',
          regionCode: '412',
          regionName: 'Rukungiri',
        },
        {
          fullCode: 'UG-435',
          regionCode: '435',
          regionName: 'Rwampara',
        },
        {
          fullCode: 'UG-111',
          regionCode: '111',
          regionName: 'Sembabule',
        },
        {
          fullCode: 'UG-232',
          regionCode: '232',
          regionName: 'Serere',
        },
        {
          fullCode: 'UG-426',
          regionCode: '426',
          regionName: 'Sheema',
        },
        {
          fullCode: 'UG-215',
          regionCode: '215',
          regionName: 'Sironko',
        },
        {
          fullCode: 'UG-211',
          regionCode: '211',
          regionName: 'Soroti',
        },
        {
          fullCode: 'UG-212',
          regionCode: '212',
          regionName: 'Tororo',
        },
        {
          fullCode: 'UG-113',
          regionCode: '113',
          regionName: 'Wakiso',
        },
        {
          fullCode: 'UG-313',
          regionCode: '313',
          regionName: 'Yumbe',
        },
        {
          fullCode: 'UG-330',
          regionCode: '330',
          regionName: 'Zombo',
        },
      ],
    },
  ],
  [
    'ukraine',
    {
      countryCode: 'UA',
      countryName: 'Ukraine',
      regions: [
        {
          fullCode: 'UA-43',
          regionCode: '43',
          regionName: 'Crimea',
        },
        {
          fullCode: 'UA-71',
          regionCode: '71',
          regionName: 'Cherkasy',
        },
        {
          fullCode: 'UA-74',
          regionCode: '74',
          regionName: 'Chernihiv',
        },
        {
          fullCode: 'UA-77',
          regionCode: '77',
          regionName: 'Chernivtsi',
        },
        {
          fullCode: 'UA-12',
          regionCode: '12',
          regionName: 'Dnipropetrovsk',
        },
        {
          fullCode: 'UA-14',
          regionCode: '14',
          regionName: 'Donetsk',
        },
        {
          fullCode: 'UA-26',
          regionCode: '26',
          regionName: 'Ivano-Frankivsk',
        },
        {
          fullCode: 'UA-63',
          regionCode: '63',
          regionName: 'Kharkiv',
        },
        {
          fullCode: 'UA-65',
          regionCode: '65',
          regionName: 'Kherson',
        },
        {
          fullCode: 'UA-68',
          regionCode: '68',
          regionName: 'Khmelnytskyi',
        },
        {
          fullCode: 'UA-35',
          regionCode: '35',
          regionName: 'Kirovohrad',
        },
        {
          fullCode: 'UA-30',
          regionCode: '30',
          regionName: 'Kyiv City',
        },
        {
          fullCode: 'UA-32',
          regionCode: '32',
          regionName: 'Kyiv',
        },
        {
          fullCode: 'UA-09',
          regionCode: '09',
          regionName: 'Luhansk',
        },
        {
          fullCode: 'UA-46',
          regionCode: '46',
          regionName: 'Lviv',
        },
        {
          fullCode: 'UA-48',
          regionCode: '48',
          regionName: 'Mykolaiv',
        },
        {
          fullCode: 'UA-51',
          regionCode: '51',
          regionName: 'Odesa',
        },
        {
          fullCode: 'UA-53',
          regionCode: '53',
          regionName: 'Poltava',
        },
        {
          fullCode: 'UA-56',
          regionCode: '56',
          regionName: 'Rivne',
        },
        {
          fullCode: 'UA-40',
          regionCode: '40',
          regionName: 'Sevastopol',
        },
        {
          fullCode: 'UA-59',
          regionCode: '59',
          regionName: 'Sumy',
        },
        {
          fullCode: 'UA-61',
          regionCode: '61',
          regionName: 'Ternopil',
        },
        {
          fullCode: 'UA-05',
          regionCode: '05',
          regionName: 'Vinnytsia',
        },
        {
          fullCode: 'UA-07',
          regionCode: '07',
          regionName: 'Volyn',
        },
        {
          fullCode: 'UA-21',
          regionCode: '21',
          regionName: 'Transcarpathia',
        },
        {
          fullCode: 'UA-23',
          regionCode: '23',
          regionName: 'Zaporizhzhia',
        },
        {
          fullCode: 'UA-18',
          regionCode: '18',
          regionName: 'Zhytomyr',
        },
      ],
    },
  ],
  [
    'united arab emirates',
    {
      countryCode: 'AE',
      countryName: 'United Arab Emirates',
      regions: [
        {
          fullCode: 'AE-AJ',
          regionCode: 'AJ',
          regionName: '‘Ajmān',
        },
        {
          fullCode: 'AE-AZ',
          regionCode: 'AZ',
          regionName: 'Abu Dhabi',
        },
        {
          fullCode: 'AE-FU',
          regionCode: 'FU',
          regionName: 'Fujairah',
        },
        {
          fullCode: 'AE-SH',
          regionCode: 'SH',
          regionName: 'Sharjah',
        },
        {
          fullCode: 'AE-DU',
          regionCode: 'DU',
          regionName: 'Dubai',
        },
        {
          fullCode: 'AE-RK',
          regionCode: 'RK',
          regionName: 'Ras Al Khaimah',
        },
        {
          fullCode: 'AE-UQ',
          regionCode: 'UQ',
          regionName: 'Umm Al Quwain',
        },
      ],
    },
  ],
  [
    'united kingdom of great britain and northern ireland',
    {
      countryCode: 'GB',
      countryName: 'United Kingdom of Great Britain and Northern Ireland',
      regions: [
        {
          fullCode: 'GB-ENG',
          regionCode: 'ENG',
          regionName: 'England',
        },
        {
          fullCode: 'GB-NIR',
          regionCode: 'NIR',
          regionName: 'Northern Ireland',
        },
        {
          fullCode: 'GB-SCT',
          regionCode: 'SCT',
          regionName: 'Scotland',
        },
        {
          fullCode: 'GB-WLS',
          regionCode: 'WLS',
          regionName: 'Wales [Cymru GB-CYM]',
        },
        {
          fullCode: 'GB-EAW',
          regionCode: 'EAW',
          regionName: 'England and Wales',
        },
        {
          fullCode: 'GB-GBN',
          regionCode: 'GBN',
          regionName: 'Great Britain',
        },
        {
          fullCode: 'GB-UKM',
          regionCode: 'UKM',
          regionName: 'United Kingdom',
        },
        {
          fullCode: 'GB-CAM',
          regionCode: 'CAM',
          regionName: 'Cambridgeshire',
        },
        {
          fullCode: 'GB-CMA',
          regionCode: 'CMA',
          regionName: 'Cumbria',
        },
        {
          fullCode: 'GB-DBY',
          regionCode: 'DBY',
          regionName: 'Derbyshire',
        },
        {
          fullCode: 'GB-DEV',
          regionCode: 'DEV',
          regionName: 'Devon',
        },
        {
          fullCode: 'GB-DOR',
          regionCode: 'DOR',
          regionName: 'Dorset',
        },
        {
          fullCode: 'GB-ESX',
          regionCode: 'ESX',
          regionName: 'East Sussex',
        },
        {
          fullCode: 'GB-ESS',
          regionCode: 'ESS',
          regionName: 'Essex',
        },
        {
          fullCode: 'GB-GLS',
          regionCode: 'GLS',
          regionName: 'Gloucestershire',
        },
        {
          fullCode: 'GB-HAM',
          regionCode: 'HAM',
          regionName: 'Hampshire',
        },
        {
          fullCode: 'GB-HRT',
          regionCode: 'HRT',
          regionName: 'Hertfordshire',
        },
        {
          fullCode: 'GB-KEN',
          regionCode: 'KEN',
          regionName: 'Kent',
        },
        {
          fullCode: 'GB-LAN',
          regionCode: 'LAN',
          regionName: 'Lancashire',
        },
        {
          fullCode: 'GB-LEC',
          regionCode: 'LEC',
          regionName: 'Leicestershire',
        },
        {
          fullCode: 'GB-LIN',
          regionCode: 'LIN',
          regionName: 'Lincolnshire',
        },
        {
          fullCode: 'GB-NFK',
          regionCode: 'NFK',
          regionName: 'Norfolk',
        },
        {
          fullCode: 'GB-NYK',
          regionCode: 'NYK',
          regionName: 'North Yorkshire',
        },
        {
          fullCode: 'GB-NTT',
          regionCode: 'NTT',
          regionName: 'Nottinghamshire',
        },
        {
          fullCode: 'GB-OXF',
          regionCode: 'OXF',
          regionName: 'Oxfordshire',
        },
        {
          fullCode: 'GB-SOM',
          regionCode: 'SOM',
          regionName: 'Somerset',
        },
        {
          fullCode: 'GB-STS',
          regionCode: 'STS',
          regionName: 'Staffordshire',
        },
        {
          fullCode: 'GB-SFK',
          regionCode: 'SFK',
          regionName: 'Suffolk',
        },
        {
          fullCode: 'GB-SRY',
          regionCode: 'SRY',
          regionName: 'Surrey',
        },
        {
          fullCode: 'GB-WAR',
          regionCode: 'WAR',
          regionName: 'Warwickshire',
        },
        {
          fullCode: 'GB-WSX',
          regionCode: 'WSX',
          regionName: 'West Sussex',
        },
        {
          fullCode: 'GB-WOR',
          regionCode: 'WOR',
          regionName: 'Worcestershire',
        },
        {
          fullCode: 'GB-LND',
          regionCode: 'LND',
          regionName: 'London, City of',
        },
        {
          fullCode: 'GB-BDG',
          regionCode: 'BDG',
          regionName: 'Barking and Dagenham',
        },
        {
          fullCode: 'GB-BNE',
          regionCode: 'BNE',
          regionName: 'Barnet',
        },
        {
          fullCode: 'GB-BEX',
          regionCode: 'BEX',
          regionName: 'Bexley',
        },
        {
          fullCode: 'GB-BEN',
          regionCode: 'BEN',
          regionName: 'Brent',
        },
        {
          fullCode: 'GB-BRY',
          regionCode: 'BRY',
          regionName: 'Bromley',
        },
        {
          fullCode: 'GB-CMD',
          regionCode: 'CMD',
          regionName: 'Camden',
        },
        {
          fullCode: 'GB-CRY',
          regionCode: 'CRY',
          regionName: 'Croydon',
        },
        {
          fullCode: 'GB-EAL',
          regionCode: 'EAL',
          regionName: 'Ealing',
        },
        {
          fullCode: 'GB-ENF',
          regionCode: 'ENF',
          regionName: 'Enfield',
        },
        {
          fullCode: 'GB-GRE',
          regionCode: 'GRE',
          regionName: 'Greenwich',
        },
        {
          fullCode: 'GB-HCK',
          regionCode: 'HCK',
          regionName: 'Hackney',
        },
        {
          fullCode: 'GB-HMF',
          regionCode: 'HMF',
          regionName: 'Hammersmith and Fulham',
        },
        {
          fullCode: 'GB-HRY',
          regionCode: 'HRY',
          regionName: 'Haringey',
        },
        {
          fullCode: 'GB-HRW',
          regionCode: 'HRW',
          regionName: 'Harrow',
        },
        {
          fullCode: 'GB-HAV',
          regionCode: 'HAV',
          regionName: 'Havering',
        },
        {
          fullCode: 'GB-HIL',
          regionCode: 'HIL',
          regionName: 'Hillingdon',
        },
        {
          fullCode: 'GB-HNS',
          regionCode: 'HNS',
          regionName: 'Hounslow',
        },
        {
          fullCode: 'GB-ISL',
          regionCode: 'ISL',
          regionName: 'Islington',
        },
        {
          fullCode: 'GB-KEC',
          regionCode: 'KEC',
          regionName: 'Kensington and Chelsea',
        },
        {
          fullCode: 'GB-KTT',
          regionCode: 'KTT',
          regionName: 'Kingston upon Thames',
        },
        {
          fullCode: 'GB-LBH',
          regionCode: 'LBH',
          regionName: 'Lambeth',
        },
        {
          fullCode: 'GB-LEW',
          regionCode: 'LEW',
          regionName: 'Lewisham',
        },
        {
          fullCode: 'GB-MRT',
          regionCode: 'MRT',
          regionName: 'Merton',
        },
        {
          fullCode: 'GB-NWM',
          regionCode: 'NWM',
          regionName: 'Newham',
        },
        {
          fullCode: 'GB-RDB',
          regionCode: 'RDB',
          regionName: 'Redbridge',
        },
        {
          fullCode: 'GB-RIC',
          regionCode: 'RIC',
          regionName: 'Richmond upon Thames',
        },
        {
          fullCode: 'GB-SWK',
          regionCode: 'SWK',
          regionName: 'Southwark',
        },
        {
          fullCode: 'GB-STN',
          regionCode: 'STN',
          regionName: 'Sutton',
        },
        {
          fullCode: 'GB-TWH',
          regionCode: 'TWH',
          regionName: 'Tower Hamlets',
        },
        {
          fullCode: 'GB-WFT',
          regionCode: 'WFT',
          regionName: 'Waltham Forest',
        },
        {
          fullCode: 'GB-WND',
          regionCode: 'WND',
          regionName: 'Wandsworth',
        },
        {
          fullCode: 'GB-WSM',
          regionCode: 'WSM',
          regionName: 'Westminster',
        },
        {
          fullCode: 'GB-BNS',
          regionCode: 'BNS',
          regionName: 'Barnsley',
        },
        {
          fullCode: 'GB-BIR',
          regionCode: 'BIR',
          regionName: 'Birmingham',
        },
        {
          fullCode: 'GB-BOL',
          regionCode: 'BOL',
          regionName: 'Bolton',
        },
        {
          fullCode: 'GB-BRD',
          regionCode: 'BRD',
          regionName: 'Bradford',
        },
        {
          fullCode: 'GB-BUR',
          regionCode: 'BUR',
          regionName: 'Bury',
        },
        {
          fullCode: 'GB-CLD',
          regionCode: 'CLD',
          regionName: 'Calderdale',
        },
        {
          fullCode: 'GB-COV',
          regionCode: 'COV',
          regionName: 'Coventry',
        },
        {
          fullCode: 'GB-DNC',
          regionCode: 'DNC',
          regionName: 'Doncaster',
        },
        {
          fullCode: 'GB-DUD',
          regionCode: 'DUD',
          regionName: 'Dudley',
        },
        {
          fullCode: 'GB-GAT',
          regionCode: 'GAT',
          regionName: 'Gateshead',
        },
        {
          fullCode: 'GB-KIR',
          regionCode: 'KIR',
          regionName: 'Kirklees',
        },
        {
          fullCode: 'GB-KWL',
          regionCode: 'KWL',
          regionName: 'Knowsley',
        },
        {
          fullCode: 'GB-LDS',
          regionCode: 'LDS',
          regionName: 'Leeds',
        },
        {
          fullCode: 'GB-LIV',
          regionCode: 'LIV',
          regionName: 'Liverpool',
        },
        {
          fullCode: 'GB-MAN',
          regionCode: 'MAN',
          regionName: 'Manchester',
        },
        {
          fullCode: 'GB-NET',
          regionCode: 'NET',
          regionName: 'Newcastle upon Tyne',
        },
        {
          fullCode: 'GB-NTY',
          regionCode: 'NTY',
          regionName: 'North Tyneside',
        },
        {
          fullCode: 'GB-OLD',
          regionCode: 'OLD',
          regionName: 'Oldham',
        },
        {
          fullCode: 'GB-RCH',
          regionCode: 'RCH',
          regionName: 'Rochdale',
        },
        {
          fullCode: 'GB-ROT',
          regionCode: 'ROT',
          regionName: 'Rotherham',
        },
        {
          fullCode: 'GB-SHN',
          regionCode: 'SHN',
          regionName: 'St. Helens',
        },
        {
          fullCode: 'GB-SLF',
          regionCode: 'SLF',
          regionName: 'Salford',
        },
        {
          fullCode: 'GB-SAW',
          regionCode: 'SAW',
          regionName: 'Sandwell',
        },
        {
          fullCode: 'GB-SFT',
          regionCode: 'SFT',
          regionName: 'Sefton',
        },
        {
          fullCode: 'GB-SHF',
          regionCode: 'SHF',
          regionName: 'Sheffield',
        },
        {
          fullCode: 'GB-SOL',
          regionCode: 'SOL',
          regionName: 'Solihull',
        },
        {
          fullCode: 'GB-STY',
          regionCode: 'STY',
          regionName: 'South Tyneside',
        },
        {
          fullCode: 'GB-SKP',
          regionCode: 'SKP',
          regionName: 'Stockport',
        },
        {
          fullCode: 'GB-SND',
          regionCode: 'SND',
          regionName: 'Sunderland',
        },
        {
          fullCode: 'GB-TAM',
          regionCode: 'TAM',
          regionName: 'Tameside',
        },
        {
          fullCode: 'GB-TRF',
          regionCode: 'TRF',
          regionName: 'Trafford',
        },
        {
          fullCode: 'GB-WKF',
          regionCode: 'WKF',
          regionName: 'Wakefield',
        },
        {
          fullCode: 'GB-WLL',
          regionCode: 'WLL',
          regionName: 'Walsall',
        },
        {
          fullCode: 'GB-WGN',
          regionCode: 'WGN',
          regionName: 'Wigan',
        },
        {
          fullCode: 'GB-WRL',
          regionCode: 'WRL',
          regionName: 'Wirral',
        },
        {
          fullCode: 'GB-WLV',
          regionCode: 'WLV',
          regionName: 'Wolverhampton',
        },
        {
          fullCode: 'GB-BAS',
          regionCode: 'BAS',
          regionName: 'Bath and North East Somerset',
        },
        {
          fullCode: 'GB-BDF',
          regionCode: 'BDF',
          regionName: 'Bedford',
        },
        {
          fullCode: 'GB-BBD',
          regionCode: 'BBD',
          regionName: 'Blackburn with Darwen',
        },
        {
          fullCode: 'GB-BPL',
          regionCode: 'BPL',
          regionName: 'Blackpool',
        },
        {
          fullCode: 'GB-BCP',
          regionCode: 'BCP',
          regionName: 'Bournemouth, Christchurch and Poole',
        },
        {
          fullCode: 'GB-BRC',
          regionCode: 'BRC',
          regionName: 'Bracknell Forest',
        },
        {
          fullCode: 'GB-BNH',
          regionCode: 'BNH',
          regionName: 'Brighton and Hove',
        },
        {
          fullCode: 'GB-BST',
          regionCode: 'BST',
          regionName: 'Bristol, City of',
        },
        {
          fullCode: 'GB-BKM',
          regionCode: 'BKM',
          regionName: 'Buckinghamshire',
        },
        {
          fullCode: 'GB-CBF',
          regionCode: 'CBF',
          regionName: 'Central Bedfordshire',
        },
        {
          fullCode: 'GB-CHE',
          regionCode: 'CHE',
          regionName: 'Cheshire East',
        },
        {
          fullCode: 'GB-CHW',
          regionCode: 'CHW',
          regionName: 'Cheshire West and Chester',
        },
        {
          fullCode: 'GB-CON',
          regionCode: 'CON',
          regionName: 'Cornwall',
        },
        {
          fullCode: 'GB-DAL',
          regionCode: 'DAL',
          regionName: 'Darlington',
        },
        {
          fullCode: 'GB-DER',
          regionCode: 'DER',
          regionName: 'Derby',
        },
        {
          fullCode: 'GB-DUR',
          regionCode: 'DUR',
          regionName: 'Durham, County',
        },
        {
          fullCode: 'GB-ERY',
          regionCode: 'ERY',
          regionName: 'East Riding of Yorkshire',
        },
        {
          fullCode: 'GB-HAL',
          regionCode: 'HAL',
          regionName: 'Halton',
        },
        {
          fullCode: 'GB-HPL',
          regionCode: 'HPL',
          regionName: 'Hartlepool',
        },
        {
          fullCode: 'GB-HEF',
          regionCode: 'HEF',
          regionName: 'Herefordshire',
        },
        {
          fullCode: 'GB-IOW',
          regionCode: 'IOW',
          regionName: 'Isle of Wight',
        },
        {
          fullCode: 'GB-IOS',
          regionCode: 'IOS',
          regionName: 'Isles of Scilly',
        },
        {
          fullCode: 'GB-KHL',
          regionCode: 'KHL',
          regionName: 'Kingston upon Hull',
        },
        {
          fullCode: 'GB-LCE',
          regionCode: 'LCE',
          regionName: 'Leicester',
        },
        {
          fullCode: 'GB-LUT',
          regionCode: 'LUT',
          regionName: 'Luton',
        },
        {
          fullCode: 'GB-MDW',
          regionCode: 'MDW',
          regionName: 'Medway',
        },
        {
          fullCode: 'GB-MDB',
          regionCode: 'MDB',
          regionName: 'Middlesbrough',
        },
        {
          fullCode: 'GB-MIK',
          regionCode: 'MIK',
          regionName: 'Milton Keynes',
        },
        {
          fullCode: 'GB-NEL',
          regionCode: 'NEL',
          regionName: 'North East Lincolnshire',
        },
        {
          fullCode: 'GB-NLN',
          regionCode: 'NLN',
          regionName: 'North Lincolnshire',
        },
        {
          fullCode: 'GB-NNH',
          regionCode: 'NNH',
          regionName: 'North Northamptonshire',
        },
        {
          fullCode: 'GB-NSM',
          regionCode: 'NSM',
          regionName: 'North Somerset',
        },
        {
          fullCode: 'GB-NBL',
          regionCode: 'NBL',
          regionName: 'Northumberland',
        },
        {
          fullCode: 'GB-NGM',
          regionCode: 'NGM',
          regionName: 'Nottingham',
        },
        {
          fullCode: 'GB-PTE',
          regionCode: 'PTE',
          regionName: 'Peterborough',
        },
        {
          fullCode: 'GB-PLY',
          regionCode: 'PLY',
          regionName: 'Plymouth',
        },
        {
          fullCode: 'GB-POR',
          regionCode: 'POR',
          regionName: 'Portsmouth',
        },
        {
          fullCode: 'GB-RDG',
          regionCode: 'RDG',
          regionName: 'Reading',
        },
        {
          fullCode: 'GB-RCC',
          regionCode: 'RCC',
          regionName: 'Redcar and Cleveland',
        },
        {
          fullCode: 'GB-RUT',
          regionCode: 'RUT',
          regionName: 'Rutland',
        },
        {
          fullCode: 'GB-SHR',
          regionCode: 'SHR',
          regionName: 'Shropshire',
        },
        {
          fullCode: 'GB-SLG',
          regionCode: 'SLG',
          regionName: 'Slough',
        },
        {
          fullCode: 'GB-SGC',
          regionCode: 'SGC',
          regionName: 'South Gloucestershire',
        },
        {
          fullCode: 'GB-STH',
          regionCode: 'STH',
          regionName: 'Southampton',
        },
        {
          fullCode: 'GB-SOS',
          regionCode: 'SOS',
          regionName: 'Southend-on-Sea',
        },
        {
          fullCode: 'GB-STT',
          regionCode: 'STT',
          regionName: 'Stockton-on-Tees',
        },
        {
          fullCode: 'GB-STE',
          regionCode: 'STE',
          regionName: 'Stoke-on-Trent',
        },
        {
          fullCode: 'GB-SWD',
          regionCode: 'SWD',
          regionName: 'Swindon',
        },
        {
          fullCode: 'GB-TFW',
          regionCode: 'TFW',
          regionName: 'Telford and Wrekin',
        },
        {
          fullCode: 'GB-THR',
          regionCode: 'THR',
          regionName: 'Thurrock',
        },
        {
          fullCode: 'GB-TOB',
          regionCode: 'TOB',
          regionName: 'Torbay',
        },
        {
          fullCode: 'GB-WRT',
          regionCode: 'WRT',
          regionName: 'Warrington',
        },
        {
          fullCode: 'GB-WBK',
          regionCode: 'WBK',
          regionName: 'West Berkshire',
        },
        {
          fullCode: 'GB-WNH',
          regionCode: 'WNH',
          regionName: 'West Northamptonshire',
        },
        {
          fullCode: 'GB-WIL',
          regionCode: 'WIL',
          regionName: 'Wiltshire',
        },
        {
          fullCode: 'GB-WNM',
          regionCode: 'WNM',
          regionName: 'Windsor and Maidenhead',
        },
        {
          fullCode: 'GB-WOK',
          regionCode: 'WOK',
          regionName: 'Wokingham',
        },
        {
          fullCode: 'GB-YOR',
          regionCode: 'YOR',
          regionName: 'York',
        },
        {
          fullCode: 'GB-ANN',
          regionCode: 'ANN',
          regionName: 'Antrim and Newtownabbey',
        },
        {
          fullCode: 'GB-AND',
          regionCode: 'AND',
          regionName: 'Ards and North Down',
        },
        {
          fullCode: 'GB-ABC',
          regionCode: 'ABC',
          regionName: 'Armagh City, Banbridge and Craigavon',
        },
        {
          fullCode: 'GB-BFS',
          regionCode: 'BFS',
          regionName: 'Belfast City',
        },
        {
          fullCode: 'GB-CCG',
          regionCode: 'CCG',
          regionName: 'Causeway Coast and Glens',
        },
        {
          fullCode: 'GB-DRS',
          regionCode: 'DRS',
          regionName: 'Derry and Strabane',
        },
        {
          fullCode: 'GB-FMO',
          regionCode: 'FMO',
          regionName: 'Fermanagh and Omagh',
        },
        {
          fullCode: 'GB-LBC',
          regionCode: 'LBC',
          regionName: 'Lisburn and Castlereagh',
        },
        {
          fullCode: 'GB-MEA',
          regionCode: 'MEA',
          regionName: 'Mid and East Antrim',
        },
        {
          fullCode: 'GB-MUL',
          regionCode: 'MUL',
          regionName: 'Mid-Ulster',
        },
        {
          fullCode: 'GB-NMD',
          regionCode: 'NMD',
          regionName: 'Newry, Mourne and Down',
        },
        {
          fullCode: 'GB-ABE',
          regionCode: 'ABE',
          regionName: 'Aberdeen City',
        },
        {
          fullCode: 'GB-ABD',
          regionCode: 'ABD',
          regionName: 'Aberdeenshire',
        },
        {
          fullCode: 'GB-ANS',
          regionCode: 'ANS',
          regionName: 'Angus',
        },
        {
          fullCode: 'GB-AGB',
          regionCode: 'AGB',
          regionName: 'Argyll and Bute',
        },
        {
          fullCode: 'GB-CLK',
          regionCode: 'CLK',
          regionName: 'Clackmannanshire',
        },
        {
          fullCode: 'GB-DGY',
          regionCode: 'DGY',
          regionName: 'Dumfries and Galloway',
        },
        {
          fullCode: 'GB-DND',
          regionCode: 'DND',
          regionName: 'Dundee City',
        },
        {
          fullCode: 'GB-EAY',
          regionCode: 'EAY',
          regionName: 'East Ayrshire',
        },
        {
          fullCode: 'GB-EDU',
          regionCode: 'EDU',
          regionName: 'East Dunbartonshire',
        },
        {
          fullCode: 'GB-ELN',
          regionCode: 'ELN',
          regionName: 'East Lothian',
        },
        {
          fullCode: 'GB-ERW',
          regionCode: 'ERW',
          regionName: 'East Renfrewshire',
        },
        {
          fullCode: 'GB-EDH',
          regionCode: 'EDH',
          regionName: 'Edinburgh, City of',
        },
        {
          fullCode: 'GB-ELS',
          regionCode: 'ELS',
          regionName: 'Eilean Siar',
        },
        {
          fullCode: 'GB-FAL',
          regionCode: 'FAL',
          regionName: 'Falkirk',
        },
        {
          fullCode: 'GB-FIF',
          regionCode: 'FIF',
          regionName: 'Fife',
        },
        {
          fullCode: 'GB-GLG',
          regionCode: 'GLG',
          regionName: 'Glasgow City',
        },
        {
          fullCode: 'GB-HLD',
          regionCode: 'HLD',
          regionName: 'Highland',
        },
        {
          fullCode: 'GB-IVC',
          regionCode: 'IVC',
          regionName: 'Inverclyde',
        },
        {
          fullCode: 'GB-MLN',
          regionCode: 'MLN',
          regionName: 'Midlothian',
        },
        {
          fullCode: 'GB-MRY',
          regionCode: 'MRY',
          regionName: 'Moray',
        },
        {
          fullCode: 'GB-NAY',
          regionCode: 'NAY',
          regionName: 'North Ayrshire',
        },
        {
          fullCode: 'GB-NLK',
          regionCode: 'NLK',
          regionName: 'North Lanarkshire',
        },
        {
          fullCode: 'GB-ORK',
          regionCode: 'ORK',
          regionName: 'Orkney Islands',
        },
        {
          fullCode: 'GB-PKN',
          regionCode: 'PKN',
          regionName: 'Perth and Kinross',
        },
        {
          fullCode: 'GB-RFW',
          regionCode: 'RFW',
          regionName: 'Renfrewshire',
        },
        {
          fullCode: 'GB-SCB',
          regionCode: 'SCB',
          regionName: 'Scottish Borders',
        },
        {
          fullCode: 'GB-ZET',
          regionCode: 'ZET',
          regionName: 'Shetland Islands',
        },
        {
          fullCode: 'GB-SAY',
          regionCode: 'SAY',
          regionName: 'South Ayrshire',
        },
        {
          fullCode: 'GB-SLK',
          regionCode: 'SLK',
          regionName: 'South Lanarkshire',
        },
        {
          fullCode: 'GB-STG',
          regionCode: 'STG',
          regionName: 'Stirling',
        },
        {
          fullCode: 'GB-WDU',
          regionCode: 'WDU',
          regionName: 'West Dunbartonshire',
        },
        {
          fullCode: 'GB-WLN',
          regionCode: 'WLN',
          regionName: 'West Lothian',
        },
        {
          fullCode: 'GB-BGW',
          regionCode: 'BGW',
          regionName: 'Blaenau Gwent',
        },
        {
          fullCode: 'GB-BGE',
          regionCode: 'BGE',
          regionName: 'Bridgend [Pen-y-bont ar Ogwr GB-POG]',
        },
        {
          fullCode: 'GB-CAY',
          regionCode: 'CAY',
          regionName: 'Caerphilly [Caerffili GB-CAF]',
        },
        {
          fullCode: 'GB-CRF',
          regionCode: 'CRF',
          regionName: 'Cardiff [Caerdydd GB-CRD]',
        },
        {
          fullCode: 'GB-CMN',
          regionCode: 'CMN',
          regionName: 'Carmarthenshire [Sir Gaerfyrddin GB-GFY]',
        },
        {
          fullCode: 'GB-CGN',
          regionCode: 'CGN',
          regionName: 'Ceredigion [Sir Ceredigion]',
        },
        {
          fullCode: 'GB-CWY',
          regionCode: 'CWY',
          regionName: 'Conwy',
        },
        {
          fullCode: 'GB-DEN',
          regionCode: 'DEN',
          regionName: 'Denbighshire [Sir Ddinbych GB-DDB]',
        },
        {
          fullCode: 'GB-FLN',
          regionCode: 'FLN',
          regionName: 'Flintshire [Sir y Fflint GB-FFL]',
        },
        {
          fullCode: 'GB-GWN',
          regionCode: 'GWN',
          regionName: 'Gwynedd',
        },
        {
          fullCode: 'GB-AGY',
          regionCode: 'AGY',
          regionName: 'Isle of Anglesey [Sir Ynys Môn GB-YNM]',
        },
        {
          fullCode: 'GB-MTY',
          regionCode: 'MTY',
          regionName: 'Merthyr Tydfil [Merthyr Tudful GB-MTU]',
        },
        {
          fullCode: 'GB-MON',
          regionCode: 'MON',
          regionName: 'Monmouthshire [Sir Fynwy GB-FYN]',
        },
        {
          fullCode: 'GB-NTL',
          regionCode: 'NTL',
          regionName: 'Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]',
        },
        {
          fullCode: 'GB-NWP',
          regionCode: 'NWP',
          regionName: 'Newport [Casnewydd GB-CNW]',
        },
        {
          fullCode: 'GB-PEM',
          regionCode: 'PEM',
          regionName: 'Pembrokeshire [Sir Benfro GB-BNF]',
        },
        {
          fullCode: 'GB-POW',
          regionCode: 'POW',
          regionName: 'Powys',
        },
        {
          fullCode: 'GB-RCT',
          regionCode: 'RCT',
          regionName: 'Rhondda Cynon Taff [Rhondda CynonTaf]',
        },
        {
          fullCode: 'GB-SWA',
          regionCode: 'SWA',
          regionName: 'Swansea [Abertawe GB-ATA]',
        },
        {
          fullCode: 'GB-TOF',
          regionCode: 'TOF',
          regionName: 'Torfaen [Tor-faen]',
        },
        {
          fullCode: 'GB-VGL',
          regionCode: 'VGL',
          regionName: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]',
        },
        {
          fullCode: 'GB-WRX',
          regionCode: 'WRX',
          regionName: 'Wrexham [Wrecsam GB-WRC]',
        },
      ],
    },
  ],
  [
    'united states minor outlying islands',
    {
      countryCode: 'UM',
      countryName: 'United States Minor Outlying Islands',
      regions: [
        {
          fullCode: 'UM-81',
          regionCode: '81',
          regionName: 'Baker Island',
        },
        {
          fullCode: 'UM-84',
          regionCode: '84',
          regionName: 'Howland Island',
        },
        {
          fullCode: 'UM-86',
          regionCode: '86',
          regionName: 'Jarvis Island',
        },
        {
          fullCode: 'UM-67',
          regionCode: '67',
          regionName: 'Johnston Atoll',
        },
        {
          fullCode: 'UM-89',
          regionCode: '89',
          regionName: 'Kingman Reef',
        },
        {
          fullCode: 'UM-71',
          regionCode: '71',
          regionName: 'Midway Islands',
        },
        {
          fullCode: 'UM-76',
          regionCode: '76',
          regionName: 'Navassa Island',
        },
        {
          fullCode: 'UM-95',
          regionCode: '95',
          regionName: 'Palmyra Atoll',
        },
        {
          fullCode: 'UM-79',
          regionCode: '79',
          regionName: 'Wake Island',
        },
      ],
    },
  ],
  [
    'uruguay',
    {
      countryCode: 'UY',
      countryName: 'Uruguay',
      regions: [
        {
          fullCode: 'UY-AR',
          regionCode: 'AR',
          regionName: 'Artigas',
        },
        {
          fullCode: 'UY-CA',
          regionCode: 'CA',
          regionName: 'Canelones',
        },
        {
          fullCode: 'UY-CL',
          regionCode: 'CL',
          regionName: 'Cerro Largo',
        },
        {
          fullCode: 'UY-CO',
          regionCode: 'CO',
          regionName: 'Colonia',
        },
        {
          fullCode: 'UY-DU',
          regionCode: 'DU',
          regionName: 'Durazno',
        },
        {
          fullCode: 'UY-FS',
          regionCode: 'FS',
          regionName: 'Flores',
        },
        {
          fullCode: 'UY-FD',
          regionCode: 'FD',
          regionName: 'Florida',
        },
        {
          fullCode: 'UY-LA',
          regionCode: 'LA',
          regionName: 'Lavalleja',
        },
        {
          fullCode: 'UY-MA',
          regionCode: 'MA',
          regionName: 'Maldonado',
        },
        {
          fullCode: 'UY-MO',
          regionCode: 'MO',
          regionName: 'Montevideo',
        },
        {
          fullCode: 'UY-PA',
          regionCode: 'PA',
          regionName: 'Paysandú',
        },
        {
          fullCode: 'UY-RN',
          regionCode: 'RN',
          regionName: 'Río Negro',
        },
        {
          fullCode: 'UY-RV',
          regionCode: 'RV',
          regionName: 'Rivera',
        },
        {
          fullCode: 'UY-RO',
          regionCode: 'RO',
          regionName: 'Rocha',
        },
        {
          fullCode: 'UY-SA',
          regionCode: 'SA',
          regionName: 'Salto',
        },
        {
          fullCode: 'UY-SJ',
          regionCode: 'SJ',
          regionName: 'San José',
        },
        {
          fullCode: 'UY-SO',
          regionCode: 'SO',
          regionName: 'Soriano',
        },
        {
          fullCode: 'UY-TA',
          regionCode: 'TA',
          regionName: 'Tacuarembó',
        },
        {
          fullCode: 'UY-TT',
          regionCode: 'TT',
          regionName: 'Treinta y Tres',
        },
      ],
    },
  ],
  [
    'uzbekistan',
    {
      countryCode: 'UZ',
      countryName: 'Uzbekistan',
      regions: [
        {
          fullCode: 'UZ-AN',
          regionCode: 'AN',
          regionName: 'Andijan',
        },
        {
          fullCode: 'UZ-BU',
          regionCode: 'BU',
          regionName: 'Bukhara',
        },
        {
          fullCode: 'UZ-FA',
          regionCode: 'FA',
          regionName: 'Fergana',
        },
        {
          fullCode: 'UZ-JI',
          regionCode: 'JI',
          regionName: 'Jizzakh',
        },
        {
          fullCode: 'UZ-NG',
          regionCode: 'NG',
          regionName: 'Namangan',
        },
        {
          fullCode: 'UZ-NW',
          regionCode: 'NW',
          regionName: 'Navoiy',
        },
        {
          fullCode: 'UZ-QA',
          regionCode: 'QA',
          regionName: 'Qashqadaryo',
        },
        {
          fullCode: 'UZ-QR',
          regionCode: 'QR',
          regionName: 'Karakalpakstan',
        },
        {
          fullCode: 'UZ-SA',
          regionCode: 'SA',
          regionName: 'Samarqand',
        },
        {
          fullCode: 'UZ-SI',
          regionCode: 'SI',
          regionName: 'Sirdaryo',
        },
        {
          fullCode: 'UZ-SU',
          regionCode: 'SU',
          regionName: 'Surkhondaryo',
        },
        {
          fullCode: 'UZ-TK',
          regionCode: 'TK',
          regionName: 'Tashkent City',
        },
        {
          fullCode: 'UZ-TO',
          regionCode: 'TO',
          regionName: 'Tashkent',
        },
        {
          fullCode: 'UZ-XO',
          regionCode: 'XO',
          regionName: 'Khorezm',
        },
      ],
    },
  ],
  [
    'vanuatu',
    {
      countryCode: 'VU',
      countryName: 'Vanuatu',
      regions: [
        {
          fullCode: 'VU-MAP',
          regionCode: 'MAP',
          regionName: 'Malampa',
        },
        {
          fullCode: 'VU-PAM',
          regionCode: 'PAM',
          regionName: 'Pénama',
        },
        {
          fullCode: 'VU-SAM',
          regionCode: 'SAM',
          regionName: 'Sanma',
        },
        {
          fullCode: 'VU-SEE',
          regionCode: 'SEE',
          regionName: 'Shéfa',
        },
        {
          fullCode: 'VU-TAE',
          regionCode: 'TAE',
          regionName: 'Taféa',
        },
        {
          fullCode: 'VU-TOB',
          regionCode: 'TOB',
          regionName: 'Torba',
        },
      ],
    },
  ],
  [
    'venezuela, bolivarian republic of',
    {
      countryCode: 'VE',
      countryName: 'Venezuela, Bolivarian Republic of',
      regions: [
        {
          fullCode: 'VE-Z',
          regionCode: 'Z',
          regionName: 'Amazonia',
        },
        {
          fullCode: 'VE-B',
          regionCode: 'B',
          regionName: 'Anzoategui',
        },
        {
          fullCode: 'VE-C',
          regionCode: 'C',
          regionName: 'Apure',
        },
        {
          fullCode: 'VE-D',
          regionCode: 'D',
          regionName: 'Aragua',
        },
        {
          fullCode: 'VE-E',
          regionCode: 'E',
          regionName: 'Barinas',
        },
        {
          fullCode: 'VE-F',
          regionCode: 'F',
          regionName: 'Bolivar',
        },
        {
          fullCode: 'VE-G',
          regionCode: 'G',
          regionName: 'Carabobo',
        },
        {
          fullCode: 'VE-H',
          regionCode: 'H',
          regionName: 'Cojedes',
        },
        {
          fullCode: 'VE-Y',
          regionCode: 'Y',
          regionName: 'Amacuro Delta',
        },
        {
          fullCode: 'VE-W',
          regionCode: 'W',
          regionName: 'Federal Dependencies',
        },
        {
          fullCode: 'VE-A',
          regionCode: 'A',
          regionName: 'Caracas',
        },
        {
          fullCode: 'VE-I',
          regionCode: 'I',
          regionName: 'Falcon',
        },
        {
          fullCode: 'VE-J',
          regionCode: 'J',
          regionName: 'Guarico',
        },
        {
          fullCode: 'VE-X',
          regionCode: 'X',
          regionName: 'La Guaira',
        },
        {
          fullCode: 'VE-K',
          regionCode: 'K',
          regionName: 'Lara',
        },
        {
          fullCode: 'VE-L',
          regionCode: 'L',
          regionName: 'Merida',
        },
        {
          fullCode: 'VE-M',
          regionCode: 'M',
          regionName: 'Miranda',
        },
        {
          fullCode: 'VE-N',
          regionCode: 'N',
          regionName: 'Monagas',
        },
        {
          fullCode: 'VE-O',
          regionCode: 'O',
          regionName: 'New Sparta',
        },
        {
          fullCode: 'VE-P',
          regionCode: 'P',
          regionName: 'Portuguesa',
        },
        {
          fullCode: 'VE-R',
          regionCode: 'R',
          regionName: 'Sucre',
        },
        {
          fullCode: 'VE-S',
          regionCode: 'S',
          regionName: 'Tachira',
        },
        {
          fullCode: 'VE-T',
          regionCode: 'T',
          regionName: 'Trujillo',
        },
        {
          fullCode: 'VE-U',
          regionCode: 'U',
          regionName: 'Yaracuy',
        },
        {
          fullCode: 'VE-V',
          regionCode: 'V',
          regionName: 'Zulia',
        },
      ],
    },
  ],
  [
    'viet nam',
    {
      countryCode: 'VN',
      countryName: 'Viet Nam',
      regions: [
        {
          fullCode: 'VN-44',
          regionCode: '44',
          regionName: 'An Giang',
        },
        {
          fullCode: 'VN-43',
          regionCode: '43',
          regionName: 'Bà Rịa - Vũng Tàu',
        },
        {
          fullCode: 'VN-54',
          regionCode: '54',
          regionName: 'Bắc Giang',
        },
        {
          fullCode: 'VN-53',
          regionCode: '53',
          regionName: 'Bắc Kạn',
        },
        {
          fullCode: 'VN-55',
          regionCode: '55',
          regionName: 'Bạc Liêu',
        },
        {
          fullCode: 'VN-56',
          regionCode: '56',
          regionName: 'Bắc Ninh',
        },
        {
          fullCode: 'VN-50',
          regionCode: '50',
          regionName: 'Bến Tre',
        },
        {
          fullCode: 'VN-31',
          regionCode: '31',
          regionName: 'Bình Định',
        },
        {
          fullCode: 'VN-57',
          regionCode: '57',
          regionName: 'Bình Dương',
        },
        {
          fullCode: 'VN-58',
          regionCode: '58',
          regionName: 'Bình Phước',
        },
        {
          fullCode: 'VN-40',
          regionCode: '40',
          regionName: 'Bình Thuận',
        },
        {
          fullCode: 'VN-59',
          regionCode: '59',
          regionName: 'Cà Mau',
        },
        {
          fullCode: 'VN-CT',
          regionCode: 'CT',
          regionName: 'Cần Thơ',
        },
        {
          fullCode: 'VN-04',
          regionCode: '04',
          regionName: 'Cao Bằng',
        },
        {
          fullCode: 'VN-DN',
          regionCode: 'DN',
          regionName: 'Đà Nẵng',
        },
        {
          fullCode: 'VN-33',
          regionCode: '33',
          regionName: 'Đắk Lắk',
        },
        {
          fullCode: 'VN-72',
          regionCode: '72',
          regionName: 'Đắk Nông',
        },
        {
          fullCode: 'VN-71',
          regionCode: '71',
          regionName: 'Điện Biên',
        },
        {
          fullCode: 'VN-39',
          regionCode: '39',
          regionName: 'Đồng Nai',
        },
        {
          fullCode: 'VN-45',
          regionCode: '45',
          regionName: 'Đồng Tháp',
        },
        {
          fullCode: 'VN-30',
          regionCode: '30',
          regionName: 'Gia Lai',
        },
        {
          fullCode: 'VN-03',
          regionCode: '03',
          regionName: 'Hà Giang',
        },
        {
          fullCode: 'VN-63',
          regionCode: '63',
          regionName: 'Hà Nam',
        },
        {
          fullCode: 'VN-HN',
          regionCode: 'HN',
          regionName: 'Hà Nội',
        },
        {
          fullCode: 'VN-23',
          regionCode: '23',
          regionName: 'Hà Tĩnh',
        },
        {
          fullCode: 'VN-61',
          regionCode: '61',
          regionName: 'Hải Dương',
        },
        {
          fullCode: 'VN-HP',
          regionCode: 'HP',
          regionName: 'Hải Phòng',
        },
        {
          fullCode: 'VN-73',
          regionCode: '73',
          regionName: 'Hậu Giang',
        },
        {
          fullCode: 'VN-SG',
          regionCode: 'SG',
          regionName: 'Hồ Chí Minh(local variant is Sai Gon)',
        },
        {
          fullCode: 'VN-14',
          regionCode: '14',
          regionName: 'Hòa Bình',
        },
        {
          fullCode: 'VN-66',
          regionCode: '66',
          regionName: 'Hưng Yên',
        },
        {
          fullCode: 'VN-34',
          regionCode: '34',
          regionName: 'Khánh Hòa',
        },
        {
          fullCode: 'VN-47',
          regionCode: '47',
          regionName: 'Kiến Giang',
        },
        {
          fullCode: 'VN-28',
          regionCode: '28',
          regionName: 'Kon Tum',
        },
        {
          fullCode: 'VN-01',
          regionCode: '01',
          regionName: 'Lai Châu',
        },
        {
          fullCode: 'VN-35',
          regionCode: '35',
          regionName: 'Lâm Đồng',
        },
        {
          fullCode: 'VN-09',
          regionCode: '09',
          regionName: 'Lạng Sơn',
        },
        {
          fullCode: 'VN-02',
          regionCode: '02',
          regionName: 'Lào Cai',
        },
        {
          fullCode: 'VN-41',
          regionCode: '41',
          regionName: 'Long An',
        },
        {
          fullCode: 'VN-67',
          regionCode: '67',
          regionName: 'Nam Định',
        },
        {
          fullCode: 'VN-22',
          regionCode: '22',
          regionName: 'Nghệ An',
        },
        {
          fullCode: 'VN-18',
          regionCode: '18',
          regionName: 'Ninh Bình',
        },
        {
          fullCode: 'VN-36',
          regionCode: '36',
          regionName: 'Ninh Thuận',
        },
        {
          fullCode: 'VN-68',
          regionCode: '68',
          regionName: 'Phú Thọ',
        },
        {
          fullCode: 'VN-32',
          regionCode: '32',
          regionName: 'Phú Yên',
        },
        {
          fullCode: 'VN-24',
          regionCode: '24',
          regionName: 'Quảng Bình',
        },
        {
          fullCode: 'VN-27',
          regionCode: '27',
          regionName: 'Quảng Nam',
        },
        {
          fullCode: 'VN-29',
          regionCode: '29',
          regionName: 'Quảng Ngãi',
        },
        {
          fullCode: 'VN-13',
          regionCode: '13',
          regionName: 'Quảng Ninh',
        },
        {
          fullCode: 'VN-25',
          regionCode: '25',
          regionName: 'Quảng Trị',
        },
        {
          fullCode: 'VN-52',
          regionCode: '52',
          regionName: 'Sóc Trăng',
        },
        {
          fullCode: 'VN-05',
          regionCode: '05',
          regionName: 'Sơn La',
        },
        {
          fullCode: 'VN-37',
          regionCode: '37',
          regionName: 'Tây Ninh',
        },
        {
          fullCode: 'VN-20',
          regionCode: '20',
          regionName: 'Thái Bình',
        },
        {
          fullCode: 'VN-69',
          regionCode: '69',
          regionName: 'Thái Nguyên',
        },
        {
          fullCode: 'VN-21',
          regionCode: '21',
          regionName: 'Thanh Hóa',
        },
        {
          fullCode: 'VN-26',
          regionCode: '26',
          regionName: 'Thừa Thiên-Huế',
        },
        {
          fullCode: 'VN-46',
          regionCode: '46',
          regionName: 'Tiền Giang',
        },
        {
          fullCode: 'VN-51',
          regionCode: '51',
          regionName: 'Trà Vinh',
        },
        {
          fullCode: 'VN-07',
          regionCode: '07',
          regionName: 'Tuyên Quang',
        },
        {
          fullCode: 'VN-49',
          regionCode: '49',
          regionName: 'Vĩnh Long',
        },
        {
          fullCode: 'VN-70',
          regionCode: '70',
          regionName: 'Vĩnh Phúc',
        },
        {
          fullCode: 'VN-06',
          regionCode: '06',
          regionName: 'Yên Bái',
        },
      ],
    },
  ],
  [
    'wallis and futuna',
    {
      countryCode: 'WF',
      countryName: 'Wallis and Futuna',
      regions: [
        {
          fullCode: 'WF-AL',
          regionCode: 'AL',
          regionName: 'Alo',
        },
        {
          fullCode: 'WF-SG',
          regionCode: 'SG',
          regionName: 'Sigave',
        },
        {
          fullCode: 'WF-UV',
          regionCode: 'UV',
          regionName: 'Uvea',
        },
      ],
    },
  ],
  [
    'yemen',
    {
      countryCode: 'YE',
      countryName: 'Yemen',
      regions: [
        {
          fullCode: 'YE-AD',
          regionCode: 'AD',
          regionName: 'Aden',
        },
        {
          fullCode: 'YE-AM',
          regionCode: 'AM',
          regionName: 'Amran',
        },
        {
          fullCode: 'YE-AB',
          regionCode: 'AB',
          regionName: 'Abyan',
        },
        {
          fullCode: 'YE-DA',
          regionCode: 'DA',
          regionName: 'Dhale',
        },
        {
          fullCode: 'YE-BA',
          regionCode: 'BA',
          regionName: 'Beida',
        },
        {
          fullCode: 'YE-HU',
          regionCode: 'HU',
          regionName: 'Western Coast',
        },
        {
          fullCode: 'YE-JA',
          regionCode: 'JA',
          regionName: 'Jouf',
        },
        {
          fullCode: 'YE-MR',
          regionCode: 'MR',
          regionName: 'Mahra',
        },
        {
          fullCode: 'YE-MW',
          regionCode: 'MW',
          regionName: 'Mahwit',
        },
        {
          fullCode: 'YE-SA',
          regionCode: 'SA',
          regionName: 'Sanaa City',
        },
        {
          fullCode: 'YE-SU',
          regionCode: 'SU',
          regionName: 'Socotra',
        },
        {
          fullCode: 'YE-DH',
          regionCode: 'DH',
          regionName: 'Dhamar',
        },
        {
          fullCode: 'YE-HD',
          regionCode: 'HD',
          regionName: 'Hadhramaut',
        },
        {
          fullCode: 'YE-HJ',
          regionCode: 'HJ',
          regionName: 'Hajjah',
        },
        {
          fullCode: 'YE-IB',
          regionCode: 'IB',
          regionName: 'Ibb',
        },
        {
          fullCode: 'YE-LA',
          regionCode: 'LA',
          regionName: 'Lahij',
        },
        {
          fullCode: 'YE-MA',
          regionCode: 'MA',
          regionName: 'Marib',
        },
        {
          fullCode: 'YE-RA',
          regionCode: 'RA',
          regionName: 'Raymah',
        },
        {
          fullCode: 'YE-SD',
          regionCode: 'SD',
          regionName: 'Saada',
        },
        {
          fullCode: 'YE-SN',
          regionCode: 'SN',
          regionName: 'Sanaa',
        },
        {
          fullCode: 'YE-SH',
          regionCode: 'SH',
          regionName: 'Shabwah',
        },
        {
          fullCode: 'YE-TA',
          regionCode: 'TA',
          regionName: 'Taiz',
        },
      ],
    },
  ],
  [
    'zambia',
    {
      countryCode: 'ZM',
      countryName: 'Zambia',
      regions: [
        {
          fullCode: 'ZM-02',
          regionCode: '02',
          regionName: 'Central',
        },
        {
          fullCode: 'ZM-08',
          regionCode: '08',
          regionName: 'Copperbelt',
        },
        {
          fullCode: 'ZM-03',
          regionCode: '03',
          regionName: 'Eastern',
        },
        {
          fullCode: 'ZM-04',
          regionCode: '04',
          regionName: 'Luapula',
        },
        {
          fullCode: 'ZM-09',
          regionCode: '09',
          regionName: 'Lusaka',
        },
        {
          fullCode: 'ZM-10',
          regionCode: '10',
          regionName: 'Muchinga',
        },
        {
          fullCode: 'ZM-06',
          regionCode: '06',
          regionName: 'North-Western',
        },
        {
          fullCode: 'ZM-05',
          regionCode: '05',
          regionName: 'Northern',
        },
        {
          fullCode: 'ZM-07',
          regionCode: '07',
          regionName: 'Southern',
        },
        {
          fullCode: 'ZM-01',
          regionCode: '01',
          regionName: 'Western',
        },
      ],
    },
  ],
  [
    'zimbabwe',
    {
      countryCode: 'ZW',
      countryName: 'Zimbabwe',
      regions: [
        {
          fullCode: 'ZW-BU',
          regionCode: 'BU',
          regionName: 'Bulawayo',
        },
        {
          fullCode: 'ZW-HA',
          regionCode: 'HA',
          regionName: 'Harare',
        },
        {
          fullCode: 'ZW-MA',
          regionCode: 'MA',
          regionName: 'Manicaland',
        },
        {
          fullCode: 'ZW-MC',
          regionCode: 'MC',
          regionName: 'Mashonaland Central',
        },
        {
          fullCode: 'ZW-ME',
          regionCode: 'ME',
          regionName: 'Mashonaland East',
        },
        {
          fullCode: 'ZW-MW',
          regionCode: 'MW',
          regionName: 'Mashonaland West',
        },
        {
          fullCode: 'ZW-MV',
          regionCode: 'MV',
          regionName: 'Masvingo',
        },
        {
          fullCode: 'ZW-MN',
          regionCode: 'MN',
          regionName: 'Matabeleland North',
        },
        {
          fullCode: 'ZW-MS',
          regionCode: 'MS',
          regionName: 'Matabeleland South',
        },
        {
          fullCode: 'ZW-MI',
          regionCode: 'MI',
          regionName: 'Midlands',
        },
      ],
    },
  ],
]);

// cspell:enable
