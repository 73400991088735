import {
  type CountryIso3166,
  ISO_3166_COUNTRY_DATA,
  type RegionIso3166,
} from '../../../data/countriesIso3166';

export const UNITED_STATES_ALIASES: Set<string> = new Set([
  'United States of America',
  'United States',
  'USA',
  'U.S.A.',
  'US',
  'U.S.',
]);

export const UNITED_STATES_OF_AMERICA = 'United States of America';
export const CANADA = 'Canada';
export const UNITED_STATES_COUNTRY_CODE = 'US';

// Account for possible bad data in the DB with U.S. aliases
function normalizedLowerCaseCountryName(query: string): string {
  const lowerCasedQuery = query.trim().toLowerCase();
  for (const alias of UNITED_STATES_ALIASES) {
    if (alias.toLowerCase() === lowerCasedQuery) {
      return UNITED_STATES_OF_AMERICA.toLowerCase();
    }
  }
  return lowerCasedQuery;
}

export function findCountryByCodeOrName(
  query: string,
): CountryIso3166 | undefined {
  if (!query) {
    return undefined;
  }

  const normalizedQuery = normalizedLowerCaseCountryName(query.trim());
  const country = ISO_3166_COUNTRY_DATA.get(normalizedQuery);

  if (country) {
    return country;
  }

  // If not found by code, search by full country name
  for (const [, value] of ISO_3166_COUNTRY_DATA.entries()) {
    if (value.countryCode.toLowerCase() === normalizedQuery.toLowerCase()) {
      return value;
    }
  }

  return undefined;
}

export function findRegionByCountry(
  countryQuery: string, // name or code
  regionQuery: string, // fullCode, regionName, or regionCode
): RegionIso3166 | undefined {
  const country = findCountryByCodeOrName(countryQuery.trim());
  const regionVal = regionQuery.trim().toLowerCase();
  if (country) {
    return country.regions.find(
      (region) =>
        region.fullCode.toLowerCase() === regionVal ||
        region.regionName.toLowerCase() === regionVal ||
        region.regionCode.toLowerCase() === regionVal,
    );
  }
  return undefined;
}

export type Iso3166Country = {
  countryName: string;
  regions: {
    regionName: string;
    regionCode: string;
    fullCode: string;
  }[];
};

export function transformIso3166CountriesToList(): Iso3166Country[] {
  // Prioritize United States and Canada to appear first in the list
  const priorityOrder: { [key: string]: number } = {
    [UNITED_STATES_OF_AMERICA]: 1,
    [CANADA]: 2,
  };

  const countryAndRegionList = Array.from(ISO_3166_COUNTRY_DATA.values()).map(
    (country) => ({
      countryName: country.countryName,
      regions: country.regions.map((region) => ({
        regionName: region.regionName,
        regionCode: region.regionCode,
        fullCode: region.fullCode,
      })),
    }),
  );

  // Business Rules for sorting:
  // 1. United States is 1st
  // 2. Canada is 2nd
  // 3. sorted alphabetically after that
  if (
    countryAndRegionList[0].countryName === UNITED_STATES_OF_AMERICA &&
    countryAndRegionList[1].countryName === CANADA &&
    countryAndRegionList[2].countryName === 'Afghanistan'
  ) {
    return countryAndRegionList;
  }

  // Otherwise, sort the list putting USA first and Canada 2nd
  // this is a fail safe in case the list is ever scraped again and whomever does it forgets to sort based on above rules
  return countryAndRegionList.sort((a, b) => {
    // Check for special sorting cases
    const aPriority = priorityOrder[a.countryName] || 999;
    const bPriority = priorityOrder[b.countryName] || 999;

    // Sort by priority if different, otherwise by alphabetical order
    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }

    // Default sorting by country name
    return a.countryName.localeCompare(b.countryName);
  });
}

export function getCountryRegions(country: string | undefined): string[] {
  if (!country) {
    return [];
  }

  return (
    transformIso3166CountriesToList()
      .find((c) => c.countryName === country)
      ?.regions.map((r) => r.regionName) || ([] as string[])
  );
}

export function isValidCountryAndRegionForIso3116(
  country: string,
  region: string,
): boolean {
  const isoCountry = findCountryByCodeOrName(country);
  if (!isoCountry) {
    return false;
  }

  const countryRegion = findRegionByCountry(country, region);

  return !!countryRegion;
}
